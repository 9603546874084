<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
        <h2 style="text-align: center;color: #ff0033;">{{ title }}</h2>
        <div style="overflow: scroll;" v-html="mDataTable"></div>
      <br />
    </v-container>
  </template>
  
  <script>
    import api from "@/services/lambdaApi";
    import moment from 'moment'
  
    export default {
      name: "deliverystatus",
    //   components: {
    //   },
      data () {
        return {
          loading: false,
          title: "",
          mDataArray: [],
          mDataTable: ""
        }
      },
      async mounted () {
        try {
          this.loading = true;
          await this.loadData();

          setInterval(this.loadData, 120000);
          // } catch (error) {
        } finally {
          this.loading = false;
        }
      },
      methods: {
          async mapData() {
              if (this.mDataArray) {
                this.mDataTable = `<table border="1" cellpadding="0" cellspacing="0" style="border-color:#3399CC;font-family: Tahoma;">`;

                // Header
                this.mDataTable += `<tr>`;
                this.mDataArray.forEach(items => {
                    this.mDataTable += `<td valign="middle" align="center" style="font-size:20px;font-weight:bold;">`;
                    this.mDataTable += `<div>${items[0].deliveryTime}</div>`;
                    this.mDataTable += `<div style="text-align:right;color:red;">${items.length}&nbsp;&nbsp;</div>`;
                    this.mDataTable += "</td>";
                });
                this.mDataTable += `</tr>`;

                // Detail
                this.mDataTable += `<tr>`;
                this.mDataArray.forEach(items => {
                    this.mDataTable += `<td style="vertical-align:top;padding:0px;">`;
                    items.forEach(item => {
                        this.mDataTable += `<table width="100%" border="0" cellpadding="0" cellspacing="0">`;
                        this.mDataTable += `<tr>`;
                        this.mDataTable += `<td width="95%" style="border:1px solid #3399CC;color:#ffffff;background-color:${item.orderColor};">`;
                        this.mDataTable += `<span style='font-size: 10px'>${item.branchName}</span>`;
                        this.mDataTable += `<br/><span style='font-size: 18px'>#${item.orderId}</span>`;
                        this.mDataTable += `<br/><span>${item.customerName}</span>`;
                        // this.mDataTable += `<br/><span>${item.phoneNumber}</span>`;
                        this.mDataTable += `<br/><span>${this.numberFormat(item.grandTotal, 2)}</span>`;
                        this.mDataTable += "</td>";
                        this.mDataTable += `<td width="5%" style="border:1px solid #3399CC;color:#ffffff;background-color:${item.shippingColor};">&nbsp</td>`;
                        this.mDataTable += `</tr>`;
                        this.mDataTable += `</table>`;
                    });
                    this.mDataTable += "</td>";
                });
                this.mDataTable += `</tr>`;
                
                this.mDataTable += "</table>";
              }
          },
          numberFormat(value, decimal) {
            return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
          },
          async loadData () {
              try {
                  // this.loading = true;

                  let branchId = "";
                  if (this.userBrcode != "") {
                    branchId = this.userBrcode
                  }
                  else {
                    branchId = this.$route.params?.b ?? "";
                    if (branchId == "0") 
                      branchId = "";
                  }

                  let deliveyDate = this.$route.params?.d ?? "";
                  if (deliveyDate == "") {
                    deliveyDate = moment(this.currentDate).format('YYYY-MM-DD');
                  }

                  let filter = { branchId: branchId, deliveryDate: deliveyDate };
                  let result = await api.getDeliveryStatus(filter);
                  // console.log('-- getDeliveryStatus --', result.data)
                  this.title = result?.data?.title ?? "";
                  this.mDataArray = result?.data?.data ?? [];
                  await this.mapData();
              } catch (error) {
                  alert("ไม่สามารถอัพเดทข้อมูลได้ กรุณากดปุ่ม ok แล้วเปิดหน้า Order Status อีกครั้ง");
                  // alert(error);
              } finally {
                  // this.loading = false;
              }
          },
      },
  
      computed: {
        currentDate() {
          // return "2021-08-09";
          return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        },
        userCode() {
          return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
        },
        userGroup() {
          return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
        },
        userType() {
          return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
        },
        userBrcode() {
          return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
        },
      }
    }
  </script>
  
  <style scoped>
/* table.fixed { table-layout:fixed; }
table.fixed td { overflow: hidden; } */
  /* table {
    table-layout: fixed;
    width: 100%;
  }
  td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid red;
  } */
  </style>