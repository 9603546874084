<template>
  <v-container>
    <v-row v-if="loading"
      width="100%"
      class="fill-height ma-0"
      align-center
      justify="center">
      <v-progress-circular
        indeterminate
        color="primary"
        width="6"
        size="64"
      ></v-progress-circular>
    </v-row>
    <v-row class="justify-center ma-2" v-else>
      <v-card style="width:400px;">
        <v-img
          class="white--text align-end"
          src="@/assets/login_header.jpg"
          height="200px">
          <v-card-title primary-title>
            Login
          </v-card-title>
        </v-img>
        <v-card-text>
          <v-form @submit.prevent="submit">
            <!-- Username -->
            <v-text-field
              name="username"
              label="Username"
              id="username"
              autocomplete="username"
              v-model="account.username"/>

            <!-- Password -->
            <v-text-field
              name="password"
              label="Password"
              id="password"
              autocomplete="password"
              v-model="account.password"
              :append-icon="isShowPassword ? 'visibility' : 'visibility_off'"
              @click:append="isShowPassword = !isShowPassword"
              :type="isShowPassword ? 'text' : 'password'"
              counter/>

            <v-row class="justify-space-between px-3 pt-5">
              <!-- <v-btn text @click.prevent="$router.push('/forgotpassword')">Forgot Password</v-btn> -->
              <v-spacer></v-spacer>
              <v-btn type="submit" color="primary" :disabled="!account.username || !account.password">Login</v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
// import api from "@/services/lambdaApi";

export default {
  mounted() {
    // if (api.isLoggedIn()) {
    //   this.$router.push("/home");
    // }
    if (this.$store.state.isLogged) {
      try {
        this.loading = true;
        this.$router.push("/home", () => {});
      } catch (error) {
        console.log(error.toString())
      } finally {
        // this.loading = false;
      }
    }
  },
  data() {
    return {
      loading: false,
      isShowPassword: false,
      account: {
        username: "",
        password: ""
      }
    };
  },
  methods: {
    submit() {
      if (this.account.username == null || this.account.username == "") {
        alert('Please enter username')
        return;
      };
      if (this.account.password == null || this.account.password == "") {
        alert('Please enter password')
        return;
      };
      // this.$router.push("/product/index");
      // this.$router.push("/home");
      this.$store.dispatch({
        type: "doLogin",
        username: this.account.username,
        password: this.account.password
      });
    }
  }
};
</script>

<style></style>
