<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row>
      <v-col lg="6" md="6" sm="12" cols="12">
        <div><h3>Owner Ids</h3></div>
        <div class="row mt-n1" v-if="ownerIds != null">
            <v-col cols="10">
              <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
              <div>import column header (ownerId)</div>
            </v-col>
            <v-col cols="2">
              <v-icon @click="ownerIds=[]">delete</v-icon>
            </v-col>
        </div>
        <div class="row mt-n1" v-if="ownerIds != null">
            <v-col cols="12">
              <div class="table_sub">
                <table class="table table-striped" width="100%">
                  <thead class="head-dark" align-center>
                    <tr>
                      <th width="90%" style="text-align:left">Owner Id</th>
                      <th width="10%" style="text-align:left">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                      <template v-for="item in ownerIds">
                      <tr>
                          <td>{{ item }}</td>
                          <td>
                            <v-icon @click="removeItem(item, ownerIds)">delete</v-icon>
                          </td>
                      </tr>
                      </template>
                  </tbody>
                </table>
              </div>
            </v-col>
        </div>
      </v-col>

      <v-col lg="3" md="3" sm="12" cols="12">
        <v-btn class="mt-3" @click="loadData" color="primary">
        <v-icon left>search</v-icon>
        <span>Query</span>
        </v-btn>
        <v-btn class="ml-3 mt-3" @click="exportClick" color="primary">
          <v-icon left>save</v-icon>
          <span>Export</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="mt-1">
        <v-data-table
            height="320"
            fixed-header
            :headers="headers"
            :items="mDataArray"
            sort-by="userId"
            :sort-desc="false"
            :items-per-page="15">
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.userId }}</td>
            <td>{{ item.cardType }}</td>
          </tr>
        </template>
        </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import api from "@/services/lambdaApi";
  import moment from 'moment'
  import XLSX from 'xlsx';

  export default {
    name: "customersavedcard",
    data () {
      return {
        loading: false,
        ownerIds: [],
        mDataArray: [],
        headers: [
          { text: "User Id", value: "userId" },
          { text: "Card Type", value: "cardType" }
        ],
      }
    },
    // async mounted () {
    // },
    methods: {
      removeItem: function (value, objectList) {
        let item = objectList.find(d => d === value);
        if (item) {
          let index = objectList.indexOf(item);
          if (index >= 0) {
            objectList.splice(index, 1)
          }
        }
      },
      onFileImportSelected(e) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles(file) { // detect file event
          var reader = new FileReader();
          var vm = this;
          reader.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, { type: "array" });
              let sheetName = workbook.SheetNames[0];
              /* DO SOMETHING WITH workbook HERE */
              // console.log(workbook);
              let worksheet = workbook.Sheets[sheetName];
              // console.log(XLSX.utils.sheet_to_json(worksheet));
              let items = XLSX.utils.sheet_to_json(worksheet);
              // console.log('-- items --', items);
              items.forEach(item => {
                var mItem = vm.ownerIds.find(d => d === item.ownerId);
                if (!mItem) {
                  vm.ownerIds.push(item.ownerId.toString() ?? "");
                }
              });
          };
          reader.readAsArrayBuffer(file);
      },
      async exportClick() {
        try {
            this.loading = true;
            let cdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            const dataWS = XLSX.utils.json_to_sheet(this.mDataArray)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, dataWS)
            XLSX.writeFile(wb,`customersavedcard_${moment(cdate).format('YYYYMMDD')}.xlsx`)
        } catch (error) {
            alert(error)
        } finally {
            this.loading = false;
        }
      },
      async loadData () {
          try {
              if (this.ownerIds == null || this.ownerIds.length == 0) {
                  alert('Please enter ownerIds')
                  return;
              };
              this.loading = true;
              let result = await api.getCardType(this.ownerIds);
              // console.log("--- data list ---", result.data);
              this.mDataArray = result.data;
          } catch (error) {
              alert(error.response.data);
          } finally {
              this.loading = false;
          }
      },
    },

    computed: {
      userCode() {
        return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
      },
      userGroup() {
        return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
      },
      userType() {
        return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
      },
      userBrcode() {
        return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
      },
    }
  }
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
.table_sub {
  height: 200px;
  overflow: auto;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>