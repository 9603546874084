import axios from "axios";
import { apiUrl, NOT_CONNECT_NETWORK, NETWORK_CONNECTION_MESSAGE } from "@/services/constants";
import router from "@/router";
import auth from "@/services/auth";

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

// axios.interceptors.request.use(async config => {
//   if (!isAbsoluteURLRegex.test(config.url)) {
//     //const userToken = localStorage.getItem(server.TOKEN_KEY);
//     config.headers = { 
//       'Access-Control-Allow-Origin': '*',
//       'Content-Type': 'application/json' 
//     };
//     //config.headers =  { "Access-Control-Allow-Origin": "*" }
//     // if (userToken) {
//     //   config.headers = { "x-access-token": userToken };
//     // }
//     config.url = apiUrl + "/" + config.url;
//   }
//   // console.log('-- httpClient --', config);
//   config.timeout = 0; //600000; //60000; //10000; // 10 Second
//   return config;
// });

// change to cognito
axios.interceptors.request.use(async config => {
  if (!isAbsoluteURLRegex.test(config.url)) {

    config.headers = {
      'Access-Control-Allow-Origin': `*`, 
      'Content-Type': `application/json`
    };

    if (config.url != 'refresh_token' && config.url != 'sign_in' && config.url != 'login') {
      const token = await auth.getToken();
      // console.log("token -- ", token, " --");
      if (token != null && token.toString().trim() != "") {
        config.headers['Authorization'] = `${token}`
      }
    };

    // console.log("config.headers -- ", config.headers, " --");
    // console.log("config.url -- ", config.url, " --");

    config.url = apiUrl + "/" + config.url;
  }

  // console.log('-- httpClient --', config);
  config.timeout = 0; //600000; //60000; //10000; // 10 Second
  return config;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log(JSON.stringify(error, undefined, 2));
    router.push("/");
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (!error.response) {
      return Promise.reject({
        code: NOT_CONNECT_NETWORK,
        message: NETWORK_CONNECTION_MESSAGE
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
