<template>
  <v-card ripple id="Summary">
    <v-list-item four-line>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">{{title}}</v-list-item-title>
        <v-list-item-subtitle class="title">Total : {{total_value}}</v-list-item-subtitle>
        <v-list-item-subtitle class="title">Online : {{online_value}}</v-list-item-subtitle>
        <v-list-item-subtitle class="title">TeleSale : {{telesale_value}}</v-list-item-subtitle>
        <v-list-item-subtitle class="title">Lazada : {{lazada_value}}</v-list-item-subtitle>
        <v-list-item-subtitle class="title">Shopee : {{shopee_value}}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar tile size="40" :color="avatar_bg">
        <v-icon color="white"> {{avatar_ic}} </v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "Summary",
  props: ["title", "total_value", "online_value", "telesale_value", "lazada_value", "shopee_value", "avatar_bg", "avatar_ic"]
};
</script>

<style></style>