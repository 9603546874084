<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-alert
      v-model="show_success"
      dismissible
      outlined
      text
      type="success"
    >Update ข้อมูลเว็บ เรียบร้อยแล้ว</v-alert>
    <v-alert
      v-model="show_error"
      dismissible
      outlined
      text
      type="error"
    >Update ข้อมูลเว็บ ไม่สำเร็จ!!</v-alert>
    <!-- Table section -->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="couponId"
        :sort-desc="true"
        :items-per-page="5"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Coupon</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              @keyup.enter="searchClick"
            ></v-text-field>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>
            <v-btn @click="refreshPromotion" color="green darken-2" dark class="mb-2">
              <v-icon left>refresh</v-icon>
              <span>Update Web</span>
            </v-btn>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn @click="newPromotion" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>New Coupon</span>
            </v-btn>
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.couponId }}</td>
            <td>{{ item.couponName }}</td>
            <td>{{ item.couponDescription }}</td>
            <td>{{ item.startDate }}</td>
            <td>{{ item.endDate }}</td>
            <td>
              <v-checkbox readonly v-model="item.active"></v-checkbox>
            </td>
            <td>
              <v-icon class="mr-2" @click="editItem(item)">
                edit
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";

export default {
  name: "promotion",
  data() {
    return {
      loading: false,
      search: "",
      show_success: false,
      show_error: false,
      enabled: true,
      mDataArray: [],
      headers: [
        { text: "Coupon Id", value: "couponId" },
        { text: "Coupon Name", value: "couponName" },
        { text: "Description", value: "couponDescription" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        {
          text: "Active",
          align: "left",
          // sortable: false,
          value: "active",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      this.loadCoupon();
  },
  methods: {
    searchClick() {
      this.loadCoupon();
    },
    editItem(item) {
      this.$router.push(`/promotion/edit/${item.couponId}`);
    },
    async refreshPromotion() {
      try {
        this.loading = true;
        let response = await api.refreshCoupon({});
        console.log("--- refreshCoupon ---", response.data);
        if (response.data.statusCode == 200) {
          this.show_error = false;
          this.show_success = true;
        } else {
          this.show_error = true;
          this.show_success = false;
        }
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    newPromotion() {
      this.$router.push("/promotion/edit/0");
    },
    async loadCoupon() {
      try {
        this.loading = true;
        let data = { couponName: this.search }
        let result = await api.getCouponList(data);
        this.mDataArray = result.data;
        // console.log("--- coupon list ---", result.data);
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>