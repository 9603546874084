<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="article_id"
        :sort-desc="true"
        :items-per-page="5"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Article</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              clearable
              v-model="search"
              label="Search"
              single-line
              hide-details
              @keyup.enter="searchClick"
            ></v-text-field>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>
            <v-btn @click="newPromotion" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>New Article</span>
            </v-btn>
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-img
                contain
                class="ma-2"
                :eager="true"
                :src="item.imageUrl"
                lazy-src="@/assets/noimage.png"
                aspect-ratio="1"
                min-width="80"
                max-width="80"
                max-height="80"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      v-show="loading"
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </td>
            <td>{{ item.article_id }}</td>
            <td>{{ item.article_name_en }}</td>
            <td>{{ item.article_name_th }}</td>
            <td>{{ item.artiale_type_en }}</td>
            <td>{{ item.artiale_type_th }}</td>
            <td>
              <v-icon class="mr-2" @click="editItem(item)">
                edit
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";

export default {
  name: "articles",
  data() {
    return {
      loading: false,
      search: "",
      mDataArray: [],
      headers: [
        {
          text: "Image",
          align: "left",
          sortable: false,
          value: "imageUrl",
        },
        { text: "Article Id", value: "article_id" },
        { text: "Article Name (EN)", value: "article_name_en" },
        { text: "Article Name (TH)", value: "article_name_th" },
        { text: "Article Type (EN)", value: "artiale_type_en" },
        { text: "Article Type (TH)", value: "artiale_type_th" },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      this.loadArticle();
  },
  methods: {
    searchClick() {
      this.loadArticle();
    },
    editItem(item) {
      this.$router.push(`/article/edit/${item.article_id}`);
    },
    newPromotion() {
      this.$router.push("/article/edit/0");
    },
    async loadArticle() {
      try {
        this.loading = true;
        let data = { searchText: this.search ?? "" }
        let result = await api.getArticle(data);
        this.mDataArray = result.data;
        // console.log("--- article list ---", result.data);
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>