<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="80"
          :width="8"
          color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-card class="mx-0 pa-5" outlined>
            <v-form @submit.prevent="submit">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="voucher.voucherCode"
                    label="Voucher Code"
                    disabled
                  ></v-text-field>

                  <v-text-field
                    outlined
                    v-model="voucher.orderId"
                    label="Order Id"
                    @keyup="uppercase"
                    :disabled="voucher.voucherCode!=''"
                  ></v-text-field>

                  <v-text-field
                    outlined
                    v-model="voucher.requestDesc"
                    label="Remark"
                  ></v-text-field>
  
                  <v-text-field
                    outlined
                    v-model="voucher.amount"
                    label="Amount"
                    type="number"
                    class="inputNumber"
                  ></v-text-field>

                  <v-text-field
                    outlined
                    v-model="voucher.requestCode"
                    label="Request By"
                    disabled
                  ></v-text-field>

                  <v-text-field
                    outlined
                    v-model="voucher.createdDate"
                    label="Request Date"
                    disabled
                  ></v-text-field>

                  <v-checkbox 
                    readonly 
                    v-model="voucher.voucherFlag"
                    label="Account Confirm">
                  </v-checkbox>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <br />
              <v-row>
                <v-btn class="mr-4" @click="cancel">
                  Cancel
                </v-btn>
  
                <v-btn class="mr-4" color="primary" type="submit" :disabled="voucher.voucherFlag!=0">
                  Confirm
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import api from "@/services/lambdaApi";
  
  export default {
    name: "edit-request",
    // components:{
    // },
    data: () => ({
      loading: false,
      voucher: {
        voucherCode: "",
        orderId: "",
        amount: 0,
        requestCode: "",
        requestDesc: "",
        createdDate: "",
        voucherFlag: 0,
      }
    }),
    async mounted() {
      try {
        this.loading = true;

        let voucherCode = (this.$route.params.id ?? "0").toString();
        if (voucherCode != "0") {
          let data = { voucherCode: voucherCode }
          let result = await api.getVoucherById(data);
          this.voucher = result.data;
          // console.log("--- load ---", JSON.stringify(result.data));
        }
        else {
          this.voucher.requestCode = this.userCode;
        }
      }
      finally {
        this.loading = false;
      } 
    },
    methods: {
      uppercase() {
        this.voucher.orderId = this.voucher?.orderId?.toUpperCase();
      },
      cancel() {
        this.$router.back();
      },
      async submit() {
        try {
          let voucherCode = this.voucher.voucherCode ?? "";
          let orderId = this.voucher.orderId ?? "";
          let remark = this.voucher.requestDesc ?? "";
          let amount = parseFloat(this.voucher.amount ?? 0);
          if (orderId == "") {
            alert("Please enter orderId");
            return;
          };
          if (remark == "") {
            alert("Please enter remark");
            return;
          };
          if (amount <= 0) {
            alert("Please enter amount");
            return;
          };
          if (confirm("Confirm voucher request?")) {
            this.loading = true;
  
            let data = { 
              action: "voucherrequest",
              voucherCode: voucherCode,
              orderId: orderId,
              remark: remark, 
              amount: amount, 
              userCode: this.userCode 
            };
            // alert(JSON.stringify(data));
            let result = await api.createVoucher(data);
            // alert(result.data);
            // console.log("voucherCode:", result.data);
            this.$router.back();
          }
        } catch (error) {
          alert(error)
        }
        finally {
          this.loading = false;
        }
      },
    },
    computed: {
      userCode() {
        return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
      }
    }
  };
  </script>
  
  <style scoped>
  table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
     background-color: rgba(0, 0, 0, .05);
   }
   .table_store {
     height: 200px;
     overflow: auto;
  }
  .inputNumber >>> input[type='number'] {
      -moz-appearance:textfield;
  }
  .inputNumber >>> input::-webkit-outer-spin-button,
  .inputNumber >>> input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  </style>