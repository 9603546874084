import axios from "axios";
import store from "@/store";
import { server } from "@/services/constants";

const apiUrl = "https://l6hfuc4dt5.execute-api.ap-southeast-1.amazonaws.com/production";
const apiUrl2 = "https://i9xmmtqrmf.execute-api.ap-southeast-1.amazonaws.com/Prod";

const getExpireTime = (sec) => {
    const t = new Date();
    if (sec > 0)
        t.setSeconds(t.getSeconds() + sec);
    return parseInt(t / 1000); //utc datetime
}

const getToken = async () => {
    var d1 = localStorage.getItem(server.EXPIRES_TIME) ?? 0;
    if (d1 == null)
        d1 = 0;
    if (d1 > 0) {
        var d2 = getExpireTime(0);
        if (d2 > d1) {
            try {
                let response = await refresh_token();
                // console.log("--- refreshToken ---", d1, d2, response);
                localStorage.setItem(server.ACCESS_TOKEN, response.accessToken);
                localStorage.setItem(server.ID_TOKEN, response.idToken);
                localStorage.setItem(server.EXPIRES_IN, response.expiresIn);
                localStorage.setItem(server.EXPIRES_TIME, getExpireTime(response.expiresIn - 120));
                return response.idToken;
            } catch (error) {
                store.dispatch("doLogout");
            }
        }
    }
    let token = localStorage.getItem(server.ID_TOKEN) ?? "";
    if (token==null || token.toString().trim()=="")
        store.dispatch("doLogout")
    else 
        return token;
};

const getInstanceWithOutToken = async (api_url) => {
    const instance = axios.create({
        baseURL: `${api_url}`,
        headers: {
            'Access-Control-Allow-Origin': `*`,
            'Content-Type': `application/json`
        },
        timeout: 600000
    });
    return instance;
};

const getInstance = async (api_url) => {
    const token = await getToken();
    const instance = axios.create({
        baseURL: `${api_url}`,
        headers: {
            'Access-Control-Allow-Origin': `*`,
            // 'Authorization': `Bearer ${token}`, 
            'Authorization': `${token}`, 
            'Content-Type': `application/json`
        },
        timeout: 600000
    });
    return instance;
};

const refresh_token = async () => {
    let userCode = localStorage.getItem(server.USERNAME) ?? "";
    let refreshToken = localStorage.getItem(server.REFRESH_TOKEN) ?? "";
    const instance = await getInstanceWithOutToken(apiUrl2);
    let payload = {
        "userCode": userCode,
        "refreshToken": refreshToken
    };
    return (
        await instance.post("/refresh_token", payload)
    ).data;
};

const listFile = async () => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.get("/s3/list-file")
    ).data;
};

const getFile = async (filename) => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.get(`/s3/get-file/${filename}`)
    ).data;
};

const getUploadUrl = async (filename) => {
    const instance = await getInstance(apiUrl);
    let payload = {
        "file_name": filename
    };
    return (
        await instance.post("/s3/generate-signed-url-upload-file", payload)
    ).data;
};

const listSegment = async (count, nextToken) => {
    const instance = await getInstance(apiUrl);
    // const nToken = nextToken ? `/${nextToken}` : ``;
    const nToken = ``;
    return (
        await instance.get(`/pinpoint/segment/list/${count}${nToken}`)
    ).data;
};

const deleteSegment = async (segment_id) => {
    const instance = await getInstance(apiUrl);
    let payload = {
        "segment_id": segment_id
    };
    return (
        await instance.post("/pinpoint/segment/delete", payload)
    ).data;
};

const listTemplate = async (count) => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.get(`/pinpoint/template/list/${count}`)
    ).data;
};

const getTemplate = async (type, template_name) => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.get(`/pinpoint/template/get/${type}/${template_name}`)
    ).data;
};

const createTemplate = async (template) => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.post("/pinpoint/template/create", template)
    ).data;
};

const updateTemplate = async (template) => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.post("/pinpoint/template/update", template)
    ).data;
};

const deleteTemplate = async (type, template_name) => {
    const instance = await getInstance(apiUrl);
    let payload = {
        "type": type,
        "name": template_name
    };
    return (
        await instance.post("/pinpoint/template/delete", payload)
    ).data;
};

const listCampaign = async (count) => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.get(`/pinpoint/campaign/list/${count}`)
    ).data;
};

const getCampaign = async (type, campaign_id) => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.get(`/pinpoint/campaign/get/${type}/${campaign_id}`)
    ).data;
};

const createCampaign  = async (campaign) => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.post("/pinpoint/campaign/create", campaign)
    ).data;
};

const updateCampaign  = async (campaign) => {
    const instance = await getInstance(apiUrl);
    return (
        await instance.post("/pinpoint/campaign/update", campaign)
    ).data;
};

const deleteCampaign = async (type, campaign_id) => {
    const instance = await getInstance(apiUrl);
    let payload = {
        "type": type,
        "campaign_id": campaign_id
    };
    return (
        await instance.post("/pinpoint/campaign/delete", payload)
    ).data;
};

export default {
    listFile,
    getFile,
    
    getUploadUrl,

    listSegment,
    deleteSegment,

    listTemplate,
    getTemplate,
    createTemplate,
    updateTemplate,
    deleteTemplate,

    listCampaign,
    getCampaign,
    createCampaign,
    updateCampaign,
    deleteCampaign
};