<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="creationTime"
        :sort-desc="true"
        :items-per-page="15"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Picking</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <!-- <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              @keyup.enter="searchClick"
            ></v-text-field> -->

            <!-- <v-text-field
              hide-details
              v-model="branchId"
              label="Brcode"
              @keyup.enter="searchClick"
            ></v-text-field> -->

            <v-select 
              clearable
              hide-details 
              v-model="branchSelect"
              :items="stores" 
              item-text="storeName" 
              item-value="storeCode" 
              label="Branch"
              :disabled="userBrcode != ''"
              return-object
            ></v-select>

            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->

            <!-- <v-dialog
              ref="dialog1"
              v-model="modal1"
              :return-value.sync="date1"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  v-model="date1"
                  label="From Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date1"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal1 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog1.save(date1)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog> -->

            <!-- <v-dialog
              ref="dialog2"
              v-model="modal2"
              :return-value.sync="date2"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  v-model="date2"
                  label="To Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date2"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal2 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog2.save(date2)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog> -->

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              clearable
              hide-details
              v-model="orderId"
              label="OrderId"
              @keyup.enter="searchClick"
            ></v-text-field>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <!-- <v-btn @click="newDocument" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>New Document</span>
            </v-btn> -->
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td :class="getRowClass(item)">
              <v-icon class="mr-2" color="red darken-2" @click="startPicking(item)" v-if="item.pickingStartTime == ''">
                send
              </v-icon>
              <v-icon class="mr-2" @click="editItem(item)" v-else>
                edit
              </v-icon>
              <!-- <v-icon class="mr-2" @click="editItem(item)">
                edit
              </v-icon> -->
            </td>
            <td :class="getRowClass(item)">{{ item.orderId }}</td>
            <td :class="getRowClass(item)">{{ item.branchName }}</td>
            <!-- <td>{{ item.branchId }} - {{ item.branchName }}</td> -->
            <!-- <td>{{ item.firstName }}</td>
            <td>{{ item.lastName }}</td> -->
            <td :class="getRowClass(item)">{{ item.shippingType }}</td>
            <!-- <td>{{ item.grandTotal }}</td> -->
            <td :class="getRowClass(item)">{{ item.picker }}</td>
            <td :class="getRowClass(item)">{{ item.pickingStartTime }}</td>
            <td :class="getRowClass(item)">{{ item.pickingEndTime }}</td>
            <td :class="getRowClass(item)">{{ item.pickingStatus }}</td>
            <td :class="getRowClass(item)">
              <v-checkbox readonly v-model="item.isPaid"></v-checkbox>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";

export default {
  name: "picking",
  data() {
    return {
      loading: false,
      // search: "",
      branchId: "",
      orderId: "",
      stores:[],
      branchSelect: null,
      // date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // modal1: false,
      // modal2: false,
      // mOrders: [],
      mDataArray: [],
      headers: [
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action",
        },
        { text: "OrderId", value: "orderId" },
        { text: "Branch", value: "branchId" },
        // { text: "FirstName", value: "firstName" },
        // { text: "LastName", value: "lastName" },
        { text: "ShippingType", value: "shippingType" },
        // { text: "GrandTotal", value: "grandTotal" },
        { text: "Picker", value: "picker" },
        { text: "StartTime", value: "pickingStartTime" },
        { text: "EndTime", value: "pickingEndTime" },
        { text: "Status", value: "pickingStatus" },
        {
          text: "IsPaid",
          align: "left",
          // sortable: false,
          value: "isPaid",
        },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      try {
        this.loading = true;
        let filter = { fileName: "online_stores.json" };
        let result = await api.getS3Json(filter);
        this.stores = result.data;

        if (this.userBrcode != "") {
          let mStore = this.stores.find(d => d.storeCode === this.userBrcode);
          if (mStore) {
            this.branchSelect = mStore;
          }
        }

        await this.loadData();

        setInterval(this.searchClick, 300000);
        
        // } catch (error) {
      } finally {
        this.loading = false;
      }
  },
  methods: {
    getRowClass(item) {
      let s = item?.shippingType ?? "";
      if (s.toLowerCase().indexOf("express") == -1)
        return ""
      else
        return "row_express";
    },
    async searchClick() {
      try {
        this.loading = true;
        await this.loadData();
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async startPicking(item) {
      if (confirm("Confirm start picking?")) {
        try {
          let data = {
            "action": "startPicking",
            "orderId": item.orderId,
            "userCode": ""
          }
          console.log('-- request startPicking --', JSON.stringify(data));
          let result = await api.updatePicking(data);
          console.log('-- result startPicking --', result.data ?? "");
          
          let pickingStartTime = result.data["pickingStartTime"] ?? "";
          if (pickingStartTime != "") {
            let mItem = this.mDataArray.find(d => d.orderId === item.orderId);
            if (mItem) {
              mItem["pickingStartTime"] = result.data["pickingStartTime"] ?? "";
              mItem["pickingStatus"] = result.data["pickingStatus"] ?? "";
              let index = this.mDataArray.indexOf(mItem);
              this.$set(this.mDataArray, index, mItem);
            }
          }
        } catch (error) {
        } finally {
          this.barcodeScan = "";
        }
      }
    },
    editItem(item) {
      this.$router.push(`/picking/edit/${item.orderId}`);
    },
    // newDocument() {
    //   this.$router.push("/picking/edit/0");
    // },
    async loadData() {
      // try {
      //   this.loading = true;
        if (this.branchSelect != null)
          this.branchId = this.branchSelect.storeCode;
        else
          this.branchId = "";

        // let filter = { orderId: this.orderId, branchId: this.branchId, startDate: this.date1, endDate: this.date2, isPaid: 2, picker: this.picker };
        let filter = { orderId: this.orderId, branchId: this.branchId, isPaid: 2, picker: this.picker };

        // console.log("--- filter ---", filter);
        let result = await api.getOrderList(filter);
        // console.log("--- order list ---", result.data);
        this.mDataArray = result.data;
        // } catch (error) {
      // } finally {
      //   this.loading = false;
      // }
    },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    userGroup() {
      return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    },
    userType() {
      return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    },
    userBrcode() {
      return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    },
    picker() {
      return (this.userType == 'Picker' || this.userType == 'User') ? this.userCode : "";
    }
    // mDataArray() {
    //   if (this.userType == 'Picker' || this.userType == 'User')
    //     return this.mOrders.filter(o => o.picker == this.userCode)
    //   else
    //     return this.mOrders ?? [];
    // },
  }
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.row_express {
  background-color: #FC0FC0;
  color: #ffffff;
}
</style>