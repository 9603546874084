<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row>
      <v-col lg="6" md="6" sm="12" cols="12">
        <v-select
          clearable
          hide-details
          v-model="branchSelect"
          :items="branchs"
          item-text="storeName" 
          item-value="storeCode" 
          label="Branch"
          :disabled="userBrcode != ''"
          return-object
        ></v-select>
      </v-col>
      <v-col lg="6" md="6" sm="12" cols="12">
        <v-menu
          hide-details
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Date"
              prepend-icon="mdi-calendar"
              :disabled="userBrcode != ''"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="$refs.menu.save(date)"
            v-model="date"
            no-title
            scrollable>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-btn class="mr-3" @click="refreshData" color="primary">
      <v-icon left>refresh</v-icon>
      <span>Refresh</span>
    </v-btn>
    <v-btn @click="$router.push(`/dashboard/dailysummary`)" color="primary" v-if="userBrcode == null || userBrcode == ''">
      <v-icon left>dashboard</v-icon>
      <span>สรุปยอดขายประจำวัน</span>
    </v-btn>

    <h2 class="header mt-1">Daily Report : {{ date }}</h2>
    <!-- Summary section -->
    <v-row>
      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Revenue"
          avatar_ic="mdi-cart-outline"
          avatar_bg="#00a65a"
          :total_value="getTotalRevenueTotal()"
          :online_value="getTotalRevenue()"
          :telesale_value="getTotalRevenueTeleSale()"
          :lazada_value="getTotalRevenueLazada()"
          :shopee_value="getTotalRevenueShopee()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Shipping"
          avatar_ic="directions_car"
          avatar_bg="#f39c12"
          :total_value="getTotalShippingTotal()"
          :online_value="getTotalShipping()"
          :telesale_value="getTotalShippingTeleSale()"
          :lazada_value="getTotalShippingLazada()"
          :shopee_value="getTotalShippingShopee()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Ticket"
          avatar_ic="auto_awesome_motion"
          avatar_bg="#dd4b39"
          :total_value="getTotalTicketTotal()"
          :online_value="getTotalTicket()"
          :telesale_value="getTotalTicketTeleSale()"
          :lazada_value="getTotalTicketLazada()"
          :shopee_value="getTotalTicketShopee()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Avg Spending"
          avatar_ic="mdi-gift-outline"
          avatar_bg="#00c0ef"
          :total_value="getTotalAvgSpendingTotal()"
          :online_value="getTotalAvgSpending()"
          :telesale_value="getTotalAvgSpendingTeleSale()"
          :lazada_value="getTotalAvgSpendingLazada()"
          :shopee_value="getTotalAvgSpendingShopee()"
        />
      </v-col>
    </v-row>
    <br />
    <v-card>
      <BarChart style="height: 400px;" :chartData="datacollection" />
    </v-card>

    <br />
    <h2 class="header">Weekly Report : {{ getCurrentWeekly() }}</h2>
    <!-- Summary section -->
    <v-row>
      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Revenue"
          avatar_ic="mdi-cart-outline"
          avatar_bg="#00a65a"
          :total_value="getTotalRevenueTotalWeekly()"
          :online_value="getTotalRevenueWeekly()"
          :telesale_value="getTotalRevenueTeleSaleWeekly()"
          :lazada_value="getTotalRevenueLazadaWeekly()"
          :shopee_value="getTotalRevenueShopeeWeekly()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Shipping"
          avatar_ic="directions_car"
          avatar_bg="#f39c12"
          :total_value="getTotalShippingTotalWeekly()"
          :online_value="getTotalShippingWeekly()"
          :telesale_value="getTotalShippingTeleSaleWeekly()"
          :lazada_value="getTotalShippingLazadaWeekly()"
          :shopee_value="getTotalShippingShopeeWeekly()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Ticket"
          avatar_ic="auto_awesome_motion"
          avatar_bg="#dd4b39"
          :total_value="getTotalTicketTotalWeekly()"
          :online_value="getTotalTicketWeekly()"
          :telesale_value="getTotalTicketTeleSaleWeekly()"
          :lazada_value="getTotalTicketLazadaWeekly()"
          :shopee_value="getTotalTicketShopeeWeekly()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Avg Spending"
          avatar_ic="mdi-gift-outline"
          avatar_bg="#00c0ef"
          :total_value="getTotalAvgSpendingTotalWeekly()"
          :online_value="getTotalAvgSpendingWeekly()"
          :telesale_value="getTotalAvgSpendingTeleSaleWeekly()"
          :lazada_value="getTotalAvgSpendingLazadaWeekly()"
          :shopee_value="getTotalAvgSpendingShopeeWeekly()"
        />
      </v-col>
    </v-row>
    <br />
    <v-card>
      <BarChart style="height: 400px;" :chartData="datacollectionWeekly" />
    </v-card>
    <br />
    <h2 class="header">Monthly Report : {{ getCurrentMonthly() }}</h2>
    <!-- Summary section -->
    <v-row>
      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Revenue"
          avatar_ic="mdi-cart-outline"
          avatar_bg="#00a65a"
          :total_value="getTotalRevenueTotalMonthly()"
          :online_value="getTotalRevenueMonthly()"
          :telesale_value="getTotalRevenueTeleSaleMonthly()"
          :lazada_value="getTotalRevenueLazadaMonthly()"
          :shopee_value="getTotalRevenueShopeeMonthly()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Shipping"
          avatar_ic="directions_car"
          avatar_bg="#f39c12"
          :total_value="getTotalShippingTotalMonthly()"
          :online_value="getTotalShippingMonthly()"
          :telesale_value="getTotalShippingTeleSaleMonthly()"
          :lazada_value="getTotalShippingLazadaMonthly()"
          :shopee_value="getTotalShippingShopeeMonthly()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Ticket"
          avatar_ic="auto_awesome_motion"
          avatar_bg="#dd4b39"
          :total_value="getTotalTicketTotalMonthly()"
          :online_value="getTotalTicketMonthly()"
          :telesale_value="getTotalTicketTeleSaleMonthly()"
          :lazada_value="getTotalTicketLazadaMonthly()"
          :shopee_value="getTotalTicketShopeeMonthly()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Avg Spending"
          avatar_ic="mdi-gift-outline"
          avatar_bg="#00c0ef"
          :total_value="getTotalAvgSpendingTotalMonthly()"
          :online_value="getTotalAvgSpendingMonthly()"
          :telesale_value="getTotalAvgSpendingTeleSaleMonthly()"
          :lazada_value="getTotalAvgSpendingLazadaMonthly()"
          :shopee_value="getTotalAvgSpendingShopeeMonthly()"
        />
      </v-col>
    </v-row>
    <br />
    <v-card>
      <BarChart style="height: 400px;" :chartData="datacollectionMonthly" />
    </v-card>

    <br />
    <h2 class="header">Yearly Report : {{ getCurrentYearly() }}</h2>
    <!-- Summary section -->
    <v-row>
      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Revenue"
          avatar_ic="mdi-cart-outline"
          avatar_bg="#00a65a"
          :total_value="getTotalRevenueTotalYearly()"
          :online_value="getTotalRevenueYearly()"
          :telesale_value="getTotalRevenueTeleSaleYearly()"
          :lazada_value="getTotalRevenueLazadaYearly()"
          :shopee_value="getTotalRevenueShopeeYearly()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Shipping"
          avatar_ic="directions_car"
          avatar_bg="#f39c12"
          :total_value="getTotalShippingTotalYearly()"
          :online_value="getTotalShippingYearly()"
          :telesale_value="getTotalShippingTeleSaleYearly()"
          :lazada_value="getTotalShippingLazadaYearly()"
          :shopee_value="getTotalShippingShopeeYearly()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Ticket"
          avatar_ic="auto_awesome_motion"
          avatar_bg="#dd4b39"
          :total_value="getTotalTicketTotalYearly()"
          :online_value="getTotalTicketYearly()"
          :telesale_value="getTotalTicketTeleSaleYearly()"
          :lazada_value="getTotalTicketLazadaYearly()"
          :shopee_value="getTotalTicketShopeeYearly()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Avg Spending"
          avatar_ic="mdi-gift-outline"
          avatar_bg="#00c0ef"
          :total_value="getTotalAvgSpendingTotalYearly()"
          :online_value="getTotalAvgSpendingYearly()"
          :telesale_value="getTotalAvgSpendingTeleSaleYearly()"
          :lazada_value="getTotalAvgSpendingLazadaYearly()"
          :shopee_value="getTotalAvgSpendingShopeeYearly()"
        />
      </v-col>
    </v-row>
    <br />
    <v-card>
      <BarChart style="height: 400px;" :chartData="datacollectionYearly" />
    </v-card>

    <br />
    <h2 class="header">Summary Report</h2>
    <!-- Summary section -->
    <v-row>
      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Revenue"
          avatar_ic="mdi-cart-outline"
          avatar_bg="#00a65a"
          :total_value="getTotalRevenueTotalSummary()"
          :online_value="getTotalRevenueSummary()"
          :telesale_value="getTotalRevenueTeleSaleSummary()"
          :lazada_value="getTotalRevenueLazadaSummary()"
          :shopee_value="getTotalRevenueShopeeSummary()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Shipping"
          avatar_ic="directions_car"
          avatar_bg="#f39c12"
          :total_value="getTotalShippingTotalSummary()"
          :online_value="getTotalShippingSummary()"
          :telesale_value="getTotalShippingTeleSaleSummary()"
          :lazada_value="getTotalShippingLazadaSummary()"
          :shopee_value="getTotalShippingShopeeSummary()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Ticket"
          avatar_ic="auto_awesome_motion"
          avatar_bg="#dd4b39"
          :total_value="getTotalTicketTotalSummary()"
          :online_value="getTotalTicketSummary()"
          :telesale_value="getTotalTicketTeleSaleSummary()"
          :lazada_value="getTotalTicketLazadaSummary()"
          :shopee_value="getTotalTicketShopeeSummary()"
        />
      </v-col>

      <v-col lg="3" md="6" sm="12" cols="12">
        <StockCard
          title="Avg Spending"
          avatar_ic="mdi-gift-outline"
          avatar_bg="#00c0ef"
          :total_value="getTotalAvgSpendingTotalSummary()"
          :online_value="getTotalAvgSpendingSummary()"
          :telesale_value="getTotalAvgSpendingTeleSaleSummary()"
          :lazada_value="getTotalAvgSpendingLazadaSummary()"
          :shopee_value="getTotalAvgSpendingShopeeSummary()"
        />
      </v-col>
    </v-row>
    <br />
    <v-card>
      <BarChart style="height: 400px;" :chartData="datacollectionSummary" />
    </v-card>
    <br />
  </v-container>
</template>

<script>
  import api from "@/services/lambdaApi";
  import BarChart from '@/components/charts/BarChart'
  import StockCard from "@/components/cards/Summary";
  // import StockCard2 from "@/components/cards/StockCard";
  import moment from 'moment'

  export default {
    name: "salereport",
    components: {
      BarChart,
      StockCard,
      // StockCard2
    },
    data () {
      return {
        loading: false,
        branchSelect: null,
        branchs: [],

        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,

        //Daily
        mData: [],
        mAmountArray: [],
        mTicketArray: [],
        mAmountTeleSaleArray: [],
        mTicketTeleSaleArray: [],
        mAmountLazadaArray: [],
        mTicketLazadaArray: [],
        mAmountShopeeArray: [],
        mTicketShopeeArray: [],
        mSumAmountArray: [],
        mSumTicketArray: [],
        datacollection: {},

        //Weekly
        mDataWeekly: [],
        mAmountWeeklyArray: [],
        mTicketWeeklyArray: [],
        mAmountTeleSaleWeeklyArray: [],
        mTicketTeleSaleWeeklyArray: [],
        mAmountLazadaWeeklyArray: [],
        mTicketLazadaWeeklyArray: [],
        mAmountShopeeWeeklyArray: [],
        mTicketShopeeWeeklyArray: [],
        mSumAmountWeeklyArray: [],
        mSumTicketWeeklyArray: [],
        datacollectionWeekly: {},

        //Monthly
        mDataMonthly: [],
        mAmountMonthlyArray: [],
        mTicketMonthlyArray: [],
        mAmountTeleSaleMonthlyArray: [],
        mTicketTeleSaleMonthlyArray: [],
        mAmountLazadaMonthlyArray: [],
        mTicketLazadaMonthlyArray: [],
        mAmountShopeeMonthlyArray: [],
        mTicketShopeeMonthlyArray: [],
        mSumAmountMonthlyArray: [],
        mSumTicketMonthlyArray: [],
        datacollectionMonthly: {},

        //Yearly
        mDataYearly: [],
        mAmountYearlyArray: [],
        mTicketYearlyArray: [],
        mAmountTeleSaleYearlyArray: [],
        mTicketTeleSaleYearlyArray: [],
        mAmountLazadaYearlyArray: [],
        mTicketLazadaYearlyArray: [],
        mAmountShopeeYearlyArray: [],
        mTicketShopeeYearlyArray: [],
        mSumAmountYearlyArray: [],
        mSumTicketYearlyArray: [],
        datacollectionYearly: {},

        //Summary
        mDataSummary: [],
        mAmountSummaryArray: [],
        mTicketSummaryArray: [],
        mAmountTeleSaleSummaryArray: [],
        mTicketTeleSaleSummaryArray: [],
        mAmountLazadaSummaryArray: [],
        mTicketLazadaSummaryArray: [],
        mAmountShopeeSummaryArray: [],
        mTicketShopeeSummaryArray: [],
        mSumAmountSummaryArray: [],
        mSumTicketSummaryArray: [],
        datacollectionSummary: {},
      }
    },
    async mounted () {
      try {
        this.loading = true;
        let filter = { fileName: "online_stores.json" };
        let result = await api.getS3Json(filter);
        this.branchs = result.data;

        let brcode = this.userBrcode;
        if (brcode == null || brcode == "") {
          this.branchSelect = null;
        }
        else {
          let mStore = this.branchs.find(d => d.storeCode === brcode);
          if (mStore) {
            this.branchSelect = mStore;
          }
        }

        await this.refreshData();
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    methods: {
    numberFormat(value, decimal) {
      return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
    },
    async refreshData() {
      await this.loadData();
    },
    async mapDailyData() {
        if (this.mData) {
          let tickets = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          let tickets_telesale = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts_telesale = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          let tickets_lazada = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts_lazada = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          let tickets_shopee = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts_shopee = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          let sum_tickets = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          let sum_amounts = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          let ranges = this.mData["ranges"] ?? [];
          if (ranges && ranges.length > 0) {
            ranges.forEach(r => {
              let timeAt = r["timeAt"] ?? 0;
              if (timeAt >= 0 && timeAt <= 23) {
                let ticket = r["tickets"] ?? 0;
                let amount = r["amounts"] ?? 0;
                let ticket_telesale = r["tele_ticket"] ?? 0;
                let amount_telesale = r["tele_amount"] ?? 0;
                let ticket_lazada = r["lazada_ticket"] ?? 0;
                let amount_lazada = r["lazada_amount"] ?? 0;
                let ticket_shopee = r["shopee_ticket"] ?? 0;
                let amount_shopee = r["shopee_amount"] ?? 0;
                tickets[timeAt] = ticket;
                amounts[timeAt] = amount;
                tickets_telesale[timeAt] = ticket_telesale;
                amounts_telesale[timeAt] = amount_telesale;
                tickets_lazada[timeAt] = ticket_lazada;
                amounts_lazada[timeAt] = amount_lazada;
                tickets_shopee[timeAt] = ticket_shopee;
                amounts_shopee[timeAt] = amount_shopee;
                sum_tickets[timeAt] = ticket + ticket_telesale + ticket_lazada + ticket_shopee;
                sum_amounts[timeAt] = amount + amount_telesale + amount_lazada + amount_shopee;
              }
            });
          };
          this.mTicketArray = tickets;
          this.mAmountArray = amounts;
          this.mTicketTeleSaleArray = tickets_telesale;
          this.mAmountTeleSaleArray = amounts_telesale;
          this.mTicketLazadaArray = tickets_lazada;
          this.mAmountLazadaArray = amounts_lazada;
          this.mTicketShopeeArray = tickets_shopee;
          this.mAmountShopeeArray = amounts_shopee;
          this.mSumTicketArray = sum_tickets;
          this.mSumAmountArray = sum_amounts;
        }
    },
    async mapWeeklyData() {
        if (this.mDataWeekly) {
          let tickets = [0,0,0,0,0,0,0]
          let amounts = [0,0,0,0,0,0,0]
          let tickets_telesale = [0,0,0,0,0,0,0]
          let amounts_telesale = [0,0,0,0,0,0,0]
          let tickets_lazada = [0,0,0,0,0,0,0]
          let amounts_lazada = [0,0,0,0,0,0,0]
          let tickets_shopee = [0,0,0,0,0,0,0]
          let amounts_shopee = [0,0,0,0,0,0,0]
          let sum_tickets = [0,0,0,0,0,0,0]
          let sum_amounts = [0,0,0,0,0,0,0]
          let days = this.mDataWeekly["days"] ?? [];
          if (days && days.length > 0) {
            days.forEach(r => {
              let dayofweek = r["dayofweek"] ?? 0;
              if (dayofweek >= 0 && dayofweek <= 6) {
                let ticket = r["tickets"] ?? 0;
                let amount = r["amounts"] ?? 0;
                let ticket_telesale = r["tele_ticket"] ?? 0;
                let amount_telesale = r["tele_amount"] ?? 0;
                let ticket_lazada = r["lazada_ticket"] ?? 0;
                let amount_lazada = r["lazada_amount"] ?? 0;
                let ticket_shopee = r["shopee_ticket"] ?? 0;
                let amount_shopee = r["shopee_amount"] ?? 0;
                tickets[dayofweek] = ticket;
                amounts[dayofweek] = amount;
                tickets_telesale[dayofweek] = ticket_telesale;
                amounts_telesale[dayofweek] = amount_telesale;
                tickets_lazada[dayofweek] = ticket_lazada;
                amounts_lazada[dayofweek] = amount_lazada;
                tickets_shopee[dayofweek] = ticket_shopee;
                amounts_shopee[dayofweek] = amount_shopee;
                sum_tickets[dayofweek] = ticket + ticket_telesale + ticket_lazada + ticket_shopee;
                sum_amounts[dayofweek] = amount + amount_telesale + amount_lazada + amount_shopee;
              }
            });
          };
          this.mTicketWeeklyArray = tickets;
          this.mAmountWeeklyArray = amounts;
          this.mTicketTeleSaleWeeklyArray = tickets_telesale;
          this.mAmountTeleSaleWeeklyArray = amounts_telesale;
          this.mTicketLazadaWeeklyArray = tickets_lazada;
          this.mAmountLazadaWeeklyArray = amounts_lazada;
          this.mTicketShopeeWeeklyArray = tickets_shopee;
          this.mAmountShopeeWeeklyArray = amounts_shopee;
          this.mSumTicketWeeklyArray = sum_tickets;
          this.mSumAmountWeeklyArray = sum_amounts;
        }
    },
    async mapMonthlyData() {
        if (this.mDataMonthly) {
          var tickets = []
          var amounts = []
          var tickets_telesale = []
          var amounts_telesale = []
          var tickets_lazada = []
          var amounts_lazada = []
          var tickets_shopee = []
          var amounts_shopee = []
          var sum_tickets = []
          var sum_amounts = []
          let maxDays = this.mDataMonthly?.maxDays;
          for (let index = 0; index < maxDays; index++) {
            tickets.push(0);
            amounts.push(0);
            tickets_telesale.push(0);
            amounts_telesale.push(0);
            tickets_lazada.push(0);
            amounts_lazada.push(0);
            tickets_shopee.push(0);
            amounts_shopee.push(0);
            sum_tickets.push(0);
            sum_amounts.push(0);
          }
          let days = this.mDataMonthly["days"] ?? [];
          if (days && days.length > 0) {
            days.forEach(r => {
              let day = r["day"] ?? 0;
              if (day >= 1 && day <= maxDays) {
                let ticket = r["tickets"] ?? 0;
                let amount = r["amounts"] ?? 0;
                let ticket_telesale = r["tele_ticket"] ?? 0;
                let amount_telesale = r["tele_amount"] ?? 0;
                let ticket_lazada = r["lazada_ticket"] ?? 0;
                let amount_lazada = r["lazada_amount"] ?? 0;
                let ticket_shopee = r["shopee_ticket"] ?? 0;
                let amount_shopee = r["shopee_amount"] ?? 0;
                tickets[day-1] = ticket;
                amounts[day-1] = amount;
                tickets_telesale[day-1] = ticket_telesale;
                amounts_telesale[day-1] = amount_telesale;
                tickets_lazada[day-1] = ticket_lazada;
                amounts_lazada[day-1] = amount_lazada;
                tickets_shopee[day-1] = ticket_shopee;
                amounts_shopee[day-1] = amount_shopee;
                sum_tickets[day-1] = ticket + ticket_telesale + ticket_lazada + ticket_shopee;
                sum_amounts[day-1] = amount + amount_telesale + amount_lazada + amount_shopee;
              }
            });
          };
          this.mTicketMonthlyArray = tickets;
          this.mAmountMonthlyArray = amounts;
          this.mTicketTeleSaleMonthlyArray = tickets_telesale;
          this.mAmountTeleSaleMonthlyArray = amounts_telesale;
          this.mTicketLazadaMonthlyArray = tickets_lazada;
          this.mAmountLazadaMonthlyArray = amounts_lazada;
          this.mTicketShopeeMonthlyArray = tickets_shopee;
          this.mAmountShopeeMonthlyArray = amounts_shopee;
          this.mSumTicketMonthlyArray = sum_tickets;
          this.mSumAmountMonthlyArray = sum_amounts;
        }
    },
    async mapYearlyData() {
        if (this.mDataYearly) {
          let tickets = [0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts = [0,0,0,0,0,0,0,0,0,0,0,0]
          let tickets_telesale = [0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts_telesale = [0,0,0,0,0,0,0,0,0,0,0,0]
          let tickets_lazada = [0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts_lazada = [0,0,0,0,0,0,0,0,0,0,0,0]
          let tickets_shopee = [0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts_shopee = [0,0,0,0,0,0,0,0,0,0,0,0]
          let sum_tickets = [0,0,0,0,0,0,0,0,0,0,0,0]
          let sum_amounts = [0,0,0,0,0,0,0,0,0,0,0,0]
          let months = this.mDataYearly["months"] ?? [];
          if (months && months.length > 0) {
            months.forEach(r => {
              let month = r["month"] ?? 0;
              if (month >= 1 && month <= 12) {
                let ticket = r["tickets"] ?? 0;
                let amount = r["amounts"] ?? 0;
                let ticket_telesale = r["tele_ticket"] ?? 0;
                let amount_telesale = r["tele_amount"] ?? 0;
                let ticket_lazada = r["lazada_ticket"] ?? 0;
                let amount_lazada = r["lazada_amount"] ?? 0;
                let ticket_shopee = r["shopee_ticket"] ?? 0;
                let amount_shopee = r["shopee_amount"] ?? 0;
                tickets[month-1] = ticket;
                amounts[month-1] = amount;
                tickets_telesale[month-1] = ticket_telesale;
                amounts_telesale[month-1] = amount_telesale;
                tickets_lazada[month-1] = ticket_lazada;
                amounts_lazada[month-1] = amount_lazada;
                tickets_shopee[month-1] = ticket_shopee;
                amounts_shopee[month-1] = amount_shopee;
                sum_tickets[month-1] = ticket + ticket_telesale + ticket_lazada + ticket_shopee;
                sum_amounts[month-1] = amount + amount_telesale + amount_lazada + amount_shopee;
              }
            });
          };
          this.mTicketYearlyArray = tickets;
          this.mAmountYearlyArray = amounts;
          this.mTicketTeleSaleYearlyArray = tickets_telesale;
          this.mAmountTeleSaleYearlyArray = amounts_telesale;
          this.mTicketLazadaYearlyArray = tickets_lazada;
          this.mAmountLazadaYearlyArray = amounts_lazada;
          this.mTicketShopeeYearlyArray = tickets_shopee;
          this.mAmountShopeeYearlyArray = amounts_shopee;
          this.mSumTicketYearlyArray = sum_tickets;
          this.mSumAmountYearlyArray = sum_amounts;
        }
    },
    async mapSummaryData() {
        if (this.mDataSummary) {
          let tickets = [0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts = [0,0,0,0,0,0,0,0,0,0,0,0]
          let tickets_telesale = [0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts_telesale = [0,0,0,0,0,0,0,0,0,0,0,0]
          let tickets_lazada = [0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts_lazada = [0,0,0,0,0,0,0,0,0,0,0,0]
          let tickets_shopee = [0,0,0,0,0,0,0,0,0,0,0,0]
          let amounts_shopee = [0,0,0,0,0,0,0,0,0,0,0,0]
          let sum_tickets = [0,0,0,0,0,0,0,0,0,0,0,0]
          let sum_amounts = [0,0,0,0,0,0,0,0,0,0,0,0]
          let years = this.mDataSummary["years"] ?? [];
          if (years && years.length > 0) {
            years.forEach(r => {
              let year = r["year"] ?? 0;
              if (year >= 2021) {
                let ticket = r["tickets"] ?? 0;
                let amount = r["amounts"] ?? 0;
                let ticket_telesale = r["tele_ticket"] ?? 0;
                let amount_telesale = r["tele_amount"] ?? 0;
                let ticket_lazada = r["lazada_ticket"] ?? 0;
                let amount_lazada = r["lazada_amount"] ?? 0;
                let ticket_shopee = r["shopee_ticket"] ?? 0;
                let amount_shopee = r["shopee_amount"] ?? 0;
                tickets[year-2021] = ticket;
                amounts[year-2021] = amount;
                tickets_telesale[year-2021] = ticket_telesale;
                amounts_telesale[year-2021] = amount_telesale;
                tickets_lazada[year-2021] = ticket_lazada;
                amounts_lazada[year-2021] = amount_lazada;
                tickets_shopee[year-2021] = ticket_shopee;
                amounts_shopee[year-2021] = amount_shopee;
                sum_tickets[year-2021] = ticket + ticket_telesale + ticket_lazada + ticket_shopee;
                sum_amounts[year-2021] = amount + amount_telesale + amount_lazada + amount_shopee;
              }
            });
          };
          this.mTicketSummaryArray = tickets;
          this.mAmountSummaryArray = amounts;
          this.mTicketTeleSaleSummaryArray = tickets_telesale;
          this.mAmountTeleSaleSummaryArray = amounts_telesale;
          this.mTicketLazadaSummaryArray = tickets_lazada;
          this.mAmountLazadaSummaryArray = amounts_lazada;
          this.mTicketShopeeSummaryArray = tickets_shopee;
          this.mAmountShopeeSummaryArray = amounts_shopee;
          this.mSumTicketSummaryArray = sum_tickets;
          this.mSumAmountSummaryArray = sum_amounts;
        }
    },
    async loadData () {
      try {
        this.loading = true;
        let paymentDate = this.date == null || this.date == "" ? "" : moment(this.date).format('YYYY-MM-DD');
        let data = { brcode: this.branchSelect?.storeCode ?? "0000", paymentDateStamp: paymentDate };
        let result = await api.getSaleReport(data);
        // console.log('-- salereport --', result.data)
        this.mData = result?.data["daily"];
        this.mDataWeekly = result?.data["weekly"];
        // console.log('-- weekly --', this.mDataWeekly);
        this.mDataMonthly = result?.data["monthly"];
        this.mDataYearly = result?.data["yearly"];
        this.mDataSummary= result?.data["summary"];
        await this.mapDailyData();
        await this.mapWeeklyData();
        await this.mapMonthlyData();
        await this.mapYearlyData();
        await this.mapSummaryData();

        await this.fillData();
        await this.fillDataWeekly();
        await this.fillDataMonthly();
        await this.fillDataYearly();
        await this.fillDataSummary();
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },

    //Daily  
    async fillData () {
      this.datacollection = {
          //Data to be represented on x-axis
          labels: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', 
                  '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
          datasets: [
            {
              type: 'bar',
              label: 'Online Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenues(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#43A047'
              // ],
              yAxisID: '0',
              backgroundColor: '#3D5B96',
              order: 6,
              // backgroundColor: [
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',

              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)'
              // ],
            },
            {
              type: 'bar',
              label: 'TeleSale Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesTeleSale(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#f87979',
              order: 7,
              // backgroundColor: [
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',

              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)'
              // ],
            },
            {
              type: 'bar',
              label: 'Lazada Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesLazada(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#00BCD4',
              order: 8,
              // hidden: true,
            },
            {
              type: 'bar',
              label: 'Shopee Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#FC0FC0',
              data: this.getRevenuesShopee(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#FC0FC0',
              order: 9,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Online Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getTickets(),
              borderColor: '#03A9F4',
              // borderColor: [
              //   '#3F51B5'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 1,
            },
            {
              type: 'line',
              label: 'TeleSale Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#ff0000',
              data: this.getTicketsTeleSale(),
              borderColor: '#ff0000',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 2,
            },
            {
              type: 'line',
              label: 'Lazada Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#FFC107',
              data: this.getTicketsLazada(),
              borderColor: '#FFC107',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 3,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Shopee Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#CDDC39',
              data: this.getTicketsShopee(),
              borderColor: '#CDDC39',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 4,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Total Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#9C27B0',
              data: this.getSumTickets(),
              borderColor: '#9C27B0',
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 5,
              hidden: true,
            },
            {
              // type: 'line',
              // label: 'Total Revenue',
              // pointBackgroundColor: 'white',
              // borderWidth: 2,
              // pointBorderColor: '#4CAF50',
              // data: this.getSumRevenues(),
              // borderColor: '#4CAF50',
              // yAxisID: '0',
              // backgroundColor: [ 'transparent' ],
              // order: 4,

              type: 'bar',
              label: 'Total Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#4CAF50',
              data: this.getSumRevenues(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#4CAF50',
              order: 10,
              stack: 'false',
              hidden: true,
            },
          ]};
      },
      getRevenues() {
        return this.mAmountArray;
      },
      getTickets() {
        return this.mTicketArray;
      },
      getRevenuesTeleSale() {
        return this.mAmountTeleSaleArray;
      },
      getTicketsTeleSale() {
        return this.mTicketTeleSaleArray;
      },
      getRevenuesLazada() {
        return this.mAmountLazadaArray;
      },
      getTicketsLazada() {
        return this.mTicketLazadaArray;
      },
      getRevenuesShopee() {
        return this.mAmountShopeeArray;
      },
      getTicketsShopee() {
        return this.mTicketShopeeArray;
      },
      getSumRevenues() {
        return this.mSumAmountArray;
      },
      getSumTickets() {
        return this.mSumTicketArray;
      },

      getTotalRevenueTotal() {
        return parseFloat(this.mData?.total_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
        // return parseFloat(this.mData?.amounts2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalRevenue() {
        return parseFloat(this.mData?.amounts ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
        // return parseFloat(this.mData?.amounts2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalRevenueTeleSale() {
        return parseFloat(this.mData?.tele_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
        // return parseFloat(this.mData?.amounts2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalRevenueLazada() {
        return parseFloat(this.mData?.lazada_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalRevenueShopee() {
        return parseFloat(this.mData?.shopee_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },

      getTotalShippingTotal() {
        return parseFloat(this.mData?.total_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
        // return parseFloat(this.mData?.shippings2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalShipping() {
        return parseFloat(this.mData?.shippings ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
        // return parseFloat(this.mData?.shippings2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalShippingTeleSale() {
        return parseFloat(this.mData?.tele_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
        // return parseFloat(this.mData?.shippings2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalShippingLazada() {
        return parseFloat(this.mData?.lazada_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalShippingShopee() {
        return parseFloat(this.mData?.shopee_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },

      getTotalTicketTotal() {
        return parseInt(this.mData?.total_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
        // return parseInt(this.mData?.tickets2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
      },
      getTotalTicket() {
        return parseInt(this.mData?.tickets ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
        // return parseInt(this.mData?.tickets2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
      },
      getTotalTicketTeleSale() {
        return parseInt(this.mData?.tele_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
        // return parseInt(this.mData?.tickets2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
      },
      getTotalTicketLazada() {
        return parseInt(this.mData?.lazada_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
      },
      getTotalTicketShopee() {
        return parseInt(this.mData?.shopee_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
      },

      getTotalAvgSpendingTotal() {
        return parseFloat(this.mData?.total_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
        // return parseFloat(this.mData?.averageSpending2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalAvgSpending() {
        return parseFloat(this.mData?.averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
        // return parseFloat(this.mData?.averageSpending2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalAvgSpendingTeleSale() {
        return parseFloat(this.mData?.tele_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
        // return parseFloat(this.mData?.averageSpending2 ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },   
      getTotalAvgSpendingLazada() {
        return parseFloat(this.mData?.lazada_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },
      getTotalAvgSpendingShopee() {
        return parseFloat(this.mData?.shopee_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      },

    // Weekly
    async fillDataWeekly () {
      this.datacollectionWeekly = {
          //Data to be represented on x-axis
          labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          datasets: [
            {
              type: 'bar',
              label: 'Online Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesWeekly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#43A047'
              // ],
              yAxisID: '0',
              backgroundColor: '#3D5B96',
              order: 6,
              // backgroundColor: [
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              // ],
            },
            {
              type: 'bar',
              label: 'TeleSale Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesTeleSaleWeekly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#f87979',
              order: 7,
              // backgroundColor: [
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              // ],
            },
            {
              type: 'bar',
              label: 'Lazada Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesLazadaWeekly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#00BCD4',
              order: 8,
              // hidden: true,
            },
            {
              type: 'bar',
              label: 'Shopee Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#FC0FC0',
              data: this.getRevenuesShopeeWeekly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#FC0FC0',
              order: 9,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Online Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getTicketsWeekly(),
              borderColor: '#03A9F4',
              // borderColor: [
              //   '#3F51B5'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 1,
            },
            {
              type: 'line',
              label: 'TeleSale Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#ff0000',
              data: this.getTicketsTeleSaleWeekly(),
              borderColor: '#ff0000',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 2,
            },
            {
              type: 'line',
              label: 'Lazada Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#FFC107',
              data: this.getTicketsLazadaWeekly(),
              borderColor: '#FFC107',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 3,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Shopee Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#CDDC39',
              data: this.getTicketsShopeeWeekly(),
              borderColor: '#CDDC39',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 4,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Total Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#9C27B0',
              data: this.getSumTicketsWeekly(),
              borderColor: '#9C27B0',
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 5,
              hidden: true,
            },
            {
              type: 'bar',
              label: 'Total Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#4CAF50',
              data: this.getSumRevenuesWeekly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#4CAF50',
              order: 10,
              stack: 'false',
              hidden: true,
            },
          ]};
    },
    getCurrentWeekly() {
      return `${this.mDataWeekly?.week} (${this.mDataWeekly?.week_sdate}) - (${this.mDataWeekly?.week_edate})`;
    },
    getRevenuesWeekly() {
      return this.mAmountWeeklyArray;
    },
    getTicketsWeekly() {
      return this.mTicketWeeklyArray;
    },
    getTicketsTeleSaleWeekly() {
      return this.mTicketTeleSaleWeeklyArray;
    },
    getRevenuesTeleSaleWeekly() {
      return this.mAmountTeleSaleWeeklyArray;
    },
    getTicketsLazadaWeekly() {
      return this.mTicketLazadaWeeklyArray;
    },
    getRevenuesLazadaWeekly() {
      return this.mAmountLazadaWeeklyArray;
    },
    getTicketsShopeeWeekly() {
      return this.mTicketShopeeWeeklyArray;
    },
    getRevenuesShopeeWeekly() {
      return this.mAmountShopeeWeeklyArray;
    },
    getSumRevenuesWeekly() {
      return this.mSumAmountWeeklyArray;
    },
    getSumTicketsWeekly() {
      return this.mSumTicketWeeklyArray;
    },

    getTotalRevenueTotalWeekly() {
      return parseFloat(this.mDataWeekly?.total_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueWeekly() {
      return parseFloat(this.mDataWeekly?.amounts ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueTeleSaleWeekly() {
      return parseFloat(this.mDataWeekly?.tele_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueLazadaWeekly() {
      return parseFloat(this.mDataWeekly?.lazada_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueShopeeWeekly() {
      return parseFloat(this.mDataWeekly?.shopee_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    getTotalShippingTotalWeekly() {
      return parseFloat(this.mDataWeekly?.total_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingWeekly() {
      return parseFloat(this.mDataWeekly?.shippings ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingTeleSaleWeekly() {
      return parseFloat(this.mDataWeekly?.tele_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingLazadaWeekly() {
      return parseFloat(this.mDataWeekly?.lazada_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingShopeeWeekly() {
      return parseFloat(this.mDataWeekly?.shopee_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    getTotalTicketTotalWeekly() {
      return parseInt(this.mDataWeekly?.total_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketWeekly() {
      return parseInt(this.mDataWeekly?.tickets ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketTeleSaleWeekly() {
      return parseInt(this.mDataWeekly?.tele_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketLazadaWeekly() {
      return parseInt(this.mDataWeekly?.lazada_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketShopeeWeekly() {
      return parseInt(this.mDataWeekly?.shopee_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    
    getTotalAvgSpendingTotalWeekly() {
      return parseFloat(this.mDataWeekly?.total_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingWeekly() {
      return parseFloat(this.mDataWeekly?.averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingTeleSaleWeekly() {
      return parseFloat(this.mDataWeekly?.tele_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingLazadaWeekly() {
      return parseFloat(this.mDataWeekly?.lazada_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingShopeeWeekly() {
      return parseFloat(this.mDataWeekly?.shopee_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    // Monthly
    async fillDataMonthly () {
      let labels = [];
      let maxDays = this.mDataMonthly?.maxDays;
      for (let index = 0; index < maxDays; index++) {
        labels.push(index+1);
      }
      this.datacollectionMonthly = {
          //Data to be represented on x-axis
          labels: labels,
          datasets: [
            {
              type: 'bar',
              label: 'Online Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesMonthly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#43A047'
              // ],
              yAxisID: '0',
              backgroundColor: '#3D5B96',
              order: 6,
              // backgroundColor: [
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',

              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',

              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              // ],
            },
            {
              type: 'bar',
              label: 'TeleSale Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesTeleSaleMonthly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#f87979',
              order: 7,
              // backgroundColor: [
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',

              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',

              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              // ],
            },
            {
              type: 'bar',
              label: 'Lazada Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesLazadaMonthly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#00BCD4',
              order: 8,
              // hidden: true,
            },
            {
              type: 'bar',
              label: 'Shopee Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#FC0FC0',
              data: this.getRevenuesShopeeMonthly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#FC0FC0',
              order: 9,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Online Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getTicketsMonthly(),
              borderColor: '#03A9F4',
              // borderColor: [
              //   '#3F51B5'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 1,
            },
            {
              type: 'line',
              label: 'TeleSale Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#ff0000',
              data: this.getTicketsTeleSaleMonthly(),
              borderColor: '#ff0000',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 2,
            },
            {
              type: 'line',
              label: 'Lazada Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#FFC107',
              data: this.getTicketsLazadaMonthly(),
              borderColor: '#FFC107',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 3,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Shopee Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#CDDC39',
              data: this.getTicketsShopeeMonthly(),
              borderColor: '#CDDC39',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 4,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Total Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#9C27B0',
              data: this.getSumTicketsMonthly(),
              borderColor: '#9C27B0',
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 5,
              hidden: true,
            },
            {
              type: 'bar',
              label: 'Total Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#4CAF50',
              data: this.getSumRevenuesMonthly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#4CAF50',
              order: 10,
              stack: 'false',
              hidden: true,
            },
          ]};
    },
    getCurrentMonthly() {
      return this.mDataMonthly?.month;
    },
    getRevenuesMonthly() {
      return this.mAmountMonthlyArray;
    },
    getTicketsMonthly() {
      return this.mTicketMonthlyArray;
    },
    getRevenuesTeleSaleMonthly() {
      return this.mAmountTeleSaleMonthlyArray;
    },
    getTicketsTeleSaleMonthly() {
      return this.mTicketTeleSaleMonthlyArray;
    },
    getRevenuesLazadaMonthly() {
      return this.mAmountLazadaMonthlyArray;
    },
    getTicketsLazadaMonthly() {
      return this.mTicketLazadaMonthlyArray;
    },
    getRevenuesShopeeMonthly() {
      return this.mAmountShopeeMonthlyArray;
    },
    getTicketsShopeeMonthly() {
      return this.mTicketShopeeMonthlyArray;
    },
    getSumRevenuesMonthly() {
      return this.mSumAmountMonthlyArray;
    },
    getSumTicketsMonthly() {
      return this.mSumTicketMonthlyArray;
    },

    getTotalRevenueTotalMonthly() {
      return parseFloat(this.mDataMonthly?.total_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueMonthly() {
      return parseFloat(this.mDataMonthly?.amounts ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueTeleSaleMonthly() {
      return parseFloat(this.mDataMonthly?.tele_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueLazadaMonthly() {
      return parseFloat(this.mDataMonthly?.lazada_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueShopeeMonthly() {
      return parseFloat(this.mDataMonthly?.shopee_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    getTotalShippingTotalMonthly() {
      return parseFloat(this.mDataMonthly?.total_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingMonthly() {
      return parseFloat(this.mDataMonthly?.shippings ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingTeleSaleMonthly() {
      return parseFloat(this.mDataMonthly?.tele_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingLazadaMonthly() {
      return parseFloat(this.mDataMonthly?.lazada_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingShopeeMonthly() {
      return parseFloat(this.mDataMonthly?.shopee_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    getTotalTicketTotalMonthly() {
      return parseInt(this.mDataMonthly?.total_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketMonthly() {
      return parseInt(this.mDataMonthly?.tickets ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketTeleSaleMonthly() {
      return parseInt(this.mDataMonthly?.tele_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketLazadaMonthly() {
      return parseInt(this.mDataMonthly?.lazada_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketShopeeMonthly() {
      return parseInt(this.mDataMonthly?.shopee_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },

    getTotalAvgSpendingTotalMonthly() {
      return parseFloat(this.mDataMonthly?.total_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingMonthly() {
      return parseFloat(this.mDataMonthly?.averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingTeleSaleMonthly() {
      return parseFloat(this.mDataMonthly?.tele_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingLazadaMonthly() {
      return parseFloat(this.mDataMonthly?.lazada_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingShopeeMonthly() {
      return parseFloat(this.mDataMonthly?.shopee_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    // Yearly
    async fillDataYearly () {
      this.datacollectionYearly = {
          //Data to be represented on x-axis
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          datasets: [
            {
              type: 'bar',
              label: 'Online Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesYearly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#43A047'
              // ],
              yAxisID: '0',
              backgroundColor: '#3D5B96',
              order: 6,
              // backgroundColor: [
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              // ],
            },
            {
              type: 'bar',
              label: 'TeleSale Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesTeleSaleYearly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#f87979',
              order: 7,
            },
            {
              type: 'bar',
              label: 'Lazada Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesLazadaYearly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#00BCD4',
              order: 8,
              // hidden: true,
            },
            {
              type: 'bar',
              label: 'Shopee Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#FC0FC0',
              data: this.getRevenuesShopeeYearly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#FC0FC0',
              order: 9,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Online Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getTicketsYearly(),
              borderColor: '#03A9F4',
              // borderColor: [
              //   '#3F51B5'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 1,
            },
            {
              type: 'line',
              label: 'TeleSale Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#ff0000',
              data: this.getTicketsTeleSaleYearly(),
              borderColor: '#ff0000',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 2,
            },
            {
              type: 'line',
              label: 'Lazada Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#FFC107',
              data: this.getTicketsLazadaYearly(),
              borderColor: '#FFC107',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 3,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Shopee Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#CDDC39',
              data: this.getTicketsShopeeYearly(),
              borderColor: '#CDDC39',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 4,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Total Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#9C27B0',
              data: this.getSumTicketsYearly(),
              borderColor: '#9C27B0',
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 5,
              hidden: true,
            },
            {
              type: 'bar',
              label: 'Total Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#4CAF50',
              data: this.getSumRevenuesYearly(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#4CAF50',
              order: 10,
              stack: 'false',
              hidden: true,
            },
          ]};
    },
    getCurrentYearly() {
      let year = this.date == null || this.date == "" ? "" : moment(this.date).format('YYYY');
      return year;
    },
    getRevenuesYearly() {
      return this.mAmountYearlyArray;
    },
    getTicketsYearly() {
      return this.mTicketYearlyArray;
    },
    getRevenuesTeleSaleYearly() {
      return this.mAmountTeleSaleYearlyArray;
    },
    getTicketsTeleSaleYearly() {
      return this.mTicketTeleSaleYearlyArray;
    },
    getRevenuesLazadaYearly() {
      return this.mAmountLazadaYearlyArray;
    },
    getTicketsLazadaYearly() {
      return this.mTicketLazadaYearlyArray;
    },
    getRevenuesShopeeYearly() {
      return this.mAmountShopeeYearlyArray;
    },
    getTicketsShopeeYearly() {
      return this.mTicketShopeeYearlyArray;
    },
    getSumRevenuesYearly() {
      return this.mSumAmountYearlyArray;
    },
    getSumTicketsYearly() {
      return this.mSumTicketYearlyArray;
    },

    getTotalRevenueTotalYearly() {
      return parseFloat(this.mDataYearly?.total_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueYearly() {
      return parseFloat(this.mDataYearly?.amounts ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueTeleSaleYearly() {
      return parseFloat(this.mDataYearly?.tele_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueLazadaYearly() {
      return parseFloat(this.mDataYearly?.lazada_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueShopeeYearly() {
      return parseFloat(this.mDataYearly?.shopee_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    getTotalShippingTotalYearly() {
      return parseFloat(this.mDataYearly?.total_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingYearly() {
      return parseFloat(this.mDataYearly?.shippings ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingTeleSaleYearly() {
      return parseFloat(this.mDataYearly?.tele_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingLazadaYearly() {
      return parseFloat(this.mDataYearly?.lazada_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingShopeeYearly() {
      return parseFloat(this.mDataYearly?.shopee_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    getTotalTicketTotalYearly() {
      return parseInt(this.mDataYearly?.total_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketYearly() {
      return parseInt(this.mDataYearly?.tickets ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketTeleSaleYearly() {
      return parseInt(this.mDataYearly?.tele_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketLazadaYearly() {
      return parseInt(this.mDataYearly?.lazada_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketShopeeYearly() {
      return parseInt(this.mDataYearly?.shopee_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },

    getTotalAvgSpendingTotalYearly() {
      return parseFloat(this.mDataYearly?.total_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingYearly() {
      return parseFloat(this.mDataYearly?.averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingTeleSaleYearly() {
      return parseFloat(this.mDataYearly?.tele_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingLazadaYearly() {
      return parseFloat(this.mDataYearly?.lazada_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingShopeeYearly() {
      return parseFloat(this.mDataYearly?.shopee_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    // Summary
    async fillDataSummary () {
      this.datacollectionSummary = {
          //Data to be represented on x-axis
          labels: ['2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032'],
          datasets: [
            {
              type: 'bar',
              label: 'Online Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesSummary(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#43A047'
              // ],
              yAxisID: '0',
              backgroundColor: '#3D5B96',
              order: 6,
              // backgroundColor: [
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              //   'rgba(255, 99, 132, 0.2)',
              //   'rgba(54, 162, 235, 0.2)',
              //   'rgba(255, 206, 86, 0.2)',
              //   'rgba(75, 192, 192, 0.2)',
              //   'rgba(153, 102, 255, 0.2)',
              //   'rgba(255, 159, 64, 0.2)',
              // ],
            },
            {
              type: 'bar',
              label: 'TeleSale Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesTeleSaleSummary(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#f87979',
              order: 7,
            },
            {
              type: 'bar',
              label: 'Lazada Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getRevenuesLazadaSummary(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#00BCD4',
              order: 8,
              // hidden: true,
            },
            {
              type: 'bar',
              label: 'Shopee Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#FC0FC0',
              data: this.getRevenuesShopeeSummary(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#FC0FC0',
              order: 9,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Online Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#249EBF',
              data: this.getTicketsSummary(),
              borderColor: '#03A9F4',
              // borderColor: [
              //   '#3F51B5'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 1,
            },
            {
              type: 'line',
              label: 'TeleSale Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#ff0000',
              data: this.getTicketsTeleSaleSummary(),
              borderColor: '#ff0000',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 2,
            },
            {
              type: 'line',
              label: 'Lazada Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#FFC107',
              data: this.getTicketsLazadaSummary(),
              borderColor: '#FFC107',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 3,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Shopee Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#CDDC39',
              data: this.getTicketsShopeeSummary(),
              borderColor: '#CDDC39',
              // borderColor: [
              //   '#ff0000'
              // ],
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 4,
              // hidden: true,
            },
            {
              type: 'line',
              label: 'Total Ticket',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#9C27B0',
              data: this.getSumTicketsSummary(),
              borderColor: '#9C27B0',
              yAxisID: '1',
              backgroundColor: [ 'transparent' ],
              order: 5,
              hidden: true,
            },
            {
              type: 'bar',
              label: 'Total Revenue',
              pointBackgroundColor: 'white',
              borderWidth: 2,
              pointBorderColor: '#4CAF50',
              data: this.getSumRevenuesSummary(),
              borderColor: 'transparent',
              // borderColor: [
              //   '#FF9800'
              // ],
              yAxisID: '0',
              backgroundColor: '#4CAF50',
              order: 10,
              stack: 'false',
              hidden: true,
            },
          ]};
    },
    getRevenuesSummary() {
      return this.mAmountSummaryArray;
    },
    getTicketsSummary() {
      return this.mTicketSummaryArray;
    },
    getRevenuesTeleSaleSummary() {
      return this.mAmountTeleSaleSummaryArray;
    },
    getTicketsTeleSaleSummary() {
      return this.mTicketTeleSaleSummaryArray;
    },
    getRevenuesLazadaSummary() {
      return this.mAmountLazadaSummaryArray;
    },
    getTicketsLazadaSummary() {
      return this.mTicketLazadaSummaryArray;
    },
    getRevenuesShopeeSummary() {
      return this.mAmountShopeeSummaryArray;
    },
    getTicketsShopeeSummary() {
      return this.mTicketShopeeSummaryArray;
    },
    getSumRevenuesSummary() {
      return this.mSumAmountSummaryArray;
    },
    getSumTicketsSummary() {
      return this.mSumTicketSummaryArray;
    },

    getTotalRevenueTotalSummary() {
      return parseFloat(this.mDataSummary?.total_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueSummary() {
      return parseFloat(this.mDataSummary?.amounts ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueTeleSaleSummary() {
      return parseFloat(this.mDataSummary?.tele_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueLazadaSummary() {
      return parseFloat(this.mDataSummary?.lazada_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalRevenueShopeeSummary() {
      return parseFloat(this.mDataSummary?.shopee_amount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    getTotalShippingTotalSummary() {
      return parseFloat(this.mDataSummary?.total_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingSummary() {
      return parseFloat(this.mDataSummary?.shippings ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingTeleSaleSummary() {
      return parseFloat(this.mDataSummary?.tele_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingLazadaSummary() {
      return parseFloat(this.mDataSummary?.lazada_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalShippingShopeeSummary() {
      return parseFloat(this.mDataSummary?.shopee_shipping ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },

    getTotalTicketTotalSummary() {
      return parseInt(this.mDataSummary?.total_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketSummary() {
      return parseInt(this.mDataSummary?.tickets ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketTeleSaleSummary() {
      return parseInt(this.mDataSummary?.tele_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketLazadaSummary() {
      return parseInt(this.mDataSummary?.lazada_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },
    getTotalTicketShopeeSummary() {
      return parseInt(this.mDataSummary?.shopee_ticket ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":0});
    },

    getTotalAvgSpendingTotalSummary() {
      return parseFloat(this.mDataSummary?.total_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingSummary() {
      return parseFloat(this.mDataSummary?.averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingTeleSaleSummary() {
      return parseFloat(this.mDataSummary?.tele_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingLazadaSummary() {
      return parseFloat(this.mDataSummary?.lazada_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    },
    getTotalAvgSpendingShopeeSummary() {
      return parseFloat(this.mDataSummary?.shopee_averageSpending ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
    }

  },

  computed: {
    currentDate() {
      // return "2021-08-09";
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    },
    // currentYear() {
    //   return this.currentDate.substr(0, 4);
    // },
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    userGroup() {
      return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    },
    userType() {
      return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    },
    userBrcode() {
      return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    },
    // branchs() {
    //   return this.$store.getters["branchs"] ?? [];
    // },
  }
}
</script>

<style scoped>
  .header {
  font-size: 20px;
  font-weight: bold;
  color: #B71C1C;
}
</style>