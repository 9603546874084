<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-0 pa-5" outlined>
          <v-form @submit.prevent="submit">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="campaignsContent.ID"
                  label="ID"
                  :disabled="ID != ''"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  v-model="campaignsContent.IsPreview"
                  label="Preview"
                ></v-checkbox>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="campaignsContent.Name"
                  label="Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="campaignsContent.Name"
                  label="Name"
                ></v-text-field>
              </v-col>
            </v-row> -->

            <v-divider></v-divider>
            <br />
            <template>
              <v-row>
                <v-col cols="12">
                    <v-row class="ma-0">
                        <h3>Campaigns</h3>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-btn class="mr-3" color="primary" @click.stop="showBanner(null)">
                            <v-icon left>add</v-icon>Banner
                        </v-btn>
                        <v-btn class="mr-3" color="primary" @click.stop="showCarousel(null)">
                            <v-icon left>add</v-icon>Carousel
                        </v-btn>
                        <v-btn color="primary" @click.stop="showGrid(null)">
                            <v-icon left>add</v-icon>Grid
                        </v-btn>
                    </v-row>

                    <div class="table_campaigns" v-if="campaigns != null">
                      <table class="table table-striped" width="100%">
                          <thead class="head-dark" align-center>
                          <tr>
                              <th width="30%" style="text-align:left">Type</th>
                              <th width="60%" style="text-align:left">Title</th>
                              <th scope="10%" style="text-align:left">Action</th>
                          </tr>
                          </thead>
                          <draggable v-model="campaigns" tag="tbody">
                              <tr v-for="item, index in campaigns" :key="item.type + index">
                                  <td v-if="item.type">{{ item.type }}</td>
                                  <td v-else></td>
                                  <td v-if="item.title && item.title.text">{{ item.title.text }}</td>
                                  <td v-else></td>
                                  <td>
                                      <v-icon @click="showPopup(item)">
                                          edit
                                      </v-icon>
                                      <v-icon class="ml-3" @click="removeDetailItem(campaigns, item)">
                                          delete
                                      </v-icon>
                                  </td>
                              </tr>
                          </draggable>
                      </table>
                    </div>
                </v-col>
              </v-row>
            </template>

            <v-divider></v-divider>
            <br />
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click="cancel">
                Cancel
              </v-btn>

              <v-btn color="primary" type="submit">
                Confirm
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- Banner -->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="isShowBanner"
          max-width="1100px">
          <v-card>
            <v-card-title>Banner</v-card-title>
            <v-divider></v-divider>
            <div class="pa-5" v-if="banner && banner.title">
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    outlined
                    v-model="banner.title.text"
                    label="Title Text"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="banner.title.text_color"
                    label="Title Text Color"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="banner.title.background_color"
                    label="Title Background Color"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- content -->
              <v-row>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="bannerUrl"
                    label="BannerUrl"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="destination"
                    label="Destination"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn class="mt-3" color="primary" @click.stop="addBanner">
                    <v-icon left>add</v-icon>Add
                  </v-btn>
                </v-col>
              </v-row>
              <div class="table_banner" v-if="banner.content && banner.content.banners">
                <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                    <tr>
                        <th width="45%" style="text-align:left">BannerUrl</th>
                        <th width="45%" style="text-align:left">Destination</th>
                        <th scope="10%" style="text-align:left">Action</th>
                    </tr>
                    </thead>
                    <draggable v-model="banner.content.banners" tag="tbody">
                        <tr v-for="item, index in banner.content.banners" :key="item.bannerUrl + index">
                            <td v-if="item.bannerUrl">{{ item.bannerUrl }}</td>
                            <td v-else></td>
                            <td v-if="item.destination">{{ item.destination }}</td>
                            <td v-else></td>
                            <td>
                                <v-icon @click="removeDetailItem(banner.content.banners, item)">
                                    delete
                                </v-icon>
                            </td>
                        </tr>
                    </draggable>
                </table>
              </div>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="isShowBanner = false">
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="addCampaignBanner">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <!-- Grid -->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="isShowGrid"
          max-width="1100px">
          <v-card>
            <v-card-title>Grid</v-card-title>
            <v-divider></v-divider>
            <div class="pa-5" v-if="grid && grid.title">
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    outlined
                    v-model="grid.title.text"
                    label="Title Text"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="grid.title.text_color"
                    label="Title Text Color"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="grid.title.background_color"
                    label="Title Background Color"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- content -->
              <v-row>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="bannerUrl"
                    label="BannerUrl"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="destination"
                    label="Destination"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn class="mt-3" color="primary" @click.stop="addGrid">
                    <v-icon left>add</v-icon>Add
                  </v-btn>
                </v-col>
              </v-row>
              <div class="table_banner" v-if="grid.content && grid.content.banners">
                <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                    <tr>
                        <th width="45%" style="text-align:left">BannerUrl</th>
                        <th width="45%" style="text-align:left">Destination</th>
                        <th scope="10%" style="text-align:left">Action</th>
                    </tr>
                    </thead>
                    <draggable v-model="grid.content.banners" tag="tbody">
                        <tr v-for="item, index in grid.content.banners" :key="item.bannerUrl + index">
                            <td v-if="item.bannerUrl">{{ item.bannerUrl }}</td>
                            <td v-else></td>
                            <td v-if="item.destination">{{ item.destination }}</td>
                            <td v-else></td>
                            <td>
                                <v-icon @click="removeDetailItem(grid.content.banners, item)">
                                    delete
                                </v-icon>
                            </td>
                        </tr>
                    </draggable>
                </table>
              </div>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="isShowGrid = false">
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="addCampaignGrid">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <!-- Carousel -->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="isShowCarousel"
          max-width="1100px">
          <v-card>
            <v-card-title>Carousel</v-card-title>
            <v-divider></v-divider>
            <div class="pa-5" v-if="carousel && carousel.title">
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    outlined
                    v-model="carousel.title.text"
                    label="Title Text"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="carousel.title.text_color"
                    label="Title Text Color"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="carousel.title.background_color"
                    label="Title Background Color"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- content -->
              <v-row>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="bannerUrl"
                    label="Label"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="destination"
                    label="Destination"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn class="mt-3" color="primary" @click.stop="addCarousel">
                    <v-icon left>add</v-icon>Add
                  </v-btn>
                </v-col>
              </v-row>
              <div class="table_carousel" v-if="carousel.content && carousel.content.categories">
                <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                    <tr>
                        <th width="45%" style="text-align:left">Label</th>
                        <th width="45%" style="text-align:left">Destination</th>
                        <th scope="10%" style="text-align:left">Action</th>
                    </tr>
                    </thead>
                    <draggable v-model="carousel.content.categories" tag="tbody">
                        <tr v-for="item, index in carousel.content.categories" :key="item.label + index">
                            <td v-if="item.label">{{ item.label }}</td>
                            <td v-else></td>
                            <td v-if="item.destination">{{ item.destination }}</td>
                            <td v-else></td>
                            <td>
                                <v-icon @click="removeDetailItem(carousel.content.categories, item)">
                                    delete
                                </v-icon>
                            </td>
                        </tr>
                    </draggable>
                </table>
              </div>

              <v-row class="pa-5">
                <h3>Products</h3>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn color="primary" @click.stop="showProductSearch=true">
                  <v-icon left>add</v-icon>
                  Add Product
                </v-btn> 
                <ProductSearch v-model="showProductSearch" :onSelect="onSelectMulti" />
                <v-btn color="primary" @click="clearProduct">
                  <v-icon left>delete</v-icon>
                  Clear All
                </v-btn>
                <v-spacer></v-spacer>
                <v-tooltip bottom color="pink darken-4">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 class="mr-3" v-bind="attrs" v-on="on">Import Excel (XLSX)</h3>
                  </template>
                  <span>ใส่รหัสสินค้า 1 column ต้องมี column header ชื่อ prcode</span>
                  </v-tooltip>
                <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
                <v-spacer></v-spacer>
              </v-row>

              <br />
              <template>
                <div class="row">
                  <div class="col-12">
                    <table class="table table-striped" width="100%">
                      <thead class="thead-dark" align-center>
                        <tr>
                          <th scope="col" style="text-align: left">Image</th>
                          <th scope="col" style="text-align: left">(C) Pr Code</th>
                          <th scope="col" style="text-align: left">Pr Name</th>
                          <th scope="col" style="text-align: left">Online</th>
                          <!-- <th scope="col" style="text-align: left">Enabled</th> -->
                          <th scope="col" style="text-align: left">Action</th>
                        </tr>
                      </thead>
                      <draggable v-model="mProductArray" tag="tbody">
                        <tr v-for="item in mProductArray" :key="item.cprcode">
                          <td style="vertical-align: middle;">
                              <v-img
                                contain
                                class="ma-2"
                                :eager="true"
                                :src="item.image"
                                lazy-src="@/assets/noimage.png"
                                aspect-ratio="1"
                                min-width="80"
                                max-width="80"
                                max-height="80"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular v-show="loading"
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                          </td>
                          <td style="vertical-align: middle;">{{ item.cprcode }}</td>
                          <td style="vertical-align: middle;">{{ item.hema_name_en }}</td>
                          <td style="vertical-align: middle;">
                            <v-checkbox
                              readonly
                              v-model="item.master_online"
                            ></v-checkbox>
                          </td>
                          <!-- <td style="vertical-align: middle;">
                            <v-checkbox
                              readonly
                              v-model="item.enabled"
                            ></v-checkbox>
                          </td> -->
                          <td style="vertical-align: middle;">
                            <v-icon @click="removeItem(item)">
                              delete
                            </v-icon>
                          </td>
                        </tr>
                      </draggable>
                    </table>
                  </div>
                </div>
              </template>
            </div>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="isShowCarousel = false">
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="addCampaignCarousel">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <!-- <v-color-picker 
      v-show="false"
      mode="hex"
      hide-canvas
      hide-sliders
      show-swatches
      hide-inputs
      v-model="banner.title.background_color">
    </v-color-picker> -->
  </v-container>
</template>

<script>
import api from "@/services/lambdaApi";
import campaignsService from "@/services/campaignsService";
import XLSX from 'xlsx';
import draggable from 'vuedraggable'
import ProductSearch from "@/components/product/ProductSearch2"
// import ColorPicker from "@/components/ColorPicker"

export default {
  name: "campaignscontent-edit",
  components: {
      draggable,
      ProductSearch,
      // ColorPicker
  },
  data: () => ({
    loading: false,
    isShowBanner: false,
    isShowCarousel: false,
    isShowGrid: false,
    showProductSearch: false,
    showColorPicker: false,
    showBackgroundColorPicker: false,
    ID: "",
    campaignsContent: {
      ID: "",
      Name: "",
      IsPreview: false,
      // campaigns: [
      // {
      //     "type": "banner",
      //     "title": {
      //         "text": "Some section title (required)",
      //         "text_color": "#2c2a74",
      //         "background_color": "#ffffff"
      //     },
      //     "content": {
      //         "banners": [
      //         {
      //             "bannerUrl": "https://shop.villamarket.com/img/App-Banner-1000x250px.bcb2bb7c.jpg",
      //             "destination": "https://shop.villamarket.com"
      //         }
      //         ]
      //     }
      //     },
      //     {
      //         "type": "carousel",
      //         "title": {
      //             "text": "Carousel section title",
      //             "text_color": "#2c2a74",
      //             "background_color": "#ffffff"
      //         },
      //         "content": {
      //             "categories": [
      //             {
      //                 "label": "Best seller",
      //                 "destination": "https://shop.villamarket.com"
      //             },
      //             {
      //                 "label": "Beef",
      //                 "destination": "https://shop.villamarket.com"
      //             },
      //                         {
      //                 "label": "Chicken & Duck",
      //                 "destination": "https://shop.villamarket.com"
      //             }
      //             ],
      //             "products": [
      //             160669,
      //             204597,
      //             160669,
      //             204597,
      //             160669,
      //             204597,
      //             160669,
      //             204597,
      //             ]
      //         }
      //     },
      //     {
      //         "type": "grid",
      //         "title": {
      //             "text": "Grid section title",
      //             "text_color": "#2c2a74",
      //             "background_color": "#ffffff"
      //         },
      //         "content": {
      //             "banners": [
      //             {
      //                 "bannerUrl": "https://shop.villamarket.com/img/App-Banner-1000x250px.bcb2bb7c.jpg",
      //                 "destination": "https://shop.villamarket.com"
      //             }
      //             ]
      //         }
      //     }
      // ]
    },
    bannerUrl: "",
    destination: "",
    campaigns: [],
    campaign: {},
    banner: {},
    carousel: {},
    grid: {},
    mProductArray: []
  }),
  async mounted() {
    try {
      this.loading = true;
      this.resetBanner();
      this.ID = this.$route.params.id ?? "";
      if (this.ID != "")
      {
        let result = await campaignsService.getItem(this.ID);
        if (result != null) {
          if (result.Data) {
              this.campaignsContent = result.Data;
              let dataJson = JSON.parse(result.Data.Data);
              // console.log(dataJson);
              this.campaigns = dataJson.campaigns;
              // console.log(this.campaigns);
          }
        }
      }
    } catch (error) {
      alert(error)
    }
    finally {
      this.loading = false;
    } 
  },

  methods: {
    removeDetailItem: function (objectList, item) {
      let index = objectList.indexOf(item);
      if (index >= 0) {
        objectList.splice(index, 1)
      }
    },
    showPopup(item) {
      switch (item.type) {
        case "banner":
          this.showBanner(item);
          break;
        case "grid":
          this.showGrid(item);
          break;
        case "carousel":
          this.showCarousel(item);
          break;
        default:
          alert('Type not found')
          break;
      }
    },

    // Banner
    resetBanner() {
      this.banner = {
        new: true,
        type: "banner",
        title: {
          text: "",
          text_color: "#2c2a74",
          background_color: "#ffffff"
        },
        content: {
          banners: []
        }
      }
    },
    showBanner(item) {
      if (item == null) {
        this.resetBanner();
      } 
      else {
        this.banner = item;
        this.banner["new"] = false;
      };
      this.isShowBanner = true;
    },
    addBanner() {
      if (!this.bannerUrl) {
        alert("Please enter BannerUrl");
        return;
      };
      if (!this.destination) {
        alert("Please enter Destination");
        return;
      };
      let newItem = {
        bannerUrl: this.bannerUrl,
        destination: this.destination
      };
      if (!this.banner.content)
        this.banner["content"] = {
          banners: []
        };
      if (!this.banner.content.banners)
        this.banner.content.banners = [];
      this.banner.content.banners.push(newItem);
      this.bannerUrl = "";
      this.destination = "";
    },
    addCampaignBanner() {
      if (this.banner["new"] ?? false == true)
        this.campaigns.push(this.banner);
      delete this.banner["new"];
      this.isShowBanner = false;
    },
    
    // Grid
    resetGrid() {
      this.grid = {
        new: true,
        type: "grid",
        title: {
          text: "",
          text_color: "#2c2a74",
          background_color: "#ffffff"
        },
        content: {
          banners: []
        }
      }
    },
    showGrid(item) {
      if (item == null) {
        this.resetGrid();
      } 
      else {
        this.grid = item;
        this.grid["new"] = false;
      };
      this.isShowGrid = true;
    },
    addGrid() {
      if (!this.bannerUrl) {
        alert("Please enter BannerUrl");
        return;
      };
      if (!this.destination) {
        alert("Please enter Destination");
        return;
      };
      let newItem = {
        bannerUrl: this.bannerUrl,
        destination: this.destination
      };
      if (!this.grid.content)
        this.grid["content"] = {
          banners: []
        };
      if (!this.grid.content.banners)
        this.grid.content.banners = [];
      this.grid.content.banners.push(newItem);
      this.bannerUrl = "";
      this.destination = "";
    },
    addCampaignGrid() {
      if (this.grid["new"] ?? false == true)
        this.campaigns.push(this.grid);
      delete this.grid["new"];
      this.isShowGrid = false;
    },

    // Carousel
    resetCarousel() {
      this.carousel = {
        new: true,
        type: "carousel",
        title: {
          text: "",
          text_color: "#2c2a74",
          background_color: "#ffffff"
        },
        content: {
          categories: [],
          products: []
        }
      }
    },
    async showCarousel(item) {
      if (item == null) {
        this.resetCarousel();
        this.mProductArray = [];
      } 
      else {
        this.carousel = item;
        this.carousel["new"] = false;
        // this.mProductArray = this.carousel.content?.products ?? [];
        this.mProductArray = [];
        let prcodeList = this.carousel.content?.products ?? [];
        if (prcodeList && prcodeList.length > 0) {
          let result = await api.getProductByPrcodeList(prcodeList);
          this.mProductArray = result.data;
        }
      };
      this.isShowCarousel = true;
    },
    addCarousel() {
      if (!this.bannerUrl) {
        alert("Please enter Label");
        return;
      };
      if (!this.destination) {
        alert("Please enter Destination");
        return;
      };
      let newItem = {
        label: this.bannerUrl,
        destination: this.destination
      };
      if (!this.carousel.content)
        this.carousel["content"] = {
          categories: [],
          products: []
      };
      if (!this.carousel.content.categories)
        this.carousel.content.categories = [];
      this.carousel.content.categories.push(newItem);
      this.bannerUrl = "";
      this.destination = "";
    },
    addCampaignCarousel() {
      if (!this.carousel.content)
        this.carousel["content"] = {
          categories: [],
          products: []
      };
      let prcodeList = [];
      this.mProductArray.forEach(item => {
        prcodeList.push(parseInt(item.cprcode));
      });
      this.carousel.content["products"] = prcodeList;
      if (this.carousel["new"] ?? false == true)
        this.campaigns.push(this.carousel);
      delete this.carousel["new"];
      // console.log('-- campaigns --', JSON.stringify(this.campaigns));
      this.isShowCarousel = false;
    },

    clearProduct() {
      this.mProductArray = [];
    },
    onFileImportSelected(e) {
      try {
        this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile.value = "";
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    previewFiles(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let prcodes = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- prcodes --', prcodes);
            let prcodeList = [];
            prcodes.forEach(item => {
              prcodeList.push(parseInt(item.prcode ?? 0));
            });
            if (prcodeList && prcodeList.length>0) {
              // console.log('-- prcodeList --', prcodeList);
              let result = await api.getProductByPrcodeList(prcodeList);
              let productList = result.data;
              // console.log('-- productList --', productList);
              productList.forEach(product => {
                // console.log('-- product --', product);
                vm.onSelect(product);
              });
            }
        };
        reader.readAsArrayBuffer(file);
    },
    onSelectMulti(items) {
      if (items != null) {
        items.forEach(item => {
          if (item.selected == 1) {
            let mProduct = this.mProductArray.find(d => d.cprcode === item.cprcode);
            if (!mProduct) {
              let product = {
                "cprcode": item.cprcode,
                "hema_name_en": item.hema_name_en,
                "image": item.image,
                "master_online": item.master_online,
                // "enabled": item.enabled
              };
              this.mProductArray.push(product);
            }
          }
        });
      }
    },
    onSelect(item) {
      //console.log(JSON.stringify(item));
      if (item != null) {
        let mProduct = this.mProductArray.find(d => d.cprcode === item.cprcode);
        if (!mProduct) {
          let product = {
            "cprcode": item.cprcode,
            "hema_name_en": item.hema_name_en,
            "image": item.image,
            "master_online": item.master_online,
            // "enabled": item.enabled
          };
          this.mProductArray.push(product);
        }
      }
    },
    removeItem: function (item) {
      let mProduct = this.mProductArray.find(d => d.cprcode === item.cprcode);
      if (mProduct) {
        let index = this.mProductArray.indexOf(mProduct);
        if (index >= 0) {
          this.mProductArray.splice(index, 1)
        }
      }
    },
    
    cancel() {
      this.$router.back();
    },
    async submit() {
      try {
        this.loading = true;
        this.campaigns.forEach(item => {
            delete item["new"];
        });
        let result;
        let data = {
          ID: this.campaignsContent.ID,
          Name: this.campaignsContent.Name,
          IsPreview: this.campaignsContent.IsPreview,
          Data: JSON.stringify({ campaigns: this.campaigns })
        };
        // console.log(JSON.stringify(data));
        if (this.ID == "") {
          result = await campaignsService.createItem(data);
        }
        else {
          result = await campaignsService.updateItem(data);
        }
        // console.log(JSON.stringify(result));
        // if (result.Status == "success")
        //     alert('Save Success.')
        this.$router.back();
      } catch (error) {
        alert(error);
      }
      finally {
        this.loading = false;
      }
    },
  },
  // computed: {
  // }
};
</script>

<style scoped>
table {
border-spacing: 0 0.5rem;
}
.table_campaigns {
 height: 320px;
 overflow: auto;
}
td {
 padding: 5px;
 vertical-align: top;
}
tbody tr:nth-of-type(odd) {
 background-color: rgba(0, 0, 0, .05);
}
.header {
font-size: 20px;
font-weight: bold;
color: #0D47A1;
background-color: rgba(0, 0, 0, .05);
}
.table_banner {
 height: 220px;
 overflow: auto;
}
.table_carousel {
 height: 220px;
 overflow: auto;
}
</style>