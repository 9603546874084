<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row>
          <v-col lg="3" md="3" sm="12" cols="12">
            <v-select 
              outlined 
              v-model="fyearSelect"
              :items="fyearArray"
              label="From Year"
              required
              v-on:change="loadData"
            ></v-select>
          </v-col>
          <v-col lg="3" md="3" sm="12" cols="12">
            <v-select 
              outlined 
              v-model="tyearSelect"
              :items="tyearArray"
              label="To Year"
              required
              v-on:change="loadData"
            ></v-select>
          </v-col>
          <v-col lg="3" md="3" sm="12" cols="12">
            <v-btn @click="loadData" color="primary">
              <v-icon left>search</v-icon>
              <span>Query</span>
            </v-btn>&nbsp;
            <v-btn @click="exportClick" color="primary">
              <v-icon left>save</v-icon>
              <span>Export</span>
            </v-btn>
          </v-col>
      </v-row>
      <template>
        <BarChart style="height: 500px;" :chartData="datacollection" />
      </template>
    </v-container>
  </template>
  
  <script>
    import api from "@/services/lambdaApi";
    import BarChart from '@/components/charts/BarChart'
    import moment from 'moment'
    import XLSX from 'xlsx';
  
    export default {
      name: "salestrend",
      components: {
        BarChart
      },
      data () {
        return {
          loading: false,
          mDataArray: [],

          fyearArray: [],
          tyearArray: [],
          fyearSelect: 0,
          tyearSelect: 0,
  
          //BarChart
          mAmountArray: {},
          mTicketArray: {},
          datacollection: {},
        }
      },
      async mounted () {
        try {
          this.loading = true;
          this.tyearSelect = this.currentYear;
          this.fyearSelect = this.tyearSelect - 2;

          let yearArray = [];
          for (let index = this.fyearSelect; index <= this.tyearSelect; index++) {
            yearArray.push(index);
          }
          this.fyearArray = yearArray;
          this.tyearArray = yearArray;

          await this.loadData();
          // } catch (error) {
        } finally {
          this.loading = false;
        }
      },
      methods: {
        async mapData() {
            if (this.mDataArray) {
                this.mTicketArray = [];
                this.mAmountArray = [];
                let years = Object.keys(this.mDataArray);
                years.forEach(y => {
                    let amounts = [];
                    let tickets = [];
                    for (let index = 1; index <= 52; index++) {
                        amounts.push(0);
                        tickets.push(0);
                    }

                    let weeks = this.mDataArray[y] ?? [];
                    if (weeks && weeks.length > 0) {
                        weeks.forEach(r => {
                            let week = r["week"] ?? 0;
                            if (week >= 1 && week <= 52) {
                                let ticket = r["tickets"] ?? 0;
                                let amount = r["sales"] ?? 0;
                                tickets[week-1] = ticket;
                                amounts[week-1] = amount;
                            }
                        });
                    };
                    this.mTicketArray[y] = tickets;
                    this.mAmountArray[y] = amounts;
                });
            }
        },
        async loadData () {
            try {
                // this.loading = true;
                let data = { action: "salesTrend", fyear: this.fyearSelect, tyear: this.tyearSelect };
                let result = await api.getSaleReport(data);
                this.mDataArray = result.data;
                await this.mapData();
                await this.fillData();
            } catch (error) {
                alert(error);
            } finally {
                // this.loading = false;
            }
        },
        async exportClick() {
            try {
              this.loading = true;
              let dataExports = [];
              let years = Object.keys(this.mDataArray);
              years.forEach(y => {
                let rows = this.mDataArray[y] ?? [];
                if (rows && rows.length > 0) {
                  rows.forEach(r => {
                    let row = {
                      year: r["year"] ?? 0,
                      week: r["week"] ?? 0,
                      sales: r["sales"] ?? 0,
                      tickets: r["tickets"] ?? 0,
                    }
                    dataExports.push(row);
                  });
                }
              });

              let cdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
              const dataWS = XLSX.utils.json_to_sheet(dataExports)
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, dataWS)
              XLSX.writeFile(wb,`SalesTrend_${moment(cdate).format('YYYYMMDD')}.xlsx`)
            } catch (error) {
              alert(error)
            } finally {
              this.loading = false;
            }
        },
        //BarChart  
        async fillData () {
            let labels = [];
            for (let index = 1; index <= 52; index++) {
                labels.push(index);
            }

            let backgroundColor = [
                '#DFFF00',
                '#0000FF',
                '#DE3163',
                '#6495ED',
                '#40E0D0',
                '#CCCCFF',
                '#FFBF00',
                '#FF7F50',
                '#008000',
                '#9FE2BF',
                '#00FF00',
                '#FF00FF',

                '#DFFF00',
                '#0000FF',
                '#DE3163',
                '#6495ED',
                '#40E0D0',
                '#CCCCFF',
                '#FFBF00',
                '#FF7F50',
                '#008000',
                '#9FE2BF',
                '#00FF00',
                '#FF00FF',

                '#DFFF00',
                '#0000FF',
                '#DE3163',
                '#6495ED',
                '#40E0D0',
                '#CCCCFF',
                '#FFBF00',
                '#FF7F50',
                '#008000',
                '#9FE2BF',
                '#00FF00',
                '#FF00FF',
            ];

            let datasets = [];
            let order = 0;
            let years = Object.keys(this.mDataArray);

            years.forEach(y => {
                datasets.push(
                {
                    type: 'line',
                    label: 'Tickets '.concat(y),
                    pointBackgroundColor: 'white',
                    borderWidth: 2,
                    pointBorderColor: '#249EBF',
                    data: this.getTickets(y),
                    borderColor: backgroundColor[order],
                    // borderColor: [
                    //   '#3F51B5'
                    // ],
                    yAxisID: '1',
                    backgroundColor: [ 'transparent' ],
                    // stack: 'false',
                    order: order++,
                  }
                );
            });

            years.forEach(y => {
                datasets.push(
                {
                    type: 'bar',
                    label: 'Sales '.concat(y),
                    pointBackgroundColor: 'white',
                    borderWidth: 2,
                    pointBorderColor: '#249EBF',
                    data: this.getSales(y),
                    borderColor: 'transparent',
                    // borderColor: [
                    //   '#43A047'
                    // ],
                    yAxisID: '0',
                    backgroundColor: backgroundColor[order],
                    stack: (order).toString(),
                    order: order++,
                  }
                );
            });

            this.datacollection = {
                labels: labels,
                datasets: datasets
            };
        },
        getSales(year) {
            return this.mAmountArray[year] ?? [];
        },
        getTickets(year) {
            return this.mTicketArray[year] ?? [];
        },
      },
  
      computed: {
        currentYear() {
          return moment(this.currentDate, "YYYY-MM-DD").year();
        },
        currentDate() {
          // return "2021-08-09";
          return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        },
        userCode() {
          return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
        },
        userGroup() {
          return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
        },
        userType() {
          return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
        },
        userBrcode() {
          return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
        },
      }
    }
  </script>
  
  <style scoped>
  </style>