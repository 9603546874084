<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
        <v-row>
          <v-col lg="3" md="3" sm="12" cols="12">
            <v-select 
              outlined 
              v-model="yearSelect"
              :items="yearArray"
              label="Year"
              required
              v-on:change="loadData"
            ></v-select>
          </v-col>
          <v-col lg="3" md="3" sm="12" cols="12">
            <v-btn @click="loadData" color="primary">
              <v-icon left>search</v-icon>
              <span>Query</span>
            </v-btn>&nbsp;
            <v-btn @click="exportClick" color="primary">
              <v-icon left>save</v-icon>
              <span>Export</span>
            </v-btn>
          </v-col>
        </v-row>
        <div style="overflow: scroll;" v-html="mDataTable"></div>
      <br />
      <!-- <template>
        <BarChart style="height: 500px;" :chartData="datacollection" />
      </template> -->
    </v-container>
  </template>
  
  <script>
    import api from "@/services/lambdaApi";
    // import BarChart from '@/components/charts/BarChart'
    import moment from 'moment'
    import XLSX from 'xlsx';
  
    export default {
      name: "salesdashboard",
      // components: {
      //   BarChart
      // },
      data () {
        return {
          loading: false,

          yearArray: [],
          yearSelect: 0,
          mDataArray: [],
          fieldArray: [],
          mDataTable: "",

          //BarChart
          mAmountArray: {},
          mTicketArray: {},
          datacollection: {},
        }
      },
      async mounted () {
        try {
          this.loading = true;

          this.yearSelect = this.currentYear;
          this.yearArray = [];
          // this.yearArray.push(this.yearSelect);
          for (let index = 2022; index <= this.yearSelect; index++) {
            this.yearArray.push(index);
          }

          await this.loadData();

          // setInterval(this.loadData, 120000);
        } catch (error) {
          alert(error)
        } finally {
          this.loading = false;
        }
      },
      methods: {
          async mapData() {
              if (this.mDataArray) {
                this.mDataTable = `<table border="1" cellpadding="0" cellspacing="0" style="border-color:#3399CC;font-family: Tahoma;">`;

                // Header
                this.mDataTable += `<tr>`;
                this.fieldArray = [];
                let headers = Object.keys(this.mDataArray[0]);
                headers.forEach(item => {
                    // if (item=='brcode' || item=='brname' || item.indexOf('_amounts') != -1 || item.indexOf('diff_') != -1) {
                      this.fieldArray.push(item);
                      this.mDataTable += `<td valign="middle" align="center" style="font-size:16px;font-weight:bold;padding:10px;">`;
                      this.mDataTable += `<div>${item.replace('_amounts','')}</div>`;
                      this.mDataTable += "</td>";
                    // }
                });
                this.mDataTable += `</tr>`;

                // Detail
                this.mDataArray.forEach(item => {
                  this.mDataTable += `<tr>`;
                  this.fieldArray.forEach(h => {
                      if (h=='title') {
                        this.mDataTable += `<td style="color:#000000;background-color:#ffffff;vertical-align:top;text-align:left;padding:0px;padding:5px;">`;
                        this.mDataTable += `<span>${item[h]}</span>`;
                      } else {
                        this.mDataTable += `<td style="color:#000000;background-color:#ffffff;vertical-align:top;text-align:right;padding:0px;padding:5px;">`;
                        if (item['title'] == 'Total Sales')
                          this.mDataTable += `<span>${this.numberFormat(item[h], 2)}</span>`
                        else
                          this.mDataTable += `<span>${this.numberFormat(item[h], 0)}</span>`;
                      }
                      this.mDataTable += "</td>";
                    });
                  this.mDataTable += `</tr>`;
                });
                this.mDataTable += "</table>";
              }
          },
          numberFormat(value, decimal) {
            return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
          },
          // async mapDataGraph() {
          //   if (this.mDataArray && this.mDataArray?.length>0 && this.fieldArray && this.fieldArray?.length>0) {
          //     this.mTicketArray = [];
          //     this.mAmountArray = [];
          //     this.fieldArray.forEach(h => {
          //       if (h.search('_amounts') != -1) {
          //         let amounts = [];
          //         let tickets = [];

          //         this.mDataArray.forEach(item => {
          //           if (item["brcode"] != "0000") {
          //             amounts.push(0);
          //             tickets.push(0);
          //           }
          //         });
                  
          //         let count = 0;
          //         this.mDataArray.forEach(item => {
          //           if (item["brcode"] != "0000") {
          //             amounts[count] = item[h] ?? 0;
          //             tickets[count] = item[h.replace('_amounts', '_tickets')] ?? 0;
          //             count += 1;
          //           }
          //         });
                  
          //         this.mTicketArray[h.replace('_amounts','_tickets')] = tickets;
          //         this.mAmountArray[h.replace('_amounts','_sales')] = amounts;
          //       }
          //     });
          //   }
          // },
          // // BarChart  
          // async fillData () {
          //   let labels = [];
          //   if (this.mDataArray) {
          //     this.mDataArray.forEach(item => {
          //       if (item["brcode"] != "0000")
          //         labels.push(item["brname"] ?? "");
          //     });
          //   }

          //   let backgroundColor = [
          //       '#DFFF00',
          //       '#0000FF',
          //       '#DE3163',
          //       '#6495ED',
          //       '#40E0D0',
          //       '#CCCCFF',
          //       '#FFBF00',
          //       '#FF7F50',
          //       '#008000',
          //       '#9FE2BF',
          //       '#00FF00',
          //       '#FF00FF',

          //       '#DFFF00',
          //       '#0000FF',
          //       '#DE3163',
          //       '#6495ED',
          //       '#40E0D0',
          //       '#CCCCFF',
          //       '#FFBF00',
          //       '#FF7F50',
          //       '#008000',
          //       '#9FE2BF',
          //       '#00FF00',
          //       '#FF00FF',

          //       '#DFFF00',
          //       '#0000FF',
          //       '#DE3163',
          //       '#6495ED',
          //       '#40E0D0',
          //       '#CCCCFF',
          //       '#FFBF00',
          //       '#FF7F50',
          //       '#008000',
          //       '#9FE2BF',
          //       '#00FF00',
          //       '#FF00FF',
          //   ];

          //   let datasets = [];
          //   let order = 0;
          //   let headers = Object.keys(this.mTicketArray);

          //   headers.forEach(h => {
          //       datasets.push(
          //       {
          //           type: 'line',
          //           label: h,
          //           pointBackgroundColor: 'white',
          //           borderWidth: 2,
          //           pointBorderColor: '#249EBF',
          //           data: this.getTickets(h),
          //           borderColor: backgroundColor[order],
          //           // borderColor: [
          //           //   '#3F51B5'
          //           // ],
          //           yAxisID: '1',
          //           backgroundColor: [ 'transparent' ],
          //           // stack: 'false',
          //           order: order++,
          //         }
          //       );
          //   });

          //   headers = Object.keys(this.mAmountArray);
          //   headers.forEach(h => {
          //       datasets.push(
          //       {
          //           type: 'bar',
          //           label: h,
          //           pointBackgroundColor: 'white',
          //           borderWidth: 2,
          //           pointBorderColor: '#249EBF',
          //           data: this.getSales(h),
          //           borderColor: 'transparent',
          //           // borderColor: [
          //           //   '#43A047'
          //           // ],
          //           yAxisID: '0',
          //           backgroundColor: backgroundColor[order],
          //           stack: (order).toString(),
          //           order: order++,
          //         }
          //       );
          //   });

          //   this.datacollection = {
          //       labels: labels,
          //       datasets: datasets
          //   };
          // },
          async loadData () {
              try {
                  // this.loading = true;
                  let filter = { action: "salesDashboard", year: this.yearSelect };
                  let result = await api.getSaleReport(filter);
                  this.mDataArray = result?.data ?? [];
                  await this.mapData();
                  // await this.mapDataGraph();
                  // await this.fillData();
              } catch (error) {
                  alert("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                  // alert(error);
              } finally {
                  // this.loading = false;
              }
          },
          async exportClick() {
            try {
              this.loading = true;
              // let dataExports = [];
              // this.mDataArray.forEach(item => {
              //   let row = {};
              //   this.fieldArray.forEach(h => {
              //     row[h.replace('_amounts','')] = item[h]
              //   });
              //   dataExports.push(row);
              // });
              let cdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
              const dataWS = XLSX.utils.json_to_sheet(this.mDataArray)
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, dataWS)
              XLSX.writeFile(wb,`SalesDashboard_${moment(cdate).format('YYYYMMDD')}.xlsx`)
            } catch (error) {
              alert(error)
            } finally {
              this.loading = false;
            }
          },
          // getSales(h) {
          //   return this.mAmountArray[h] ?? [];
          // },
          // getTickets(h) {
          //     return this.mTicketArray[h] ?? [];
          // },
      },
  
      computed: {
        currentYear() {
          return moment(this.currentDate, "YYYY-MM-DD").year();
        },
        currentDate() {
          // return "2021-08-09";
          return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        },
        userCode() {
          return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
        },
        userGroup() {
          return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
        },
        userType() {
          return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
        },
        userBrcode() {
          return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
        },
      }
    }
  </script>
  
  <style scoped>
  </style>