<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="80"
          :width="8"
          color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-card class="mx-0 pa-5" outlined>
            <v-form @submit.prevent="submit">
              <v-row>
                <!-- Left -->
                <v-col cols="5">
                  <v-text-field
                    outlined
                    v-model="coupon.couponId"
                    label="Coupon Id"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- Right -->
                <v-col cols="7">
                  <v-row>
                    <v-col cols="4">
                      <v-switch
                        v-model="coupon.conditions.isActive"
                        label="Active"
                        color="success"
                        hide-details
                      ></v-switch>
                      <v-switch
                        v-model="coupon.conditions.firstTimerOnly"
                        label="New Customer"
                        color="success"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col cols="4">
                      <v-switch
                        v-model="coupon.conditions.isSingleUseCode"
                        label="Single UseCode"
                        color="success"
                        hide-details
                      ></v-switch>
                      <v-switch
                          v-model="coupon.isPromotion"
                          label="Promotion"
                          color="success"
                          hide-details
                      ></v-switch>
                    </v-col>
                    <v-col cols="4">
                      <v-switch
                        v-model="coupon.conditions.appOnly"
                        label="App Only"
                        color="success"
                        hide-details
                      ></v-switch>
                      <v-switch
                          v-model="coupon.conditions.webOnly"
                          label="Web Only"
                          color="success"
                          hide-details
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
  
              <v-row>
                <!-- Left -->
                <v-col cols="5">
                  <!-- <v-text-field
                    outlined
                    v-model="coupon.couponName"
                    label="Coupon Name"
                    required
                  ></v-text-field> -->
  
                  <v-textarea
                    outlined
                    v-model="coupon.description"
                    label="Description"
                  ></v-textarea>
  
                  <div class="subheader">Avaliable Store :</div>
                  <div class="table_store">
                    <table class="table table-striped" width="100%">
                      <thead class="head-dark" align-center>
                        <tr>
                          <th width="30%" style="text-align:left">Store Code</th>
                          <th width="40%" style="text-align:left">Store Name</th>
                          <th width="30%" style="text-align:left">
                              <v-checkbox
                                  height="1"
                                  v-model="selectAll"
                                  label="Select"
                                  @change="onSelectAllChange"
                              ></v-checkbox>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          <template v-for="store in avail_store">
                          <tr>
                              <td>{{ store.storeCode }}</td>
                              <td>{{ store.storeName }}</td>
                              <td>
                              <v-checkbox
                                  height="1"
                                  v-model="store.visibility"
                              ></v-checkbox>
                              </td>
                          </tr>
                          </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
  
                <!-- Right -->
                <v-col cols="3">
                  <v-select
                      outlined
                      v-model="coupon.type_"
                      label="Coupon Type"
                      :items="couponTypes"
                  ></v-select>
  
                  <v-text-field
                    outlined
                    v-model="coupon.conditions.cartTotal"
                    type="number"
                    label="Cart Total"
                  ></v-text-field>
  
                  <v-text-field
                    outlined
                    v-model="coupon.conditions.usesPerCustomer"
                    type="number"
                    label="Uses Per Customer"
                  ></v-text-field>
                  
                  <v-menu
                    hide-details
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="startDate"
                        label="Start Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="$refs.menu1.save(startDate)"
                      v-model="startDate"
                      no-title
                      scrollable>
                    </v-date-picker>
                  </v-menu>
  
                  <v-menu
                    hide-details
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="endDate"
                        label="End Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="$refs.menu2.save(endDate)"
                      v-model="endDate"
                      no-title
                      scrollable>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-expansion-panels :value="1">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="subheader">Coupon Code List :</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row v-if="coupon.couponCodes != null">
                          <v-col cols="10">
                            <input type="file" accept=".xlsx" @change="onFileImportSelected_1" ref="importFile_1">
                            <div>import column header (couponCode)</div>
                          </v-col>
                          <v-col cols="2">
                            <v-icon @click="coupon.couponCodes=[]">delete</v-icon>
                          </v-col>
                        </v-row>
                        <v-row v-if="coupon.couponCodes != null">
                          <v-col cols="12">
                            <div class="table_sub">
                              <table class="table table-striped" width="100%">
                                <thead class="head-dark" align-center>
                                  <tr>
                                    <th width="80%" style="text-align:left">Coupon Code</th>
                                    <th width="20%" style="text-align:left">Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                    <template v-for="item in coupon.couponCodes">
                                    <!-- <tr :key="excludeProduct"> -->
                                    <tr>
                                        <td>{{ item }}</td>
                                        <td>
                                          <v-icon @click="removeItem(item, coupon.couponCodes)">delete</v-icon>
                                        </td>
                                    </tr>
                                    </template>
                                </tbody>
                              </table>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
  
                  <br />
                  <v-expansion-panels :value="1">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="subheader">Customer Code List :</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row v-if="coupon.conditions.eligibleCustomers != null">
                          <v-col cols="10">
                            <input type="file" accept=".xlsx" @change="onFileImportSelected_2" ref="importFile_2">
                            <div>import column header (customerCode)</div>
                          </v-col>
                          <v-col cols="2">
                            <v-icon @click="coupon.conditions.eligibleCustomers=[]">delete</v-icon>
                          </v-col>
                        </v-row>
                        <v-row v-if="coupon.conditions.eligibleCustomers != null">
                          <v-col cols="12">
                            <div class="table_sub">
                              <table class="table table-striped" width="100%">
                                <thead class="head-dark" align-center>
                                  <tr>
                                    <th width="80%" style="text-align:left">Customer Code</th>
                                    <th width="20%" style="text-align:left">Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                    <template v-for="item in coupon.conditions.eligibleCustomers">
                                    <!-- <tr :key="excludeProduct"> -->
                                    <tr>
                                        <td>{{ item }}</td>
                                        <td>
                                          <v-icon @click="removeItem(item, coupon.conditions.eligibleCustomers)">delete</v-icon>
                                        </td>
                                    </tr>
                                    </template>
                                </tbody>
                              </table>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
  
                  <div class="table_store">
                    <table class="table table-striped" width="100%">
                      <thead class="head-dark" align-center>
                        <tr>
                          <th width="70%" style="text-align:left" class="subheader">No Combine :</th>
                          <th width="30%" style="text-align:left">
                              <v-checkbox
                                  height="1"
                                  v-model="selectNoCombineAll"
                                  label="Select"
                                  @change="onSelectNoCombineAllChange"
                              ></v-checkbox>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          <template v-for="item in couponTypes2">
                          <tr>
                              <td>{{ item.couponType }}</td>
                              <td>
                              <v-checkbox
                                  height="1"
                                  v-model="item.selected"
                              ></v-checkbox>
                              </td>
                          </tr>
                          </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
  
              <v-row>
                <v-col cols="6">
                  <v-expansion-panels :value="1">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="header">Disc :</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-text-field
                          outlined
                          v-model="coupon.disc.cash"
                          type="number"
                          label="Cash"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          v-model="coupon.disc.percentage"
                          type="number"
                          label="Percentage"
                        ></v-text-field>
                        <v-expansion-panels :value="1">
                          <v-expansion-panel>
                            <v-expansion-panel-header class="subheader">Products :</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row v-if="coupon.disc.products != null">
                                <v-col cols="10">
                                  <input type="file" accept=".xlsx" @change="onFileImportSelected_3" ref="importFile_3">
                                  <div>import column header (prcode)</div>
                                </v-col>
                                <v-col cols="2">
                                  <v-icon @click="coupon.disc.products=[]">delete</v-icon>
                                </v-col>
                              </v-row>
                              <v-row v-if="coupon.disc.products != null">
                                <v-col cols="12">
                                  <div class="table_sub">
                                    <table class="table table-striped" width="100%">
                                      <thead class="head-dark" align-center>
                                        <tr>
                                          <th width="80%" style="text-align:left">Cprcode</th>
                                          <th width="20%" style="text-align:left">Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                          <template v-for="item in coupon.disc.products">
                                          <!-- <tr :key="excludeProduct"> -->
                                          <tr>
                                              <td>{{ item }}</td>
                                              <td>
                                                <v-icon @click="removeItem(item, coupon.disc.products)">delete</v-icon>
                                              </td>
                                          </tr>
                                          </template>
                                      </tbody>
                                    </table>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <br />
                        <v-expansion-panels :value="1">
                          <v-expansion-panel>
                            <v-expansion-panel-header class="subheader">Exclude :</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row v-if="coupon.disc.exclude != null">
                                <v-col cols="10">
                                  <input type="file" accept=".xlsx" @change="onFileImportSelected_4" ref="importFile_4">
                                  <div>import column header (prcode)</div>
                                </v-col>
                                <v-col cols="2">
                                  <v-icon @click="coupon.disc.exclude=[]">delete</v-icon>
                                </v-col>
                              </v-row>
                              <v-row v-if="coupon.disc.exclude != null">
                                <v-col cols="12">
                                  <div class="table_sub">
                                    <table class="table table-striped" width="100%">
                                      <thead class="head-dark" align-center>
                                        <tr>
                                          <th width="80%" style="text-align:left">Cprcode</th>
                                          <th width="20%" style="text-align:left">Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                          <template v-for="item in coupon.disc.exclude">
                                          <!-- <tr :key="excludeProduct"> -->
                                          <tr>
                                              <td>{{ item }}</td>
                                              <td>
                                                <v-icon @click="removeItem(item, coupon.disc.exclude)">delete</v-icon>
                                              </td>
                                          </tr>
                                          </template>
                                      </tbody>
                                    </table>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
  
                  <br />
                  <v-expansion-panels :value="1">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="header">Bogo :</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-expansion-panels :value="1">
                          <v-expansion-panel>
                            <v-expansion-panel-header class="subheader">Buy :</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-text-field
                                outlined
                                v-model="coupon.bogo.buy.quantity"
                                type="number"
                                label="Quantity"
                              ></v-text-field>
                              <v-row v-if="coupon.bogo.buy.products != null">
                                <v-col cols="10">
                                  <input type="file" accept=".xlsx" @change="onFileImportSelected_7" ref="importFile_7">
                                  <div>import column header (prcode)</div>
                                </v-col>
                                <v-col cols="2">
                                  <v-icon @click="coupon.bogo.buy.products=[]">delete</v-icon>
                                </v-col>
                              </v-row>
                              <v-row v-if="coupon.bogo.buy.products != null">
                                <v-col cols="12">
                                  <div class="table_sub">
                                    <table class="table table-striped" width="100%">
                                      <thead class="head-dark" align-center>
                                        <tr>
                                          <th width="80%" style="text-align:left">Cprcode</th>
                                          <th width="20%" style="text-align:left">Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                          <template v-for="item in coupon.bogo.buy.products">
                                          <!-- <tr :key="excludeProduct"> -->
                                          <tr>
                                              <td>{{ item }}</td>
                                              <td>
                                                <v-icon @click="removeItem(item, coupon.bogo.buy.products)">delete</v-icon>
                                              </td>
                                          </tr>
                                          </template>
                                      </tbody>
                                    </table>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <br />
                        <v-expansion-panels :value="1">
                          <v-expansion-panel>
                            <v-expansion-panel-header class="subheader">Get :</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-text-field
                                outlined
                                v-model="coupon.bogo.get.quantity"
                                type="number"
                                label="Quantity"
                              ></v-text-field>
                              <v-row v-if="coupon.bogo.get.products != null">
                                <v-col cols="10">
                                  <input type="file" accept=".xlsx" @change="onFileImportSelected_8" ref="importFile_8">
                                  <div>import column header (prcode)</div>
                                </v-col>
                                <v-col cols="2">
                                  <v-icon @click="coupon.bogo.get.products=[]">delete</v-icon>
                                </v-col>
                              </v-row>
                              <v-row v-if="coupon.bogo.get.products != null">
                                <v-col cols="12">
                                  <div class="table_sub">
                                    <table class="table table-striped" width="100%">
                                      <thead class="head-dark" align-center>
                                        <tr>
                                          <th width="80%" style="text-align:left">Cprcode</th>
                                          <th width="20%" style="text-align:left">Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                          <template v-for="item in coupon.bogo.get.products">
                                          <!-- <tr :key="excludeProduct"> -->
                                          <tr>
                                              <td>{{ item }}</td>
                                              <td>
                                                <v-icon @click="removeItem(item, coupon.bogo.get.products)">delete</v-icon>
                                              </td>
                                          </tr>
                                          </template>
                                      </tbody>
                                    </table>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <br />
                  <v-expansion-panels :value="1">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="header">Specific Discount :</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-text-field
                          outlined
                          v-model="specificDiscount.minimumValue"
                          type="number"
                          label="Minimum Value"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          v-model="specificDiscount.cash"
                          type="number"
                          label="Cash"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          v-model="specificDiscount.percentage"
                          type="number"
                          label="Percentage"
                        ></v-text-field>
                        <v-expansion-panels :value="1">
                          <v-expansion-panel>
                            <v-expansion-panel-header class="subheader">Products :</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row v-if="specificDiscount.skus != null">
                                <v-col cols="10">
                                  <input type="file" accept=".xlsx" @change="onFileImportSelected_6" ref="importFile_6">
                                  <div>import column header (prcode)</div>
                                </v-col>
                                <v-col cols="2">
                                  <v-icon @click="specificDiscount.skus=[]">delete</v-icon>
                                </v-col>
                              </v-row>
                              <v-row v-if="specificDiscount.skus != null">
                                <v-col cols="12">
                                  <div class="table_sub">
                                    <table class="table table-striped" width="100%">
                                      <thead class="head-dark" align-center>
                                        <tr>
                                          <th width="80%" style="text-align:left">Cprcode</th>
                                          <th width="20%" style="text-align:left">Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                          <template v-for="item in specificDiscount.skus">
                                          <!-- <tr :key="excludeProduct"> -->
                                          <tr>
                                              <td>{{ item }}</td>
                                              <td>
                                                <v-icon @click="removeItem(item, specificDiscount.skus)">delete</v-icon>
                                              </td>
                                          </tr>
                                          </template>
                                      </tbody>
                                    </table>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
  
                <v-col cols="6">
                  <v-expansion-panels :value="1">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="header">Two4 :</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-text-field
                          outlined
                          v-model="coupon.two4.price"
                          type="number"
                          label="Price"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          v-model="coupon.two4.quantity"
                          type="number"
                          label="Quantity"
                        ></v-text-field>
                        <v-expansion-panels :value="1">
                          <v-expansion-panel>
                            <v-expansion-panel-header class="subheader">Products :</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row v-if="coupon.two4.products != null">
                                <v-col cols="10">
                                  <input type="file" accept=".xlsx" @change="onFileImportSelected_5" ref="importFile_5">
                                  <div>import column header (prcode)</div>
                                </v-col>
                                <v-col cols="2">
                                  <v-icon @click="coupon.two4.products=[]">delete</v-icon>
                                </v-col>
                              </v-row>
                              <v-row v-if="coupon.two4.products != null">
                                <v-col cols="12">
                                  <div class="table_sub">
                                    <table class="table table-striped" width="100%">
                                      <thead class="head-dark" align-center>
                                        <tr>
                                          <th width="80%" style="text-align:left">Cprcode</th>
                                          <th width="20%" style="text-align:left">Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                          <template v-for="item in coupon.two4.products">
                                          <!-- <tr :key="excludeProduct"> -->
                                          <tr>
                                              <td>{{ item }}</td>
                                              <td>
                                                <v-icon @click="removeItem(item, coupon.two4.products)">delete</v-icon>
                                              </td>
                                          </tr>
                                          </template>
                                      </tbody>
                                    </table>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
  
                  <br />
                  <v-expansion-panels :value="1">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="header">FreeShip :</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-select
                            outlined
                            v-model="freeShip.discountType"
                            label="Discount Type"
                            :items="['FULL','PARTIAL']"
                        ></v-select>
                        <v-text-field
                          outlined
                          v-model="freeShip.shippingDiscount"
                          type="number"
                          label="Shipping Discount"
                        ></v-text-field>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <br />
                  <v-expansion-panels :value="1">
                    <v-expansion-panel>
                      <!-- <v-expansion-panel-header>
                        <div>
                          <v-btn @click="newGroupMinimum" color="primary">
                            <v-icon left>add</v-icon>
                            <span>New Group</span>
                          </v-btn>
                        </div>
                        <span class="header">Group Minimum</span>
                      </v-expansion-panel-header> -->
                      <v-expansion-panel-header class="header">Group Minimum :</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template>
                          <v-btn @click="newGroupMinimum" color="primary">
                            <v-icon left>add</v-icon>
                            <span>New Group</span>
                          </v-btn>
                        </template>
                        <template v-for="(group, index) in groupMinimum">
                          <div>
                            <br/><v-divider></v-divider><br/>
                            <v-row>
                              <v-col cols="8">
                                <v-text-field
                                  outlined
                                  v-model="group.minimum"
                                  type="number"
                                  label="Minimum"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-btn @click="removeItem(group, groupMinimum)" color="#03A9F4">
                                  <v-icon left>delete</v-icon>
                                  <span>Delete</span>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-expansion-panels :value="1">
                              <v-expansion-panel>
                                <v-expansion-panel-header class="subheader">Products :</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-row v-if="group.productList != null">
                                    <v-col cols="10">
                                      <input type="file" accept=".xlsx" @change="onFileImportSelected_9($event, group, 'importFile_9_' + index.toString())" :ref="'importFile_9_' + index.toString()">
                                      <div>import column header (prcode)</div>
                                    </v-col>
                                    <v-col cols="2">
                                      <v-icon @click="group.productList=[]">delete</v-icon>
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="group.productList != null">
                                    <v-col cols="12">
                                      <div class="table_sub">
                                        <table class="table table-striped" width="100%">
                                          <thead class="head-dark" align-center>
                                            <tr>
                                              <th width="80%" style="text-align:left">Cprcode</th>
                                              <th width="20%" style="text-align:left">Delete</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                              <template v-for="item in group.productList">
                                              <!-- <tr :key="excludeProduct"> -->
                                              <tr>
                                                  <td>{{ item }}</td>
                                                  <td>
                                                    <v-icon @click="removeItem(item, group.productList)">delete</v-icon>
                                                  </td>
                                              </tr>
                                              </template>
                                          </tbody>
                                        </table>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </div>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
  
              <br />
              <v-divider></v-divider>
              <br />
              <v-row>
                <v-spacer></v-spacer>
                <v-btn class="mr-4" @click="cancel">
                  Cancel
                </v-btn>
  
                <v-btn color="primary" type="submit">
                  Confirm
                </v-btn>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import XLSX from 'xlsx';
  import moment from 'moment';
  import api from "@/services/lambdaApi";
  
  export default {
    name: "coupon3-edit",
    // components:{
    // },
    data: () => ({
      loading: false,
      selectAll: false,
      selectNoCombineAll: false,
      couponTypes: ["BOGO","TWO4","DISC","FREESHIP","SPECIFICSKU"],
      couponTypes2: [
        { couponType: "BOGO", selected: false},
        { couponType: "TWO4", selected: false},
        { couponType: "DISC", selected: false},
        { couponType: "FREESHIP", selected: false},
        { couponType: "SPECIFICSKU", selected: false}
      ],
      avail_store: [],
      freeShip: {discountType: "", shippingDiscount: 0},
      specificDiscount: {minimumValue: 0, cash: 0, percentage: 0, skus: []},
      groupMinimum: [],
      coupon: {
        couponId: 0,
        description: "",
        couponCodes: [],
        type_: "",
        isPromotion: false,
        conditions: {
          cartTotal: 0,
          usesPerCustomer: 0,
          eligibleBranches: [],
          eligibleCustomers: [],
          firstTimerOnly: false,
          isActive: false,
          isSingleUseCode: false,
          appOnly: false,
          webOnly: false,
          noCombine: [],
          startDate: "",
          endDate: "",
          groupMinimum: []
        },
        bogo: {
          buy: {products: [], quantity: 0},
          get: {products: [], quantity: 0}
        },
        // counter: {usageCount: 0, usageLimit: 0, usedCodes: []},
        disc: {cash: 0, percentage: 0, exclude: [], products: []},
        two4: {price: 0, quantity: 0, products: []},
        freeShip: {discountType: "", shippingDiscount: 0},
        specificDiscount: {minimumValue: 0, cash: 0, percentage: 0, skus: []}
      },
      startDate: "",
      endDate: "",
      menu1: false,
      menu2: false,
    }),
    async mounted() {
      try {
        this.loading = true;
  
        let filter = { fileName: "online_stores.json" };
        let avail_store = await api.getS3Json(filter);
        this.avail_store = avail_store.data;
  
        // console.log("--- avail_store ---", JSON.stringify(this.avail_store));
  
        let couponId = parseInt(this.$route.params.id ?? 0);
        if (couponId != 0) {
          let data = { couponId: couponId }
          let result = await api.getCoupon3ById(data);
          // console.log("--- data ---", JSON.stringify(result.data));
          this.coupon = result.data;
          this.startDate = this.coupon?.conditions?.startDate;
          this.endDate = this.coupon?.conditions?.endDate;

          this.setAvailStore();
          this.setNoCombine();

          if (this.coupon?.freeShip) {
            this.freeShip = this.coupon.freeShip;
            // this.coupon["freeShip"] = {discountType: "", shippingDiscount: 0};
          }
          if (this.coupon?.specificDiscount) {
            this.specificDiscount = this.coupon.specificDiscount;
            // this.coupon["specificDiscount"] = {minimumValue: 0, cash: 0, percentage: 0, skus: []};
          }
          if (this.coupon?.conditions?.groupMinimum) {
            this.groupMinimum = this.coupon.conditions.groupMinimum;
          }
          // console.log("--- load ---", JSON.stringify(this.coupon));
        }
      // } catch (error) {
      }
      finally {
        this.loading = false;
      } 
    },
  
    methods: {
      newGroupMinimum() {
        this.groupMinimum.push({productList: [], minimum: 0});
      },
      removeItem: function (value, objectList) {
        let item = objectList.find(d => d === value);
        if (item) {
          let index = objectList.indexOf(item);
          if (index >= 0) {
            objectList.splice(index, 1)
          }
        }
      },
      onSelectNoCombineAllChange() {
        this.couponTypes2.forEach(item => {
          item.selected = this.selectNoCombineAll;
        });
      },
      setNoCombine() {
          let noCombine = this.coupon?.conditions?.noCombine;
          noCombine.forEach(item => {
            let mItem = this.couponTypes2.find(d => d.couponType === item);
            if (mItem) {
              mItem.selected = true;
            }
          });
      },
      setAvailStore() {
          let availStores = this.coupon?.conditions?.eligibleBranches;
          availStores.forEach(item => {
            let mItem = this.avail_store.find(d => d.storeCode === item);
            if (mItem) {
              mItem.visibility = true;
            }
          });
      },
      onSelectAllChange() {
        this.avail_store.forEach(item => {
          item.visibility = this.selectAll;
        });
      },
  
      onFileImportSelected_1(e) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles_1(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile_1.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles_1(file) { // detect file event
          var reader = new FileReader();
          var vm = this;
          reader.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, { type: "array" });
              let sheetName = workbook.SheetNames[0];
              /* DO SOMETHING WITH workbook HERE */
              // console.log(workbook);
              let worksheet = workbook.Sheets[sheetName];
              // console.log(XLSX.utils.sheet_to_json(worksheet));
              let items = XLSX.utils.sheet_to_json(worksheet);
              // console.log('-- items --', items);
              items.forEach(item => {
                var mItem = vm.coupon.couponCodes.find(d => d === item.couponCode);
                if (!mItem) {
                  vm.coupon.couponCodes.push(item.couponCode.toString() ?? "0");
                }
              });
          };
          reader.readAsArrayBuffer(file);
      },
      onFileImportSelected_2(e) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles_2(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile_2.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles_2(file) { // detect file event
          var reader = new FileReader();
          var vm = this;
          reader.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, { type: "array" });
              let sheetName = workbook.SheetNames[0];
              /* DO SOMETHING WITH workbook HERE */
              // console.log(workbook);
              let worksheet = workbook.Sheets[sheetName];
              // console.log(XLSX.utils.sheet_to_json(worksheet));
              let items = XLSX.utils.sheet_to_json(worksheet);
              // console.log('-- items --', items);
              items.forEach(item => {
                var mItem = vm.coupon.conditions.eligibleCustomers.find(d => d === item.customerCode);
                if (!mItem) {
                  vm.coupon.conditions.eligibleCustomers.push(item.customerCode.toString() ?? "0");
                }
              });
          };
          reader.readAsArrayBuffer(file);
      },
      onFileImportSelected_3(e) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles_3(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile_3.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles_3(file) { // detect file event
          var reader = new FileReader();
          var vm = this;
          reader.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, { type: "array" });
              let sheetName = workbook.SheetNames[0];
              /* DO SOMETHING WITH workbook HERE */
              // console.log(workbook);
              let worksheet = workbook.Sheets[sheetName];
              // console.log(XLSX.utils.sheet_to_json(worksheet));
              let items = XLSX.utils.sheet_to_json(worksheet);
              // console.log('-- items --', items);
              items.forEach(item => {
                if (item.prcode > 0) {
                  var mItem = vm.coupon.disc.products.find(d => d === item.prcode);
                  if (!mItem) {
                    vm.coupon.disc.products.push(parseInt(item.prcode ?? 0));
                  }
                }
              });
          };
          reader.readAsArrayBuffer(file);
      },
      onFileImportSelected_4(e) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles_4(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile_4.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles_4(file) { // detect file event
          var reader = new FileReader();
          var vm = this;
          reader.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, { type: "array" });
              let sheetName = workbook.SheetNames[0];
              /* DO SOMETHING WITH workbook HERE */
              // console.log(workbook);
              let worksheet = workbook.Sheets[sheetName];
              // console.log(XLSX.utils.sheet_to_json(worksheet));
              let items = XLSX.utils.sheet_to_json(worksheet);
              // console.log('-- items --', items);
              items.forEach(item => {
                if (item.prcode > 0) {
                  var mItem = vm.coupon.disc.exclude.find(d => d === item.prcode);
                  if (!mItem) {
                    vm.coupon.disc.exclude.push(parseInt(item.prcode ?? 0));
                  }
                }
              });
          };
          reader.readAsArrayBuffer(file);
      },
      onFileImportSelected_5(e) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles_5(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile_5.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles_5(file) { // detect file event
          var reader = new FileReader();
          var vm = this;
          reader.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, { type: "array" });
              let sheetName = workbook.SheetNames[0];
              /* DO SOMETHING WITH workbook HERE */
              // console.log(workbook);
              let worksheet = workbook.Sheets[sheetName];
              // console.log(XLSX.utils.sheet_to_json(worksheet));
              let items = XLSX.utils.sheet_to_json(worksheet);
              // console.log('-- items --', items);
              items.forEach(item => {
                if (item.prcode > 0) {
                  var mItem = vm.coupon.two4.products.find(d => d === item.prcode);
                  if (!mItem) {
                    vm.coupon.two4.products.push(parseInt(item.prcode ?? 0));
                  }
                }
              });
          };
          reader.readAsArrayBuffer(file);
      },
      onFileImportSelected_6(e) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles_6(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile_6.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles_6(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.prcode > 0) {
                var mItem = vm.specificDiscount.skus.find(d => d === item.prcode);
                if (!mItem) {
                  vm.specificDiscount.skus.push(parseInt(item.prcode ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
      },
      onFileImportSelected_7(e) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles_7(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile_7.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles_7(file) { // detect file event
          var reader = new FileReader();
          var vm = this;
          reader.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, { type: "array" });
              let sheetName = workbook.SheetNames[0];
              /* DO SOMETHING WITH workbook HERE */
              // console.log(workbook);
              let worksheet = workbook.Sheets[sheetName];
              // console.log(XLSX.utils.sheet_to_json(worksheet));
              let items = XLSX.utils.sheet_to_json(worksheet);
              // console.log('-- items --', items);
              items.forEach(item => {
                if (item.prcode > 0) {
                  var mItem = vm.coupon.bogo.buy.products.find(d => d === item.prcode);
                  if (!mItem) {
                    vm.coupon.bogo.buy.products.push(parseInt(item.prcode ?? 0));
                  }
                }
              });
          };
          reader.readAsArrayBuffer(file);
      },
      onFileImportSelected_8(e) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles_8(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile_8.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles_8(file) { // detect file event
          var reader = new FileReader();
          var vm = this;
          reader.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, { type: "array" });
              let sheetName = workbook.SheetNames[0];
              /* DO SOMETHING WITH workbook HERE */
              // console.log(workbook);
              let worksheet = workbook.Sheets[sheetName];
              // console.log(XLSX.utils.sheet_to_json(worksheet));
              let items = XLSX.utils.sheet_to_json(worksheet);
              // console.log('-- items --', items);
              items.forEach(item => {
                if (item.prcode > 0) {
                  var mItem = vm.coupon.bogo.get.products.find(d => d === item.prcode);
                  if (!mItem) {
                    vm.coupon.bogo.get.products.push(parseInt(item.prcode ?? 0));
                  }
                }
              });
          };
          reader.readAsArrayBuffer(file);
      },
      onFileImportSelected_9(e, group, ref) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles_9(files[0],group);
        // } catch (error) {
        }
        finally {
          var obj = this.$refs[ref];
          if (obj != null) obj[0].value = "";
          // this.$refs.importFile_9.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles_9(file, group) { // detect file event
        var reader = new FileReader();
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.prcode > 0) {
                var mItem = group.productList.find(d => d === item.prcode);
                if (!mItem) {
                  group.productList.push(parseInt(item.prcode ?? 0));
                }
              }
            });
            // console.log('-- group --', group);
        };
        reader.readAsArrayBuffer(file);
      },
  
      cancel() {
        this.$router.back();
      },
      async submit() {
        try {
          this.loading = true;
          let data = this.coupon;
  
          try {
            data.conditions.startDate = this.startDate == null || this.startDate == "" ? "" : moment(this.startDate).format('YYYY-MM-DD');
          } catch (error) {
          }
          try {
            data.conditions.endDate = this.endDate == null || this.endDate == "" ? "" : moment(this.endDate).format('YYYY-MM-DD');
          } catch (error) {
          }
          try {
            data.conditions.cartTotal = parseFloat(data.conditions.cartTotal);
          } catch (error) {
          }
          try {
            data.conditions.usesPerCustomer = parseInt(data.conditions.usesPerCustomer);
          } catch (error) {
          }
  
          try {
            data.disc.cash = parseFloat(data.disc.cash);
          } catch (error) {
          }
          try {
            data.disc.percentage = parseFloat(data.disc.percentage);
          } catch (error) {
          }
  
          try {
            data.two4.price = parseFloat(data.two4.price);
          } catch (error) {
          }
          try {
            data.two4.quantity = parseInt(data.two4.quantity);
          } catch (error) {
          }
  
          // try {
          //   data.counter.usageCount = parseInt(data.counter.usageCount);
          // } catch (error) {
          // }
          // try {
          //   data.counter.usageLimit = parseInt(data.counter.usageLimit);
          // } catch (error) {
          // }
  
          try {
            data.bogo.buy.quantity = parseInt(data.bogo.buy.quantity);
          } catch (error) {
          }
          try {
            data.bogo.get.quantity = parseInt(data.bogo.get.quantity);
          } catch (error) {
          }
          
          let avail_store = [];
          this.avail_store.forEach(item => {
              if (item.visibility) {
                avail_store.push(item.storeCode);
              }
          });
          data.conditions.eligibleBranches = avail_store;
          let noCombine = [];
          this.couponTypes2.forEach(item => {
              if (item.selected) {
                noCombine.push(item.couponType);
              }
          });
          data.conditions.noCombine = noCombine;
          
          let groupMinimum = [];
          this.groupMinimum.forEach(item => {
              groupMinimum.push( {
                minimum: parseFloat(item.minimum),
                productList: item.productList
              });
          });
          data.conditions.groupMinimum = groupMinimum;

          data.freeShip = this.freeShip;
          data.specificDiscount = {
            minimumValue: parseFloat(this.specificDiscount.minimumValue), 
            cash: parseFloat(this.specificDiscount.cash), 
            percentage: parseFloat(this.specificDiscount.percentage), 
            skus: this.specificDiscount.skus
          };

          // console.log('request', JSON.stringify(data));
          // return;
          let result;
          if (data.couponId == 0) {
            result = await api.createCoupon3(data);
          }
          else {
            result = await api.updateCoupon3(data);
          }
          // console.log('result', JSON.stringify(result));
          let response = result.data;
          // console.log('response', JSON.stringify(response));
  
          this.$router.back();
        } catch (error) {
          alert(error)
        }
        finally {
          this.loading = false;
        }
      },
    }
  };
  </script>
  
  <style scoped>
  table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
     background-color: rgba(0, 0, 0, .05);
   }
  .table_store {
     height: 320px;
     overflow: auto;
  }
  .table_sub {
     height: 220px;
     overflow: auto;
  }
  .subject {
    color: #0D47A1;
  }
  .header {
    font-size: 18px;
    font-weight: bold;
    color: #D50000;
  }
  .subheader {
    font-size: 18px;
    font-weight: bold;
    color: #0D47A1;
  }
  </style>