<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-snackbar v-model="showMessageAlert" color="red" :timeout="5000">
      Unable to message this customer. /ไม่สามารถส่งข้อความถึงลูกค้ารายนี้ได้
    </v-snackbar>
    <transition name="section">
      <ChatRoom
        ref="chatRoom"
        class="
          chat-room
          tw-bg-white
          tw-h-screen
          tw-w-1/2
          tw-fixed
          tw-z-10
          tw-shadow-md
          tw-drop-shadow-md
          tw-top-0
          tw-right-0
        "
        v-if="showChatModal"
        :selectedRoomID="order.ownerId"
        :order="order"
        @close="showChatModal = false"
        @update-unread-message="onUpdateUnreadMessage"
      ></ChatRoom>
    </transition>

    <button
      @click="onOpenChat"
      v-if="!showChatModal"
      class="minimize-btn tw-bg-primary"
    >
      <v-badge v-if="hasUnreadMessage" overlap dot color="pink">
        <svg
          class="tw-w-6 tw-h-6 tw-text-white tw-fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 31.1c-141.4 0-255.1 93.12-255.1 208c0 49.62 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734c1.249 3 4.021 4.766 7.271 4.766c66.25 0 115.1-31.76 140.6-51.39c32.63 12.25 69.02 19.39 107.4 19.39c141.4 0 255.1-93.13 255.1-207.1S397.4 31.1 256 31.1zM127.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S145.7 271.1 127.1 271.1zM256 271.1c-17.75 0-31.1-14.25-31.1-31.1s14.25-32 31.1-32s31.1 14.25 31.1 32S273.8 271.1 256 271.1zM383.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S401.7 271.1 383.1 271.1z"
          />
        </svg>
      </v-badge>
      <svg
        v-else
        class="tw-w-6 tw-h-6 tw-text-white tw-fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          d="M256 31.1c-141.4 0-255.1 93.12-255.1 208c0 49.62 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734c1.249 3 4.021 4.766 7.271 4.766c66.25 0 115.1-31.76 140.6-51.39c32.63 12.25 69.02 19.39 107.4 19.39c141.4 0 255.1-93.13 255.1-207.1S397.4 31.1 256 31.1zM127.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S145.7 271.1 127.1 271.1zM256 271.1c-17.75 0-31.1-14.25-31.1-31.1s14.25-32 31.1-32s31.1 14.25 31.1 32S273.8 271.1 256 271.1zM383.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S401.7 271.1 383.1 271.1z"
        />
      </svg>
    </button>

    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-0 pa-5" outlined>
          <div class="posInfo" v-if="isShowRef(order.orderId)">Ref : {{ order.basketId }}</div>
          <v-form @submit.prevent="submit">
            <v-row>
              <!-- Left -->
              <v-col cols="3">
                <v-text-field
                  outlined
                  v-model="order.orderId"
                  label="Order Id"
                  disabled
                ></v-text-field>
                <v-expansion-panels :value="1" v-if="payment != null && shipping != null">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="subheader">Shipping</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            outlined
                            v-model="shipping.shippingFirstName"
                            label="First Name"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingLastName"
                            label="Last Name"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingAddress"
                            label="Address"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingSubDistrict"
                            label="Sub District"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingDistrict"
                            label="District"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingProvince"
                            label="Province"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingPostcode"
                            label="Postcode"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingLat"
                            label="Latitude"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingLon"
                            label="Longitude"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingPhone"
                            label="Phone"
                            readonly
                            :append-outer-icon="'phone_iphone'"
                            @click:append-outer="popupChangePhone"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingEmail"
                            label="Email"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="shipping.shippingType"
                            label="Shipping Type"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  outlined
                  v-model="branchCodeName"
                  label="Branch"
                  disabled
                ></v-text-field>
                <v-expansion-panels :value="1" v-if="payment != null && scheduleList != null">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="subheader">Schedule</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template v-for="item, index in scheduleList">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              outlined
                              v-model="item.scheduleId"
                              label="Schedule Id"
                              readonly
                            ></v-text-field>
                            <v-text-field
                              outlined
                              v-model="item.mode"
                              label="Shipping Mode"
                              readonly
                            ></v-text-field>
                            <v-text-field
                              outlined
                              v-model="item.deliveryFee"
                              label="Delivery Fee"
                              readonly
                            ></v-text-field>
                            <v-text-field
                              outlined
                              v-model="item.expressFee"
                              label="Express Fee"
                              readonly
                            ></v-text-field>
                            <v-text-field v-if="item.preOrder != null && item.preOrder.trim() != ''"
                              outlined
                              v-model="item.preOrder"
                              label="Pre Order"
                              readonly
                            ></v-text-field>
                            <v-text-field v-if="item.nationwideConfig != null && item.nationwideConfig.trim() != ''"
                              outlined
                              v-model="item.nationwideConfig"
                              label="Nationwide"
                              readonly
                            ></v-text-field>
                            <v-text-field
                              outlined
                              v-model="item.dateTime"
                              label="Delivery Datetime"
                              readonly
                            ></v-text-field>
                            <v-text-field
                              outlined
                              v-model="item.pickingStatus"
                              label="Picking Status"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <!-- Right -->
              <v-col cols="3">
                <v-text-field
                  outlined
                  v-model="order.creationTime"
                  label="Create Datetime"
                  disabled
                ></v-text-field>
                <v-expansion-panels :value="1" v-if="payment != null && payment != null">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="subheader">Payment</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row v-if="(payment.isPaid == null || payment.isPaid == false) && (userCode == 'V1944' || userCode == 'V17221' || userGroup == 'it')">
                        <v-col cols="12" align="center">
                          <v-btn @click="manualPaymentClick" color="primary">
                            <v-icon left>edit</v-icon>
                            <span>Manual Payment</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            outlined
                            v-model="payment.subTotal"
                            label="Sub Total"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="payment.shippingAmount"
                            label="Shipping Amount"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="payment.expressFeeAmount"
                            label="Express Amount"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="payment.discountAmount"
                            label="Discount Amount"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="payment.grandTotal"
                            label="Grand Total"
                            readonly
                          ></v-text-field>
                          <v-checkbox
                            v-model="payment.isPaid"
                            label="Is Paid"
                            readonly
                          ></v-checkbox>
                          <v-text-field
                            outlined
                            v-model="payment.totalPaid"
                            label="Total Paid"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="payment.paymentMethod"
                            label="Payment Method"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="payment.issuerBank"
                            label="Issuer Bank"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="payment.paymentId"
                            label="Payment Id"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="payment.status"
                            label="Status"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="payment.timestamp"
                            label="Time Stamp"
                            readonly
                          ></v-text-field>
                          <v-textarea
                            outlined
                            v-model="payment.remark"
                            label="Remark"
                            readonly
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  outlined
                  v-model="order.lastEdited"
                  label="Lastupdate Datetime"
                  disabled
                ></v-text-field>
                <v-expansion-panels :value="1" v-if="payment != null && billingInfo != null">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="subheader">Billing Info</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row v-if="billingInfo != null">
                        <v-col cols="12">
                          <v-checkbox
                            v-model="billingInfo.requestTaxInvoice"
                            label="Request TaxInvoice"
                            readonly
                          ></v-checkbox>
                          <v-text-field
                            outlined
                            v-model="billingInfo.billingName"
                            value=""
                            label="Name"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.billingAddress"
                            label="Address"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.billingSubDistrict"
                            label="Sub District"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.billingDistrict"
                            label="District"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.billingProvince"
                            label="Province"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.billingPostcode"
                            label="Postcode"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.customerFirstName"
                            label="Customer First Name"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.customerLastName"
                            label="Customer Last Name"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.billingPhone"
                            label="Phone"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.customerEmail"
                            label="Email"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.customerCompanyName"
                            label="Company Name"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.customerCompanyBranch"
                            label="Company Branch"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="billingInfo.customerTaxId"
                            label="Tax Id"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <!-- <br /> -->

            <v-row>
              <v-col cols="3">
                <div style="height:20px">
                  <v-checkbox
                    v-model="order.requestSubstitute"
                    label="Request Substitute"
                    readonly
                  ></v-checkbox>
                </div>
                <!-- <div style="height:20px"> -->
                <v-checkbox
                  v-model="order.noPlasticBag"
                  label="No PlasticBags"
                  readonly
                ></v-checkbox>
                <!-- </div> -->
                <v-textarea
                  outlined
                  v-model="order.specialComment"
                  label="Special Comment"
                  readonly
                ></v-textarea>
              </v-col>
              <v-col cols="3">
                <br />
                <div class="table_sub">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th width="100%" style="text-align:left">Voucher Id</th>
                      </tr>
                    </thead>
                    <tbody>
                        <!-- <template v-for="item in order.couponList"> -->
                        <template v-for="item in order.voucherId">
                        <tr>
                            <td>{{ item }}</td>
                        </tr>
                        </template>
                    </tbody>
                  </table>
                </div>
              </v-col>
              <v-col cols="3">
                <br />
                <div class="table_sub">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th width="100%" style="text-align:left">Coupon Code</th>
                      </tr>
                    </thead>
                    <tbody>
                        <template v-for="item in order.couponCodeList">
                        <tr>
                            <td>{{ item }}</td>
                        </tr>
                        </template>
                    </tbody>
                  </table>
                </div>
              </v-col>
              <v-col cols="3">
                <br />
                <h3>Picking</h3>
                <!-- <br /> -->
                <div v-if="picking != null">
                  <div>Picker : {{ picking.picker }}</div>
                  <div>Status : {{ picking.pickingStatus }}</div>
                  <div>Start : {{ picking.pickingStartTime }}</div>
                  <div>End : {{ picking.pickingEndTime }}</div>
                  <div>Diff Amount : {{ picking.diffAmount }}</div>
                  <div>Diff Remark : {{ picking.diffRemark }}</div>
                  <!-- <div class="posInfo">Pos Amount : {{ order.posAmount }}</div> -->
                  <div class="posInfo">Pos Diff : {{ order.posDiff }}</div>
                  <div class="posInfo">Ticket No : {{ order.posTicketNo }}</div>
                  <div class="posInfo">Refund Gift : {{ order.refundgift }}</div>
                  <div class="posInfo">Gift Type : {{ order.giftType }}</div>
                </div>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <template>
              <v-row class="ma-0">
                <h1>Order Items</h1>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-row>
            </template>
            <template>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped" width="100%">
                    <thead class="thead-dark" align-center>
                      <tr>
                        <th scope="col" style="text-align: left">Image</th>
                        <th scope="col" style="text-align: left">Sid</th>
                        <th scope="col" style="text-align: left">Barcode</th>
                        <th scope="col" style="text-align: left">(C) Prcode</th>
                        <th scope="col" style="text-align: left">PrName</th>
                        <th scope="col" style="text-align: left">SizeDesc</th>
                        <th scope="col" style="text-align: center">OrderQty</th>
                        <th scope="col" style="text-align: center">PosQty</th>
                        <th scope="col" style="text-align: center">Price</th>
                        <th scope="col" style="text-align: center">Amount</th>
                        <th scope="col" style="text-align: center">PreOrder</th>
                      </tr>
                    </thead>
                    <!-- <draggable v-model="mDataArray" tag="tbody"> -->
                    <tbody>
                      <template v-for="item in mDataArray">
                        <tr>
                          <td>
                              <v-img
                                contain
                                class="ma-2"
                                style="cursor: pointer"
                                :eager="true"
                                :src="item.image"
                                @click="showImage(item)"
                                lazy-src="@/assets/noimage.png"
                                aspect-ratio="1"
                                min-width="80"
                                max-width="80"
                                max-height="80"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular v-show="loading"
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                          </td>
                          <td>{{ item.scheduleId }}</td>
                          <!-- <td>{{ item.barcode }}</td> -->
                          <td>{{ item.barcode }}<div>plu : {{ item.plu_no }}</div><div>stock : {{ item.cf_qty }}<span class="warehouse" v-if="item.warehouse != null && item.warehouse.trim() != ''"> {{item.warehouse}} </span></div></td>
                          <td>{{ item.cprcode }}</td>
                          <td>{{ item.productName }}</td>
                          <td>{{ item.hema_sizedesc }}</td>
                          <td style="text-align: right">{{ item.quantity }}</td>
                          <td style="text-align: right">{{ item.posQty }}</td>
                          <td style="text-align: right">{{ item.price }}</td>
                          <td style="text-align: right">{{ item.rowTotal }}</td>
                          <td style="text-align: center">
                            <v-checkbox class="mt-4 ml-10" readonly v-model="item.isPreOrder"></v-checkbox>
                          </td>
                        </tr>
                        <tr v-if="item.remark != null && item.remark.trim() != ''">
                          <td colspan="10">Remark : {{ item.remark }}</td>
                        </tr>
                      </template>
                    </tbody>
                    <!-- </draggable> -->
                  </table>
                </div>
              </div>
            </template>

            <v-divider></v-divider>
            <template>
              <v-row class="ma-0">
                <h1>Substitute Items</h1>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-row>
            </template>
            <template v-if="order.subStitute != null && order.subStitute.length>0">
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped" width="100%">
                    <thead class="thead-dark" align-center>
                      <tr>
                        <th scope="col" style="text-align: left">Image</th>
                        <!-- <th scope="col" style="text-align: left">Sid</th> -->
                        <th scope="col" style="text-align: left">Barcode</th>
                        <th scope="col" style="text-align: left">(C) Prcode</th>
                        <th scope="col" style="text-align: left">PrName</th>
                        <th scope="col" style="text-align: left">SizeDesc</th>
                        <th scope="col" style="text-align: right">OrderQty</th>
                        <th scope="col" style="text-align: right">PosQty</th>
                        <th scope="col" style="text-align: right">Price</th>
                        <th scope="col" style="text-align: right">Amount</th>
                      </tr>
                    </thead>
                    <!-- <draggable v-model="mDataArray" tag="tbody"> -->
                    <tbody>
                      <template v-for="item in order.subStitute">
                        <tr>
                          <td>
                              <v-img
                                contain
                                class="ma-2"
                                style="cursor: pointer"
                                :eager="true"
                                :src="item.image"
                                @click="showImage(item)"
                                lazy-src="@/assets/noimage.png"
                                aspect-ratio="1"
                                min-width="80"
                                max-width="80"
                                max-height="80"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular v-show="loading"
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                          </td>
                          <!-- <td>{{ item.scheduleId }}</td> -->
                          <td>{{ item.barcode }}<div>plu : {{ item.plu_no }}</div></td>
                          <td>{{ item.prcode }}</td>
                          <td>{{ item.prname }}</td>
                          <td>{{ item.hema_sizedesc }}</td>
                          <td style="text-align: right">{{ item.orderQty }}</td>
                          <td style="text-align: right">{{ item.posQty }}</td>
                          <td style="text-align: right">{{ item.posPrice }}</td>
                          <td style="text-align: right">{{ item.posTotal }}</td>
                        </tr>
                      </template>
                    </tbody>
                    <!-- </draggable> -->
                  </table>
                </div>
              </div>
            </template>

            <br />
            <v-divider></v-divider>
            <br />
            <v-row>
              <v-spacer></v-spacer>

              <v-btn color="green darken-2" dark @click="dialog = true" v-if="isShowQrCode">
                Scan Qrcode
              </v-btn>

              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="red darken-2" dark @click="isPrint = true" v-if="isShowQrCode">
                Print Sticker
              </v-btn>

              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn class="mr-4" color="primary" @click="cancel">
                Back
              </v-btn>

              <!-- <v-btn color="primary" type="submit">
                Confirm
              </v-btn> -->
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="isManualPayment"
          max-width="500px"
        >
          <v-card>
            <v-card-title>Manual Payment Order:  #{{ order.orderId }}</v-card-title>
            <v-divider></v-divider>
              <v-row class="ma-1">
                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="manualPayment_TotalPaid"
                    label="Total Paid"
                    type="number"
                    class="inputPrice"
                  ></v-text-field>
                  <v-textarea
                    outlined
                    v-model="manualPayment_Remark"
                    label="Remark"
                  ></v-textarea>
                </v-col>
              </v-row>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="isManualPayment = false"
              >
                Close
              </v-btn>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="primary" @click="confirmPaymentClick">
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="isChangePhone"
          max-width="500px"
        >
          <v-card>
            <v-card-title>Change Phone Order:  #{{ order.orderId }}</v-card-title>
            <v-divider></v-divider>
              <v-row class="ma-10">
                <v-col cols="12">
                  <!-- type="number"
                  class="inputPrice" -->
                  <v-text-field
                    outlined
                    v-model="newShippingPhone"
                    label="Phone no"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="isChangePhone = false"
              >
                Close
              </v-btn>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="primary" @click="confirmChangePhone" :disabled="!newShippingPhone || order.posTicketNo.length!=0">
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          max-width="700px"
        >
          <v-card>
            <v-card-title>Order - {{ order.branchName }}</v-card-title>
            <div class="ml-6">{{ currentDatetime }}</div>
            <v-divider></v-divider>
              <v-row>
                <v-col cols="4">
                  <template>
                    <div class="col-sm-10">
                      <div class="form-control-static">
                        <qrcode-vue
                          class="qrcode"
                          :value="qrCode"
                          :margin='margin >>> 0'
                          :renderAs="renderAs"
                          :size="size >>> 0"
                          :level="level"
                          :background="background"
                          :foreground='foreground'
                        />
                      </div>
                    </div>
                    <!-- <label class="header col-sm-2 control-label" style="font-size:12px">{{ qrCode }}</label> -->
                  </template>
                </v-col>
                <v-col cols="8">
                  <div class="mt-2 header2">Order: #{{ orderId }}</div>
                  <div class="header2">Customer: {{ shippingFullName }}</div>
                  <div class="header2">Phone no: {{ phoneNo }}</div>
                  <div>Placed on: {{ placedOn }}</div>
                  <div style="padding-right:5px">{{ address }}</div>
                  <div style="padding-right:5px">{{ province }}</div>
                  <div>Delivery on: {{ deliveryOn }}</div>
                  <!-- <div>Sub total: {{ subTotal }}</div> -->
                  <div>Delivery fee: {{ deliveryFee }}</div>
                  <!-- <div>Discount: {{ discountAmount }}</div> -->
                  <div class="mb-2">Order total: {{ grandTotal }}</div>
                </v-col>
              </v-row>
            <v-divider></v-divider>
            <v-card-actions>
              <span class="header2" style="font-size:13px">{{ qrCode }}</span>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <!-- <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="primary" @click="confirmPos" v-if="picking.pickingStatus == 'Picking'">
                Finish
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="isPrint"
          max-width="700px"
        >
          <v-card>
            <v-card-title>Print Sticker - {{ order.branchName }}</v-card-title>
            <div class="ml-6"><span class="header2">Order: #{{ orderId }}</span> - {{ currentDatetime }}</div>
            <v-divider></v-divider>
              <v-row>
                <v-col cols="12">
                  <v-row class="ma-1">
                    <v-col cols="5">
                      <div class="mt-3">
                        <v-text-field
                          outlined
                          v-model="picking.dryQty"
                          label="Dry (ถุง)"
                          type="number"
                          class="inputPrice"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          v-model="picking.freshQty"
                          label="Fresh (ถุง)"
                          type="number"
                          class="inputPrice"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          v-model="picking.frozenQty"
                          label="Frozen (ถุง)"
                          type="number"
                          class="inputPrice"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="7">
                      <div class="mt-3">
                        <v-text-field
                          outlined
                          v-model="picking.diffAmount"
                          label="Diff Amount"
                        ></v-text-field>
                        <v-textarea
                          outlined
                          v-model="picking.diffRemark"
                          label="Diff Remark"
                        ></v-textarea>
                        <!-- <v-text-field
                          outlined
                          v-model="qtySticker"
                          label="จำนวน Sticker"
                          type="number"
                          class="inputPrice"
                        ></v-text-field> -->
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="isPrint = false"
              >
                Close
              </v-btn>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-select
                hide-details
                v-model="qtySticker"
                :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]"
                label="จำนวน Sticker"
              ></v-select>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="primary" @click="printSticker">
                Print Sticker
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <ShowImage 
      v-model="isShowImage" 
      :msg="showimage_msg"
      :imageName="showimage_image_name"
      :width="showimage_width"
      :callBack="showimage_callback">
    </ShowImage>
  </v-container>
</template>

<script>
// import XLSX from 'xlsx';
// import Vue from 'vue'
import moment from 'moment'
import QrcodeVue from 'qrcode.vue'
// import DatetimePicker from 'vuetify-datetime-picker'
import api from "@/services/lambdaApi";
import ShowImage from "@/components/ShowImage";
// import draggable from 'vuedraggable'
import pdfMake from 'pdfmake'
import pdfFonts from '@/assets/custom-fonts.js'

// Vue.use(DatetimePicker)

export default {
  name: "order-edit",
  components:{
    QrcodeVue,
    ShowImage,
    ChatRoom: () => import("@/views/Chat/ChatRoom.vue"),
  },
  data: () => ({
    // QRCODE
    // value: 'https://example.com',
    size: 200,
    level: 'H',
    background: '#ffffff',
    foreground: '#000000',
    renderAs: 'svg',
    margin: 0,

    loading: false,
    dialog: false,
    isPrint: false,
    qtySticker: 6,
    reqTaxInvoice: false,
    requestTaxInvoice: "",

    isManualPayment: false,
    manualPayment_TotalPaid: 0,
    manualPayment_Remark: "",

    isChangePhone: false,
    newShippingPhone: "",

    isShowImage: false,
    showimage_msg: "",
    showimage_image_name: "",
    showimage_width: 400,
    showimage_callback: null,
    
    order: {},
    shipping: {},
    scheduleList: [],
    billingInfo: {
      billingName: "",
      billingAddress: "",
      billingSubDistrict: "",
      billingDistrict: "",
      billingProvince: "",
      billingPostcode: "",
      billingPhone: "",
      customerFirstName: "",
      customerLastName: "",
      customerEmail: "",
      requestTaxInvoice: false
    },
    payment: {},
    picking: {
      picker: "",
      pickingStatus: "",
      pickingStartTime: "",
      pickingEndTime: "",
      dryQty: 0,
      freshQty: 0,
      frozenQty: 0,
      diffAmount: 0,
      diffRemark: ""
    },
    mDataArray: [],
    //Chat
    showChatModal: false,
    canPushNotification: false,
    hasUnreadMessage: false,
    showMessageAlert: false,
  }),
  async mounted() {
    try {
      this.loading = true;
      let orderId = this.$route.params.id ?? "";
      if (orderId != 0) {
        let data = { orderId: orderId }
        let result = await api.getOrderById(data);
        // console.log("--- load ---", JSON.stringify(result.data));
        this.order = result.data;
        this.shipping = this.order?.shipping;
        if (this.order?.shipping?.scheduleList) {
          this.scheduleList = this.order?.shipping?.scheduleList;
        }
        if (this.order?.billingInfo != null) {
          this.billingInfo = this.order?.billingInfo;
        }
        this.payment = this.order?.payment;
        if (this.order?.picking != null) {
          this.picking = this.order?.picking;
        }

        if (this.order?.billingInfo != null && (this.order?.billingInfo["requestTaxInvoice"] ?? false) == true) {
          this.reqTaxInvoice = true;
          this.requestTaxInvoice = "XXX";
        } else {
          this.reqTaxInvoice = false;
          this.requestTaxInvoice = "";
        }
        this.mDataArray = this.order?.productList;
      }
    // } catch (error) {
    }
    finally {
      this.loading = false;
    } 
    //Chat
    // console.log("get can push notification");
    this.canPushNotification = await this.$store.dispatch(
      "chat/getCanPushNotification",
      { userID: this.order.ownerId }
    );
  },

  methods: {
    showImage(item) {
      this.showimage_callback = null;
      this.showimage_msg = item.productName;
      this.showimage_image_name = item.image;
      this.showimage_width = 400;
      this.isShowImage = true;
    },
    isShowRef(orderId) {
      return orderId?.toString().toUpperCase().substr(0,1) == 'L';
    },
    manualPaymentClick() {
      this.manualPayment_TotalPaid = this.payment?.grandTotal ?? 0;
      this.manualPayment_Remark = this.payment?.remark ?? "";
      this.isManualPayment = true;
    },
    popupChangePhone() {
      this.newShippingPhone = this.shipping.shippingPhone ?? "";
      this.isChangePhone = true;
    },
    async confirmChangePhone() {
      let newPhone = this.newShippingPhone ?? "";
      if (newPhone == null || newPhone.trim() == "") {
        alert('Please enter Phone no')
        return;
      }
      // if (newPhone.length != 10) {
      //   alert('Invalid Phone no')
      //   return;
      // }
      if (confirm("Confirm Cnange Phone?")) {
        try {
          this.loading = true;
          let data = {
            "action": "changeShippingPhone",
            "orderId": this.order.orderId,
            "shippingPhone": newPhone.trim(),
            "userCode": this.userCode
          };
          // console.log('request', JSON.stringify(data));
          let result = await api.updatePicking(data);
          // console.log('result', result.data.message);
          if (result.data.message == "completed.") {
            this.shipping["shippingPhone"] = newPhone;
          }
          this.isChangePhone = false;
        } catch (error) {
          alert(error);
        }
        finally {
          this.loading = false;
        }
      }
    },
    async confirmPaymentClick() {
      let totalPaid = parseFloat(this.manualPayment_TotalPaid);
      let remark = this.manualPayment_Remark ?? "";
      if (totalPaid <= 0) {
        alert('Please enter Total Paid')
        return;
      }
      if (remark == null || remark == "") {
        alert('Please enter Remark')
        return;
      }
      if (confirm("Confirm Manaul Payment?")) {
        try {
          this.loading = true;
          let data = {
            "action": "updateManualPayment",
            "orderId": this.order.orderId,
            "totalPaid": totalPaid,
            "remark": remark,
            "userCode": this.userCode
          };
          // console.log('request', JSON.stringify(data));
          let result = await api.updatePicking(data);
          // console.log('result', JSON.stringify(result));
          this.payment = result.data ?? {};
          this.isManualPayment = false;
        } catch (error) {
          alert(error);
        }
        finally {
          this.loading = false;
        }
      }
    },
    cancel() {
      this.$router.back();
    },
    async submit() {
    //   try {
    //     this.loading = true;
    //     let data = this.manualstock;
    //     data.enabled = this.enabled ? 1 : 0;
    //     data.startDate = this.startDate == null || this.startDate == "" ? "" : moment(this.startDate).format('YYYY-MM-DD');
    //     data.endDate = this.endDate == null || this.endDate == "" ? "" : moment(this.endDate).format('YYYY-MM-DD');
    //     let items = [];
    //     this.mDataArray.forEach(item => {
    //       items.push({ brcode: item.brcode, prcode: parseInt(item.prcode ?? 0), stockQty: parseInt(item.stockQty ?? 0) });
    //     });
    //     data.items = items;
    //     // console.log('request', JSON.stringify(data));
    //     let result;
    //     if (data.docId == 0) {
    //       result = await api.createManualStock(data);
    //     }
    //     else {
    //       result = await api.updateManualStock(data);
    //     }
    //     // console.log('result', JSON.stringify(result));
    //     this.$router.back();
    //   // } catch (error) {
    //   }
    //   finally {
    //     this.loading = false;
    //   }
    },

    //pdfmake
    getContents() {
      var contents = [];
      for (let page = 1; page <= this.qtySticker; page++) {
        var content = {
          style: 'table',
          table: {
            headerRows: 0,
            widths: ['*', 50],
            body: [
              [{ text: 'Order : #' + this.orderId, style: 'header' }, { qr: this.qrCode, fit: '60', rowSpan: 4 }],
              [{ text: "", fontSize: 8}, {}],
              // [{ text: 'Customer : ' + this.shippingFullName + ' T:' + this.phoneNo, fontSize: 8}, {}],
              // [{ text: 'Customer : ' + this.shippingFullName, fontSize: 8}, {}],

              [{ text: '\nPlaced on : ' + this.placedOn, fontSize: 9 }, {}],
              [{ text: 'Delivery : ' + this.deliveryOn, fontSize: 11 }, {}],

              // [{ text: '\n' + this.addressValue, fontSize: 8, colSpan: 2, noWrap: true }, {}],
              [{ text: '\n' + this.addressValue, fontSize: 8, colSpan: 2, maxHeight: 15 }, {}],
              [{ text: this.provinceValue, fontSize: 8, colSpan: 2, maxHeight: 15 }, {}],

              // [{ text: '\nOrder at : ' + this.order.branchName, fontSize: 9 }, {}],
              [{ text: '\nOrder at : ' + this.order.branchName, fontSize: 9 }, { text: this.expressText, style: 'header', fontSize: 12, color: 'red' }],
              [{ text: '\n', fontSize: 9 }, {}],

              [{ fontSize: 8, colSpan: 2,
                table: {
                  headerRows: 0,
                  widths: [35, 15, 25, 25, 25, 25, 25],
                  body: [
                    [
                      { lineHeight: 0.8, text: 'จำนวนถุง : ', fontSize: 8, border: [false, false, false, false] }, 
                      { lineHeight: 0.8, text: 'Dry ', fontSize: 8, border: [false, false, false, false] }, { text: this.dryQty?.toString() ?? '', fontSize: 8, alignment: 'center' },
                      { lineHeight: 0.8, text: 'Fresh ', fontSize: 8, border: [false, false, false, false] }, { text: this.freshQty?.toString() ?? '', fontSize: 8, alignment: 'center' },
                      { lineHeight: 0.8, text: 'Frozen ', fontSize: 8, border: [false, false, false, false] }, { text: this.frozenQty?.toString() ?? '', fontSize: 8, alignment: 'center' }
                    ],
                    [{ text: '\n', colSpan: 7, fontSize: 8, border: [false, false, false, false] }],

                    // [{ fontSize: 8, colSpan: 7,
                    //   table: {
                    //     headerRows: 0,
                    //     widths: [50, 25, "*"],
                    //     body: [
                    //       [
                    //         { lineHeight: 0.8, text: 'Req', fontSize: 8, border: [false, false, false, false] }, { text: this.requestTaxInvoice, fontSize: 8, border: [true, true, true, true] }, {},
                    //       ]
                    //     ]
                    //   },
                    //   // defaultBorder: false,
                    //   layout: 'noBorders'
                    // }]

                    [
                      { lineHeight: 0.8, text: 'Request : ', fontSize: 8, border: [false, false, false, false] }, 
                      { lineHeight: 0.8, text: 'Tax', fontSize: 8, border: [false, false, false, false] }, { text: this.requestTaxInvoice, fontSize: 8, alignment: 'center' },
                      // { lineHeight: 0.8, text: 'Tax', colSpan: 2, fontSize: 8, border: [false, false, false, false] }, { text: this.requestTaxInvoice, fontSize: 8, alignment: 'center' },
                      { lineHeight: 0.8, text: `${this.currentDatetime}    [${page} / ${this.qtySticker}]`, colSpan: 4, fontSize: 8, border: [false, false, false, false], alignment: 'right' }, 
                      // { text: '', fontSize: 8, border: [false, false, false, false] },
                      // { lineHeight: 0.8, text: ' ', fontSize: 8, border: [false, false, false, false] }, { text: '', fontSize: 8, border: [false, false, false, false] }
                    ],

                    // [ //{},
                    //   // { lineHeight: 0.8, colSpan: 2, text: 'Request', fontSize: 8, border: [false, false, false, false] }, 
                    //   { lineHeight: 0.8, text: 'Tax', colSpan: 2, fontSize: 8, border: [false, false, false, false] }, { text: this.requestTaxInvoice, fontSize: 8 },
                    //   { lineHeight: 0.8, text: ' ', fontSize: 8, border: [false, false, false, false] }, { text: '', fontSize: 8, border: [false, false, false, false] },
                    //   { lineHeight: 0.8, text: ' ', fontSize: 8, border: [false, false, false, false] }, { text: '', fontSize: 8, border: [false, false, false, false] }
                    // ],
                  ]
                },
                defaultBorder: false,
                // layout: 'noBorders'
              },
              ],
            ]
          },
          layout: 'noBorders',
          // pageBreak: "after" 
        };
        if (page < this.qtySticker) {
          content['pageBreak'] = 'after';
        }
        contents.push(content);
      }
      return contents;
    },
    printStickerPdf() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
          bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
          italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
          bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
        },
        // Kanit Font
        Kanit: {
          normal: 'Kanit-Regular.ttf',
          bold: 'Kanit-Medium.ttf',
          italics: 'Kanit-Italic.ttf',
          bolditalics: 'Kanit-MediumItalic.ttf'          
        }
      }
      const docDefinition = {
        pageSize: {
          width: 199,
          height: 256 //'auto'
        },
        pageOrientation: 'landscape',
        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 10, 20, 10, 20 ],

        content: this.getContents(),

        // content: [
        //   {
        //     style: 'table',
        //     table: {
        //       headerRows: 0,
        //       widths: ['*', 50],
        //       body: [
        //         [{ text: 'Order : #' + this.orderId, style: 'header' }, { qr: this.qrCode, fit: '60', rowSpan: 4 }],
        //         [{ text: 'Customer : ' + this.shippingFullName, fontSize: 8}, {}],

        //         [{ text: '\nPlaced on : ' + this.placedOn, fontSize: 9 }, {}],
        //         [{ text: 'Delivery time : ' + this.deliveryOn }, {}],

        //         // [{ text: '\n' + this.addressValue, fontSize: 8, colSpan: 2, noWrap: true }, {}],
        //         [{ text: '\n' + this.addressValue, fontSize: 8, colSpan: 2 }, {}],
        //         [{ text: this.provinceValue, fontSize: 8, colSpan: 2 }, {}],

        //         [{ text: '\nOrder at : ' + this.order.branchName, fontSize: 9 }, {}],
        //         [{ text: '\n', fontSize: 9 }, {}],

        //         [{ fontSize: 8, colSpan: 2,
        //           table: {
        //             headerRows: 0,
        //             widths: [35, 15, 25, 25, 25, 25, 25],
        //             body: [
        //               [
        //                 { lineHeight: 0.8, text: 'จำนวนถุง : ', fontSize: 8, border: [false, false, false, false] }, 
        //                 { lineHeight: 0.8, text: 'Dry ', fontSize: 8, border: [false, false, false, false] }, { text: this.dryQty?.toString() ?? '', fontSize: 8, alignment: 'center' },
        //                 { lineHeight: 0.8, text: 'Fresh ', fontSize: 8, border: [false, false, false, false] }, { text: this.freshQty?.toString() ?? '', fontSize: 8, alignment: 'center' },
        //                 { lineHeight: 0.8, text: 'Frozen ', fontSize: 8, border: [false, false, false, false] }, { text: this.frozenQty?.toString() ?? '', fontSize: 8, alignment: 'center' }
        //               ],
        //               [{ text: '\n', colSpan: 7, fontSize: 8, border: [false, false, false, false] }],

        //               // [{ fontSize: 8, colSpan: 7,
        //               //   table: {
        //               //     headerRows: 0,
        //               //     widths: [50, 25, "*"],
        //               //     body: [
        //               //       [
        //               //         { lineHeight: 0.8, text: 'Req', fontSize: 8, border: [false, false, false, false] }, { text: this.requestTaxInvoice, fontSize: 8, border: [true, true, true, true] }, {},
        //               //       ]
        //               //     ]
        //               //   },
        //               //   // defaultBorder: false,
        //               //   layout: 'noBorders'
        //               // }]

        //               [
        //                 { lineHeight: 0.8, text: 'Request : ', fontSize: 8, border: [false, false, false, false] }, 
        //                 { lineHeight: 0.8, text: 'Tax', fontSize: 8, border: [false, false, false, false] }, { text: this.requestTaxInvoice, fontSize: 8, alignment: 'center' },
        //                 // { lineHeight: 0.8, text: 'Tax', colSpan: 2, fontSize: 8, border: [false, false, false, false] }, { text: this.requestTaxInvoice, fontSize: 8, alignment: 'center' },
        //                 { lineHeight: 0.8, text: this.currentDatetime, colSpan: 4, fontSize: 8, border: [false, false, false, false], alignment: 'right' }, 
        //                 // { text: '', fontSize: 8, border: [false, false, false, false] },
        //                 // { lineHeight: 0.8, text: ' ', fontSize: 8, border: [false, false, false, false] }, { text: '', fontSize: 8, border: [false, false, false, false] }
        //               ],

        //               // [ //{},
        //               //   // { lineHeight: 0.8, colSpan: 2, text: 'Request', fontSize: 8, border: [false, false, false, false] }, 
        //               //   { lineHeight: 0.8, text: 'Tax', colSpan: 2, fontSize: 8, border: [false, false, false, false] }, { text: this.requestTaxInvoice, fontSize: 8 },
        //               //   { lineHeight: 0.8, text: ' ', fontSize: 8, border: [false, false, false, false] }, { text: '', fontSize: 8, border: [false, false, false, false] },
        //               //   { lineHeight: 0.8, text: ' ', fontSize: 8, border: [false, false, false, false] }, { text: '', fontSize: 8, border: [false, false, false, false] }
        //               // ],
        //             ]
        //           },
        //           defaultBorder: false,
        //           // layout: 'noBorders'
        //         },
        //         ],
        //       ]
        //     },
        //     layout: 'noBorders'
        //   },
        // ],

        styles: {
          header: {
            fontSize: 15,
            bold: true,
            alignment: 'left'
          },
          table: {
            // margin: [0, 5, 0, 15]
          },
        },

        defaultStyle: {
          font: 'Kanit',
          lineHeight: 0.5
        }
      }
      // pdfMake.createPdf(docDefinition).open({}, window)
      pdfMake.createPdf(docDefinition).open();
    },
    async printSticker() {
      try {
        let data = {
          "action": "printSticker",
          "orderId": this.order.orderId,
          "userCode": "",
          "dryQty": parseInt(this.picking?.dryQty ?? 0),
          "freshQty": parseInt(this.picking?.freshQty ?? 0),
          "frozenQty": parseInt(this.picking?.frozenQty ?? 0),
          "diffAmount": parseFloat(this.picking?.diffAmount ?? 0),
          "diffRemark": this.picking?.diffRemark ?? ""
        }
        // console.log('-- request printSticker --', JSON.stringify(data));
        let result = await api.updatePicking(data);
        // console.log('-- result printSticker --', result.data ?? "");
        
        // let pickingStatus = result.data["pickingStatus"] ?? "";
        // if (pickingStatus != "") {
          // this.picking['pickingStatus'] = result.data["pickingStatus"] ?? "";
          // this.picking['pickingStartTime'] = result.data["pickingStartTime"] ?? "";
          // this.picking['pickingEndTime'] = result.data["pickingEndTime"] ?? "";
        // }

        this.printStickerPdf();
      } catch (error) {
        alert(error)
      } finally {
      }
    },

    getCheckSum(s_digits)
    {
        var ll_asc; 
        var iresult, idivide;
        // ldigit = s_digits.Length;
        iresult = 0;
        var count = 0;
        Array.from(s_digits).forEach(c => {
          ll_asc = parseInt(c ?? 0);
          if ((++count % 2) == 0) {
            ll_asc = ll_asc * 3;
            // console.log('-- count -- ', c, ' - ', count)
          }
          iresult = iresult + ll_asc;
        });
        idivide = iresult % 10;

        return idivide == 0 ? 0 : 10 - idivide;

        // var digits;
        // if (idivide == 0)
        //     digits = 0;
        // else
        //     digits = 10 - idivide;
        // return digits;
    },
    onOpenChat() {
      if (this.canPushNotification) {
        this.showChatModal = true;
      } else {
        this.showChatModal = false;
        this.showMessageAlert = true;
      }
    },
    onUpdateUnreadMessage(value) {
      this.hasUnreadMessage = value;
    },
  },
  computed: {
    windowRef() {
      return this.$store.state["chat"].windowRef;
    },
    isShowQrCode() {
      return (this.orderId?.toString().toUpperCase().substr(0,1)=="T" || this.orderId?.toString().toUpperCase().substr(0,1)=="C") && (this.payment.isPaid == null || this.payment.isPaid==false)
      && (this.order?.isVoid==null || this.order?.isVoid==false) 
    },
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    userGroup() {
      return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    },
    userType() {
      return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    },
    userBrcode() {
      return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    },
    branchCodeName() {
      return `${this.order?.branchId} - ${this.order?.branchName}`
    },

    addressValue() {
      if (this.shipping.shippingType=='DELIVERY')
        return this.address;
      else if (this.shipping.shippingType=='PICKUP')
        return 'PICKUP';
    },
    provinceValue() {
      if (this.shipping.shippingType=='DELIVERY')
        return this.province;
      else
        return '';
    },
    currentDatetime() {
      return moment(Date.now()).format('YYYY/MM/DD HH:mm:ss');
    },
    dryQty() {
      return parseInt(this.picking.dryQty ?? 0);
    },
    freshQty() {
      return parseInt(this.picking.freshQty ?? 0);
    },
    frozenQty() {
      return parseInt(this.picking.frozenQty ?? 0);
    },
    totalBagQty() {
      return this.dryQty + this.freshQty + this.frozenQty;
    },
    expressText() {
      var express_text = "";
      this.shipping?.scheduleList?.forEach(item => {
        if (item.mode?.toString().toUpperCase() == "EXPRESS") {
          express_text = "EXPRESS";
          return false;;
        };
      });
      return express_text;
    },
    orderId() {
      var orderNo = `000000000000${this.order?.orderId ?? ""}`;
      return orderNo.substr(orderNo.length-12, 12);
    },
    phoneNo() {
      let phoneNo = `0000000000${(this.shipping?.shippingPhone ?? "").replaceAll("+66", "0").replaceAll(" (Line)", "").replaceAll("+", "").replaceAll("\u200b", "").trim()}`;
      return phoneNo.substr(phoneNo.length-10, 10);
    },
    vplusPhoneNo() {
      let phoneNo = `0000000000${(this.order?.vplusPhoneNo ?? "").replaceAll("+66", "0").replaceAll(" (Line)", "").replaceAll("+", "").replaceAll("\u200b", "").trim()}`;
      return phoneNo.substr(phoneNo.length-10, 10);
    },
    totalQty() {
      let value = parseInt(this.order?.totalQty ?? 0).toLocaleString("th-TH");
      return value;
    },
    subTotal() {
      let value1 = parseFloat(this.payment?.subTotal ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      let value2 = parseInt(this.order?.totalQty ?? 0).toLocaleString("th-TH");
      return `${value1} (${value2} items)`;
    },
    deliveryFee() {
      //********** บวกค่าขนส่ง Express 50 บาท/
      var extra_ship = 0;
      this.shipping?.scheduleList?.forEach(item => {
        if (item.mode?.toString().toUpperCase() == "EXPRESS") {
          extra_ship = 50;
          return false;;
        };
      });
      //********** บวกค่าขนส่ง Express 50 บาท/
      let value = parseFloat((this.payment?.shippingAmount ?? 0) + extra_ship).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      // let value = parseFloat(this.payment?.shippingAmount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      return value;
    },
    discountAmount() {
      let value = parseFloat(this.payment?.discountAmount ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      return value;
    },
    grandTotal() {
      let value = parseFloat(this.payment?.grandTotal ?? 0).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2});
      return value;
    },
    couponCode() {
      // var coupon_code = "00000000";
      // let couponCodeList = this.order?.couponCodeList ?? [];
      // if (couponCodeList != null && couponCodeList.length > 0) {
      //   coupon_code = `00000000${couponCodeList[0] ?? ""}`;
      //   coupon_code = coupon_code.substr(coupon_code.length-8, 8);
      //   // console.log('-- coupon_code --', couponCodeList[0]);
      // }
      // return coupon_code;

      var coupon_code = "00000000";
      let couponCodeList = this.order?.couponCodeList ?? [];
      if (couponCodeList != null && couponCodeList.length > 0) {
        couponCodeList.forEach(code => {
          if (code.length == 8) {
            coupon_code = code;
            // console.log('-- couponCode --', coupon_code);
            return false;
          };
        });
      };
      // console.log('-- couponCode 2 --', coupon_code);
      return coupon_code;
    },
    qrCode() {
      //********** บวกค่าขนส่ง Express 50 บาท/
      var extra_ship = 0;
      this.shipping?.scheduleList?.forEach(item => {
        if (item.mode?.toString().toUpperCase() == "EXPRESS") {
          extra_ship = 50;
          return false;;
        };
      });
      //********** บวกค่าขนส่ง Express 50 บาท/

      let shippingAmount = parseFloat((this.payment?.shippingAmount ?? 0) + extra_ship);
      
      // let shippingAmount = parseFloat(this.payment?.shippingAmount ?? 0);
      let grandTotal = parseFloat(this.payment?.grandTotal ?? 0);
      let sTotal = parseFloat(grandTotal - shippingAmount).toLocaleString("th-TH", {"minimumFractionDigits":2,"maximumFractionDigits":2}).replaceAll(",", "").split(".");
      // sTotal[0] = "241";
      let v1 = `0000000${sTotal[0] ?? ""}${sTotal[1]}`;
      let total_amt = v1.substr(v1.length-9, 9);

      let total_amtCal = total_amt.replace("-","0");
      
      let v2 = `0000${parseInt((this.payment?.shippingAmount ?? 0) + extra_ship)}`;
      let ship_amt = v2.substr(v2.length-4, 4);
      // console.log('-- ship_amt -- ', ship_amt);

      let orderCal = this.orderId.replace("T", "0").replace("L", "0").replace("C", "0");
      // let value_checksum = `${this.phoneNo}${orderCal}${total_amtCal}${ship_amt}`;
      // let value_checksum2 = `${this.phoneNo}${this.orderId}${total_amt}${ship_amt}`;
      let value_checksum = `${this.vplusPhoneNo}${orderCal}${total_amtCal}${ship_amt}`;
      let value_checksum2 = `${this.vplusPhoneNo}${this.orderId}${total_amt}${ship_amt}`;
      //phone_number+increment_id+total_amt+ship_amt;

      // var value_checksum = '08630226010070001819060012863500200';
      let checkSum = this.getCheckSum(value_checksum);
      let barcode = `${value_checksum2}${this.couponCode}`;
      // barcode = '08630226010070001819060012863500200000000000'; //1 '08983818520410000094530002578750090000000000'; //9;

      let payment_method = "0";
      // console.log('-- checkSum -- ', checkSum);
      return  `${barcode}${payment_method}${checkSum}`;
    },

    shippingFullName() {
      return `${this.order?.shipping?.shippingFirstName ?? ""}`;
      //return `${this.order?.shipping?.shippingFirstName ?? ""}  ${this.order?.shipping?.shippingLastName ?? ""}`;
    },
    address() {
      return `${this.shipping?.shippingAddress?.trim() ?? ""} ${this.shipping?.shippingSubDistrict?.trim() ?? ""}`;
      // return `${this.shipping?.shippingAddress?.trim() ?? ""} ${this.shipping?.shippingSubDistrict?.trim() ?? ""} ${this.shipping?.shippingDistrict?.trim() ?? ""} `;
    },
    province() {
      return `${this.shipping?.shippingDistrict?.trim() ?? ""} ${this.shipping?.shippingProvince?.trim() ?? ""} ${this.shipping?.shippingPostcode?.trim() ?? ""}`;
      // return `${this.shipping?.shippingProvince?.trim() ?? ""} ${this.shipping?.shippingPostcode?.trim() ?? ""}`;
    },
    placedOn() {
      return (this.payment?.timestamp ?? "").replaceAll("-", "/");;
    },
    deliveryOn() {
      var deliveryOnText = "";
      let scheduleList = this.shipping?.scheduleList ?? [];
      if (scheduleList != null && scheduleList.length > 0) {
        deliveryOnText = (scheduleList[0]?.dateTime ?? "").replaceAll("-", "/");
        var delivery = deliveryOnText.substr(deliveryOnText.length-19, 16);
        if (delivery.length == 16) {
          var hh = parseInt(delivery.substr(11, 2)) + 1;
          hh = `00${hh ?? 0}`;
          hh = hh.substr(hh.length-2, 2);
          let mm = delivery.substr(14, 2);
          delivery = `${delivery} - ${hh}:${mm}`;
        }
        return delivery;
      }
      return deliveryOnText;
    }
  },
  watch: {
    showChatModal: {
      immediate: true,
      handler: function (value) {
        if (value) {
          setTimeout(() => {
            const chatRoomRef = this.$refs.chatRoom;
            console.log("chatRoomRef", chatRoomRef, window.innerHeight);
            chatRoomRef.$el.style.height = window.innerHeight + "px";
          }, 300);
        }
      },
    },
  },
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
}
.table_sub {
   height: 200px;
   overflow: auto;
}
.subject {
  color: #0D47A1;
}
.header2 {
  font-size: 16px;
  font-weight: bold;
  color: #0D47A1;
}
.header {
  font-size: 18px;
  font-weight: bold;
  color: #D50000;
}
.subheader {
  font-size: 18px;
  font-weight: bold;
  color: #0D47A1;
}
.posInfo {
  color: #D50000;
}
.inputPrice >>> input[type='number'] {
    -moz-appearance:textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.warehouse {
  margin: 5px;
  background-color: red;
  color: #ffffff;
  padding: 3px
}

.section-leave-active,
.section-enter-active {
  transition: 0.5s;
}
.section-enter {
  transform: translateX(100%);
}
.section-leave-to {
  transform: translateX(100%);
}

.minimize-btn {
  position: fixed;
  padding: 0.5rem;
  top: 12rem;
  right: 0;
  border-radius: 0.25rem 0 0 0.25rem;
  z-index: 10;
}
</style>