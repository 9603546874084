<template>
    <div>
      <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="mDataArray"
          sort-by="sendNotifyId"
          :sort-desc="true"
          :items-per-page="5"
        >
          <!-- table top section -->
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Send Notify</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                clearable
                hide-details
                v-model="notifyId"
                label="Notify Id"
                @keyup.enter="searchClick"
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                clearable
                hide-details
                v-model="title"
                label="Title"
                @keyup.enter="searchClick"
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-icon class="mr-2" @click="searchClick">
                search
              </v-icon>
              <v-spacer></v-spacer>
              <v-btn @click="newNotify" color="primary" dark class="mb-2">
                <v-icon left>add</v-icon>
                <span>New Notify</span>
              </v-btn>
            </v-toolbar>
          </template>
  
          <!-- table tr section -->
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.sendNotifyId }}</td>
              <td>{{ item.title }}</td>
              <!-- <td>{{ item.body }}</td> -->
              <td>{{ item.refNo }}</td>
              <!-- <td>{{ item.brcode }}</td> -->
              <!-- <td>{{ item.createdDatetime }}</td> -->
              <td>{{ item.effectDatetime }}</td>
              <td>{{ item.updatedDatetime }}</td>
              <td>{{ item.statusDesc }}</td>
              <td>
                <v-checkbox readonly v-model="item.active"></v-checkbox>
              </td>
              <td>
                <v-icon class="mr-2" @click="editItem(item)">
                  edit
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    </div>
  </template>
  
  <script>
  import api from "@/services/lambdaApi";
  
  export default {
    name: "sendnotify",
    data() {
      return {
        loading: false,
        notifyId: "",
        title: "",
        mDataArray: [],
        headers: [
          { text: "Notify Id", value: "sendNotifyId" },
          { text: "Title", value: "title" },
          // { text: "Description", value: "body" },
          { text: "Promotion Id", value: "refNo" },
          // { text: "Brcode", value: "brcode" },
          // { text: "Create Datetime", value: "createdDatetime" },
          { text: "Effect Datetime", value: "effectDatetime" },
          { text: "Update Datetime", value: "updatedDatetime" },
          { text: "Status", value: "statusDesc" },
          {
            text: "Active",
            align: "left",
            // sortable: false,
            value: "active",
          },
          {
            text: "Action",
            align: "left",
            sortable: false,
            value: "action",
          },
        ],
      };
    },
    // components: {
    // },
    async mounted() {
        this.loadData();
    },
    methods: {
      searchClick() {
        this.loadData();
      },
      editItem(item) {
        this.$router.push(`/sendnotify/edit/${item.sendNotifyId}`);
      },
      newNotify() {
        this.$router.push("/sendnotify/edit/0");
      },
      async loadData() {
        try {
          this.loading = true;
          let notifyId = 0;
          if ((this.notifyId ?? "" != "") && (this.notifyId.length > 0))
            notifyId = parseInt(this.notifyId ?? 0);
          let filter = { sendNotifyId: notifyId, title: this.title };
          // console.log("--- filter ---", filter);
          let result = await api.getSendNotifyList(filter);
          // console.log("--- data list ---", result.data);
          this.mDataArray = result.data;
          // } catch (error) {
        } finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  </style>