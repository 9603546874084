<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-10  pa-5" outlined>
          <v-form @submit.prevent="submit">
            <template>
              <v-row class="ma-0">
                <h1 style="color: #F44336;">ใช้รหัส C เท่านั้น</h1>
                <v-spacer></v-spacer>
                <h1>Product Import Excel (XLSX)</h1>
                <v-spacer></v-spacer>
              </v-row>
            </template>

            <template>

              <v-divider></v-divider>
              <v-row class="ma-0">
                <v-col cols="5">
                  <h3 class="mt-2">Product Information</h3>
                </v-col>
                <v-col cols="4">
                  <v-btn color="blue darken-2" dark class="mb-2" :href="getlinkDownload('product_info.xlsx')">
                    <v-icon left>get_app</v-icon>
                    <span>Download Template (XLSX)</span>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn @click="importClick('importproductinfo')" color="green darken-2" dark class="mb-2">
                    <v-icon left>arrow_circle_up</v-icon>
                    <span>Import Excel (XLSX)</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <v-row class="ma-0">
                <v-col cols="5">
                  <h3 class="mt-2">Product Range</h3>
                </v-col>
                <v-col cols="4">
                  <v-btn color="blue darken-2" dark class="mb-2" :href="getlinkDownload('product_range.xlsx')">
                    <v-icon left>get_app</v-icon>
                    <span>Download Template (XLSX)</span>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn @click="importClick('importproductrange')" color="green darken-2" dark class="mb-2">
                    <v-icon left>arrow_circle_up</v-icon>
                    <span>Import Excel (XLSX)</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <v-row class="ma-0">
                <v-col cols="5">
                  <h3 class="mt-2">Product Meta</h3>
                </v-col>
                <v-col cols="4">
                  <v-btn color="blue darken-2" dark class="mb-2" :href="getlinkDownload('product_meta.xlsx')">
                    <v-icon left>get_app</v-icon>
                    <span>Download Template (XLSX)</span>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn @click="importClick('importproductmeta')" color="green darken-2" dark class="mb-2">
                    <v-icon left>arrow_circle_up</v-icon>
                    <span>Import Excel (XLSX)</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <v-row class="ma-0">
                <v-col cols="5">
                  <h3 class="mt-2">Product Attribute (Alcohol)</h3>
                </v-col>
                <v-col cols="4">
                  <v-btn color="blue darken-2" dark class="mb-2" :href="getlinkDownload('product_att_alcohol.xlsx')">
                    <v-icon left>get_app</v-icon>
                    <span>Download Template (XLSX)</span>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn @click="importClick('importproductattributealcohol')" color="green darken-2" dark class="mb-2">
                    <v-icon left>arrow_circle_up</v-icon>
                    <span>Import Excel (XLSX)</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <v-row class="ma-0">
                <v-col cols="5">
                  <h3 class="mt-2">Product Attribute (Butcher)</h3>
                </v-col>
                <v-col cols="4">
                  <v-btn color="blue darken-2" dark class="mb-2" :href="getlinkDownload('product_att_butcher.xlsx')">
                    <v-icon left>get_app</v-icon>
                    <span>Download Template (XLSX)</span>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn @click="importClick('importproductattributebutcher')" color="green darken-2" dark class="mb-2">
                    <v-icon left>arrow_circle_up</v-icon>
                    <span>Import Excel (XLSX)</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <v-row class="ma-0">
                <v-col cols="5">
                  <h3 class="mt-2">Product Attribute (Fruit & Veg Toy)</h3>
                </v-col>
                <v-col cols="4">
                  <v-btn color="blue darken-2" dark class="mb-2" :href="getlinkDownload('product_att_fruit.xlsx')">
                    <v-icon left>get_app</v-icon>
                    <span>Download Template (XLSX)</span>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn @click="importClick('importproductattributefruit')" color="green darken-2" dark class="mb-2">
                    <v-icon left>arrow_circle_up</v-icon>
                    <span>Import Excel (XLSX)</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <v-row class="ma-0">
                <v-col cols="5">
                  <h3 class="mt-2">Product Attribute (HBA & Household)</h3>
                </v-col>
                <v-col cols="4">
                  <v-btn color="blue darken-2" dark class="mb-2" :href="getlinkDownload('product_att_hba.xlsx')">
                    <v-icon left>get_app</v-icon>
                    <span>Download Template (XLSX)</span>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn @click="importClick('importproductattributehba')" color="green darken-2" dark class="mb-2">
                    <v-icon left>arrow_circle_up</v-icon>
                    <span>Import Excel (XLSX)</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <v-row class="ma-0">
                <v-col cols="5">
                  <h3 class="mt-2">Product Attribute (Others)</h3>
                </v-col>
                <v-col cols="4">
                  <v-btn color="blue darken-2" dark class="mb-2" :href="getlinkDownload('product_att_others.xlsx')">
                    <v-icon left>get_app</v-icon>
                    <span>Download Template (XLSX)</span>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn @click="importClick('importproductattributeothers')" color="green darken-2" dark class="mb-2">
                    <v-icon left>arrow_circle_up</v-icon>
                    <span>Import Excel (XLSX)</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <v-row class="ma-0">
                <v-col cols="5">
                  <h3 class="mt-2">Product Related</h3>
                </v-col>
                <v-col cols="4">
                  <v-btn color="blue darken-2" dark class="mb-2" :href="getlinkDownload('product_related.xlsx')">
                    <v-icon left>get_app</v-icon>
                    <span>Download Template (XLSX)</span>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn @click="importClick('importproductrelated')" color="green darken-2" dark class="mb-2">
                    <v-icon left>arrow_circle_up</v-icon>
                    <span>Import Excel (XLSX)</span>
                  </v-btn>
                </v-col>
              </v-row>

            </template>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { productAttributeBucketName } from "@/services/constants";

export default {
  name: "product-import",
  // components:{
  // },
  data: () => ({
    loading: false
  }),
  async mounted() {
  },
  methods: {
    getlinkDownload(fileName) {
      let url = `https://${productAttributeBucketName}.s3-ap-southeast-1.amazonaws.com/productTemplate/${fileName}`;
      // console.log(url);
      return url;
    },
    importClick(pageName) {
      this.$router.push(`/product/${pageName}`);
    }
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>