<template fluid>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      min-width="800px"
      max-height="600px"
      max-width="1000px"
    >
      <v-card>
        <v-data-table height="500" :headers="headers" :items="mDataArray" :items-per-page="5" :loading="loading">
          <!-- table top section -->
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Product</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                label="Search"
                single-line
                hide-details
                @keyup.enter="searchClick"
              ></v-text-field>
              <v-icon class="mr-2" @click="searchClick">
                search
              </v-icon>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <!-- table tr section -->
          <template v-slot:item="{ item }">
            <tr @click="callBack(item)">
              <td>
                <v-img
                  contain
                  class="ma-2"
                  :src="item.image"
                  lazy-src="@/assets/noimage.png"
                  aspect-ratio="1"
                  min-width="80"
                  max-width="80"
                  max-height="80"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular v-show="loading"
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </td>
              <td>{{ item.cprcode }}</td>
              <td>{{ item.hema_name_en }}</td>
              <!-- <td>{{ item.hema_brand_en }}</td>
              <td>{{ item.online_category_l1_en }}</td>
              <td>{{ item.online_category_l2_en }}</td> -->
              <!-- <td>{{ item.pr_suref3 }}</td> -->
              <td>
                <v-checkbox
                  readonly
                  v-model="item.master_online"
                ></v-checkbox>
              </td>
              <!-- <td>
                <v-checkbox
                  readonly
                  v-model="item.enabled"
                ></v-checkbox>
              </td> -->
            </tr>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click.stop="show=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import api from "@/services/lambdaApi";

export default {
  name: "ProductSearch",
  data() {
    return {
      loading: false,
      search: "",
      mDataArray: [],
      headers: [
        {
          text: "Image",
          align: "left",
          sortable: false,
          value: "image"
        },
        { text: "(C) Pr Ccode", value: "cprcode" },
        { text: "Pr Name", value: "hema_name_en" },
        // { text: "Brand", value: "hema_brand_en" },
        // { text: "Category Lv1", value: "online_category_l1_en" },
        // { text: "Category Lv2", value: "online_category_l2_en" },
        // {
        //   text: "pr_suref3",
        //   align: "left",
        //   sortable: false,
        //   value: "pr_suref3"
        // },
        {
          text: "Online",
          align: "left",
          sortable: false,
          value: "master_online"
        },
        // {
        //   text: "Enabled",
        //   align: "left",
        //   sortable: false,
        //   value: "enabled"
        // },
      ],
    };
  },
  async mounted() {
    //this.loadProducts();
  },
  methods: {
    searchClick() {
      this.loadProducts();
    },
    async loadProducts() {
      try {
        this.loading = true;
        let data = { searchText: this.search };
        let result = await api.getProducts(data);
        this.mDataArray = result.data;  
      // } catch (error) {
      }
      finally {
        this.loading = false;
      }
    },
    callBack(item) {
      if (this.onSelect != null) {
        this.onSelect(item);
      }
      this.show = false;
    },
  },
  props: {
     value: Boolean,
     onSelect: null
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
};
</script>

<style></style>