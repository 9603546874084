<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row>
        <v-col lg="3" md="3" sm="12" cols="12">
            <h3>Upload xlsx file</h3>
            <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
        </v-col>
        <v-col lg="3" md="3" sm="12" cols="12">
          <v-btn class="ml-3 mt-3" color="primary" @click="clearProduct">
            <v-icon left>delete</v-icon>
            Clear All
          </v-btn>
          <v-btn class="ml-3 mt-3" @click="exportClick" color="primary">
            <v-icon left>save</v-icon>
            <span>Export</span>
          </v-btn>
        </v-col>
      </v-row>
  
      <v-card class="mt-1">
          <v-data-table
              height="500"
              fixed-header
              :headers="headers"
              :items="mDataArray"
              :sort-desc="false"
              :items-per-page="15"
          >
          <template v-slot:item="{ item }">
              <tr>
                  <!-- <td>
                    <v-icon class="mr-2" @click="editItem(item)">
                      edit
                    </v-icon>
                  </td> -->
                  <td>{{ item.iprcode }}</td>
                  <td>{{ item.campaign }}</td>
                  <td>{{ item.productName }}</td>
                  <td>{{ item.sdate }}</td>
                  <td>{{ item.edate }}</td>
                  <td style="text-align: right">{{ numberFormat(item.price, 2) }}</td>
                  <td style="text-align: right">{{ numberFormat(item.qty, 0) }}</td>
                  <td style="text-align: right">{{ numberFormat(item.sales, 2) }}</td>
                  <td>{{ item.promotion }}</td>
                  <td>{{ item.cat_lv2 }}</td>
                  <td style="text-align: right">{{ numberFormat(item.stock_qty, 0) }}</td>
                  <td style="text-align: right">{{ numberFormat(item.stock_value, 2) }}</td>
              </tr>
          </template>
          </v-data-table>
      </v-card>
    </v-container>
  </template>
  
  <script>
    import api from "@/services/lambdaApi";
    import moment from 'moment'
    import XLSX from 'xlsx';
  
    export default {
      name: "campaigntracking",
      // components: {
      // },
      data () {
        return {
          loading: false,
          mDataArray: [],
          headers: [
            //   {
            //     text: "Action",
            //     align: "left",
            //     sortable: false,
            //     value: "action",
            //     width: 60
            //   },
              { text: "PrCode", value: "iprcode" },
              { text: "Campaign", value: "campaign" },
              { text: "PrName", value: "productName" },
              { text: "StartDate", value: "sdate" },
              { text: "EndDate", value: "edate" },
              { text: "Unit Price", value: "price", align: "right" },
              { text: "Qty", value: "qty", align: "right" },
              { text: "Sales", value: "sales", align: "right" },
              { text: "Promotion", value: "promotion" },
              { text: "Cat Lv2", value: "cat_lv2" },
              { text: "Stok Qty", value: "stock_qty", align: "right" },
              { text: "Stock Value", value: "stock_value", align: "right" }
          ],
        }
      },
      // async mounted () {
      // },
      methods: {
        clearProduct() {
            this.mDataArray = []
        },
        onFileImportSelected(e) {
            try {
                this.loading = true;
                var files = e.target.files || e.dataTransfer.files;
                if (files.length == 0)
                  return;
                this.previewFiles(files[0]);
            } catch (error) {
                console.log('-- error --', error);
                this.loading = false
            }
            finally {
                this.$refs.importFile.value = "";
                // setTimeout(() => {
                // this.loading = false;
                // }, 1000);
            }
        },
        previewFiles(file) { // detect file event
            var reader = new FileReader();
            var vm = this;
            reader.onload = async function(e) {
                var data = new Uint8Array(e.target.result);
                var workbook = XLSX.read(data, { type: "array" });
                let sheetName = workbook.SheetNames[0];
                /* DO SOMETHING WITH workbook HERE */
                // console.log(workbook);
                let worksheet = workbook.Sheets[sheetName];
                // console.log(XLSX.utils.sheet_to_json(worksheet));
                let items = XLSX.utils.sheet_to_json(worksheet);
                // console.log('-- items --', items);
                let campaigns = [];
                items.forEach(item => {
                    campaigns.push(
                        {
                            iprcode: parseInt(item.prcode ?? 0),
                            campaign: item.campaign,
                            sdate: item.sdate,
                            edate: item.edate
                        }
                    );
                });
                if (campaigns && campaigns.length>0) {
                    // console.log('-- campaigns --', campaigns);
                    let result = await api.getCampaignTracking(campaigns);
                    vm.mDataArray = result.data;
                    vm.loading = false;
                }
            };
            reader.readAsArrayBuffer(file);
          },
          async exportClick() {
            try {
              this.loading = true;
              let cdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
              const dataWS = XLSX.utils.json_to_sheet(this.mDataArray)
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, dataWS)
              XLSX.writeFile(wb,`CampaignTracking_${moment(cdate).format('YYYYMMDD')}.xlsx`)
            } catch (error) {
              alert(error)
            } finally {
              this.loading = false;
            }
          },
          numberFormat(value, decimal) {
            return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
          },
      },
  
      computed: {
        userCode() {
          return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
        },
        userGroup() {
          return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
        },
        userType() {
          return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
        },
        userBrcode() {
          return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
        },
      }
    }
  </script>
  
  <style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  </style>