//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE = "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE = "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE = "An error has occurred. The photo was unable to upload.";
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

export const apiUrl = "https://pxa8yt7750.execute-api.ap-southeast-1.amazonaws.com/Prod"; //uat
export const apiUrl2 = "https://i9xmmtqrmf.execute-api.ap-southeast-1.amazonaws.com/Prod"; //cognito
export const groupBucketName = "group2-image-bucket-dev"; //uat
export const productAttributeBucketName = "villa-product-attribute-dev"; //uat

// export const apiUrl = "https://nfebsi8s55.execute-api.ap-southeast-1.amazonaws.com/Prod"; //production
// export const groupBucketName = "group2-image-bucket-main"; //production
// export const productAttributeBucketName = "villa-product-attribute-main"; //production

export const imageUrl = "https://villa-remove-bg-small-output.s3-ap-southeast-1.amazonaws.com";

export const server = {
  LOGIN_URL: `login`,
  REGISTER_URL: `register`,
  PRODUCT_URL: `searchProduct`,
  TRANSACTION_URL: `transaction`,
  REPORT_URL: `report`,
  TOKEN_KEY: ``,
  // TOKEN_KEY: `token`,
  USERNAME: `username`,
  USERFIRSTNAME: `userFirstName`,
  USERTYPE: `userType`,
  USERGROUP: `userGroup`,
  BRCODE: `brcode`,
  ACCESS_TOKEN: `accessToken`,
  ID_TOKEN: `idToken`,
  REFRESH_TOKEN: `refreshToken`,
  EXPIRES_IN: `expiresIn`,
  EXPIRES_TIME: `expiresTime`
};