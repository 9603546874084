<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-10 pa-5" outlined>
          <v-form @submit.prevent="submit">
            <template>
              <v-row class="ma-0">
                <v-spacer></v-spacer>
                <h1>Import Product Range</h1>
                <v-spacer></v-spacer>
              </v-row>
            </template>

            <v-divider></v-divider>
            <br />
            <template>
              <v-row class="ma-0">
                <h3 class="mr-3">Import Excel (XLSX)</h3>
                <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
                <!-- <v-spacer></v-spacer>
                <v-btn color="primary" @click="clearData">
                  <v-icon left>delete</v-icon>
                  Clear All
                </v-btn> -->

                <v-spacer></v-spacer>
                <v-checkbox v-if="userGroup == 'it'"
                    height="1"
                    v-model="isNewStore"
                    label="New Store"
                ></v-checkbox>
              </v-row>
            </template>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/lambdaApi";
import XLSX from 'xlsx';

export default {
  name: "import-product-range",
  // components:{
  // },
  data: () => ({
    loading: false,
    isNewStore: false,
    mDataArray: []
  }),
  // async mounted() {
  // },
  methods: {
    removeItem: function (item) {
      let mProduct = this.mDataArray.find(d => d.cprcode === item.cprcode);
      if (mProduct) {
        let index = this.mDataArray.indexOf(mProduct);
        if (index >= 0) {
          this.mDataArray.splice(index, 1)
        }
      }
    },
    removeItemStore: function (item, data) {
      let mItem = item.avail_store.find(d => d.store === data.store);
      if (mItem) {
        let index = item.avail_store.indexOf(mItem);
        if (index >= 0) {
          item.avail_store.splice(index, 1)
        }
      }
    },
    getValue(mProduct, fieldName) {
      return (mProduct[fieldName] ?? "").toUpperCase() == "Y";
    },
    addStoreItem(avail_store, mProduct, brcode) {
      let store = `store_${brcode}`;
      if (this.getValue(mProduct, store)) {
        let store_visibility = `store_${brcode}_visibility`;
        avail_store.push({
          store: brcode,
          visibility: this.getValue(mProduct, store_visibility)
        });
      }
      // let store = `store_${brcode}`;
      // let store_visibility = `store_${brcode}_visibility`;
      // let hasKey = mProduct.hasOwnProperty(store) && mProduct.hasOwnProperty(store_visibility);
      // if (hasKey) {
      //   avail_store.push({
      //     store: brcode,
      //     range: this.getValue(mProduct, store),
      //     visibility: this.getValue(mProduct, store_visibility)
      //   });
      // };
    },
    getAvailStore(mProduct) {
      let avail_store = [];
      this.addStoreItem(avail_store, mProduct, "1000");
      this.addStoreItem(avail_store, mProduct, "1001");
      this.addStoreItem(avail_store, mProduct, "1003");
      this.addStoreItem(avail_store, mProduct, "1005");
      this.addStoreItem(avail_store, mProduct, "1006");
      this.addStoreItem(avail_store, mProduct, "1007");
      this.addStoreItem(avail_store, mProduct, "1011");
      this.addStoreItem(avail_store, mProduct, "1012");
      this.addStoreItem(avail_store, mProduct, "1014");
      this.addStoreItem(avail_store, mProduct, "1015");
      this.addStoreItem(avail_store, mProduct, "1016");
      this.addStoreItem(avail_store, mProduct, "1017");
      this.addStoreItem(avail_store, mProduct, "1019");
      this.addStoreItem(avail_store, mProduct, "1020");
      this.addStoreItem(avail_store, mProduct, "1021");
      this.addStoreItem(avail_store, mProduct, "1022");
      this.addStoreItem(avail_store, mProduct, "1023");
      this.addStoreItem(avail_store, mProduct, "1024");
      this.addStoreItem(avail_store, mProduct, "1026");
      this.addStoreItem(avail_store, mProduct, "1030");
      this.addStoreItem(avail_store, mProduct, "1032");
      this.addStoreItem(avail_store, mProduct, "1033");
      this.addStoreItem(avail_store, mProduct, "1034");
      this.addStoreItem(avail_store, mProduct, "1036");
      this.addStoreItem(avail_store, mProduct, "1038");
      this.addStoreItem(avail_store, mProduct, "1039");
      this.addStoreItem(avail_store, mProduct, "1040");
      this.addStoreItem(avail_store, mProduct, "1041");
      this.addStoreItem(avail_store, mProduct, "1042");
      this.addStoreItem(avail_store, mProduct, "1044");
      this.addStoreItem(avail_store, mProduct, "1046");
      this.addStoreItem(avail_store, mProduct, "1047");
      this.addStoreItem(avail_store, mProduct, "1048");
      this.addStoreItem(avail_store, mProduct, "1049");
      this.addStoreItem(avail_store, mProduct, "1050");
      this.addStoreItem(avail_store, mProduct, "1051");
      this.addStoreItem(avail_store, mProduct, "1052");
      this.addStoreItem(avail_store, mProduct, "1053");
      this.addStoreItem(avail_store, mProduct, "1054");
      this.addStoreItem(avail_store, mProduct, "1055");
      this.addStoreItem(avail_store, mProduct, "3000");
      return avail_store;
    },
    cancel() {
      this.$router.back();
    },
    clearData() {
      this.mDataArray = []
    },
    onFileImportSelected(e) {
      try {
        this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile.value = "";
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    previewFiles(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));

            let prcodes = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- prcodes --', prcodes);
            // let prcodeList = [];
            // prcodes.forEach(item => {
            //   prcodeList.push(parseInt(item.prcode ?? 0));
            // });
            var count = 0;
            let dataArray = [];
            prcodes.forEach(item => {
                var itemUpdate = {
                    "seq": ++count,
                    "cprcode": parseInt(item["prcode"]),
                    "online_category_l1_en": (item["online_category_l1_en"] ?? "").toString(),
                    "online_category_l2_en": (item["online_category_l2_en"] ?? "").toString(),
                    "online_category_l3_en": (item["online_category_l3_en"] ?? "").toString(),
                    "avail_store": vm.getAvailStore(item),
                    "master_online": (item["online"] ?? "").toUpperCase() == "Y" ? true : false
                };
                dataArray.push(itemUpdate);
            });
            // this.mDataArray = dataArray;
            if (dataArray && dataArray.length>0) {
                var isNewStore = 0;
                if (vm.isNewStore) {
                  isNewStore = 1;
                };
                var start = 0;
                var stop = 0;
                while (stop <= dataArray.length) {
                    stop = start + 1000;
                    var dataUpdates = dataArray.filter(o => o.seq >= start && o.seq <= stop)
                    start = stop + 1;
                    // console.log('-- dataUpdates --',dataUpdates);
                    var itemUpdate = {
                      "isNewStore": parseInt(isNewStore),
                      "items": dataUpdates
                    };
                    //console.log('-- itemUpdate --', JSON.stringify(itemUpdate));
                    var result = await api.setProductRangeImport(JSON.stringify(itemUpdate));
                    // var result = await api.setProductRangeImport(JSON.stringify(dataUpdates));
                    console.log('-- result --',result);
                }
                vm.$router.back();
            }
        };
        reader.readAsArrayBuffer(file);
    },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    userGroup() {
      return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    },
    userType() {
      return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    },
    userBrcode() {
      return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    },
    pickers() {
      if (this.mPickers == null || this.orderItem == null)
        return []
      else {
        return this.mPickers.filter(o => o.brcode == this.orderItem.branchId)
      }
    },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.1rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
.table_store {
   height: 270px;
   overflow: auto;
}
.subject {
  color: #0D47A1;
}
</style>