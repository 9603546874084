<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="80"
          :width="8"
          color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-card class="mx-10 pa-5" outlined>
            <v-form @submit.prevent="submit">
              <v-row>
                <!-- Left -->
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="sendNotify.sendNotifyId"
                    label="Notify Id"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- Right -->
                <v-col cols="6">
                  <v-checkbox
                    v-model="sendNotify.active"
                    label="Active"
                  ></v-checkbox>
                </v-col>
              </v-row>
  
              <v-row>
                <!-- Left -->
                <v-col cols="6">
                  <v-text-field
                    clearable
                    outlined
                    v-model="sendNotify.title"
                    label="Title"
                  ></v-text-field>

                  <v-select
                    outlined
                    v-model="sendNotify.sender"
                    :items="senders"
                    label="Sender"
                    required
                  ></v-select>

                  <v-text-field
                    clearable
                    outlined
                    v-model="sendNotify.refNo"
                    label="Promotion Id"
                  ></v-text-field>

                  <v-datetime-picker
                    v-model="effectDatetime"
                    datetime="String"
                    label="Effect Datetime"
                  ></v-datetime-picker>

                  <v-textarea
                    clearable
                    outlined
                    v-model="sendNotify.body"
                    label="Description"
                    height="200"
                  ></v-textarea>

                  <v-text-field
                    outlined
                    v-model="sendNotify.createdDatetime"
                    label="Create Datetime"
                    disabled
                  ></v-text-field>

                  <v-text-field
                    outlined
                    v-model="sendNotify.updatedDatetime"
                    label="Update Datetime"
                    disabled
                  ></v-text-field>
                </v-col>
  
                <!-- Right -->
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="sendNotify.statusDesc"
                    label="Status"
                    disabled
                  ></v-text-field>

                  <v-select
                    outlined
                    v-model="sendNotify.service"
                    :items="services"
                    item-text="desc" 
                    item-value="id" 
                    label="Service"
                    required
                  ></v-select>

                  <v-text-field
                    clearable
                    outlined
                    v-model="sendNotify.remark"
                    label="Remark"
                  ></v-text-field>

                  <v-text-field
                    v-model="sendNotify.sendDatetime"
                    label="Send Datetime"
                    disabled
                  ></v-text-field>

                  <div class="row">
                    <v-col cols="3">
                      <h3>Owner Ids</h3>
                    </v-col>
                    <v-col cols="9">
                      <v-checkbox
                        class="mt-n1"
                        v-model="sendNotify.notifyAll"
                        label="Notify All"
                      ></v-checkbox>
                    </v-col>
                  </div>
                  <div class="row mt-n1" v-if="sendNotify.ownerIds != null">
                      <v-col cols="10">
                        <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
                        <div>import column header (ownerId)</div>
                      </v-col>
                      <v-col cols="2">
                        <v-icon @click="sendNotify.ownerIds=[]">delete</v-icon>
                      </v-col>
                  </div>
                  <div class="row mt-n1" v-if="sendNotify.ownerIds != null">
                      <v-col cols="12">
                        <div class="table_sub">
                          <table class="table table-striped" width="100%">
                            <thead class="head-dark" align-center>
                              <tr>
                                <th width="90%" style="text-align:left">Owner Id</th>
                                <th width="10%" style="text-align:left">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                                <template v-for="item in sendNotify.ownerIds">
                                <tr>
                                    <td>{{ item }}</td>
                                    <td>
                                      <v-icon @click="removeItem(item, sendNotify.ownerIds)">delete</v-icon>
                                    </td>
                                </tr>
                                </template>
                            </tbody>
                          </table>
                        </div>
                      </v-col>
                  </div>
                </v-col>
              </v-row>
  
              <br />
              <v-divider></v-divider>
              <br />
              <v-row>
                <v-spacer></v-spacer>
                <v-btn class="mr-4" @click="cancel">
                  Cancel
                </v-btn>
  
                <v-btn color="primary" type="submit">
                  Confirm
                </v-btn>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import XLSX from 'xlsx';
  import Vue from 'vue'
  import moment from 'moment'
  import api from "@/services/lambdaApi";
  import DatetimePicker from 'vuetify-datetime-picker'
  
  Vue.use(DatetimePicker)

  export default {
    name: "sendnotify-edit",
    // components:{
    // },
    data: () => ({
      loading: false,
      effectDatetime: "",
      sendNotifyId: 0,

      senders: [ "Marketing"],
      services: [
         { id: 0,  desc: "Notify"},
         { id: 1,  desc: "Promotion"}
      ],
      
      sendNotify: {
        sendNotifyId: 0,
        title: "",
        sender: "Marketing",
        service: 0,
        serviceDesc: "",
        body: "",
        refNo: "",
        remark: "",
        ownerIds: [],
        notifyAll: 0,
        effectDatetime: "",
        sendDatetime: "",
        status: 0,
        statusDesc: "",
        active: false,
        createdBy: "",
        updatedBy: "",
        createdDatetime: "",
        updatedDatetime: ""
      },
    }),
    async mounted() {
      try {
        this.loading = true;
        this.sendNotifyId = parseInt(this.$route.params.id ?? 0);
        if (this.sendNotifyId != 0) {
          let filter = { sendNotifyId: this.sendNotifyId }
          let result = await api.getSendNotifyById(filter);
          this.sendNotify = result.data;
          this.effectDatetime = this.sendNotify.effectDatetime;
          // console.log("--- load ---", JSON.stringify(result.data));
        }
      // } catch (error) {
      }
      finally {
        this.loading = false;
      } 
    },
  
    methods: {
      cancel() {
        this.$router.back();
      },
      removeItem: function (value, objectList) {
        let item = objectList.find(d => d === value);
        if (item) {
          let index = objectList.indexOf(item);
          if (index >= 0) {
            objectList.splice(index, 1)
          }
        }
      },
      onFileImportSelected(e) {
        try {
          // this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (!files.length)
            return;
          this.previewFiles(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile.value = "";
          // setTimeout(() => {
          //   this.loading = false;
          // }, 1000);
        }
      },
      previewFiles(file) { // detect file event
          var reader = new FileReader();
          var vm = this;
          reader.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, { type: "array" });
              let sheetName = workbook.SheetNames[0];
              /* DO SOMETHING WITH workbook HERE */
              // console.log(workbook);
              let worksheet = workbook.Sheets[sheetName];
              // console.log(XLSX.utils.sheet_to_json(worksheet));
              let items = XLSX.utils.sheet_to_json(worksheet);
              // console.log('-- items --', items);
              items.forEach(item => {
                var mItem = vm.sendNotify.ownerIds.find(d => d === item.ownerId);
                if (!mItem) {
                  vm.sendNotify.ownerIds.push(item.ownerId.toString() ?? "");
                }
              });
          };
          reader.readAsArrayBuffer(file);
      },
      async submit() {
        try {
          this.loading = true;
          let dataUpdate = {
            sendNotifyId: parseInt(this.sendNotify.sendNotifyId),
            title: this.sendNotify.title,
            sender: this.sendNotify.sender,
            service: this.sendNotify.service,
            body: this.sendNotify.body,
            refNo: this.sendNotify.refNo,
            remark: this.sendNotify.remark,
            ownerIds: this.sendNotify.ownerIds,
            notifyAll: this.sendNotify.notifyAll == true ? 1 : 0,
            effectDatetime: this.effectDatetime == null || this.effectDatetime == "" ? "" : moment(this.effectDatetime).format('YYYY-MM-DD HH:mm'),
            // sendDatetime: "",
            // status: 0,
            active: this.sendNotify.active == true ? 1 : 0,
            // createdBy: "",
            updatedBy: this.userCode,
            // createdDatetime: "",
            // updatedDatetime: ""
          };
          let result;
          // console.log('request - ', JSON.stringify(dataUpdate));
          if (this.sendNotifyId == 0) {
            dataUpdate.createdBy = dataUpdate.updatedBy;
            result = await api.createSendNotify(dataUpdate);
          }
          else {
            result = await api.updateSendNotify(dataUpdate);
          }
          // console.log('response - ', JSON.stringify(result));
          this.$router.back();
        } catch (error) {
          alert(error)
        }
        finally {
          this.loading = false;
        }
      },
    },
    computed: {
      userCode() {
        return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
      },
      // userGroup() {
      //   return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
      // },
      // userType() {
      //   return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
      // },
      // userBrcode() {
      //   return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
      // },
      // picker() {
      //   return (this.userType == 'Picker' || this.userType == 'User') ? this.userCode : "";
      // }
    }
  };
  </script>
  
  <style scoped>
    table {
      border-spacing: 0 0.5rem;
    }
    tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, .05);
    }
    .table_sub {
      height: 250px;
      overflow: auto;
    }
  </style>