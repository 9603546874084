<template>
    <div>
      <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="mDataArray"
          sort-by="ID"
          :items-per-page="15"
        >
          <!-- table top section -->
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Campaigns Content</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                clearable
                v-model="search"
                label="Search"
                single-line
                hide-details
                @keyup.enter="searchClick"
              ></v-text-field>
              <v-icon class="mr-2" @click="searchClick">
                search
              </v-icon>
              <v-spacer></v-spacer>
              <v-btn @click="newItem" color="primary" dark class="mb-2">
                <v-icon left>add</v-icon>
                <span>New Campaigns Content</span>
              </v-btn>
            </v-toolbar>
          </template>
  
          <!-- table tr section -->
          <template v-slot:item="{ item }">
            <tr>
              <td style="width: 140px;">
                <v-icon color="gray darken-2" class="mr-2" @click="editItem(item)">
                  edit
                </v-icon>
                <v-icon color="gray darken-2" class="mr-2" @click="deleteItem(item)">
                  delete
                </v-icon>
                <v-btn icon :href="'https://shop.villamarket.com/campaign/preview/' + item.ID" target="_blank">
                  <v-icon>search</v-icon>
                </v-btn>
              </td>
              <td>{{ item.ID }}</td>
              <td>{{ item.Name }}</td>
              <td>
                <v-checkbox readonly v-model="item.IsPreview"></v-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    </div>
  </template>
  
  <script>
  import campaignsService from "@/services/campaignsService";
  
  export default {
    name: "campaignscontent",
    data() {
      return {
        loading: false,
        search: "",
        mDataArray: [],
        headers: [
          {
            text: "Action",
            align: "left",
            sortable: false,
            value: "action",
          },
          { text: "ID", value: "ID" },
          { text: "Name", value: "Name" },
          { text: "Preview", value: "IsPreview" }
        ],
      };
    },
    // components: {
    // },
    async mounted() {
      this.loadData();
    },
    methods: {
      searchClick() {
        this.loadData();
      },
      editItem(item) {
        this.$router.push(`/campaignscontent/edit/${encodeURIComponent(item.ID)}`);
      },
      async deleteItem(item) {
        if (confirm('Confirm Delete?')) {
          try {
            this.loading = true;
            let result = await campaignsService.deleteItem(item.ID);
            if (result.Status == "success")
              await this.loadData();
          } catch (error) {
            alert(error);
          } finally {
            this.loading = false;
          }
        }
      },
      newItem() {
        this.$router.push("/campaignscontent/edit");
      },
      async loadData() {
        try {
          this.loading = true;
          let result;
          if (this.search == null || this.search == "") {
            result = await campaignsService.getItems();
            if (result.Status == "success")
              this.mDataArray = result.Data?.Items ?? [];
          }
          else {
            result = await campaignsService.getItem(this.search);
            if (result.Status == "success") {
              this.mDataArray = [];
              this.mDataArray.push(result.Data);
            }
          }
        } catch (error) {
          alert(error);
        } finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }
  </style>