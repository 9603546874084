import Vue from "vue";
import Vuex from "vuex";
import api from "@/services/lambdaApi";
import { server } from "@/services/constants";
import chat from "../store/modules/chat";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogged: false,
    username: "",
    userInfo: {},
    branchs: [],
  },
  getters: {
    isLogin(state) {
      return state.isLogged;
    },
    username(state) {
      return state.username;
    },
    userInfo(state) {
      return state.userInfo;
    },
    branchs(state) {
      return state.branchs;
    },
  },
  mutations: {
    SET_LOGGED_IN(state) {
      state.isLogged = true;
    },
    SET_LOGGED_OUT(state) {
      state.isLogged = false;
    },
    SET_USERNAME(state, value) {
      state.username = value;
    },
    SET_USERINFO(state, value) {
      state.userInfo = value;
    },
    SET_BRANCHS(state, value) {
      state.branchs = value;
    },
  },
  actions: {
    async doLogin({ commit, dispatch }, { username, password }) {
      if ((await api.login({ username, password }))) {
        let username2 = localStorage.getItem(server.USERNAME);
        await dispatch("setUserInfo", { username: username2 });
        commit("SET_LOGGED_IN");
        commit("SET_USERNAME", username);
      } else {
        await dispatch("doLogout", {});
      }
    },
    async doLogout({ commit }) {
      commit("SET_LOGGED_OUT");
      commit("SET_USERNAME", "");
      await api.logoff();
    },
    async restoreLogin({ commit, dispatch }) {
      if (await api.isLoggedIn()) {
        let username = localStorage.getItem(server.USERNAME);
        // console.log('-- restoreLogin username --', username);
        await dispatch("setUserInfo", { username: username });
        commit("SET_LOGGED_IN");
        commit("SET_USERNAME", username);
      }
    },
    async setUserInfo({ commit, dispatch }, { username }) {
      // console.log('-- username --', username);
      let data = { userCode: username };
      let result = await api.getUserById(data);
      let user = result.data;
      // console.log('-- userInfo --', user);
      if ((user["userCode"] ?? "" != "") && (user["active"] ?? 0 == 1)) {
        commit("SET_USERINFO", user);
        // await dispatch("loadBranchs", {});
      } else {
        await dispatch("doLogout", {});
      }
    },
    // async loadBranchs({ commit }) {
    //   let filter = { fileName: "online_stores.json" };
    //   let result = await api.getS3Json(filter);
    //   let branchs = result.data;
    //   console.log('-- branchs --', branchs);
    //   commit("SET_BRANCHS", branchs);
    // },
  },
  modules: {
    chat,
  },
});
