<template>
    <div>
        <!-- <div>Chat</div> -->
        <v-btn small color="pink" @click="onLogin" dark right text>
            Log in
        </v-btn>
        <div
            :class="isInit ? `chat` : `hide-chat`"
            id="container-div"
            style="width: 100vw; height: 600px"
        ></div>
    </div>
</template>

<script>
import "amazon-connect-streams";
export default {
    name: "Chat",
    data() {
        return {
            showLogin: false,
            timer: null,
            windowRef: null,
            isInit: false,
        };
    },
    methods: {
        onLogin() {
            this.windowRef = window.open(
                "https://villa-dev.my.connect.aws/ccp-v2/",
                "",
                "width=600,height=400,left=200,top=200"
            );

            var timer = setInterval(() => {
                if (this.windowRef.closed) {
                    clearInterval(timer);
                    var containerDiv = document.getElementById("container-div");
                    var instanceURL =
                        "https://villa-dev.my.connect.aws/ccp-v2/";
                    window.connect.core.initCCP(containerDiv, {
                        ccpUrl: instanceURL, // REQUIRED
                        loginPopup: false, // optional, defaults to `true`
                        loginPopupAutoClose: true, // optional, defaults to `false`
                        loginOptions: {
                            // optional, if provided opens login in new window
                            autoClose: true, // optional, defaults to `false`
                            height: 600, // optional, defaults to 578
                            width: 400, // optional, defaults to 433
                            top: 0, // optional, defaults to 0
                            left: 0, // optional, defaults to 0
                        },
                        region: "ap-southeast-1", // REQUIRED for `CHAT`, optional otherwise
                        softphone: {
                            // optional, defaults below apply if not provided
                            allowFramedSoftphone: true, // optional, defaults to false
                            disableRingtone: false, // optional, defaults to false
                            ringtoneUrl: "./ringtone.mp3", // optional, defaults to CCP’s default ringtone if a falsy value is set
                        },
                        pageOptions: {
                            //optional
                            enableAudioDeviceSettings: false, //optional, defaults to 'false'
                            enablePhoneTypeSettings: false, //optional, defaults to 'true'
                        },
                        ccpAckTimeout: 25000, //optional, defaults to 3000 (ms)
                        ccpSynTimeout: 30000, //optional, defaults to 1000 (ms)
                        ccpLoadTimeout: 10000, //optional, defaults to 5000 (ms)
                    });
                }

                window.connect.core.onInitialized(() => {
                    console.log("onInitialized");
                    this.isInit = true;
                });

                window.connect.core.onAuthFail(function() {
                    console.log("onAuthFail");
                });

                window.connect.core.onAccessDenied(function() {
                    console.log("onAccessDenied");
                });

                window.connect.agent(function(agent) {
                    console.log(agent);
                    var state = agent.getAgentStates();
                    agent.setState(state[0]);

                    const AGENT_QUEUE_PATTERN = new RegExp(
                        "arn:aws:connect:([\\w|\\-]+):(\\w+):instance/([\\w|\\-]+)/queue/agent/([\\w|\\-]+)"
                    );

                    const config = agent.getConfiguration();
                    const agentQueue = agent
                        .getConfiguration()
                        .routingProfile.queues.filter(
                            (queue) => queue.name === null
                        );

                    // group[0] all
                    // group[1] region
                    // group[2] AWS AccountID
                    // group[3] Amazon Connect InstanceID
                    // group[4] AgentID
                    const groups = AGENT_QUEUE_PATTERN.exec(
                        agentQueue[0].queueARN
                    );
                    const agentArn = `arn:aws:connect:${groups[1]}:${groups[2]}:instance/${groups[3]}/agent/${groups[4]}`;

                    console.log(agentArn);
                    const agentId = groups[4];
                    console.log("agentId", agentId);

                    window.agentId = agentId;
                });

                const eventBus = window.connect.core.getEventBus();
                eventBus.subscribe(window.connect.EventType.TERMINATED, () => {
                    console.log("Logged out");
                    // Do stuff...
                    this.isInit = false;
                });
            }, 1000);
        },
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
};
</script>
