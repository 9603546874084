<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-0 pa-5" outlined>
          <v-form @submit.prevent="submit">
            <template>
              <v-row class="ma-0">
                <v-spacer></v-spacer>
                <h1>Reports</h1>
                <v-spacer></v-spacer>
              </v-row>
            </template>

            <template>
              <v-divider></v-divider>
              <v-row class="ma-0" justify="space-around">
                <v-col lg="3" md="6" sm="12" cols="12">
                  <v-btn @click="menuClick('pickingreport')" color="primary" dark class="mb-2" width="200">
                    <v-icon left>ballot</v-icon>
                    <span>Picking Report</span>
                  </v-btn>
                </v-col>
                <v-col lg="3" md="6" sm="12" cols="12">
                  <v-btn @click="menuClick('saleorderreport')" color="primary" dark class="mb-2" width="200">
                    <v-icon left>ballot</v-icon>
                    <span>Sale Report</span>
                  </v-btn>
                </v-col>
                <!-- <v-col lg="3" md="6" sm="12" cols="12">
                  <v-btn @click="menuClick('oosreport')" color="primary" dark class="mb-2" width="200">
                    <v-icon left>ballot</v-icon>
                    <span>OOS Report</span>
                  </v-btn>
                </v-col> -->
              </v-row>
              <v-row class="ma-0" justify="space-around">
                <v-col lg="3" md="6" sm="12" cols="12">
                  <v-btn @click="menuClick('nextdayorder')" color="primary" dark class="mb-2" width="200">
                    <v-icon left>ballot</v-icon>
                    <span>Next Day Order</span>
                  </v-btn>
                </v-col>
                <v-col lg="3" md="6" sm="12" cols="12">
                  <!-- <v-btn @click="menuClick('saleorderreport')" color="primary" dark class="mb-2" width="200">
                    <v-icon left>ballot</v-icon>
                    <span>Sale Report</span>
                  </v-btn> -->
                </v-col>
              </v-row>
            </template>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { productAttributeBucketName } from "@/services/constants";

export default {
  name: "reports",
  // components:{
  // },
  data: () => ({
    loading: false
  }),
  async mounted() {
  },
  methods: {
    menuClick(pageName) {
      this.$router.push(`/reports/${pageName}`);
    }
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>