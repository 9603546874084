<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-alert
      v-model="show_success"
      dismissible
      outlined
      text
      type="success"
    >Update ข้อมูลเว็บ เรียบร้อยแล้ว</v-alert>
    <v-alert
      v-model="show_error"
      dismissible
      outlined
      text
      type="error"
    >Update ข้อมูลเว็บ ไม่สำเร็จ!!</v-alert>
    <!-- Table section -->
    <v-card>
      <v-data-table :headers="headers" :items="mDataArray" :items-per-page="5">
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Product</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              @keyup.enter="searchClick"
            ></v-text-field>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>

            <v-btn @click="refreshProduct" color="green darken-2" dark class="mb-2">
              <v-icon left>refresh</v-icon>
              <span>Update Web</span>
            </v-btn>

            <!-- <v-btn
              @click="$router.push('/product/new')"
              color="primary"
              dark
              class="mb-2"
            >
              <v-icon left>add</v-icon>
              <span>New Product</span>
            </v-btn> -->
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-img
                contain
                class="ma-2"
                :eager="true"
                :src="item.image"
                lazy-src="@/assets/noimage.png"
                aspect-ratio="1"
                min-width="80"
                max-width="80"
                max-height="80"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular  v-show="loading"
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </td>
            <td>{{ item.cprcode }}</td>
            <td>{{ item.iprcode }}</td>
            <td>{{ item.hema_name_en }}</td>
            <!-- <td>{{ item.hema_brand_en }}</td> -->
            <!-- <td>{{ item.online_category_l1_en }}</td>
            <td>{{ item.online_category_l2_en }}</td> -->
            <!-- <td>{{ item.online_category_l3_en }}</td> -->
            <!-- <td>{{ item.pr_suref3 }}</td> -->
            <td>
              <v-checkbox
                readonly
                v-model="item.master_online"
              ></v-checkbox>
            </td>
            <!-- <td>
              <v-checkbox
                readonly
                v-model="item.enabled"
              ></v-checkbox>
            </td> -->
            <td>
              <v-icon class="mr-2" @click="editItem(item)">
                edit
              </v-icon>
              <!-- <span class="ma-1"></span> -->
              <!-- <v-icon @click="deleteItem(item)">
                delete
              </v-icon> -->
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog v-model="confirmDeleteDlg" max-width="290">
        <v-card>
          <v-card-title primary-title>
            Confirm Delete
          </v-card-title>

          <v-card-text class="body">
            Are you sure to delete this product? You cannot restore it after
            clicking confirm.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDlg = false">
              Cancel
            </v-btn>

            <v-btn color="error" text @click="confirmDelete">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
// import StockCard from "@/components/cards/StockCard";
import api from "@/services/lambdaApi";

export default {
  name: "Product",
  data() {
    return {
      loading: false,
      search: "",
      selectedProductId: "",
      show_success: false,
      show_error: false,
      confirmDeleteDlg: false,
      mDataArray: [],
      headers: [
        {
          text: "Image",
          align: "left",
          sortable: false,
          value: "image"
        },
        { text: "(C) Pr Code", value: "cprcode" },
        { text: "(I) Pr Code", value: "iprcode" },
        { text: "Pr Name", value: "hema_name_en" },
        // { text: "Brand", value: "hema_brand_en" },
        // { text: "Category Lv1", value: "online_category_l1_en" },
        // { text: "Category Lv2", value: "online_category_l2_en" },
        // { text: "Category Lv3", value: "online_category_l3_en" },
        // {
        //   text: "pr_suref3",
        //   align: "left",
        //   sortable: false,
        //   value: "pr_suref3"
        // },
        {
          text: "Online",
          align: "left",
          // sortable: false,
          value: "master_online"
        },
        // {
        //   text: "Enabled",
        //   align: "left",
        //   // sortable: false,
        //   value: "enabled"
        // },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action"
        },
      ],
    };
  },
  // components: {
  //   //  StockCard
  // },
  async mounted() {
    //this.loadProducts();
  },
  methods: {
    async refreshProduct() {
      try {
        this.loading = true;
        let response = await api.refreshProduct({});
        console.log("--- refreshProduct ---", response.data);
        if (response.data.statusCode == 200) {
          this.show_error = false;
          this.show_success = true;
        } else {
          this.show_error = true;
          this.show_success = false;
        }
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    searchClick() {
      this.loadProducts();
    },
    editItem(item) {
      this.$router.push(`/product/edit/${item.cprcode}`);
    },
    deleteItem(item) {
      this.selectedProductId = item.cprcode;
      this.confirmDeleteDlg = true;
    },
    async confirmDelete() {
      await api.deleteProduct(this.selectedProductId);
      this.confirmDeleteDlg = false;
      this.loadProducts();
    },
    async loadProducts() {
      try {
        this.loading = true;
        let data = { searchText: this.search };
        let result = await api.getProducts(data);
        // console.log("--- result ---", JSON.stringify(result.data));
        this.mDataArray = result.data;  
      // } catch (error) {
      //   alert(error);
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }
</style>