<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray2"
        sort-by="ProductID"
        :items-per-page="15"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Product Rating</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="date1"
              transition="scale-transition"
              offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  clearable
                  v-model="date1"
                  label="Date (Start)"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="$refs.menu1.save(date1)"
                v-model="date1"
                no-title
                scrollable>
              </v-date-picker>
            </v-menu>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="date2"
              transition="scale-transition"
              offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  clearable
                  v-model="date2"
                  label="Date (End)"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="$refs.menu2.save(date2)"
                v-model="date2"
                no-title
                scrollable>
              </v-date-picker>
            </v-menu>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              clearable
              v-model="search"
              label="ProductID"
              single-line
              hide-details
              @keyup.enter="searchClick"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <!-- <v-btn @click="newItem" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>New Product Rating</span>
            </v-btn> -->
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td style="width: 140px;">
              <!-- <v-icon color="gray darken-2" class="mr-2" @click="editItem(item)">
                edit
              </v-icon> -->
              <!-- <v-icon color="gray darken-2" class="mr-2" @click="deleteItem(item)">
                delete
              </v-icon> -->
              <v-icon class="mr-2" color="green darken-2" v-if="item.Approved == true">
                done
              </v-icon>
              <v-btn @click="changeApproved(item)" color="primary" v-else>
                <v-icon left>done</v-icon>
                <span>Approve</span>
              </v-btn>
            </td>
            <td><v-btn text color="primary" @click="showRating(item)">{{ item.ProductID }}</v-btn></td>
            <td>{{ item.Rating }}</td>
            <td>{{ item.CreatedDate }}</td>
            <td>{{ item.Firstname }}</td>
            <td>{{ item.Lastname }}</td>
            <td>{{ item.Title }}</td>
            <td>{{ item.Detail }}</td>
            <td>{{ getLocalTime(item.PurchaseDate) }}</td>
            <!-- <td>
              <v-checkbox readonly v-model="item.Approved"></v-checkbox>
            </td> -->
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="isShowRating"
          max-width="700px">
          <v-card>
            <v-card-title>Rating ProductID : {{ skuRating.ProductID }}</v-card-title>
            <v-divider></v-divider>
            <v-row class="ma-2">
              <v-col cols="12">
                <PieChart style="height: 400px;" :chartData="chartData" />
                <div style="text-align: center;">Total Review : {{ skuRating.TotalReview }}  Avg : {{ skuRating.Avg }}</div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="isShowRating = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </v-container>
  </div>
</template>

<script>
import PieChart from '@/components/charts/PieChart'
import productRatingService from "@/services/productRatingService";
import moment from 'moment'

export default {
  name: "productrating",
  components: {
    PieChart
  },
  data() {
    return {
      loading: false,
      search: "",

      date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2: false,

      isShowRating: false,
      skuRating: {},
      mDataArray: [],
      chartData: null,
      headers: [
        // {
        //   text: "Action",
        //   align: "left",
        //   sortable: false,
        //   value: "action",
        // },
        { text: "Approve", value: "Approved" },
        { text: "ProductID", value: "ProductID" },
        { text: "Rating", value: "Rating" },
        { text: "ReviewDate", value: "CreatedDate" },
        { text: "Firstname", value: "Firstname" },
        { text: "Lastname", value: "Lastname" },
        { text: "Title", value: "Title" },
        { text: "Detail", value: "Detail" },
        { text: "PurchaseDate", value: "PurchaseDate" },
        // { text: "Approved", value: "Approved" }
      ],
    };
  },
  // components: {
  // },
  async mounted() {
    this.loadData();
  },
  methods: {
    searchClick() {
      this.loadData();
    },
    getLocalTime(d)
    {
      return moment(new Date(d)).format('YYYY-MM-DD');
    },
    async showRating(item)
    {
      try {
        // this.loading = true;
        let result = await productRatingService.getRating(item.ProductID);
        if (result.Status == "success") {
          this.skuRating = result.Data;
          this.skuRating["ProductID"] = item.ProductID;

          this.chartData = {
            labels: Object.keys(this.skuRating.Rating).map((key) => key),
            datasets: [
              {
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#0a448f'],
                data: Object.keys(this.skuRating.Rating).map((key) => this.skuRating.Rating[key])
              }
            ]
          };
          
          this.isShowRating = true;
        }
      } catch (error) {
        alert(error);
      } finally {
        // this.loading = false;
      }
    },
    async changeApproved(item)
    {
      if (confirm(`Confirm Approve ProductID ${item.ProductID}?`))
      {
        try {
          this.loading = true;
          let result = await productRatingService.changeApproved(item);
          if (result.Status == "success")
            await this.loadData();
        } catch (error) {
          alert(error);
        } finally {
          this.loading = false;
        }
      }
    },
    // editItem(item) {
    //   this.$router.push(`/productrating/edit/${encodeURIComponent(item.ProductID)}`);
    // },
    // async deleteItem(item) {
    //   if (confirm('Confirm Delete?')) {
    //     try {
    //       this.loading = true;
    //       let result = await productRatingService.deleteItem(item.ProductID);
    //       if (result.Status == "success")
    //         await this.loadData();
    //     } catch (error) {
    //       alert(error);
    //     } finally {
    //       this.loading = false;
    //     }
    //   }
    // },
    // newItem() {
    //   this.$router.push("/productrating/edit");
    // },
    async loadData() {
      try {
        this.loading = true;
        let items = [];
        let sdate = this.date1 == null || this.date1 == "" ? "" : moment(this.date1).format('YYYY-MM-DD');
        let edate = this.date2 == null || this.date2 == "" ? "" : moment(this.date2).format('YYYY-MM-DD');
        if (sdate && sdate != "")
        {
          if (edate < sdate) edate = sdate;
          while (sdate <= edate) {
            let nextToken = "";
            let last_items = null;
            let result;
            while (true) {
              result = await productRatingService.getItemsByDate(sdate, nextToken);
              // alert(JSON.stringify(result.Data?.Items));
              if (result.Status == "success") {
                if (last_items == result.Data?.Items) {
                  // alert(JSON.stringify(last_items))
                  break;
                }
                last_items = result.Data?.Items;
                if (last_items != null && last_items.length>0) {
                  // alert(JSON.stringify(last_items))
                  nextToken = result.Data?.NextToken;
                  items = items.concat(last_items);
                  if (nextToken == null || nextToken == "") break;
                }
              }
            }
            sdate = moment(sdate).add(1, 'days').format('YYYY-MM-DD');
          }
        }
        else if (this.search && this.search != "")
        {
          while (true) {
            result = await productRatingService.getItems(this.search, nextToken);
            // alert(JSON.stringify(result.Data?.Items));
            if (result.Status == "success") {
              if (last_items == result.Data?.Items) {
                // alert(JSON.stringify(last_items))
                break;
              }
              last_items = result.Data?.Items;
              if (last_items != null && last_items.length>0) {
                // alert(JSON.stringify(last_items))
                nextToken = result.Data?.NextToken;
                items = items.concat(last_items);
                if (nextToken == null || nextToken == "") break;
              }
            }
          }
        }
        if (items != null && items.length>0)
          this.mDataArray = items;
        else
          this.mDataArray = [];
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    // async loadData() {
    //   try {
    //     this.loading = true;
    //     let nextToken = "";
    //     let items = [];
    //     let last_items = null;
    //     let result;
    //     let sdate = this.date1 == null || this.date1 == "" ? "" : moment(this.date1).format('YYYY-MM-DD');
    //     if (sdate && sdate != "")
    //     {
    //       while (true) {
    //         result = await productRatingService.getItemsByDate(sdate, nextToken);
    //         // alert(JSON.stringify(result.Data?.Items));
    //         if (result.Status == "success") {
    //           if (last_items == result.Data?.Items) {
    //             // alert(JSON.stringify(last_items))
    //             break;
    //           }
    //           last_items = result.Data?.Items;
    //           if (last_items != null && last_items.length>0) {
    //             // alert(JSON.stringify(last_items))
    //             nextToken = result.Data?.NextToken;
    //             items = items.concat(last_items);
    //             if (nextToken == null || nextToken == "") break;
    //           }
    //         }
    //       }
    //     }
    //     else if (this.search && this.search != "")
    //     {
    //       while (true) {
    //         result = await productRatingService.getItems(this.search, nextToken);
    //         // alert(JSON.stringify(result.Data?.Items));
    //         if (result.Status == "success") {
    //           if (last_items == result.Data?.Items) {
    //             // alert(JSON.stringify(last_items))
    //             break;
    //           }
    //           last_items = result.Data?.Items;
    //           if (last_items != null && last_items.length>0) {
    //             // alert(JSON.stringify(last_items))
    //             nextToken = result.Data?.NextToken;
    //             items = items.concat(last_items);
    //             if (nextToken == null || nextToken == "") break;
    //           }
    //         }
    //       }
    //     }
    //     if (items != null && items.length>0)
    //       this.mDataArray = items;
    //     else
    //       this.mDataArray = [];
    //   } catch (error) {
    //     alert(error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },
  },
  computed: {
    mDataArray2() {
      if (this.mDataArray == null)
        return []
      else if (this.search && this.search != "")
        return this.mDataArray.filter(o => o.ProductID == this.search)
      else
        return this.mDataArray
    },
  }
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>