/* eslint-disable no-unused-vars */
import axios from "axios";
import { ENDPOINT, CUSTOMER_ENDPOINT, TAG_ENDPOINT, COMPLETE_MESSAGE_ENDPOINT } from "@/api/chat";

const API_KEY = "XCVJHGRIKMHGFDRVHUYTNTFDE";

const uninterceptedAxiosInstance = axios.create();

// const API_ENDPOINT =
//   "https://043mxmao5j.execute-api.ap-southeast-1.amazonaws.com/dev";

// const API_ENDPOINT =
//   "https://ccia7fz3sl.execute-api.ap-southeast-1.amazonaws.com/production";

function getToken() {
  // const session = await Auth.currentSession();
  // if (session) {
  //   return session.idToken.jwtToken;
  // }
  // return null;
  return API_KEY;
}

// async function decodeUserInfo() {
//   const token = await getToken();
//   const decoded = jwt_decode(token);
//   return decoded;
// }

function mapAgents(agents) {
  // Map with agent ID
  const agentsMap = new Map();
  agents.forEach((agent) => {
    agentsMap.set(agent.AgentID, agent);
  });
  return agentsMap;
}

const initState = {
  // rooms: {
  //   all: [],
  //   assigned: [],
  // },
  room: null,
  agents: [],
  agentsMap: null,
  channel: "main",
  selectedRoomID: null,
  user: null,
  decoded: null,
  loaded: false,
  role: "Service",
  tags: [],
  roomSocket: null,
  consoleSocket: null,
  roomLogs: [],
};

const getters = {
  rooms(state) {
    return state.rooms;
  },
  room(state) {
    return state.room;
  },
  mainParticipants(state) {
    return state.room?.MainParticipants || [];
  },
  backgroundParticipants(state) {
    return state.room?.BackgroundParticipants || [];
  },
  agents(state) {
    return state.agents;
  },
  agentsMap(state) {
    return state.agentsMap;
  },
  channel(state) {
    return state.channel;
  },
  selectedRoomID(state) {
    return state.selectedRoomID;
  },
  user(state) {
    return state.user;
  },
  decoded(state) {
    return state.decoded;
  },
  tags(state) {
    return state.tags;
  },
  roomSocket(state) {
    return state.roomSocket;
  },
  consoleSocket(state) {
    return state.consoleSocket;
  },
  roomLogs(state) {
    return state.roomLogs;
  },
};

const mutations = {
  setAllRooms(state, value) {
    state.rooms["all"] = value;
  },
  setAssignedRooms(state, value) {
    state.rooms["assigned"] = value;
  },
  setRoom(state, value) {
    state.room = value;
  },
  setAgents(state, value) {
    state.agents = value;
  },
  setChannel(state, value) {
    state.channel = value;
  },
  setSelectedRoomID(state, value) {
    state.selectedRoomID = value;
  },
  setUser(state, value) {
    state.user = value;
  },
  setDecoded(state, value) {
    state.decoded = value;
  },
  setLoaded(state, value) {
    state.loaded = value;
  },
  setAgentsMap(state, value) {
    state.agentsMap = value;
  },
  setTags(state, value) {
    state.tags = value;
  },
  setRoomSocket(state, value) {
    state.roomSocket = value;
  },
  setConsoleSocket(state, value) {
    state.consoleSocket = value;
  },
  setRoomLogs(state, value) {
    state.roomLogs = value;
  },
};

const actions = {
  async getJwt() {
    return await getToken();
  },

  // getDecodedUser() {
  //   return decodeUserInfo();
  // },

  // async listRooms(context, { nextToken }) {
  //   let list = [];
  //   const response = await axios.get(ENDPOINT + "/rooms", {
  //     params: {
  //       nextToken: nextToken,
  //     },
  //     headers: {
  //       Authorization: await getToken(),
  //     },
  //   });
  //   const { Items, NextToken } = response.data;
  //   list.push(...Items);

  //   const listMap = await Promise.all(
  //     list.map(async (l) => {
  //       const roomId = l.RoomID;
  //       const room = context.dispatch("getRoom", roomId);
  //       return room;
  //     })
  //   );

  //   return { list: listMap, nextToken: NextToken };
  // },

  // async fetchRooms(context, token, data = []) {
  //   context.commit("setLoaded", true);
  //   const session = await Auth.currentSession();

  //   do {
  //     const response = await axios.get(ENDPOINT + "/rooms", {
  //       headers: {
  //         Authorization: session.idToken.jwtToken,
  //       },
  //       params: {
  //         nextToken: token,
  //       },
  //     });

  //     const { Items } = response.data;
  //     token = response.data.NextToken;
  //     if (Items) {
  //       data = [...data, ...Items];
  //     }
  //   } while (token !== null);
  //   // console.log("fetchRooms", data);

  //   // let me = context;
  //   // const clone = await Promise.all(
  //   //   data.map(async (d) => {
  //   //     // console.log(d);
  //   //     const response = await context.dispatch("getMessageByRoomID", {
  //   //       roomID: d.RoomID,
  //   //       channel: "main",
  //   //       lastest: true,
  //   //     });
  //   //     // console.log(response.messages);
  //   //     d["messages"] = response.messages;
  //   //     // d["messageNextToken"] = response.nextToken;
  //   //     return d;
  //   //   })
  //   // );

  //   // context.commit("setAllRooms", clone);
  //   context.commit("setAllRooms", data);
  //   context.commit("setLoaded", false);
  // },

  async getRoom(context, payload) {
    try {
      const response = await uninterceptedAxiosInstance.get(
        ENDPOINT + "/production/room/" + payload,
        {
          headers: {
            Authorization: await getToken(),
          },
        }
      );
      console.log("getRoom", response);
      if (response.status !== 200) {
        console.error("getRoom error");
        return null;
      }
      const { data } = response;

      const clone = data;

      return clone;
    } catch (error) {
      console.log(error);
      return null;
    }

    // const responseMsg = await context.dispatch("getMessageByRoomID", {
    //   roomID: payload,
    //   channel: "main",
    //   lastest: true,
    // });
    // console.log(response);
    // clone["messages"] = responseMsg.messages;
    // clone["messageNextToken"] = response.nextToken;
    // context.commit("setRoom", clone);
  },

  async listAgent(context) {
    // const ENDPOINT =
    //   "https://qh0mnf23g6.execute-api.ap-southeast-1.amazonaws.com/dev";

    const ENDPOINT =
      "https://2t6wyql24f.execute-api.ap-southeast-1.amazonaws.com/production";
    const response = await uninterceptedAxiosInstance.get(
      ENDPOINT + "/agents",
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );

    console.log("listAgent", response);
    if (response.status !== 200) {
      console.error("listAgent error");
      return;
    }

    const { data } = response;
    // console.log(context, data);
    // const myID = context.state.user?.attributes.sub;
    // const filteredData = data?.filter((agent) => agent.AgentID !== myID);
    // context.commit("setAgents", filteredData);
    if (data) {
      context.commit("setAgents", data);
      const agentsMap = mapAgents(data);
      context.commit("setAgentsMap", agentsMap);
    }
  },

  async listMessages(context, payload) {
    const response = await uninterceptedAxiosInstance.get(
      ENDPOINT + "/" + payload + "/messages",
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );

    console.log(response);
    if (response.status !== 200) {
      console.error("listMessage error");
    }

    const { data } = response;
    console.log(data);
  },

  async createRoom(context, payload) {
    const body = {
      RoomID: payload.id,
      CreatorID: payload.id,
      CreatorName: payload.name,
      CustomerID: payload.id,
      CustomerName: payload.name,
      PhoneNumber: payload.phone,
      Email: payload.email,
      AllowGuest: true,
    };

    const createResponse = await uninterceptedAxiosInstance.post(
      ENDPOINT + "/production/room",
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
    console.log(createResponse);
    if (createResponse.status === 200) {
      const assignBody = {
        AgentID: payload.agentID,
        AgentName: payload.agentName,
        Channel: "main",
        AllowGuest: true,
      };

      await uninterceptedAxiosInstance.post(
        ENDPOINT + "/production/room/" + payload.id + "/assign-agent",
        assignBody,
        {
          headers: {
            Authorization: await getToken(),
          },
        }
      );
      assignBody.Channel = "background";
      await uninterceptedAxiosInstance.post(
        ENDPOINT + "/production/room/" + payload.id + "/assign-agent",
        assignBody,
        {
          headers: {
            Authorization: await getToken(),
          },
        }
      );
    }
  },

  async closeRoom(context, roomID) {
    const response = await axios.post(
      ENDPOINT + "/production/room/" + roomID + "/close",
      null,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );

    console.log("closeRoom", response);
  },
  async reOpenRoom(context, roomID) {
    const response = await axios.post(
      ENDPOINT + "/production/room/" + roomID + "/reopen",
      undefined,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );

    console.log("closeRoom", response);
  },

  async assignAgent(context, payload) {
    const body = {
      AgentID: payload.agentID,
      AgentName: payload.agentName,
      Channel: context.state.channel,
    };

    const response = await uninterceptedAxiosInstance.post(
      ENDPOINT + "/production/room/" + context.state.selectedRoomID + "/assign-agent",
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );

    console.log(response);
  },

  async unassignAgent(context, payload) {
    const body = {
      AgentID: payload.AgentID,
      AgentName: payload.Name,
      Channel: context.state.channel,
    };

    const response = await uninterceptedAxiosInstance.post(
      ENDPOINT + "/production/room/" + context.state.selectedRoomID + "/unassign-agent",
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );

    console.log(response);
  },

  async assignCustomer(context, payload) {
    const body = {
      CustomerID: payload.customerID,
      CustomerName: payload.customerName,
    };

    const response = await uninterceptedAxiosInstance.post(
      ENDPOINT + "/production/room/" + payload.roomID + "/assign-customer",
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );

    console.log(response);
  },

  async getMessageByRoomID(context, payload) {
    let nextToken = null;
    let messages = [];
    if (payload.nextToken) {
      nextToken = payload.nextToken;
    }
    do {
      const response = await uninterceptedAxiosInstance.get(
        ENDPOINT + `/production/room/${payload.roomID}/${payload.channel}/messages`,
        {
          params: {
            nextToken: nextToken,
          },
          headers: {
            Authorization: await getToken(),
          },
        }
      );
      if (response.status !== 200) {
        console.error("getMessageByRoomID error");
      }

      nextToken = response.data.NextToken;
      // console.log(nextToken)
      const { Items } = response.data;
      if (Items) {
        messages = [...messages, ...Items];
      }
      if (payload.lastest) {
        break;
      }
      if (messages.length > 20) break;
    } while (nextToken);
    return { messages: messages, nextToken: nextToken };
  },

  async getNotesByRoomID(context, payload) {
    let nextToken = null;
    let notes = [];
    do {
      const response = await uninterceptedAxiosInstance.get(
        ENDPOINT + `/production/room/${payload.roomID}/notes`,
        {
          params: {
            nextToken: nextToken,
          },
          headers: {
            Authorization: await getToken(),
          },
        }
      );
      if (response.status !== 200) {
        console.error("getMessageByRoomID error");
      }

      nextToken = response.data.NextToken;
      // console.log(nextToken)
      const { Items } = response.data;
      if (Items) {
        notes = [...notes, ...Items];
      }
    } while (nextToken);
    return { notes: notes, nextToken: nextToken };
  },

  async addNoteToRoomID(context, payload) {
    const body = {
      CreatorID: payload.creatorID,
      CreatorName: payload.creatorName,
      Text: payload.text,
    };

    console.log("addNoteToRoomID", payload, body);

    const response = await uninterceptedAxiosInstance.post(
      ENDPOINT + "/production/room/" + payload.roomID + "/add-note",
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );

    console.log(response);
    if (response.status !== 200) {
      console.error("addNote error");
    }
  },

  async removeNote(context, payload) {
    const body = {
      NoteID: payload.ID,
    };
    await uninterceptedAxiosInstance.post(
      ENDPOINT + `/production/room/${context.state.selectedRoomID}/remove-note`,
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
  },

  // async getRoomsByAgentID(context, payload) {
  //   // const response = await axios.get(API_ENDPOINT + `rooms/agent/${payload}`);
  //   let nextToken = null;
  //   let rooms = [];
  //   if (payload && payload.nextToken !== undefined) {
  //     nextToken = payload.nextToken;
  //   }
  //   const agentID = context.state.decoded["cognito:username"];
  //   do {
  //     const response = await axios.get(ENDPOINT + `/rooms/agent/${agentID}`, {
  //       params: {
  //         nextToken: nextToken,
  //       },
  //       headers: {
  //         Authorization: await getToken(),
  //       },
  //     });
  //     if (response.status !== 200) {
  //       console.error("getRoomsByAgentID error");
  //     }

  //     nextToken = response.data.NextToken;
  //     // console.log(nextToken)
  //     const { Items } = response.data;
  //     if (Items) {
  //       rooms = [...rooms, ...Items];
  //     }
  //     if (payload.lastest) {
  //       break;
  //     }
  //     // if (rooms.length > 20) break;
  //   } while (nextToken);
  //   // return { rooms: rooms, nextToken: nextToken };
  //   console.log("agent rooms", rooms);
  //   const idList = rooms.map((room) => room.RoomID);

  //   const clone = context.state.rooms.all.map((room) => {
  //     const idx = idList.indexOf(room.RoomID);
  //     if (idx >= 0) {
  //       room["isMe"] = true;
  //     } else {
  //       room["isMe"] = false;
  //     }
  //     return room;
  //   });
  //   // const clone = rooms.map((r) => {
  //   //   const found = context.state.rooms.all.find((e) => e.RoomID === r.RoomID);
  //   //   return found;
  //   // });
  //   // context.commit("setAssignedRooms", clone);
  //   context.commit("setAllRooms", clone);
  // },

  async typing(context) {
    const userInfo = context.rootState.userInfo;
    const body = {
      CreatorID: userInfo.userCode,
      CreatorName: userInfo.userFirstName,
    };
    const response = await uninterceptedAxiosInstance.post(
      ENDPOINT + `/production/room/${context.state.selectedRoomID}/typing`,
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
    console.log(response.data);
  },

  async getImageURL(context, payload) {
    const response = await uninterceptedAxiosInstance.get(
      // `https://043mxmao5j.execute-api.ap-southeast-1.amazonaws.com/dev/chat-image?filename=${payload}`
      ENDPOINT + `/production/chat-image?filename=${payload}`,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
    console.log(response);
    return response.data.URL;
  },

  async addMessage(context, payload) {
    console.log("addMessage", payload);
    let body;

    body = {
      // SenderID: context.state.user.attributes.sub,
      SenderID: payload.senderID,
      Channel: context.state.channel,
      Text: payload.text,
      AgentID: payload.senderID,
      // AgentID: context.state.user.attributes.sub,
    };

    const response = await uninterceptedAxiosInstance.post(
      ENDPOINT + "/production/room/" + payload.roomID + "/add-message",
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );

    console.log(response);
    if (response.status === 200) {
      return response.data.Message;
    }
    return null;
  },

  async addImageMessage(context, payload) {
    const userInfo = context.rootState.userInfo;

    console.log("addImageMessage", payload);
    try {
      const body = new FormData();
      body.append("Channel", context.state.channel);
      body.append("SenderID", userInfo.userCode);
      body.append("AgentID", userInfo.userCode);
      body.append("file", payload.file);

      console.log(body);

      const response = await uninterceptedAxiosInstance.post(
        ENDPOINT + `/production/room/${payload.roomID}/add-image-message`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: await getToken(),
          },
        }
      );
      console.log("addImageMessage response", response);
      return response.Message;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  async searchRooms(context, payload) {
    const result = await uninterceptedAxiosInstance.get(
      ENDPOINT + `/production/rooms/search?search=${payload.keyword}`,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
    // console.log("search", result.data);
    if (result.status === 200) {
      return result.data.Items || [];
    }
    return [];
  },

  async seenMessage(context, payload) {
    const userInfo = context.rootState.userInfo || null;
    if (!userInfo) return;
    const body = {
      MessageID: payload.Attributes,
      // CreatorID: context.state.user.attributes.sub,
      // CreatorName: context.state.user.username,
      // CreatorID: context.state.decoded["cognito:username"],
      // CreatorName: context.state.decoded["cognito:username"],
      CreatorID: userInfo.userCode,
      CreatorName: userInfo.userFirstName,
    };
    const result = await uninterceptedAxiosInstance.post(
      ENDPOINT + `/production/room/${payload.RoomID}/seen-message`,
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
    console.log(result);
  },

  async addTag(context, payload) {
    let body;
    let response;
    switch (payload.Type) {
      case "issue":
        body = {
          CreatorID: context.state.user.attributes.sub,
          CreatorName: context.state.user.username,
          Title: payload.Title,
        };
        response = await uninterceptedAxiosInstance.post(
          ENDPOINT + `/production/room/${context.state.selectedRoomID}/add-issue-tag`,
          body,
          {
            headers: {
              Authorization: await getToken(),
            },
          }
        );
        break;
      case "customer":
        body = {
          CreatorID: context.state.user.attributes.sub,
          CreatorName: context.state.user.username,
          Title: payload.Title,
        };
        response = await uninterceptedAxiosInstance.post(
          ENDPOINT + `/production/room/${context.state.selectedRoomID}/add-customer-tag`,
          body,
          {
            headers: {
              Authorization: await getToken(),
            },
          }
        );
        break;
      case "order":
        body = {
          CreatorID: context.state.user.attributes.sub,
          CreatorName: context.state.user.username,
          OrderID: payload.OrderID,
        };
        response = await uninterceptedAxiosInstance.post(
          ENDPOINT + `/production/room/${context.state.selectedRoomID}/add-order-tag`,
          body,
          {
            headers: {
              Authorization: await getToken(),
            },
          }
        );
        break;
      default:
        break;
    }
    if (response.status === 200) {
      console.log("Tag added.");
    }
  },

  async removeTag(context, payload) {
    let body;
    let response;
    switch (payload.Type) {
      case "issue":
        body = {
          Title: payload.Title,
        };
        response = await uninterceptedAxiosInstance.post(
          ENDPOINT + `/production/room/${context.state.selectedRoomID}/remove-issue-tag`,
          body,
          {
            headers: {
              Authorization: await getToken(),
            },
          }
        );
        break;
      case "customer":
        body = {
          Title: payload.Title,
        };
        response = await uninterceptedAxiosInstance.post(
          ENDPOINT +
          `/production/room/${context.state.selectedRoomID}/remove-customer-tag`,
          body,
          {
            headers: {
              Authorization: await getToken(),
            },
          }
        );
        break;
      case "order":
        body = {
          OrderID: payload.OrderId,
        };
        response = await uninterceptedAxiosInstance.post(
          ENDPOINT + `/production/room/${context.state.selectedRoomID}/remove-order-tag`,
          body,
          {
            headers: {
              Authorization: await getToken(),
            },
          }
        );
        break;
      default:
        break;
    }
    if (response.status === 200) {
      console.log("Tag removed.");
    }
  },

  async getOrderHistory(context, { userId }) {
    const response = await uninterceptedAxiosInstance.post(
      CUSTOMER_ENDPOINT + "/getUser",
      {
        ownerId: userId,
      },
      {
        headers: {
          Authorization: `bearer ` + (await getToken()),
        },
      }
    );
    const list = response.data.filter((i) => {
      // if (i.payment) {
      //   if (i.payment.isPaid) {
      //     return true;
      //   }
      // }
      // return false;
      return true;
    });
    return list;
  },

  async getOrderDetail(context, { orderID }) {
    const response = await uninterceptedAxiosInstance.post(
      CUSTOMER_ENDPOINT + "/get",
      {
        orderId: orderID,
      },
      {
        headers: {
          Authorization: `bearer ` + (await getToken()),
        },
      }
    );
    console.log("getOrderDetail", response);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  },

  // TAG
  async listTags(context) {
    const response = await uninterceptedAxiosInstance.get(
      TAG_ENDPOINT + `/tags`,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
    if (response.status === 200) {
      context.commit("setTags", response.data);
    } else {
      context.commit("setTags", []);
    }
  },
  async listRoomsByTag(context, { tag, nextToken }) {
    const response = await uninterceptedAxiosInstance.get(
      TAG_ENDPOINT + `/rooms/${tag.Type}-tag/${tag.Title}`,
      {
        params: {
          nextToken: nextToken,
        },
        headers: {
          Authorization: await getToken(),
        },
      }
    );
    if (response.status === 200) {
      const { Items, NextToken } = response.data;
      return { list: Items, nextToken: NextToken };
    }
    return null;
  },

  async getCanPushNotification(context, { userID }) {
    try {
      const response = await uninterceptedAxiosInstance.get(
        ENDPOINT + `/production/can-push-notification`,
        {
          params: {
            userID: userID,
          },
          headers: {
            Authorization: await getToken(),
          },
        }
      );
      if (response.status === 200) {
        return response.data.CanPushNotification;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  async getCompleteMessages(context) {
    const response = await axios.get(COMPLETE_MESSAGE_ENDPOINT);
    const messages = response.data?.messages || []
    return messages.sort((a, b) => a.length - b.length);
  },
  async requestToJoin(context, payload) {
    await axios.post(
      ENDPOINT + `/dev/room/${context.state.selectedRoomID}/join/request`,
      payload,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
  },
  async acceptToJoin(context, payload) {
    const body = {
      CreatorID: payload.AgentID,
      CreatorName: payload.AgentName,
      RefID: payload.RefID,
      AgentID: payload.NewAgent.ID,
      AgentName: payload.NewAgent.Name,
      Channel: payload.Channel,
    };
    await axios.post(
      ENDPOINT + `/dev/room/${payload.RoomID}/join/accept`,
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
  },
  async rejectToJoin(context, payload) {
    const body = {
      CreatorID: payload.AgentID,
      CreatorName: payload.AgentName,
      RefID: payload.RefID,
      AgentID: payload.NewAgent.ID,
      AgentName: payload.NewAgent.Name,
      Channel: payload.Channel,
    };
    await axios.post(
      ENDPOINT + `/dev/room/${payload.RoomID}/join/reject`,
      body,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
  },
  async getCanSelfAssign(context, payload) {
    const response = await axios.get(
      ENDPOINT + `/dev/room/${payload}/can-self-assign`,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    );
    if (response.status !== 200) {
      return false;
    }
    return response.data.CanSelfAssign;
  },
  async fetchEventLogs(context, payload) {
    try {
      const response = await axios.get(
        ENDPOINT + `/dev/room/${payload}/event-log`,
        {
          headers: {
            Authorization: await getToken(),
          },
        }
      );
      if (response.status !== 200) {
        context.commit("setRoomLogs", []);
      }
      const logs = response.data;
      context.commit("setRoomLogs", logs);
    } catch (error) {
      context.commit("setRoomLogs", []);
    }
  },
};

const chat = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};

export default chat;
