import httpClient from "@/services/httpClient";
import auth from "@/services/auth";
import { server } from "@/services/constants";
import router from "@/router";
import store from "@/store";
import * as productApis from "@/services/lambdaService.js"

export const checkLogin = async (to, from, next) => {
  try {
      await store.dispatch({ type: "restoreLogin" });
      if (store.state.isLogged) {
          return next();
      }
      else {
          return next("/login");
      }
  } catch (error) {
      return next("/login");
  }
};

const isLoggedIn = async () => {
  // let token = localStorage.getItem(server.TOKEN_KEY);
  let token = localStorage.getItem(server.USERNAME);
  if (token==null || token.toString().trim()=="") {
      router.push("/login", () => {});
      // return false;
  }
  else {
      return true;
  }
};

const login = async values => {
  // if ((values.username.toLowerCase().trim()=="admin" && values.password.toLowerCase().trim()=="villa@123") ||
  //   (values.username.toLowerCase().trim()=="geekcon" && values.password.toLowerCase().trim()=="villa@geekcon")) {
  //   localStorage.setItem(server.USERNAME, values.username);
  //   localStorage.setItem(server.TOKEN_KEY, values.username);
  //   // localStorage.setItem(server.TOKEN_KEY, result.data.token);
  //   router.push("/home");
  //   return true;
  // }

  const user = values.username.toLowerCase().trim().toLowerCase();
  if (user=="geekcon" || user=="v21722" || user=="v14023" || user=="v10809" || user=="rc_gent" || user=="rc_napasin")
    return await sign_in(values)
  else {
    let result = await httpClient.post(server.LOGIN_URL, values);
    // console.log(result);
    let user = result.data;
    if (user?.userCode ?? "" != "")  {
      localStorage.setItem(server.USERNAME, values.username);
      localStorage.setItem(server.TOKEN_KEY, user.userCode);
      router.push("/home", () => {});
      return true;
    } else {
      alert("User not found.");
      return false;
    }
  }
};

const sign_in = async values => {
  try {
    let user = await auth.sign_in(values.username, values.password)
    if (user.userCode != "") {
      localStorage.setItem(server.USERNAME, user.userCode);
      // localStorage.setItem(server.TOKEN_KEY, user.userCode);
      localStorage.setItem(server.ACCESS_TOKEN, user.accessToken);
      localStorage.setItem(server.ID_TOKEN, user.idToken);
      localStorage.setItem(server.REFRESH_TOKEN, user.refreshToken);
      localStorage.setItem(server.EXPIRES_IN, user.expiresIn);
      localStorage.setItem(server.EXPIRES_TIME, auth.getExpireTime(user.expiresIn - 120));
      router.push("/home", () => {});
      return true;
    } else {
      alert("User not found.");
      return false;
    }
  } catch (error) {
    if (error.response?.data)
      alert(error.response?.data)
    else
      alert(error)
  }
};

// const login = async values => {
//   // console.log("--- username ---", values.username.toLowerCase().trim());
//   // console.log("--- password ---", values.password.toLowerCase().trim());
//   if ((values.username.toLowerCase().trim()=="admin" && values.password.toLowerCase().trim()=="villa@123") ||
//     (values.username.toLowerCase().trim()=="geekcon" && values.password.toLowerCase().trim()=="villa@geekcon")) {
//     localStorage.setItem(server.USERNAME, values.username);
//     localStorage.setItem(server.TOKEN_KEY, values.username);
//     // localStorage.setItem(server.TOKEN_KEY, result.data.token);
//     router.push("/home");
//     return true;
//   }
//   else {
//     return false;
//   }
//   // let result = await httpClient.post(server.LOGIN_URL, values);
//   // if (result.data.result == "ok") {
//   //   localStorage.setItem(server.USERNAME, values.username);
//   //   localStorage.setItem(server.TOKEN_KEY, result.data.token);
//   //   router.push("/stock");
//   //   return true;
//   // } else {
//   //   return false;
//   // }
// };

const register = async values => {
  let result = await httpClient.post(server.REGISTER_URL, values);
  if (result.data.result == "ok") {
    router.go(-1);
  } else {
    alert(JSON.stringify(result));
  }
};

const logoff = async () => {
  localStorage.removeItem(server.USERNAME);
  localStorage.removeItem(server.TOKEN_KEY);
  localStorage.removeItem(server.ACCESS_TOKEN);
  localStorage.removeItem(server.ID_TOKEN);
  localStorage.removeItem(server.REFRESH_TOKEN);
  localStorage.removeItem(server.EXPIRES_IN);
  localStorage.removeItem(server.EXPIRES_TIME);
  localStorage.removeItem("userInfo");
  router.push("/login");
};

export default {
  isLoggedIn,
  login,
  register,
  logoff,
  ...productApis
};