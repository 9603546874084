<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-card>
      <v-data-table
        height="500"
        fixed-header
        :headers="headers"
        :items="mDataArray"
        sort-by="VoucherCode"
        :sort-desc="false"
        :items-per-page="15"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Voucher</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              clearable
              hide-details
              v-model="voucherCode"
              label="VoucherCode"
              @keyup.enter="searchClick"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              clearable
              hide-details
              v-model="orderId"
              label="OrderId"
              @keyup.enter="searchClick"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <v-btn @click="newVoucher" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>Create Voucher</span>
            </v-btn>
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.voucherCode }}</td>
            <td>{{ item.voucherTypeDesc }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.orderId }}</td>
            <td>{{ item.userCode }}</td>
            <td>{{ item.requestCode }}</td>
            <td>{{ item.remark }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";

export default {
  name: "voucher",
  data() {
    return {
      loading: false,
      // search: "",
      voucherCode: "",
      orderId: "",
      mDataArray: [],
      headers: [
        { text: "VoucherCode", value: "voucherCode" },
        { text: "Type", value: "voucherTypeDesc" },
        { text: "Amount", value: "amount" },
        { text: "OrderId", value: "orderId" },
        { text: "UserCode", value: "userCode" },
        { text: "RequestBy", value: "requestCode" },
        { text: "Remark", value: "remark" },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      this.loadData();
  },
  methods: {
    searchClick() {
      this.loadData();
    },
    // editItem(item) {
    //   this.$router.push(`/voucher/edit/${item.userCode}`);
    // },
    newVoucher() {
      this.$router.push("/voucher/edit/0");
    },
    async loadData() {
      try {
        this.loading = true;
        let filter = { voucherCode: this.voucherCode, orderId: this.orderId }
        // console.log("--- filter ---", filter);
        let result = await api.getVoucherList(filter);
        // console.log("--- data list ---", result.data);
        this.mDataArray = result.data;
      } catch (error) {
          alert(error)
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>