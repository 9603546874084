<template>
    <div>
      <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="mDataArray"
          sort-by="Question"
          :items-per-page="15"
        >
          <!-- table top section -->
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Survey</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                clearable
                v-model="search"
                label="Search"
                single-line
                hide-details
                @keyup.enter="searchClick"
              ></v-text-field>
              <v-icon class="mr-2" @click="searchClick">
                search
              </v-icon>
              <v-spacer></v-spacer>
              <v-btn @click="newItem" color="primary" dark class="mb-2">
                <v-icon left>add</v-icon>
                <span>New Survey</span>
              </v-btn>
            </v-toolbar>
          </template>
  
          <!-- table tr section -->
          <template v-slot:item="{ item }">
            <tr>
              <td style="width: 140px;">
                <v-icon color="gray darken-2" class="mr-2" @click="editItem(item)">
                  edit
                </v-icon>
                <v-icon color="gray darken-2" class="mr-2" @click="deleteItem(item)">
                  delete
                </v-icon>
              </td>
              <td>{{ item.Type }}</td>
              <td>{{ item.Question }}</td>
              <td>{{ getDate(item.StartTime) }}</td>
              <td>{{ getDate(item.EndTime) }}</td>
              <td>{{ item.ViewCount }}</td>
              <td>
                <v-checkbox readonly v-model="item.Required"></v-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    </div>
  </template>
  
  <script>
  import surveyService from "@/services/surveyService";
  import moment from 'moment'
  
  export default {
    name: "survey",
    data() {
      return {
        loading: false,
        search: "",
        mDataArray: [],
        headers: [
          {
            text: "Action",
            align: "left",
            sortable: false,
            value: "action",
          },
          { text: "Type", value: "Type" },
          { text: "Question", value: "Question" },
          { text: "StartTime", value: "StartTime" },
          { text: "EndTime", value: "EndTime" },
          { text: "ViewCount", value: "ViewCount" },
          { text: "Required", value: "Required" }
        ],
      };
    },
    // components: {
    // },
    async mounted() {
      this.loadData();
    },
    methods: {
      searchClick() {
        this.loadData();
      },
      getDate(d)
      {
        return moment(new Date(d)).format('YYYY-MM-DD HH:mm')
      },
      editItem(item) {
        this.$router.push(`/survey/edit/${encodeURIComponent(item.QuestionID)}`);
      },
      async deleteItem(item) {
        if (confirm('Confirm Delete?')) {
          try {
            this.loading = true;
            let result = await surveyService.deleteItem(item.QuestionID);
            if (result.Status == "success")
              await this.loadData();
          } catch (error) {
            alert(error);
          } finally {
            this.loading = false;
          }
        }
      },
      newItem() {
        this.$router.push("/survey/edit");
      },
      async loadData() {
        try {
          this.loading = true;
          let result;
          if (this.search == null || this.search == "") {
            let nextToken = "";
            let items = [];
            let last_items = null;
            while (true) {
              result = await surveyService.getItems(nextToken);
              // alert(JSON.stringify(result.Data?.Items));
              if (result.Status == "success") {
                if (last_items == result.Data?.Items) {
                  // alert(JSON.stringify(last_items))
                  break;
                }
                last_items = result.Data?.Items;
                if (last_items != null && last_items.length>0) {
                  // alert(JSON.stringify(last_items))
                  nextToken = result.Data?.NextToken;
                  items = items.concat(last_items);
                  if (nextToken == null || nextToken == "") break;
                }
              }
            }
            if (items != null && items.length>0)
              this.mDataArray = items;
            else
              this.mDataArray = [];
          }
          else {
            result = await surveyService.getItem(this.search);
            if (result.Status == "success") {
              this.mDataArray = [];
              this.mDataArray.push(result.Data);
            }
          }
        } catch (error) {
          alert(error);
        } finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }
  </style>