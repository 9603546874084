<template>
    <div>
      <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="mDataArray"
          sort-by="URL"
          :items-per-page="15"
        >
          <!-- table top section -->
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Geo Content</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                clearable
                v-model="search"
                label="Search"
                single-line
                hide-details
                @keyup.enter="searchClick"
              ></v-text-field>
              <v-icon class="mr-2" @click="searchClick">
                search
              </v-icon>
              <v-spacer></v-spacer>
              <v-btn @click="newItem" color="primary" dark class="mb-2">
                <v-icon left>add</v-icon>
                <span>New Geo Content</span>
              </v-btn>
            </v-toolbar>
          </template>
  
          <!-- table tr section -->
          <template v-slot:item="{ item }">
            <tr>
              <td style="vertical-align: top; width: 100px;">
                <v-icon color="gray darken-2" class="mr-2" @click="editItem(item)">
                  edit
                </v-icon>
                <v-icon color="gray darken-2" class="mr-2" @click="deleteItem(item)">
                  delete
                </v-icon>
              </td>
              <td style="vertical-align: top;">{{ item.URL }}</td>
              <td style="vertical-align: top;">{{ item.MetaTitle }}</td>
              <td style="vertical-align: top;">{{ item.MetaDescription }}</td>
              <td style="vertical-align: top;">{{ item.Th }}</td>
              <td style="vertical-align: top;">{{ item.En }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    </div>
  </template>
  
  <script>
  import geoService from "@/services/geoService";
  
  export default {
    name: "geocontent",
    data() {
      return {
        loading: false,
        search: "",
        mDataArray: [],
        headers: [
          {
            text: "Action",
            align: "left",
            sortable: false,
            value: "action",
          },
          { text: "URL", value: "URL" },
          { text: "Meta Title", value: "MetaTitle" },
          { text: "Meta Description", value: "MetaDescription" },
          { text: "Content (TH)", value: "Th" },
          { text: "Content (EN)", value: "En" }
        ],
      };
    },
    // components: {
    // },
    async mounted() {
      this.loadData();
    },
    methods: {
      searchClick() {
        this.loadData();
      },
      editItem(item) {
        this.$router.push(`/geocontent/edit/${encodeURIComponent(item.URL)}`);
      },
      async deleteItem(item) {
        if (confirm('Confirm Delete?')) {
          try {
            this.loading = true;
            let result = await geoService.deleteItem(item.URL, '', '');
            if (result.Status == "success")
              await this.loadData();
          } catch (error) {
            alert(error);
          } finally {
            this.loading = false;
          }
        }
      },
      newItem() {
        this.$router.push("/geocontent/edit");
      },
      async loadData() {
        try {
          this.loading = true;
          let result;
          if (this.search == null || this.search == "") {
            result = await geoService.getItems();
            if (result.Status == "success")
              this.mDataArray = result.Data?.Items ?? [];
          }
          else {
            result = await geoService.getItem(this.search);
            if (result.Status == "success") {
              this.mDataArray = [];
              this.mDataArray.push(result.Data);
            }
          }
        } catch (error) {
          alert(error);
        } finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }
  tbody td:nth-of-type(odd) {
    overflow: hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
  }
  </style>