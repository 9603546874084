<script>
//Importing Bar class from the vue-chartjs wrapper
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

var numberWithCommas = function(x) {
  return parseFloat(x?? 0).toLocaleString("th-TH", {"minimumFractionDigits":0,"maximumFractionDigits":2});
  //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//Exporting this so it can be used in other components
export default {
  extends: Bar,
  mixins:[reactiveProp],
  data() {
    return {
      //Chart.js options that controls the appearance of the chart
      options: {

        tooltips: {
          mode: 'label',
          callbacks: {
              label: function(tooltipItem, data) {
                return data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.yLabel);

                // let label = data.datasets[tooltipItem.datasetIndex].label;
                // let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                // return [label + ':' + value, 'Avg Spending'];
                // let label = data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.yLabel);

                // if (data.datasets.length == 6) // data.datasets.length)
                //   return [data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.datasetIndex), 'Avg Spending'];
                //   //return [data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.yLabel), 'Avg Spending'];
                // else
                //   // return [data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.datasetIndex)];
                //   return data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.yLabel);
              }
          }
        },

        interaction: {
            mode: 'nearest'
        },

        scales: {
          yAxes: [
            {
              stacked: true,
              id: '0',
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                    return numberWithCommas(value);
                },
              },
              gridLines: {
                display: true
              }
            },
            {
              // stacked: true,
              id: '1',
              position: 'right',
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                    return numberWithCommas(value);
                },
              },
              gridLines: {
                display: false
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false
              }
            }
          ],
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,

        // plugins: {
        //   datalabels: {
        //     anchor: 'end',
        //     align: 'end',
        //     formatter: (val) => ('$' + val)
        //   }
        // }

        // plugins: {
        //     datalabels: {
        //       display: true,
        //       align: 'center',
        //       anchor: 'center'
        //     }
        // },
      }
    };
  },
  mounted() {
    //renderChart function renders the chart with the datacollection and options object.
    // setTimeout(() => {
      this.renderChart(this.chartData, this.options);
    // }, 100);
  }
};
</script>
