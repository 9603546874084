<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-0 pa-5" outlined>
          <v-form @submit.prevent="submit">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="article.article_id"
                  label="Article Id"
                  disabled
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="article.meta_title"
                  label="Meta Title"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="article.imageUrl"
                  label="ImageUrl"
                  readonly
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="article.meta_keywords"
                  label="Meta Keywords"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="article.meta_description"
                  label="Meta Description"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <!-- Left -->
              <v-col cols="6">
                <!-- <v-text-field
                  outlined
                  v-model="article.article_id"
                  label="Article Id"
                  disabled
                ></v-text-field> -->

                <div class="header">Article (Eng)</div><br />
                <v-text-field
                  outlined
                  v-model="article.article_name_en"
                  label="Article Name (EN)"
                  required
                ></v-text-field>

                <v-combobox
                  outlined
                  v-model="article.artiale_type_en"
                  :items="articleTypeEN"
                  label="Article Type (EN)"
                  required
                ></v-combobox>

                <v-text-field
                  outlined
                  v-model="article.button_label_en"
                  label="Button Label (EN)"
                  required
                ></v-text-field>

                <v-textarea
                  outlined
                  v-model="article.description_en"
                  label="Description (EN)"
                ></v-textarea>

                <v-text-field v-if="article.article_detail_en != null"
                  outlined
                  v-model="article.article_detail_en.credit"
                  label="Credit (EN)"
                  required
                ></v-text-field>

                <v-text-field v-if="article.article_detail_en != null"
                  outlined
                  v-model="article.article_detail_en.author"
                  label="Author (EN)"
                  required
                ></v-text-field>
              </v-col>

              <!-- Right -->
              <v-col cols="6">
                <!-- <v-text-field
                  outlined
                  v-model="article.imageUrl"
                  label="ImageUrl"
                  readonly
                ></v-text-field> -->

                <div class="header">Article (Thai)</div><br />
                <v-text-field
                  outlined
                  v-model="article.article_name_th"
                  label="Article Name (TH)"
                  required
                ></v-text-field>

                <v-combobox
                  outlined
                  v-model="article.artiale_type_th"
                  :items="articleTypeTH"
                  label="Article Type (TH)"
                  required
                ></v-combobox>

                <v-text-field
                  outlined
                  v-model="article.button_label_th"
                  label="Button Label (TH)"
                  required
                ></v-text-field>

                <v-textarea
                  outlined
                  v-model="article.description_th"
                  label="Description (TH)"
                ></v-textarea>

                <v-text-field v-if="article.article_detail_th != null"
                  outlined
                  v-model="article.article_detail_th.credit"
                  label="Credit (TH)"
                  required
                ></v-text-field>

                <v-text-field v-if="article.article_detail_th != null"
                  outlined
                  v-model="article.article_detail_th.author"
                  label="Author (TH)"
                  required
                ></v-text-field>

              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col cols="12">
                <v-row class="ma-0">
                  <h3>Article Detail (Eng)</h3>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-btn color="primary" @click.stop="showArticleDetail('EN', null)">
                    <v-icon left>add</v-icon>
                    Add Detail
                  </v-btn> 
                </v-row>

                <div class="table_article_detail" v-if="article.article_detail_en != null">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th width="30%" style="text-align:left">Title</th>
                        <th width="50%" style="text-align:left">Detail</th>
                        <th width="10%" style="text-align:left">Ordered</th>
                        <th scope="10%" style="text-align:left">Action</th>
                      </tr>
                    </thead>
                    <draggable v-model="article.article_detail_en.paragraph_list" tag="tbody">
                        <tr v-for="paragraph in article.article_detail_en.paragraph_list">
                            <td>{{ paragraph.paragraph_title }}</td>
                            <td>{{ paragraph.detail }}</td>
                            <td>
                              <v-checkbox
                                  height="1"
                                  v-model="paragraph.ordered"
                                  readonly
                              ></v-checkbox>
                            </td>
                            <td>
                              <v-icon @click="showArticleDetail('EN', paragraph)">
                                edit
                              </v-icon>
                              <v-icon class="ml-3" @click="removeDetailItem(article.article_detail_en.paragraph_list, paragraph)">
                                delete
                              </v-icon>
                            </td>
                        </tr>
                    </draggable>
                  </table>
                </div>
              </v-col>
            </v-row>

            <v-divider></v-divider>  
            <br />

            <v-row>
              <v-col cols="12">
                <v-row class="ma-0">
                  <h3>Article Detail (Thai)</h3>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-btn color="primary" @click.stop="showArticleDetail('TH', null)">
                    <v-icon left>add</v-icon>
                    Add Detail
                  </v-btn> 
                </v-row>

                <div class="table_article_detail" v-if="article.article_detail_th != null">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th width="30%" style="text-align:left">Title</th>
                        <th width="50%" style="text-align:left">Detail</th>
                        <th width="10%" style="text-align:left">Ordered</th>
                        <th scope="10%" style="text-align:left">Action</th>
                      </tr>
                    </thead>
                    <draggable v-model="article.article_detail_th.paragraph_list" tag="tbody">
                        <tr v-for="paragraph in article.article_detail_th.paragraph_list">
                            <td>{{ paragraph.paragraph_title }}</td>
                            <td>{{ paragraph.detail }}</td>
                            <td>
                              <v-checkbox
                                  height="1"
                                  v-model="paragraph.ordered"
                                  readonly
                              ></v-checkbox>
                            </td>
                            <td>
                              <v-icon @click="showArticleDetail('TH', paragraph)">
                                edit
                              </v-icon>
                              <v-icon class="ml-3" @click="removeDetailItem(article.article_detail_th.paragraph_list, paragraph)">
                                delete
                              </v-icon>
                            </td>
                        </tr>
                    </draggable>
                  </table>
                </div>
              </v-col>
            </v-row>

            <br />
            <v-divider></v-divider>
            <br />

            <!-- <div v-if="!imageFile"> -->
              <h2>Select an image (เลือกได้ 1 รูป)</h2>
              <!-- <input type="file" accept=".png,.jpg,.jpeg,.bmp,.gif,.webp" @change="onFileSelected"> -->
              <input type="file" accept=".gif,.webp" @change="onFileSelected">
            <!-- </div> -->
            <!-- <div v-else> -->
              <br />
              <v-img
                class="ma-5"
                contain
                :eager="true"
                :src="imageFile"
                lazy-src="@/assets/noimage.png"
                width="100%"
                max-height="300"
              ></v-img>
              <!-- <button @click="removeImage">Remove image</button> -->
            <!-- </div> -->

            <v-divider></v-divider>  
            <br />
            <template>
              <v-row class="ma-0">
                <h1>Products</h1>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn color="primary" @click.stop="showProductSearch=true">
                  <v-icon left>add</v-icon>
                  Add Product
                </v-btn> 
                <ProductSearch v-model="showProductSearch" :onSelect="onSelectMulti" />

                <!-- <v-divider class="mx-5" inset vertical></v-divider> -->
                <v-btn color="primary" @click="clearProduct">
                  <v-icon left>delete</v-icon>
                  Clear All
                </v-btn>

                <v-spacer></v-spacer>
                <v-tooltip bottom color="pink darken-4">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 class="mr-3" v-bind="attrs" v-on="on">Import Excel (XLSX)</h3>
                  </template>
                  <span>ใส่รหัสสินค้า 1 column ต้องมี column header ชื่อ prcode</span>
                  </v-tooltip>
                <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
                <v-spacer></v-spacer>
              </v-row>
            </template>

            <br />
            <template>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped" width="100%">
                    <thead class="thead-dark" align-center>
                      <tr>
                        <th scope="col" style="text-align: left">Image</th>
                        <th scope="col" style="text-align: left">(C) Pr Code</th>
                        <th scope="col" style="text-align: left">Pr Name</th>
                        <th scope="col" style="text-align: left">Online</th>
                        <!-- <th scope="col" style="text-align: left">Enabled</th> -->
                        <th scope="col" style="text-align: left">Action</th>
                      </tr>
                    </thead>
                    <draggable v-model="mProductArray" tag="tbody">
                      <tr v-for="item in mProductArray" :key="item.cprcode">
                        <td>
                            <v-img
                              contain
                              class="ma-2"
                              :eager="true"
                              :src="item.image"
                              lazy-src="@/assets/noimage.png"
                              aspect-ratio="1"
                              min-width="80"
                              max-width="80"
                              max-height="80"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular v-show="loading"
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                        </td>
                        <td>{{ item.cprcode }}</td>
                        <td>{{ item.hema_name_en }}</td>
                        <td>
                          <v-checkbox
                            readonly
                            v-model="item.master_online"
                          ></v-checkbox>
                        </td>
                        <!-- <td>
                          <v-checkbox
                            readonly
                            v-model="item.enabled"
                          ></v-checkbox>
                        </td> -->
                        <td>
                          <v-icon @click="removeItem(item)">
                            delete
                          </v-icon>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </div>
            </template>

            <br />
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click="cancel">
                Cancel
              </v-btn>

              <v-btn color="primary" type="submit">
                Confirm
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isShowArticleDetail"
            max-width="800px"
          >
            <v-card>
              <v-card-title>Article Detail ({{ articleParagraph.type }})</v-card-title>
              <v-divider></v-divider>
              <div class="pa-5">
                <v-text-field
                  outlined
                  v-model="articleParagraph.paragraph_title"
                  label="Title"
                  required
                ></v-text-field>
                <v-textarea
                  outlined
                  v-model="articleParagraph.detail"
                  height="400"
                  label="Detail"
                ></v-textarea>
                <v-checkbox
                  v-model="articleParagraph.ordered"
                  height="1"
                  label="Ordered"
                ></v-checkbox>
              </div>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isShowArticleDetail = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="addArticleDetail"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
  </v-container>
</template>

<script>
import { groupBucketName } from "@/services/constants";
import XLSX from 'xlsx';
import axios from "axios";
import api from "@/services/lambdaApi";
import draggable from 'vuedraggable'
import ProductSearch from "@/components/product/ProductSearch2"

export default {
  name: "articles-edit",
  components:{
    draggable,
    ProductSearch
  },
  data: () => ({
    loading: false,
    isShowArticleDetail: false,
    paragraph_edit: null,
    article: {
      article_id: "",
      meta_title: "",
      meta_keywords: "",
      meta_description: "",
      artiale_type_en: "",
      artiale_type_th: "",
      article_name_en: "",
      article_name_th: "",
      button_label_en: "",
      button_label_th: "",
      description_en: "",
      description_th: "",
      imageUrl: "",
      article_detail_en: {
        paragraph_list: [], 
        credit: "",
        author: ""
      },
      article_detail_th: {
        paragraph_list: [], 
        credit: "",
        author: ""
      },
      ingredient_list: [],
      createdDate: "",
      createdDatetime: "",
      updatedDate: "",
      updatedDatetime: ""
    },
    articleParagraph: {
      type: "",
      paragraph_title: "",
      ordered: false,
      detail: ""
    },
    imageFile: null, //string
    imageUpload: null, //image
    showProductSearch: false,
    mProductArray: [],
    headers: [
      {
        text: "",
        align: "left",
        sortable: false
      },
      {
        text: "Image",
        align: "left",
        sortable: false,
        value: "image"
      },
      {
        text: "(C) Pr Ccode",
        align: "left",
        sortable: false,
        value: "cprcode"
      },
      {
        text: "Pr Name",
        align: "left",
        sortable: false,
        value: "hema_name_en"
      },
      {
        text: "Action",
        align: "left",
        sortable: false,
        value: "action"
      },
    ]
  }),
  async mounted() {
    try {
      this.loading = true;
      let article_id = this.$route.params.id ?? "0";
      if (article_id != "0") {
        let data = { article_id: article_id }
        let result = await api.getArticleById(data);
        this.article = result.data;
        this.mProductArray = result.data.ingredient_list;
        this.imageFile = result.data.imageUrl ?? "";
        // console.log("--- article ---", JSON.stringify(this.article));
      }
    // } catch (error) {
    }
    finally {
      this.loading = false;
    } 
  },

  methods: {
    showArticleDetail(ln, item) {
      this.paragraph_edit = item;
      this.articleParagraph.type = ln;
      if (item == null) {
        this.articleParagraph.paragraph_title = "";
        this.articleParagraph.ordered = false;
        this.articleParagraph.detail = "";
      }
      else {
        this.articleParagraph.paragraph_title = item.paragraph_title;
        this.articleParagraph.ordered = item.ordered;
        this.articleParagraph.detail = item.detail;
      }
      this.isShowArticleDetail = true;
    },
    addArticleDetail() {
      // alert("add article detail");
      if (this.paragraph_edit != null) {
        this.paragraph_edit.paragraph_title = this.articleParagraph.paragraph_title;
        this.paragraph_edit.ordered = this.articleParagraph.ordered;
        this.paragraph_edit.detail = this.articleParagraph.detail;
      }
      else {
        let articleDetailObj = this.articleParagraph.type == "EN" ? this.article.article_detail_en : this.article.article_detail_th;
        articleDetailObj.paragraph_list.push(
          {
            "paragraph_title": this.articleParagraph.paragraph_title,
            "ordered": this.articleParagraph.ordered,
            "detail": this.articleParagraph.detail,
          }
        );
      };
      this.isShowArticleDetail = false;
    },
    removeDetailItem: function (objectList, item) {
      let index = objectList.indexOf(item);
      if (index >= 0) {
        objectList.splice(index, 1)
      }
    },
    clearProduct() {
      this.mProductArray = []
    },
    onFileImportSelected(e) {
      try {
        this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile.value = "";
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    previewFiles(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let prcodes = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- prcodes --', prcodes);
            let prcodeList = [];
            prcodes.forEach(item => {
              prcodeList.push(parseInt(item.prcode ?? 0));
            });
            if (prcodeList && prcodeList.length>0) {
              // console.log('-- prcodeList --', prcodeList);
              let result = await api.getProductByPrcodeList(prcodeList);
              let productList = result.data;
              // console.log('-- productList --', productList);
              productList.forEach(product => {
                // console.log('-- product --', product);
                vm.onSelect(product);
              });
            }
        };
        reader.readAsArrayBuffer(file);
    },
    onSelectMulti(items) {
      if (items != null) {
        items.forEach(item => {
          if (item.selected == 1) {
            let mProduct = this.mProductArray.find(d => d.cprcode === item.cprcode);
            if (!mProduct) {
              let product = {
                "cprcode": item.cprcode,
                "hema_name_en": item.hema_name_en,
                "image": item.image,
                "master_online": item.master_online,
                // "enabled": item.enabled
              };
              this.mProductArray.push(product);
            }
          }
        });
      }
    },
    onSelect(item) {
      //console.log(JSON.stringify(item));
      if (item != null) {
        let mProduct = this.mProductArray.find(d => d.cprcode === item.cprcode);
        if (!mProduct) {
          let product = {
            "cprcode": item.cprcode,
            "hema_name_en": item.hema_name_en,
            "image": item.image,
            "master_online": item.master_online,
            // "enabled": item.enabled
          };
          this.mProductArray.push(product);
        }
      }
    },
    removeItem: function (item) {
      let mProduct = this.mProductArray.find(d => d.cprcode === item.cprcode);
      if (mProduct) {
        let index = this.mProductArray.indexOf(mProduct);
        if (index >= 0) {
          this.mProductArray.splice(index, 1)
        }
      }
    },
    cancel() {
      this.$router.back();
    },
    onFileSelected(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (files.length == 0)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.imageFile = e.target.result;
        vm.imageUpload = file;
      };
      reader.readAsDataURL(file);
    },
    // removeImage: function (e) {
    //   this.imageFile = '';
    //   this.imageUpload = null;
    // },
    async submit() {
      try {
        this.loading = true;
        let data = this.article;
        let productList = [];
        this.mProductArray.forEach(item => {
          productList.push(parseInt(item.cprcode ?? 0));
        });
        data.ingredient_list = productList;

        // console.log('request', JSON.stringify(data));
        // alert(data.article_id);
        let result;
        if (data.article_id == "") {
          result = await api.createArticle(data);
        }
        else {
          result = await api.updateArticle(data);
        }

        // console.log('result', JSON.stringify(result));
        let response = result.data;
        // console.log('response', JSON.stringify(response));

        if (response.statusCode == 200)
          await this.upLoadImage(response.article_id);

        this.$router.back();
      } catch (error) {
        alert(error);
      }
      finally {
        this.loading = false;
      }
    },
    async upLoadImage(articleId) {
      if (this.imageUpload != null) {
        let key = `Article/${articleId.toString()}`;
        let data = { bucket: groupBucketName, key: key }
        let result = await api.presignUpPics(data);
        let presignUrls = result.data;
        if (presignUrls)
        {
          let formData = new FormData();
          formData.append("Content-Type", presignUrls.fields["Content-Type"]);
          formData.append("x-amz-security-token", presignUrls.fields["x-amz-security-token"]);
          formData.append("key", presignUrls.fields["key"]);
          formData.append("AWSAccessKeyId", presignUrls.fields["AWSAccessKeyId"]);
          formData.append("policy", presignUrls.fields["policy"]);
          formData.append("signature", presignUrls.fields["signature"]);
          formData.append('file', this.imageUpload);
          axios.post(presignUrls.url,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
            ).then(function(){
              console.log('Upload image SUCCESS!!');
            })
            .catch(function(){
              console.log('Upload image FAILURE!!');
            });
        }
      }
    },
  },
  computed: {
    articleTypeEN() {
      return ["Food & Recipes", "Healthy", "Founder's Favorites", "Easy Shopping", 'Breakfast', 'Lunch', 'Dinner', 'Curated Brands']
    },
    articleTypeTH() {
      return ["อาหารและวิธีปรุง", "สุขภาพ", "คัดเลือกจากใจ โดยท่านประธาน", "ช้อปสะดวกสบาย", 'อาหารเช้า', 'อาหารเที่ยง', 'อาหารค่ำ', 'แบรนด์คัดพิเศษ']
    },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
}
.table_article_detail {
   height: 320px;
   overflow: auto;
}
td {
   padding: 5px;
   vertical-align: top;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
.header {
  font-size: 20px;
  font-weight: bold;
  color: #0D47A1;
  background-color: rgba(0, 0, 0, .05);
}
</style>