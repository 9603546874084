<template>
  <v-main>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
    <!-- <router-view name="chat" /> -->
    <!-- <keep-alive include="Product,Banner">
      <router-view></router-view>
    </keep-alive> -->
    <!-- <transition name="fade" mode="out-in"> -->
      <!-- <keep-alive v-if="router.meta.keepAlive">
        <router-view></router-view>
      </keep-alive>
      <router-view v-else></router-view> -->
    <!-- </transition> -->
    <!-- <router-view></router-view> -->
  </v-main>
</template>

<script>
export default {

}
</script>

<style>

</style>