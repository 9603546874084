<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-10 pa-5" outlined>
          <v-form @submit.prevent="submit">
            <v-row>
              <!-- Left -->
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="user.userCode"
                  label="User Code"
                  :disabled="action=='edit'"
                ></v-text-field>
              </v-col>
              <!-- Right -->
              <v-col cols="3">
                <v-text-field
                  outlined
                  v-model="password"
                  label="Password"
                  :disabled="action=='edit'"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="active"
                  label="Active"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <!-- Left -->
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="user.userFirstName"
                  label="First Name"
                  required
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="user.userLastName"
                  label="Last Name"
                  required
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="user.createdDatetime"
                  label="Create Datetime"
                  disabled
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="user.updatedDatetime"
                  label="Update Datetime"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Right -->
              <v-col cols="6">
                <v-select 
                  outlined
                  v-model="storeSelect"
                  :items="stores"
                  item-text="storeName" 
                  item-value="storeCode" 
                  label="Branch"
                  clearable
                  return-object
                ></v-select>

                <v-select
                    outlined
                    v-model="user.userType"
                    :items="userTypes"
                    label="User Type"
                ></v-select>

                <v-select
                    outlined
                    v-model="user.userGroup"
                    :items="userGroups"
                    label="User Group"
                ></v-select>
              </v-col>
            </v-row>

            <br />
            <v-divider></v-divider>
            <br />
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click="cancel">
                Cancel
              </v-btn>

              <v-btn color="primary" type="submit">
                Confirm
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/lambdaApi";
import auth from "@/services/auth";

export default {
  name: "user-edit",
  // components:{
  // },
  data: () => ({
    loading: false,
    active: false,
    action: "",
    password: "",
    storeSelect: null,
    stores:[],
    userTypes: ["SuperAdmin", "Admin", "User", "Picker"],
    userGroups: ["IT", "CS", "Account", "Buyer", "Marketing", "Operation", "SuperUser"],
    user: {
        userCode: "",
        password: "",
        userFirstName: "",
        userLastName: "",
        userType: "",
        userGroup: "",
        brcode: "",
        active: 0,
        updatedDatetime: "",
        createdDatetime: ""
    }
  }),
  async mounted() {
    try {
      this.loading = true;

      let filter = { fileName: "online_stores.json" };
      let stores = await api.getS3Json(filter);
      this.stores = stores.data;

      let userCode = (this.$route.params.id ?? "0").toString();
      if (userCode != "0") {
        this.action = "edit";
        let data = { userCode: userCode }
        let result = await api.getUserById(data);
        this.user = result.data;
        this.active = this.user.active == 1 ? true : false;

        let mStore = this.stores.find(d => d.storeCode === this.user.brcode);
        if (mStore) {
          this.storeSelect = mStore;
        }
        // console.log("--- load ---", JSON.stringify(result.data));
      }
      else {
        this.action = "new";
      }
    // } catch (error) {
    }
    finally {
      this.loading = false;
    } 
  },

  methods: {
    cancel() {
      this.$router.back();
    },
    async submit() {
      try {
        this.loading = true;
        let data = this.user;
        data.active = this.active ? 1 : 0;
        if (this.storeSelect != null) {
          data.brcode = this.storeSelect.storeCode;
        }
        else {
          data.brcode = "";
        }
        // console.log('request', JSON.stringify(data));
        let result;
        if (this.action == "new") {
          data["password"] = this.password;
          result = await auth.createUser(data);
        }
        else {
          result = await auth.updateUser(data);
        }
        // console.log('result', JSON.stringify(result));
        this.$router.back();
      // } catch (error) {
      }
      finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style scoped>
</style>