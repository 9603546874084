<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="creationTime"
        :sort-desc="true"
        :items-per-page="15"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Order</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <!-- <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              @keyup.enter="searchClick"
            ></v-text-field> -->

            <!-- <v-text-field
              hide-details
              v-model="branchId"
              label="Brcode"
              @keyup.enter="searchClick"
            ></v-text-field> -->

            <v-select 
              clearable
              hide-details 
              v-model="branchSelect"
              :items="stores" 
              item-text="storeName" 
              item-value="storeCode" 
              label="Branch"
              :disabled="userBrcode != ''"
              return-object
            ></v-select>

            <v-divider class="mx-4" inset vertical></v-divider>

            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="date1"
              transition="scale-transition"
              offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  clearable
                  v-model="date1"
                  label="Date (Start)"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="$refs.menu1.save(date1)"
                v-model="date1"
                no-title
                scrollable>
              </v-date-picker>
            </v-menu>

            <v-divider class="mx-4" inset vertical></v-divider>

            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="date2"
              transition="scale-transition"
              offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  clearable
                  v-model="date2"
                  label="Date (End)"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="$refs.menu2.save(date2)"
                v-model="date2"
                no-title
                scrollable>
              </v-date-picker>
            </v-menu>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              clearable
              hide-details
              v-model="orderId"
              label="OrderId"
              @keyup.enter="searchClick"
            ></v-text-field>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              clearable
              hide-details
              v-model="customerName"
              label="Customer"
              @keyup.enter="searchClick"
            ></v-text-field>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <!-- <v-btn @click="newDocument" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>New Document</span>
            </v-btn> -->
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td :class="getRowClass(item)">
              <v-icon class="mr-2" @click="editItem(item)">
                edit
              </v-icon>
              <v-icon class="mr-2" color="blue darken-2" @click="printOrder(item)" :disabled="!item.isPaid">
                print
              </v-icon>
            </td>
            <td :class="getRowClass(item)">{{ item.orderId }}<div v-if="isShowRef(item.orderId)">Ref : {{ item.basketId }}</div></td>
            <td :class="getRowClass(item)">{{ item.branchName }}</td>
            <td :class="getRowClass(item)"><a @click="showAppInfo(item)">{{ item.orderSource }}</a></td>
            <!-- <td>{{ item.branchId }} - {{ item.branchName }}</td> -->
            <td :class="getRowClass(item)">{{ item.firstName }}</td>
            <!-- <td>{{ item.lastName }}</td> -->
            <!-- <td>{{ item.phoneNo }}</td> -->
            <td :class="getRowClass(item)">{{ item.shippingType }}</td>
            <td :class="getRowClass(item)">{{ item.deliveryTime }}</td>
            <td :class="getRowClass(item)" style="text-align: right">{{ numberFormat(item.grandTotal, 2) }}</td>
            <!-- <td>{{ item.paymentDateTime }}</td> -->
            <td :class="getRowClass(item)">{{ item.creationTime }}</td>
            <td :class="getRowClass(item)">
              <v-checkbox readonly v-model="item.isPaid"></v-checkbox>
            </td>
            <td :class="getRowClass(item)">
              <v-icon @click="showPaymentLog(item)">
                history
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isShowPaymentLog"
            max-width="800px"
          >
            <v-card>
              <v-card-title>Payment Logs</v-card-title>
              <v-divider></v-divider>
                <template>
                  <div class="row mx-0 pa-5">
                    <div class="col-12 table_payment_log">
                      <table class="table table-striped" width="100%">
                        <thead class="thead-dark" align-center>
                          <tr>
                            <th scope="col" style="text-align: left">Order Id</th>
                            <th scope="col" style="text-align: left">Payment Type</th>
                            <th scope="col" style="text-align: left">Payment Id</th>
                            <th scope="col" style="text-align: left">Amount</th>
                            <th scope="col" style="text-align: left">Status</th>
                            <th scope="col" style="text-align: left">Timestamp</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in paymentLog">
                            <td>{{ item.orderId }}</td>
                            <td>{{ item.paymentType }}</td>
                            <td>{{ item.paymentId }}</td>
                            <td>{{ numberFormat(item.amount, 2) }}</td>
                            <td>{{ item.status }}</td>
                            <td>{{ item.timestamp }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  </template>
                <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isShowPaymentLog = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isShowAppInfo"
            max-width="500px">
            <v-card>
              <v-card-title>App Info</v-card-title>
              <v-divider></v-divider>
              <div class="ma-5">
                <div>OS : {{ appInfo.os }}</div>
                <div>Version : {{ appInfo.version }}</div>
                <div>Device : {{ appInfo.device }}</div>
                <div>Model : {{ appInfo.model }}</div>
              </div>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isShowAppInfo = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";
import pdfMake from 'pdfmake'
import pdfFonts from '@/assets/custom-fonts.js'
import axios from "axios";
import moment from 'moment'

export default {
  name: "order",
  data() {
    return {
      loading: false,
      // search: "",
      branchId: "",
      orderId: "",
      customerName: "",
      stores:[],
      isShowPaymentLog: false,
      isShowAppInfo: false,
      branchSelect: null,

      date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2: false,

      mDataArray: [],
      paymentLog: [],

      order: {},
      shipping: {},
      billingInfo: {},
      picking: {},
      requestTaxInvoice: "",
      payment: {},
      appInfo: {},

      headers: [
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action",
        },
        { text: "OrderId", value: "orderId" },
        { text: "Branch", value: "branchId" },
        { text: "OrderSource", value: "orderSource" },
        { text: "Customer", value: "firstName" },
        // { text: "LastName", value: "lastName" },
        // { text: "Phone", value: "phoneNo" },
        { text: "ShippingType", value: "shippingType" },
        { text: "Delivery", value: "deliveryTime" },
        { text: "GrandTotal", value: "grandTotal", align: "right" },
        // { text: "PayDatetime", value: "paymentDateTime" },
        { text: "CreateTime", value: "creationTime" },
        {
          text: "IsPaid",
          align: "left",
          // sortable: false,
          value: "isPaid",
        },
        {
          text: "Logs",
          align: "left",
          sortable: false,
          value: "paymentLog",
        },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      try {
        this.loading = true;
        this.date1 = moment(this.date1).subtract(1, 'days').format('YYYY-MM-DD');
        // this.date1 = moment(this.date1).subtract(2, 'days').format('YYYY-MM-DD');
        let filter = { fileName: "online_stores.json" };
        let result = await api.getS3Json(filter);
        this.stores = result.data;

        if (this.userBrcode != "") {
          let mStore = this.stores.find(d => d.storeCode === this.userBrcode);
          if (mStore) {
            this.branchSelect = mStore;
          }
        }

        await this.loadData();

        setInterval(this.searchClick, 300000);
        
        // } catch (error) {
      } finally {
        this.loading = false;
      }
  },
  methods: {
    getRowClass(item) {
      let s = item?.shippingType ?? "";
      if (s.toLowerCase().indexOf("express") == -1)
        return ""
      else
        return "row_express";
    },
    showAppInfo(item) {
      this.appInfo = item?.appInfo ?? {};
      this.isShowAppInfo = true;
    },
    isShowRef(orderId) {
      return orderId.toString().toUpperCase().substr(0,1) == 'L';
    },
    numberFormat(value, decimal) {
      return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
    },
    // print order detail
    // async getBase64ImageFromURL(url) {
    //   return new Promise((resolve, reject) => {
    //     var img = new Image();
    //     // img.setAttribute("crossOrigin", "anonymous");
    //     img.onload = () => {
    //       var canvas = document.createElement("canvas");
    //       canvas.width = img.width;
    //       canvas.height = img.height;
    //       var ctx = canvas.getContext("2d");
    //       ctx.drawImage(img, 0, 0);
    //       // var dataURL = canvas.toDataURL("image/png");
    //       var dataURL = canvas.toDataURL("image/jpeg");
    //       resolve(dataURL);
    //     };
    //     img.onerror = error => {
    //       reject(error);
    //     };
    //     img.src = url;
    //   });
    //   // console.log("--- image ---", result);
    //   // return result;
    // },
    // fetchImage(url) {
    //   return fetch(url)
    //     .then((response) => response.blob())
    //     .then(
    //       (blob) =>
    //         new Promise((resolve, reject) => {
    //           const reader = new FileReader();
    //           reader.onloadend = () => resolve(reader.result);
    //           reader.onerror = reject;
    //           reader.readAsDataURL(blob);
    //         })
    //     );
    // },
    async toDataURL(url) {
      return await axios.get(url,
        // formData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json' 
          }
        }
        ).then(function(){
          // let url = presignUrls.url + presignUrls.fields.key;
          // product_attribute_images.push(url);
          console.log('-- SUCCESS!! --');
        })
        .catch(function(){
          console.log('-- FAILURE!! --');
        });
          // var xhr = new XMLHttpRequest()
          // xhr.onload = function () {
          //   var reader = new FileReader()
          //   reader.onloadend = function () {
          //     callback(reader.result)
          //   }
          //   window.alert('after reader')
          //   reader.readAsDataURL(xhr.response)
          // }
          // xhr.open('GET', url)
          // xhr.responseType = 'blob'
          // xhr.send()
    },
    // callbackGetImage(value) {
    //   console.log("--- image ---", value);   
    // },
    async getImageBase84(url) {
      let result = await axios.get(
        url, 
        { 
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json' 
          },
          responseType: 'arraybuffer' 
        }).then(res => {
        ;`data:${res.headers['content-type']};base64,${Buffer.from(String.fromCharCode(...new Uint8Array(res.data)), 'binary')
          .toString('base64')}`
      });
      // let result = this.toDataURL(url);
      console.log("--- image ---", result); 
      return result;
    },

    // convertImgToBase64(url, callback, outputFormat) {
    //   var canvas = document.createElement('CANVAS');
    //   var ctx = canvas.getContext('2d');
    //   var img = new Image;
    //   // img.crossOrigin = 'Anonymous';
    //   img.onload = function(){
    //     canvas.height = img.height;
    //     canvas.width = img.width;
    //       ctx.drawImage(img,0,0);
    //       var dataURL = canvas.toDataURL(outputFormat || 'image/png');
    //       callback.call(this, dataURL);
    //         // Clean up
    //       canvas = null; 
    //   };
    //   img.src = url;
    // },
    getBody() {
      var bodys = [];
      bodys.push([
        // { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
      ]);
      bodys.push([
        { lineHeight: 0.8, colSpan: 7, text: 'Order : #' + this.order?.orderId, border: [false, false, false, false], style: 'header' },
      ]);
      bodys.push([
        // { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
        { lineHeight: 0.8, text: '', border: [false, false, false, false], style: 'header' },
      ]);
      bodys.push([
        // { lineHeight: 0.8, text: 'Image', border: [true, true, true, true], style: 'header2' },
        { lineHeight: 0.8, text: 'Category', border: [true, true, true, true], style: 'header2' },
        { lineHeight: 0.8, text: 'Bar Code', border: [true, true, true, true], style: 'header2' },
        { lineHeight: 0.8, text: 'Pr Code', border: [true, true, true, true], style: 'header2' },
        { lineHeight: 0.8, text: 'Plu No', border: [true, true, true, true], style: 'header2'  },
        { lineHeight: 0.8, text: 'Pr Name', border: [true, true, true, true], style: 'header2'  },
        { lineHeight: 0.8, text: 'Order Qty', border: [true, true, true, true], style: 'header2', alignment: 'right'  },
        { lineHeight: 0.8, text: 'Pick Qty', border: [true, true, true, true], style: 'header2', alignment: 'right'  },
      ]);
      this.order?.productList.forEach(item => {
        var body = [
          // { 
          //   image: `data:image/jpeg;base64,${this.getImageBase84(item.image)}`,
          //   width: 50,
			    //   height: 50,
          //   // fit: [100, 100],
          //   // border: [true, true, true, true] 
          // }, 
          { lineHeight: 0.8, text: item.online_category_l1_en.toString() ?? '', border: [true, true, true, true], style: 'item' }, 
          { lineHeight: 0.8, text: item.barcode.toString() ?? '', border: [true, true, true, true], style: 'item' }, 
          { lineHeight: 0.8, text: item.cprcode.toString() ?? '', border: [true, true, true, true], style: 'item' }, 
          { lineHeight: 0.8, text: item.plu_no.toString() ?? '', border: [true, true, true, true], style: 'item' }, 
          { lineHeight: 0.8, text: item.productName.toString() ?? '', border: [true, true, true, true], style: 'item' }, 
          { lineHeight: 0.8, text: item.quantity.toString() ?? '', border: [true, true, true, true], style: 'item', alignment: 'right' }, 
          { lineHeight: 0.8, text: '', border: [true, true, true, true], style: 'item', alignment: 'right' }, 
        ];
        bodys.push(body);
      });
      return bodys;
    },
    getContents() {
      var contents = [];
      let pageCount = 1;
      for (let page = 1; page <= pageCount; page++) {
        var content = {
          style: 'table',
          table: {
            headerRows: 0,
            // widths: [80, 100, 100, 100, 100, '*', 70, 70],
            widths: [120, 100, 100, 100, '*', 70, 70],
            body: this.getBody(),
          },
          // layout: 'noBorders',
          // pageBreak: "after" 
        };
        if (page < this.qtySticker) {
          content['pageBreak'] = 'after';
        }
        contents.push(content);
      }
      return contents;
    },
    printPdf() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
          bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
          italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
          bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
        },
        // Kanit Font
        Kanit: {
          normal: 'Kanit-Regular.ttf',
          bold: 'Kanit-Medium.ttf',
          italics: 'Kanit-Italic.ttf',
          bolditalics: 'Kanit-MediumItalic.ttf'          
        }
      }
      const docDefinition = {
        // pageSize: {
        //   width: 199,
        //   height: 256 //'auto'
        // },
        pageSize: 'A4',
        pageOrientation: 'landscape',
        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 20, 20, 20, 20 ],

        content: this.getContents(),
        styles: {
          header: {
            fontSize: 15,
            bold: true,
            alignment: 'left'
          },
          header2: {
            fontSize: 12,
            bold: true,
            alignment: 'left'
          },
          item: {
            fontSize: 10,
            bold: false,
            alignment: 'left'
          },
          table: {
            // border: true
            // margin: [0, 5, 0, 15]
          },
        },

        defaultStyle: {
          font: 'Kanit',
          lineHeight: 0.5,
        }
      }
      // pdfMake.createPdf(docDefinition).open({}, window)
      pdfMake.createPdf(docDefinition).open();
    },
    async printOrder(item) {
      try {
          if (item.orderId == null || item.orderId == "") {
              alert(`Order id ${item.orderId} not found`);
              return;
          }
          // this.loading = true;
          let data = { orderId: item.orderId }
          let result = await api.getOrderById(data);
          // console.log("--- result ---", result);
          this.order = result?.data;
          // console.log("--- result ---", this.order);
          if (this.order) {
              this.payment = this.order?.payment;
              this.shipping = this.order?.shipping;
              this.picking = this.order?.picking;
              this.printPdf();
              return;
              // if (this.picking && ((this.picking["pickingStatus"] ?? "") == "Finish"))  {
              //   this.billingInfo = this.order?.billingInfo;
              //   if (this.billingInfo != null && (this.billingInfo["requestTaxInvoice"] ?? false) == true)
              //       this.requestTaxInvoice = "XXX";
              //   else
              //       this.requestTaxInvoice = "";
              //   let brcode = this.userBrcode;
              //   // console.log("--- branchId ---", brcode, this.order["branchId"]);
              //   if ((brcode == "") || (this.order["branchId"] == brcode)) {
              //     this.printPdf();
              //     return;
              //   }
              // };
          }
          alert(`Order id ${item.orderId} not found`);
      } catch (error) {
          alert(error);
      }
      finally {
          // this.loading = false;
      }
    },

    async showPaymentLog(item) {
      if (item.paymentLog != null && item.paymentLog.length>0) {
        this.paymentLog = item.paymentLog;
      }
      else {
        let filter = { orderId: item.orderId };
        // console.log("--- filter ---", filter);
        let result = await api.getPaymentLog(filter);
        // console.log("--- payment log ---", result.data);
        this.paymentLog = result.data;
        let index = this.mDataArray.indexOf(item);
        item['paymentLog'] = result.data;
        this.$set(this.mDataArray, index, item);
      };
      this.isShowPaymentLog = true;
    },
    async searchClick() {
      try {
        this.loading = true;
        await this.loadData();
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      this.$router.push(`/order/edit/${item.orderId}`);
    },
    // newDocument() {
    //   this.$router.push("/order/edit/0");
    // },
    async loadData() {
      // try {
      //   this.loading = true;
        if (this.branchSelect != null)
          this.branchId = this.branchSelect.storeCode;
        else
          this.branchId = "";

        let startDate = this.date1 == null || this.date1 == "" ? "" : moment(this.date1).format('YYYY-MM-DD');
        let endDate = this.date2 == null || this.date2 == "" ? "" : moment(this.date2).format('YYYY-MM-DD');

        let filter = { orderId: this.orderId, branchId: this.branchId,  startDate: startDate, endDate: endDate, customerName: this.customerName };
        // console.log("--- filter ---", filter);
        let result = await api.getOrderList(filter);
        // console.log("--- order list ---", result.data);
        this.mDataArray = result.data;
        // } catch (error) {
      // } finally {
      //   this.loading = false;
      // }
    },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    userGroup() {
      return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    },
    userType() {
      return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    },
    userBrcode() {
      return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    }
  }
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table_payment_log {
  height: 300px;
  font-size: 13px;
  overflow: auto;
}

.row_express {
  background-color: #FC0FC0;
  color: #ffffff;
}
</style>