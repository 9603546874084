<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="80"
          :width="8"
          color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-card class="mx-10  pa-5" outlined>
            <v-form @submit.prevent="submit">
              <v-row>
                <!-- Left -->
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="group.groupId"
                    label="Group Id"
                    disabled
                  ></v-text-field>
                </v-col>
                <!-- Right -->
                <v-col cols="6">
                  <v-checkbox
                    v-model="enabled"
                    label="Enabled"
                  ></v-checkbox>
                </v-col>
              </v-row>
  
              <v-row>
                <!-- Left -->
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="group.groupName"
                    label="Group Name"
                    required
                  ></v-text-field>
  
                  <v-textarea
                    outlined
                    v-model="group.metadata.description"
                    label="Description"
                  ></v-textarea>
                </v-col>
  
                <!-- Right -->
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="group.metadata.slotIndex"
                    type="number"
                    label="Slot Index"
                    required
                  ></v-text-field>
  
                  <v-datetime-picker
                    v-model="startDate"
                    datetime="String"
                    label="Start Datetime"
                  ></v-datetime-picker>
  
                  <v-datetime-picker
                    v-model="endDate"
                    datetime="String"
                    label="End Datetime"
                  ></v-datetime-picker>
                </v-col>
              </v-row>
  
              <!-- <v-divider></v-divider>
              <br /> -->
  
              <!-- <div v-if="!imageFile"> -->
                <!-- <h2>Select an image (เลือกได้ 1 รูป)</h2>
                <input type="file" accept=".png,.jpg,.jpeg,.bmp" @change="onFileSelected"> -->
              <!-- </div> -->
              <!-- <div v-else> -->
                <!-- <br />
                <v-img
                  class="ma-5"
                  contain
                  :eager="true"
                  :src="imageFile"
                  lazy-src="@/assets/noimage.png"
                  width="100%"
                  max-height="300"
                ></v-img> -->
                <!-- <button @click="removeImage">Remove image</button> -->
              <!-- </div> -->
  
              <v-divider></v-divider>
              <br />
              <template>
                <v-row class="ma-0">
                  <h1>Products</h1>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-btn color="primary" @click.stop="showProductSearch=true">
                    <v-icon left>add</v-icon>
                    Add Product
                  </v-btn>  
                  <ProductSearch v-model="showProductSearch" :onSelect="onSelect" />
  
                  <v-btn color="primary" @click="clearProduct">
                    <v-icon left>delete</v-icon>
                    Clear All
                  </v-btn>
  
                  <v-spacer></v-spacer>
                  <v-tooltip bottom color="pink darken-4">
                    <template v-slot:activator="{ on, attrs }">
                      <h3 class="mr-3" v-bind="attrs" v-on="on">Import Excel (XLSX)</h3>
                    </template>
                    <span>ใส่รหัสสินค้า 1 column ต้องมี column header ชื่อ prcode</span>
                    </v-tooltip>
                  <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
  
                  <v-spacer></v-spacer>
                </v-row>
              </template>
  
              <br />
              <template>
                <div class="row">
                  <div class="col-12">
                    <table class="table table-striped" width="100%">
                      <thead class="thead-dark" align-center>
                        <tr>
                          <th scope="col" style="text-align: left">Image</th>
                          <th scope="col" style="text-align: left">(C) Pr Code</th>
                          <th scope="col" style="text-align: left">Pr Name</th>
                          <th scope="col" style="text-align: left">Stock Qty</th>
                          <th scope="col" style="text-align: left">Online Qty</th>
                          <th scope="col" style="text-align: left">Online</th>
                          <!-- <th scope="col" style="text-align: left">Enabled</th> -->
                          <th scope="col" style="text-align: left">Action</th>
                        </tr>
                      </thead>
                      <draggable v-model="mProductArray" tag="tbody">
                        <tr v-for="item in mProductArray" :key="item.cprcode">
                          <td>
                              <v-img
                                contain
                                class="ma-2"
                                :eager="true"
                                :src="item.image"
                                lazy-src="@/assets/noimage.png"
                                aspect-ratio="1"
                                min-width="80"
                                max-width="80"
                                max-height="80"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular v-show="loading"
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                          </td>
                          <td>{{ item.cprcode }}</td>
                          <td>{{ item.hema_name_en }}</td>
                          <td>{{ item.cf_qty }}</td>
                          <td>{{ item.stockQty }}</td>
                          <td>
                            <v-checkbox
                              readonly
                              v-model="item.master_online"
                            ></v-checkbox>
                          </td>
                          <!-- <td>
                            <v-checkbox
                              readonly
                              v-model="item.enabled"
                            ></v-checkbox>
                          </td> -->
                          <td>
                            <v-icon @click="removeItem(item)">
                              delete
                            </v-icon>
                          </td>
                        </tr>
                      </draggable>
                    </table>
                  </div>
                </div>
              </template>
  
              <!-- <br />
              <v-divider></v-divider> -->
              <br />
              <v-row>
                <v-spacer></v-spacer>
                <v-btn class="mr-4" @click="cancel">
                  Cancel
                </v-btn>
  
                <v-btn color="primary" type="submit">
                  Confirm
                </v-btn>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import XLSX from 'xlsx';
  import Vue from 'vue'
  import moment from 'moment'
  import DatetimePicker from 'vuetify-datetime-picker'
  import api from "@/services/lambdaApi";
  import draggable from 'vuedraggable'
  import ProductSearch from "@/components/product/ProductSearch"
  
  Vue.use(DatetimePicker)
  
  export default {
    name: "seasonal2-edit",
    components:{
      draggable,
      ProductSearch
    },
    data: () => ({
      loading: false,
      enabled: false,
      startDate: "",
      endDate: "",
      group: {
        groupId: 0,
        groupName: "",
        enabled: false,
        metadata: {
          bannerUrl: "",
          imageUrl: [],
          bannerType: "",
          categorylv1Id: 0,
          categorylv1Name: "",
          description: "",
          startDate: "",
          endDate: "",
          slotIndex: 0
        },
        subGroupList: [],
        parentGroupList: [],
        productList: []
      },
      imageFile: null, //string
      imageUpload: null, //image
      showProductSearch: false,
      mProductArray: [],
      // headers: [
      //   {
      //     text: "",
      //     align: "left",
      //     sortable: false
      //   },
      //   {
      //     text: "Image",
      //     align: "left",
      //     sortable: false,
      //     value: "image"
      //   },
      //   {
      //     text: "(C) Pr Ccode",
      //     align: "left",
      //     sortable: false,
      //     value: "cprcode"
      //   },
      //   {
      //     text: "Pr Name",
      //     align: "left",
      //     sortable: false,
      //     value: "hema_name_en"
      //   },
      //   {
      //     text: "Action",
      //     align: "left",
      //     sortable: false,
      //     value: "action"
      //   },
      // ]
    }),
    async mounted() {
      try {
        this.loading = true;
        let groupId = parseInt(this.$route.params.id ?? 0);
        if (groupId != 0) {
          let data = { groupId: groupId }
          let result = await api.getGroupById(data);
          this.enabled = result.data.enabled ?? false;
          this.startDate = result.data.metadata.startDate;
          this.endDate = result.data.metadata.endDate;
          this.group = result.data;
          this.mProductArray = this.group.productList;
          this.imageFile = result.data.metadata.imageUrl[0] ?? "";
          // console.log("--- load ---", JSON.stringify(this.group));
        }
      // } catch (error) {
      }
      finally {
        this.loading = false;
      } 
    },
  
    methods: {
      clearProduct() {
        this.mProductArray = []
      },
      onFileImportSelected(e) {
        try {
          this.loading = true;
          var files = e.target.files || e.dataTransfer.files;
          if (files.length == 0)
            return;
          this.previewFiles(files[0]);
        // } catch (error) {
        }
        finally {
          this.$refs.importFile.value = "";
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
      },
      previewFiles(file) { // detect file event
          var reader = new FileReader();
          var vm = this;
          reader.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, { type: "array" });
              let sheetName = workbook.SheetNames[0];
              /* DO SOMETHING WITH workbook HERE */
              // console.log(workbook);
              let worksheet = workbook.Sheets[sheetName];
              // console.log(XLSX.utils.sheet_to_json(worksheet));
              let prcodes = XLSX.utils.sheet_to_json(worksheet);
              // console.log('-- prcodes --', prcodes);
              let prcodeList = [];
              prcodes.forEach(item => {
                prcodeList.push(parseInt(item.prcode ?? 0));
              });
              if (prcodeList && prcodeList.length>0) {
                // console.log('-- prcodeList --', prcodeList);
                let result = await api.getProductByPrcodeList(prcodeList);
                let productList = result.data;
                // console.log('-- productList --', productList);
                productList.forEach(product => {
                  // console.log('-- product --', product);
                  vm.onSelect(product);
                });
              }
          };
          reader.readAsArrayBuffer(file);
      },
      onSelect(item) {
        //console.log(JSON.stringify(item));
        if (item != null) {
          let mProduct = this.mProductArray.find(d => d.cprcode === item.cprcode);
          if (!mProduct) {
            let product = {
              "cprcode": item.cprcode,
              "hema_name_en": item.hema_name_en,
              "image": item.image,
              "master_online": item.master_online,
              // "enabled": item.enabled
            };
            this.mProductArray.push(product);
          }
        }
      },
      removeItem: function (item) {
        let mProduct = this.mProductArray.find(d => d.cprcode === item.cprcode);
        if (mProduct) {
          let index = this.mProductArray.indexOf(mProduct);
          if (index >= 0) {
            this.mProductArray.splice(index, 1)
          }
        }
      },
      cancel() {
        this.$router.back();
      },
      onFileSelected(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.createImage(files[0]);
      },
      createImage(file) {
        // var image = new Image();
        var reader = new FileReader();
        var vm = this;
  
        reader.onload = (e) => {
          vm.imageFile = e.target.result;
          vm.imageUpload = file;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        this.imageFile = '';
        this.imageUpload = null;
      },
      async submit() {
        try {
          this.loading = true;
          let data = this.group;
          data.enabled = this.enabled;
          data.metadata.show = false;
          data.metadata.startDate = this.startDate == null || this.startDate == "" ? "" : moment(this.startDate).format('YYYY-MM-DD HH:mm');
          data.metadata.endDate = this.endDate == null || this.endDate == "" ? "" : moment(this.endDate).format('YYYY-MM-DD HH:mm');
          data.parentGroupList = [7000000001]
          data.metadata.imageUrl = [];
          data.metadata.slotIndex = parseFloat(data.metadata.slotIndex);
          let productList = [];
          let i = 0;
          this.mProductArray.forEach(item => {
            productList.push({ cprcode: parseInt(item.cprcode ?? 0), rankingScore: ++i });
          });
          data.productList = productList;
          // console.log('request', JSON.stringify(data));
          let result;
          if (data.groupId == 0) {
            result = await api.createGroup(data);
          }
          else {
            result = await api.updateGroup(data);
          }
          // console.log('result', JSON.stringify(result));
          // let response = result.data;
          // console.log('response', JSON.stringify(response));
  
          // if (response.statusCode == 200)
          //   await this.upLoadImage(response.groupId);
  
          this.$router.back();
        // } catch (error) {
        }
        finally {
          this.loading = false;
        }
      },
      // async upLoadImage(groupId) {
      //   if (this.imageUpload != null) {
      //     var count = 0;
      //     let key = groupId.toString() + "/" + groupId.toString()+ "-" + (++count).toString()
      //     let data = { bucket: groupBucketName, key: key }
      //     let result = await api.presignUpPics(data);
      //     let presignUrls = result.data;
      //     if (presignUrls)
      //     {
      //       let formData = new FormData();
      //       formData.append("Content-Type", presignUrls.fields["Content-Type"]);
      //       formData.append("x-amz-security-token", presignUrls.fields["x-amz-security-token"]);
      //       formData.append("key", presignUrls.fields["key"]);
      //       formData.append("AWSAccessKeyId", presignUrls.fields["AWSAccessKeyId"]);
      //       formData.append("policy", presignUrls.fields["policy"]);
      //       formData.append("signature", presignUrls.fields["signature"]);
      //       formData.append('file', this.imageUpload);
      //       axios.post(presignUrls.url,
      //         formData,
      //         {
      //           headers: {
      //             'Content-Type': 'multipart/form-data'
      //           }
      //         }
      //         ).then(function(){
      //           console.log('Upload image SUCCESS!!');
      //         })
      //         .catch(function(){
      //           console.log('Upload image FAILURE!!');
      //         });
      //     }
      //   }
      // },
    }
  };
  </script>
  
  <style scoped>
  table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
     background-color: rgba(0, 0, 0, .05);
   }
  </style>