<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-btn @click="$router.back()" color="primary">
      <v-icon left>arrow_back</v-icon>
      <span>Back</span>
    </v-btn>
    
    <v-row>
      <v-col lg="6" md="6" sm="12" cols="12">
        <v-menu
          hide-details
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Date"
              prepend-icon="mdi-calendar"
              :disabled="userBrcode != ''"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="$refs.menu.save(date)"
            v-model="date"
            no-title
            scrollable>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="6" md="6" sm="12" cols="12">
        <v-btn class="mt-3" @click="loadData" color="primary">
        <v-icon left>search</v-icon>
        <span>Query</span>
        </v-btn>
      </v-col>
    </v-row>
    <template v-for="branch in branchs">
        <h2 class="header mt-3">{{ branch.storeCode }} - {{ branch.storeName }}</h2>
        <v-row>
            <v-col lg="3" md="6" sm="12" cols="12">
                <StockCard
                title="Revenue"
                avatar_ic="mdi-cart-outline"
                avatar_bg="#00a65a"
                :total_value="numberFormat(branch.revenue_total, 2)"
                :online_value="numberFormat(branch.revenue_online, 2)"
                :telesale_value="numberFormat(branch.revenue_telesale, 2)"
                :lazada_value="numberFormat(branch.revenue_lazada, 2)"
                :shopee_value="numberFormat(branch.revenue_shopee, 2)"
                />
            </v-col>

            <v-col lg="3" md="6" sm="12" cols="12">
                <StockCard
                title="Shipping"
                avatar_ic="directions_car"
                avatar_bg="#f39c12"
                :total_value="numberFormat(branch.shipping_total, 2)"
                :online_value="numberFormat(branch.shipping_online, 2)"
                :telesale_value="numberFormat(branch.shipping_telesale, 2)"
                :lazada_value="numberFormat(branch.shipping_lazada, 2)"
                :shopee_value="numberFormat(branch.shipping_shopee, 2)"
                />
            </v-col>

            <v-col lg="3" md="6" sm="12" cols="12">
                <StockCard
                title="Ticket"
                avatar_ic="auto_awesome_motion"
                avatar_bg="#dd4b39"
                :total_value="numberFormat(branch.ticket_total, 0)"
                :online_value="numberFormat(branch.ticket_online, 0)"
                :telesale_value="numberFormat(branch.ticket_telesale, 0)"
                :lazada_value="numberFormat(branch.ticket_lazada, 0)"
                :shopee_value="numberFormat(branch.ticket_shopee, 0)"
                />
            </v-col>

            <v-col lg="3" md="6" sm="12" cols="12">
                <StockCard
                title="Avg Spending"
                avatar_ic="mdi-gift-outline"
                avatar_bg="#00c0ef"
                :total_value="numberFormat(branch.averageSpending_total, 2)"
                :online_value="numberFormat(branch.averageSpending_online, 2)"
                :telesale_value="numberFormat(branch.averageSpending_telesale, 2)"
                :lazada_value="numberFormat(branch.averageSpending_lazada, 2)"
                :shopee_value="numberFormat(branch.averageSpending_shopee, 2)"
                />
            </v-col>
        </v-row>
        <template v-if="branch.storeCode == '0000'">
          <v-card>
            <BarChart style="height: 400px;" :chartData="datacollection" />
          </v-card>
          <br />
        </template>
    </template>
    <br />
  </v-container>
</template>

<script>
  import api from "@/services/lambdaApi";
  import BarChart from '@/components/charts/BarChart'
  import StockCard from "@/components/cards/Summary";
  import moment from 'moment'

  export default {
    name: "dailysummary",
    components: {
      BarChart,
      StockCard
    },
    data () {
      return {
        loading: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        branchs: [],
        mDataArray: [],

        //BarChart
        mAmountArray: [],
        mTicketArray: [],
        mTeleSaleAmountArray: [],
        mTeleSaleTicketArray: [],
        mLazadaAmountArray: [],
        mLazadaTicketArray: [],
        mShopeeAmountArray: [],
        mShopeeTicketArray: [],
        mSumAmountArray: [],
        mSumTicketArray: [],
        datacollection: {},
      }
    },
    async mounted () {
      try {
        this.loading = true;
        let filter = { fileName: "online_stores.json" };
        let result = await api.getS3Json(filter);
        this.branchs = result.data;
        this.branchs.unshift({ "storeCode": "0000", "storeName": "Summary" })
        await this.loadData();
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    methods: {
        async mapData() {
            if (this.branchs) {
              var tickets = []
              var amounts = []
              var telesale_tickets = []
              var telesale_amounts = []
              var lazada_tickets = []
              var lazada_amounts = []
              var shopee_tickets = []
              var shopee_amounts = []
              var sum_tickets = []
              var sum_amounts = []
              this.branchs.forEach(item => {
                  if (item.storeCode != "0000") {
                    tickets.push(item["ticket_online"] ?? 0);
                    amounts.push(item["revenue_online"] ?? 0);
                    telesale_tickets.push(item["ticket_telesale"] ?? 0);
                    telesale_amounts.push(item["revenue_telesale"] ?? 0);
                    lazada_tickets.push(item["ticket_lazada"] ?? 0);
                    lazada_amounts.push(item["revenue_lazada"] ?? 0);
                    shopee_tickets.push(item["ticket_shopee"] ?? 0);
                    shopee_amounts.push(item["revenue_shopee"] ?? 0);
                    sum_tickets.push((item["ticket_online"] ?? 0) + (item["ticket_telesale"] ?? 0) + (item["ticket_lazada"] ?? 0) + (item["ticket_shopee"] ?? 0));
                    sum_amounts.push((item["revenue_online"] ?? 0) + (item["revenue_telesale"] ?? 0) + (item["revenue_lazada"] ?? 0) + (item["revenue_shopee"] ?? 0));
                  };
              });
              this.mTicketArray = tickets;
              this.mAmountArray = amounts;
              this.mTeleSaleTicketArray = telesale_tickets;
              this.mTeleSaleAmountArray = telesale_amounts;
              this.mLazadaTicketArray = lazada_tickets;
              this.mLazadaAmountArray = lazada_amounts;
              this.mShopeeTicketArray = shopee_tickets;
              this.mShopeeAmountArray = shopee_amounts;
              this.mSumTicketArray = sum_tickets;
              this.mSumAmountArray = sum_amounts;
            }
        },
        numberFormat(value, decimal) {
          return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
        },
        async loadData () {
            try {
                this.loading = true;
                let paymentDate = this.date == null || this.date == "" ? "" : moment(this.date).format('YYYY-MM-DD');
                let data = { action: "dailysummary", paymentDateStamp: paymentDate };
                let result = await api.getSaleReport(data);
                // console.log('-- salereport --', result.data)
                this.mDataArray = result.data;

                this.branchs.forEach(branch => {
                    branch["revenue_total"] = 0;
                    branch["revenue_online"] = 0;
                    branch["revenue_telesale"] = 0;
                    branch["revenue_lazada"] = 0;
                    branch["revenue_shopee"] = 0;

                    branch["shipping_total"] = 0;
                    branch["shipping_online"] = 0;
                    branch["shipping_telesale"] = 0;
                    branch["shipping_lazada"] = 0;
                    branch["shipping_shopee"] = 0;

                    branch["ticket_total"] = 0;
                    branch["ticket_online"] = 0;
                    branch["ticket_telesale"] = 0;
                    branch["ticket_lazada"] = 0;
                    branch["ticket_shopee"] = 0;

                    branch["averageSpending_total"] = 0;
                    branch["averageSpending_online"] = 0;
                    branch["averageSpending_telesale"] = 0;
                    branch["averageSpending_lazada"] = 0;
                    branch["averageSpending_shopee"] = 0;
                });

                this.mDataArray.forEach(item => {
                    var mItem = this.branchs.find(d => d.storeCode === item.brcode);
                    if (mItem) {
                        mItem["revenue_total"] = item["total_amount"] ?? 0;
                        mItem["revenue_online"] = item["amounts"] ?? 0;
                        mItem["revenue_telesale"] = item["tele_amount"] ?? 0;
                        mItem["revenue_lazada"] = item["lazada_amount"] ?? 0;
                        mItem["revenue_shopee"] = item["shopee_amount"] ?? 0;

                        mItem["shipping_total"] = item["total_shipping"] ?? 0;
                        mItem["shipping_online"] = item["shippings"] ?? 0;
                        mItem["shipping_telesale"] = item["tele_shipping"] ?? 0;
                        mItem["shipping_lazada"] = item["lazada_shipping"] ?? 0;
                        mItem["shipping_shopee"] = item["shopee_shipping"] ?? 0;

                        mItem["ticket_total"] = item["total_ticket"] ?? 0;
                        mItem["ticket_online"] = item["tickets"] ?? 0;
                        mItem["ticket_telesale"] = item["tele_ticket"] ?? 0;
                        mItem["ticket_lazada"] = item["lazada_ticket"] ?? 0;
                        mItem["ticket_shopee"] = item["shopee_ticket"] ?? 0;

                        mItem["averageSpending_total"] = item["total_averageSpending"] ?? 0;
                        mItem["averageSpending_online"] = item["averageSpending"] ?? 0;
                        mItem["averageSpending_telesale"] = item["tele_averageSpending"] ?? 0;
                        mItem["averageSpending_lazada"] = item["lazada_averageSpending"] ?? 0;
                        mItem["averageSpending_shopee"] = item["shopee_averageSpending"] ?? 0;
                    }
                });
                // console.log('-- mDataArray --', this.mDataArray)
                await this.mapData();
                await this.fillData();
            } catch (error) {
                alert(error);
            } finally {
                this.loading = false;
            }
        },
        //BarChart  
        async fillData () {
          let labels = [];
          this.branchs.forEach(item => {
              if (item.storeCode != "0000") {
                labels.push(item.storeName);
              };
          });
          this.datacollection = {
              //Data to be represented on x-axis
              labels: labels,
              datasets: [
                {
                  type: 'bar',
                  label: 'Online Revenue',
                  pointBackgroundColor: 'white',
                  borderWidth: 2,
                  pointBorderColor: '#249EBF',
                  data: this.getRevenues(),
                  borderColor: 'transparent',
                  // borderColor: [
                  //   '#43A047'
                  // ],
                  yAxisID: '0',
                  backgroundColor: '#3D5B96',
                  order: 6,
                },
                {
                  type: 'bar',
                  label: 'TeleSale Revenue',
                  pointBackgroundColor: 'white',
                  borderWidth: 2,
                  pointBorderColor: '#249EBF',
                  data: this.getTeleSaleRevenues(),
                  borderColor: 'transparent',
                  // borderColor: [
                  //   '#FF9800'
                  // ],
                  yAxisID: '0',
                  backgroundColor: '#f87979',
                  order: 7,
                },
                {
                  type: 'bar',
                  label: 'Lazada Revenue',
                  pointBackgroundColor: 'white',
                  borderWidth: 2,
                  pointBorderColor: '#249EBF',
                  data: this.getLazadaRevenues(),
                  borderColor: 'transparent',
                  // borderColor: [
                  //   '#FF9800'
                  // ],
                  yAxisID: '0',
                  backgroundColor: '#00BCD4',
                  order: 8,
                  // hidden: true,
                },
                {
                  type: 'bar',
                  label: 'Shopee Revenue',
                  pointBackgroundColor: 'white',
                  borderWidth: 2,
                  pointBorderColor: '#FC0FC0',
                  data: this.getShopeeRevenues(),
                  borderColor: 'transparent',
                  // borderColor: [
                  //   '#FF9800'
                  // ],
                  yAxisID: '0',
                  backgroundColor: '#FC0FC0',
                  order: 9,
                  // hidden: true,
                },
                {
                  type: 'line',
                  label: 'Online Ticket',
                  pointBackgroundColor: 'white',
                  borderWidth: 2,
                  pointBorderColor: '#249EBF',
                  data: this.getTickets(),
                  borderColor: '#03A9F4',
                  // borderColor: [
                  //   '#3F51B5'
                  // ],
                  yAxisID: '1',
                  backgroundColor: [ 'transparent' ],
                  order: 1,
                },
                {
                  type: 'line',
                  label: 'TeleSale Ticket',
                  pointBackgroundColor: 'white',
                  borderWidth: 2,
                  pointBorderColor: '#ff0000',
                  data: this.getTeleSaleTickets(),
                  borderColor: '#ff0000',
                  // borderColor: [
                  //   '#ff0000'
                  // ],
                  yAxisID: '1',
                  backgroundColor: [ 'transparent' ],
                  order: 2,
                },
                {
                  type: 'line',
                  label: 'Lazada Ticket',
                  pointBackgroundColor: 'white',
                  borderWidth: 2,
                  pointBorderColor: '#FFC107',
                  data: this.getLazadaTickets(),
                  borderColor: '#FFC107',
                  // borderColor: [
                  //   '#ff0000'
                  // ],
                  yAxisID: '1',
                  backgroundColor: [ 'transparent' ],
                  order: 3,
                  // hidden: true,
                },
                {
                  type: 'line',
                  label: 'Shopee Ticket',
                  pointBackgroundColor: 'white',
                  borderWidth: 2,
                  pointBorderColor: '#CDDC39',
                  data: this.getShopeeTickets(),
                  borderColor: '#CDDC39',
                  // borderColor: [
                  //   '#ff0000'
                  // ],
                  yAxisID: '1',
                  backgroundColor: [ 'transparent' ],
                  order: 4,
                  // hidden: true,
                },
                {
                  type: 'line',
                  label: 'Total Ticket',
                  pointBackgroundColor: 'white',
                  borderWidth: 2,
                  pointBorderColor: '#9C27B0',
                  data: this.getSumTickets(),
                  borderColor: '#9C27B0',
                  yAxisID: '1',
                  backgroundColor: [ 'transparent' ],
                  order: 5,
                  hidden: true,
                },
                {
                  type: 'bar',
                  label: 'Total Revenue',
                  pointBackgroundColor: 'white',
                  borderWidth: 2,
                  pointBorderColor: '#4CAF50',
                  data: this.getSumRevenues(),
                  borderColor: 'transparent',
                  // borderColor: [
                  //   '#FF9800'
                  // ],
                  yAxisID: '0',
                  backgroundColor: '#4CAF50',
                  order: 10,
                  stack: 'false',
                  hidden: true,
                },
              ]};
          },
          getRevenues() {
            return this.mAmountArray;
          },
          getTickets() {
            return this.mTicketArray;
          },
          getTeleSaleRevenues() {
            return this.mTeleSaleAmountArray;
          },
          getTeleSaleTickets() {
            return this.mTeleSaleTicketArray;
          },
          getLazadaRevenues() {
            return this.mLazadaAmountArray;
          },
          getLazadaTickets() {
            return this.mLazadaTicketArray;
          },
          getShopeeRevenues() {
            return this.mShopeeAmountArray;
          },
          getShopeeTickets() {
            return this.mShopeeTicketArray;
          },
          getSumRevenues() {
            return this.mSumAmountArray;
          },
          getSumTickets() {
            return this.mSumTicketArray;
          },
    },

    computed: {
      //   currentDate() {
      //     // return "2021-08-09";
      //     return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      //   },
      //   currentYear() {
      //     return this.currentDate.substr(0, 4);
      //   },
      userCode() {
        return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
      },
      userGroup() {
        return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
      },
      userType() {
        return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
      },
      userBrcode() {
        return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
      },
    }
  }
</script>

<style scoped>
.header {
  font-size: 20px;
  font-weight: bold;
  color: #B71C1C;
}
</style>