<template>
  <v-app> 
    <MainMenu v-if="$store.state.isLogged && !($route.meta.isPopup)"/>
    <Content v-else-if="$store.state.isLogged || $route.meta.isAlway"/>
    <!-- <Header v-if="$store.state.isLogged"/>
    <Menu v-if="$store.state.isLogged"/>
    <Content/> -->
    <GoTop/>
  </v-app>
</template>

<script>
// import Header from "@/components/core/Header"
// import Menu from "@/components/core/Menu"
import Content from "@/components/core/Content"
import MainMenu from "@/components/core/MainMenu"
import GoTop from "@/components/goTop/GoTop"

export default {
  name: "App",
  components:{
    MainMenu,
    // Header,
    // Menu,
    Content,
    GoTop
  },
  async mounted() {
    await this.$store.dispatch({ type: "restoreLogin" });
  },
};
</script>

<style></style>
