<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-alert
      v-model="show_success"
      dismissible
      outlined
      text
      type="success"
    >Update ข้อมูลเว็บ เรียบร้อยแล้ว</v-alert>
    <v-alert
      v-model="show_error"
      dismissible
      outlined
      text
      type="error"
    >Update ข้อมูลเว็บ ไม่สำเร็จ!!</v-alert>
    <v-alert
      v-model="show_warning"
      dismissible
      outlined
      text
      type="warning"
    >กรุณาเลือก Category ก่อน!!</v-alert>
    <!-- Table section -->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="groupId"
        :sort-desc="true"
        :items-per-page="5"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Seasonal</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-combobox 
                v-model="mCategorySelect"
                :items="mCategoryArray" 
                item-text="groupName" 
                item-value="groupId" 
                return-object
                label="Category"
                hide-details
            ></v-combobox>

            <!-- <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon> -->
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-icon class="mr-2" @click="newCategory">
              add
            </v-icon>
            <v-spacer></v-spacer>
            <v-btn @click="refreshGroup" color="green darken-2" dark class="mb-2">
              <v-icon left>refresh</v-icon>
              <span>Update Web</span>
            </v-btn>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn @click="newGroup" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>New Group</span>
            </v-btn>
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <!-- <td>
              <v-img
                contain
                class="ma-2"
                :eager="true"
                :src="item.metadata.imageUrl[0]"
                lazy-src="@/assets/noimage.png"
                aspect-ratio="1"
                min-width="80"
                max-width="80"
                max-height="80"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      v-show="loading"
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </td> -->
            <td>{{ item.groupId }}</td>
            <td>{{ item.groupName }}</td>
            <td>{{ item.metadata.slotIndex }}</td>
            <td>{{ item.metadata.startDate }}</td>
            <td>{{ item.metadata.endDate }}</td>
            <td>
              <v-checkbox readonly v-model="item.enabled"></v-checkbox>
            </td>
            <td>
              <v-icon class="mr-2" @click="editItem(item)">
                edit
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            max-width="300px"
          >
            <v-card>
              <v-card-title>New Category</v-card-title>
              <v-divider></v-divider>
                <v-text-field class="pa-5"
                  outlined
                  v-model="groupName"
                  hide-details
                ></v-text-field>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveCategory"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

    </v-card>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";

export default {
  name: "summer",
  data() {
    return {
      loading: false,
      //search: "",
      show_success: false,
      show_error: false,
      show_warning: false,
      dialog: false,
      enabled: true,
      mCategorySelect: null,
      mCategoryArray: [],
      mDataArray: [],
      groupName: "",
      group: {
        groupId: 0,
        groupName: "",
        enabled: false,
        metadata: {
          bannerUrl: "",
          imageUrl: [],
          description: "",
          startDate: "",
          endDate: "",
          slotIndex: 2
        },
        subGroupList: [],
        parentGroupList: [],
        productList: []
      },
      headers: [
        // {
        //   text: "Image",
        //   align: "left",
        //   sortable: false,
        //   value: "objectUrl",
        // },
        { text: "Group Id", value: "groupId" },
        { text: "Group Name", value: "groupName" },
        { text: "Slot Index", value: "metadata.slotIndex" },
        { text: "Start Datetime", value: "metadata.startDate" },
        { text: "End Datetime", value: "metadata.endDate" },
        {
          text: "Enabled",
          align: "left",
          // sortable: false,
          value: "enabled",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      try {
        this.loading = true;
        await this.loadCategory();
      } finally {
        this.loading = false;
      }
  },
  methods: {
    newCategory() {
      this.groupName = "";
      this.dialog = true;
    },
    async saveCategory() {
      try {
        this.loading = true;
        if (this.groupName.trim() == "") {
          return;
        }
        let data = this.group;
        data.groupName = this.groupName;
        data.enabled = true;
        data.parentGroupList = [5000000376];
        let result = await api.createGroup(data);
        let response = result.data;
        // console.log('-- response --', JSON.stringify(response));
        if (response.statusCode == 200)
          await this.loadCategory();
      // } catch (error) {
      }
      finally {
        this.dialog = false;
        this.loading = false;
      }
    },
    async searchClick() {
      await this.loadSubCategory();
    },
    editItem(item) {
      if (this.mCategorySelect == null || (this.mCategorySelect.groupId ?? 0) == 0) {
        this.show_warning = true;
        return;
      }
      this.$router.push(`/summer/edit/${item.groupId}/${this.mCategorySelect.groupId}`);
    },
    async refreshGroup() {
      try {
        this.loading = true;
        let response = await api.refreshGroup({});
        console.log("--- refreshGroup ---", response.data);
        if (response.data.statusCode == 200) {
          this.show_error = false;
          this.show_success = true;
          this.show_warning = false;
        } else {
          this.show_error = true;
          this.show_success = false;
          this.show_warning = false;
        }
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    newGroup() {
      if (this.mCategorySelect == null || (this.mCategorySelect.groupId ?? 0) == 0) {
        this.show_warning = true;
        return;
      }
      this.$router.push(`/summer/edit/0/${this.mCategorySelect.groupId}`);
    },
    async loadCategory() {
      let group = { groupId: 5000000376 }
      let category = await api.getGroupList(group);
      this.mCategoryArray = category.data;
    },
    async loadSubCategory() {
      try {
        this.loading = true;
        let group = { groupId: this.mCategorySelect.groupId }
        let category = await api.getGroupList(group);
        this.mDataArray = category.data;
        // console.log("--- sub category ---", category.data);
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>