<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="80"
          :width="8"
          color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-card>
        <v-data-table :headers="headers" :items="mDataArray" :items-per-page="15">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Product Meta</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                label="Pr Code"
                single-line
                hide-details
                @keyup.enter="searchClick"
              ></v-text-field>
              <v-icon class="mr-2" @click="searchClick">
                search
              </v-icon>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
  
          <template v-slot:item="{ item }">
            <tr>
              <td style="vertical-align: top;">
                <v-icon class="mr-2" @click="editItem(item)">
                  edit
                </v-icon>
              </td>
              <td style="vertical-align: top;">
                <v-img
                  contain
                  class="ma-2"
                  :eager="true"
                  :src="getImageUrl(item)"
                  lazy-src="@/assets/noimage.png"
                  aspect-ratio="1"
                  min-width="80"
                  max-width="80"
                  max-height="80">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center">
                      <v-progress-circular v-show="loading"
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </td>
              <td style="vertical-align: top;">{{ item.cprcode }}</td>
              <!-- <td>{{ item.iprcode }}</td> -->
              <td style="vertical-align: top;">{{ item.hema_name_en }}</td>
              <td style="vertical-align: top;">{{ item.meta_title2 }}</td>
              <td style="vertical-align: top;">{{ item.meta_keywords2 }}</td>
              <td style="vertical-align: top;">{{ item.meta_description2 }}</td>
              <td style="vertical-align: top;">
                <v-checkbox
                  readonly
                  v-model="item.master_online"
                ></v-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </template>
  
  <script>
  // import api from "@/services/lambdaApi";
  import { imageUrl } from "@/services/constants";
  import geoService from "@/services/geoService";
  
  export default {
    name: "geoproduct",
    data() {
      return {
        loading: false,
        search: "",
        mDataArray: [],
        headers: [
          {
            text: "Action",
            align: "left",
            sortable: false,
            value: "action"
          },
          {
            text: "Image",
            align: "left",
            sortable: false,
            value: "image"
          },
          { text: "Pr Code", value: "cprcode" },
          // { text: "(I) Pr Code", value: "iprcode" },
          { text: "Pr Name", value: "hema_name_en" },
          { text: "Meta Title", value: "meta_title2" },
          { text: "Meta Keywords", value: "meta_keywords2" },
          { text: "Meta Description", value: "meta_description2" },
          {
            text: "Online",
            align: "left",
            // sortable: false,
            value: "master_online"
          },
        ],
      };
    },
    // components: {
    // },
    async mounted() {
      //this.loadProducts();
    },
    methods: {
      getImageUrl(item) {
        return `${imageUrl}/${item.image}`;
      },
      searchClick() {
        this.loadProducts();
      },
      editItem(item) {
        this.$router.push(`/geocontent/productedit/${item.cprcode}`);
      },
      async loadProducts() {
        try {
          this.loading = true;
          let cprcode = parseInt(this.search);
          let result = await geoService.getProductSeo(cprcode);
          this.mDataArray = [];
          this.mDataArray.push(result);

          // let data = { searchText: this.search };
          // let result = await api.getProducts(data);
          // console.log("--- result ---", JSON.stringify(result.data));
          // this.mDataArray = result.data;  
        } catch (error) {
          alert(error);
        }
        finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
    .v-progress-circular {
      margin: 1rem;
    }
    v-data-table table {
      border-spacing: 0 0.5rem;
    }
    tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, .05);
    }
  </style>