<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-10  pa-5" outlined>
          <v-form @submit.prevent="submit">
            <v-row>
              <!-- Left -->
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="manualstock.docId"
                  label="Doc Id"
                  disabled
                ></v-text-field>
              </v-col>
              <!-- Right -->
              <v-col cols="6">
                <v-checkbox
                  v-model="enabled"
                  label="Enabled"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <!-- Left -->
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="manualstock.docName"
                  label="Doc Name"
                  required
                ></v-text-field>

                <v-textarea
                  outlined
                  v-model="manualstock.docDesc"
                  label="Description"
                ></v-textarea>
              </v-col>

              <!-- Right -->
              <v-col cols="6">
                <!-- <v-datetime-picker
                  v-model="startDate"
                  datetime="String"
                  label="Start Datetime"
                ></v-datetime-picker> -->

                <v-dialog
                  ref="dialog1"
                  v-model="modal1"
                  :return-value.sync="startDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hide-details
                      v-model="startDate"
                      label="Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal1 = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog1.save(startDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

                <!-- <v-datetime-picker
                  v-model="endDate"
                  datetime="String"
                  label="End Datetime"
                ></v-datetime-picker> -->

                <v-dialog
                  ref="dialog2"
                  v-model="modal2"
                  :return-value.sync="endDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hide-details
                      v-model="endDate"
                      label="End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal2 = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog2.save(endDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

              </v-col>
            </v-row>

            <v-divider></v-divider>
            <br />
            <template>
              <v-row class="ma-0">
                <h1>Products</h1>
                <v-divider class="mx-4" inset vertical></v-divider>
                <!-- <v-btn color="primary" @click.stop="showProductSearch=true">
                  <v-icon left>add</v-icon>
                  Add Product
                </v-btn>  
                <ProductSearch v-model="showProductSearch" :onSelect="onSelect" /> -->

                <v-btn color="primary" @click="clearData">
                  <v-icon left>delete</v-icon>
                  Clear All
                </v-btn>

                <v-spacer></v-spacer>
                <v-tooltip bottom color="pink darken-4">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 class="mr-3" v-bind="attrs" v-on="on">Import Excel (XLSX)</h3>
                  </template>
                  <span>column header ชื่อ brcode, prcode, stockQty</span>
                  </v-tooltip>
                <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">

                <v-spacer></v-spacer>
              </v-row>
            </template>

            <br />
            <template>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped" width="100%">
                    <thead class="thead-dark" align-center>
                      <tr>
                        <th scope="col" style="text-align: left">Image</th>
                        <th scope="col" style="text-align: left">Br Code</th>
                        <th scope="col" style="text-align: left">(I) Pr Code)</th>
                        <th scope="col" style="text-align: left">Pr Name</th>
                        <th scope="col" style="text-align: left">Stock Qty</th>
                        <th scope="col" style="text-align: left">Online</th>
                        <th scope="col" style="text-align: left">Action</th>
                      </tr>
                    </thead>
                    <draggable v-model="mDataArray" tag="tbody">
                      <tr v-for="item in mDataArray" :key="`${item.brcode}${item.prcode}`">
                        <td>
                            <v-img
                              contain
                              class="ma-2"
                              :eager="true"
                              :src="item.image"
                              lazy-src="@/assets/noimage.png"
                              aspect-ratio="1"
                              min-width="80"
                              max-width="80"
                              max-height="80"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular v-show="loading"
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                        </td>
                        <td>{{ item.brcode }}</td>
                        <td>{{ item.prcode }}</td>
                        <td>{{ item.prname }}</td>
                        <td>{{ item.stockQty }}</td>
                        <td>
                          <v-checkbox
                            readonly
                            v-model="item.master_online"
                          ></v-checkbox>
                        </td>
                        <!-- <td>
                          <v-checkbox
                            readonly
                            v-model="item.enabled"
                          ></v-checkbox>
                        </td> -->
                        <td>
                          <v-icon @click="removeItem(item)">
                            delete
                          </v-icon>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </div>
            </template>

            <br />
            <v-divider></v-divider>
            <br />
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click="cancel">
                Cancel
              </v-btn>

              <v-btn color="primary" type="submit">
                Confirm
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import XLSX from 'xlsx';
import Vue from 'vue'
import moment from 'moment'
// import DatetimePicker from 'vuetify-datetime-picker'
import api from "@/services/lambdaApi";
import draggable from 'vuedraggable'
// import ProductSearch from "@/components/product/ProductSearch"

// Vue.use(DatetimePicker)

export default {
  name: "manualstock-edit",
  components:{
    draggable,
    // ProductSearch
  },
  data: () => ({
    loading: false,
    enabled: false,
    startDate: "",
    endDate: "",
    modal1: false,
    modal2: false,
    manualstock: {
        docId: 0,
        docName: "",
        docDesc: "",
        startDate: "",
        endDate: "",
        enabled: 0,
        items: [],
        createdDatetime: "",
        createdDate: "",
        updatedDatetime: "",
        updatedDate: ""
    },
    // showProductSearch: false,
    mDataArray: [],
  }),
  async mounted() {
    try {
      this.loading = true;
      let docId = parseInt(this.$route.params.id ?? 0);
      if (docId != 0) {
        let data = { docId: docId }
        let result = await api.getManualStockById(data);
        this.enabled = result.data.enabled == 1 ? true : false;
        this.startDate = result.data.startDate;
        this.endDate = result.data.endDate;
        this.manualstock = result.data;
        this.mDataArray = this.manualstock.items;
        // console.log("--- load ---", JSON.stringify(result.data));
      }
    // } catch (error) {
    }
    finally {
      this.loading = false;
    } 
  },

  methods: {
    clearData() {
      this.mDataArray = []
    },
    onFileImportSelected(e) {
      try {
        this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile.value = "";
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    previewFiles(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let prcodes = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- prcodes --', prcodes);
            let prcodeList = [];
            prcodes.forEach(item => {
              var prcode = prcodeList.find(d => d === parseInt(item.prcode));
              // console.log('-- prcode --', prcode)
              if (!prcode)
                prcodeList.push(parseInt(item.prcode ?? 0));
            });
            if (prcodeList && prcodeList.length>0) {
              let result = await api.getProductByPrcodeList(prcodeList);
              let productList = result.data;
              if (productList && productList.length>0) {
                prcodes.forEach(item => {
                  var mItem2 = vm.mDataArray.find(d => d.brcode === item.brcode && d.prcode === item.prcode);
                  if (!mItem2) {
                    var mData = productList.find(d => d.cprcode === item.prcode);
                    // console.log(item.brcode, item.prcode, item.stockQty, mData.hema_name_en)
                    if (mData) {
                      var mItem = {
                        image: mData.image,
                        brcode: item.brcode,
                        prcode: item.prcode,
                        prname: mData.hema_name_en,
                        stockQty: parseInt(item.stockQty),
                        master_online: mData.master_online
                      }
                      vm.mDataArray.push(mItem);
                    }
                  }
                });
              }
            }
        };
        reader.readAsArrayBuffer(file);
    },
    // onSelect(item) {
    //   //console.log(JSON.stringify(item));
    //   if (item != null) {
    //     let mData = this.mDataArray.find(d => d.cprcode === item.cprcode);
    //     if (!mData) {
    //       let product = {
    //         "cprcode": item.cprcode,
    //         "hema_name_en": item.hema_name_en,
    //         "image": item.image,
    //         "master_online": item.master_online,
    //         // "enabled": item.enabled
    //       };
    //       this.mDataArray.push(product);
    //     }
    //   }
    // },
    removeItem: function (item) {
      let mData = this.mDataArray.find(d => d.brcode === item.brcode && d.prcode === item.prcode);
      if (mData) {
        let index = this.mDataArray.indexOf(mData);
        if (index >= 0) {
          this.mDataArray.splice(index, 1)
        }
      }
    },
    cancel() {
      this.$router.back();
    },
    async submit() {
      try {
        this.loading = true;
        let data = this.manualstock;
        data.enabled = this.enabled ? 1 : 0;
        data.startDate = this.startDate == null || this.startDate == "" ? "" : moment(this.startDate).format('YYYY-MM-DD');
        data.endDate = this.endDate == null || this.endDate == "" ? "" : moment(this.endDate).format('YYYY-MM-DD');
        let items = [];
        this.mDataArray.forEach(item => {
          items.push({ brcode: item.brcode, prcode: parseInt(item.prcode ?? 0), stockQty: parseInt(item.stockQty ?? 0) });
        });
        data.items = items;
        // console.log('request', JSON.stringify(data));
        let result;
        if (data.docId == 0) {
          result = await api.createManualStock(data);
        }
        else {
          result = await api.updateManualStock(data);
        }
        // console.log('result', JSON.stringify(result));
        this.$router.back();
      // } catch (error) {
      }
      finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>