<template>
    <v-container >
        <v-row class="justify-center ma-2">
          <v-card style="width:400px;">
            <v-img class="white--text align-end" src="@/assets/login_header.jpg" height="200px">
              <v-card-title primary-title>
                Forgot Password
              </v-card-title>
            </v-img>
              <v-card-text>
                <v-form @submit.prevent="submit">
  
                  <!-- Username -->
                  <v-text-field
                    name="username"
                    label="Username"
                    id="username"
                    autocomplete="username" 
                    v-model="account.username" 
                    :rules="usernameRules"
                  />
  
                  <!-- Old Password -->
                  <v-text-field
                    name="old_password"
                    label="Old Password"
                    id="old_password"  
                    autocomplete="old_password"                
                    :append-icon="isShowPasswordOld ? 'visibility' : 'visibility_off'"
                    @click:append="isShowPasswordOld = !isShowPasswordOld"
                    :type="isShowPasswordOld ? 'text' : 'password'"
                    counter
                    v-model="account.old_password"
                    :rules="passwordRules"/>

                  <!-- New Password -->
                  <v-text-field
                    name="new_password"
                    label="New Password"
                    id="new_password"  
                    autocomplete="new_password"                
                    :append-icon="isShowPasswordNew ? 'visibility' : 'visibility_off'"
                    @click:append="isShowPasswordNew = !isShowPasswordNew"
                    :type="isShowPasswordNew ? 'text' : 'password'"
                    counter
                    v-model="account.new_password"
                    :rules="passwordRules"/>
  
                  <!-- <span>{{account}}</span> -->
                  <v-row class="justify-space-between px-3 pt-5">
                      <v-btn text @click.prevent="$router.back()">Cancel</v-btn>    
                      <v-btn type="submit" color="primary" :disabled="!account.username || !account.old_password || !account.new_password">Confirm</v-btn>    
                  </v-row>
                </v-form>
              </v-card-text>
          </v-card>
        </v-row>
    </v-container>
</template>
  
<script>
import auth from "@/services/auth";

export default {
  data() {
    return {
      isShowPasswordOld: false,
      isShowPasswordNew: false,
      account:{
        username: "",
        old_password: "",
        new_password: ""
      },
      usernameRules:[v1=>!!v1 || "Username is required",],
      // passwordRules:[v1=>!!v1 || "Password is required", v2=>!!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(v2) || "Minimum six characters"]
      passwordRules:[v1=>!!v1 || "Password is required"]
    }
  },
  methods: {
    async submit(){
      try {
        if (this.account.username == null || this.account.username == "") {
          alert('Please enter username')
          return;
        };
        if (this.account.old_password == null || this.account.old_password == "" || this.account.old_password.length < 6) {
          alert('Please enter old password minimum 6 characters')
          return;
        };
        if (this.account.new_password == null || this.account.new_password == "" || this.account.new_password.length < 6) {
          alert('Please enter new password minimum 6 characters')
          return;
        };
        let result = await auth.change_password(this.account.username, this.account.old_password, this.account.new_password);
        // alert(result);
        this.$router.back();
      } catch (error) {
        alert(error.response.data);
      }
    }
  },
}
</script>

<style></style>