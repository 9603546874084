<template>
    <div>
      <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <template>
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="primary"
            centered
            dark
            icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <!-- <v-tab href="#tab-1">File
              <v-icon>ballot</v-icon>
            </v-tab> -->
  
            <v-tab href="#tab-2">Segment
              <v-icon>ballot</v-icon>
            </v-tab>

            <v-tab href="#tab-3">Template
              <v-icon>ballot</v-icon>
            </v-tab>

            <v-tab href="#tab-4">Campaign
              <v-icon>ballot</v-icon>
            </v-tab>
          </v-tabs>
  
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="i in 4"
              :key="i"
              :value="'tab-' + i">

              <!-- File -->
              <!-- <v-card class="ma-1" v-if="tab=='tab-1'" flat>
                <h3>Upload csv file</h3>
                <input type="file" accept=".csv" @change="onFileSelected">
                <div id="uploadPercent"></div>
                <div class="table_sub">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th width="90%" style="text-align:left">File Name</th>
                        <th width="10%" style="text-align:left">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                        <template v-for="fileName in fileList">
                        <tr>
                            <td>{{ fileName }}</td>
                            <td>
                              <v-icon @click="removeFileName(fileName)">delete</v-icon>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                  </table>
                </div>
              </v-card> -->
  
              <!-- Segment -->
              <v-card class="ma-1" v-if="tab=='tab-2'" flat>
                <v-row class="ma-0">
                  <v-col cols="4">
                    <h3>Upload csv file</h3>
                    <input type="file" accept=".csv" @change="onFileSelected">
                    <div id="uploadPercent"></div>
                  </v-col>
                  <v-col cols="8" class="mt-3">
                    <v-btn @click="loadListSegment" color="primary" dark class="mb-2">
                      <v-icon left>refresh</v-icon>
                      <span>Refresh Segment</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <div class="table_sub">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th width="30%" style="text-align:left">Id</th>
                        <th width="30%" style="text-align:left">Name</th>
                        <!-- <th width="15%" style="text-align:left">Group</th> -->
                        <th width="10%" style="text-align:left">Type</th>
                        <th width="10%" style="text-align:left">Noti</th>
                        <th width="10%" style="text-align:left">LastUpdate</th>
                        <th width="10%" style="text-align:left">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                        <template v-for="segment in segmentList">
                        <tr>
                            <td>{{ segment.Id ?? "" }}</td>
                            <td>{{ segment.Name ?? "" }}</td>
                            <!-- <td>{{ segment.SegmentGroups ?? "" }}</td> -->
                            <td>{{ segment.SegmentType ?? "" }}</td>
                            <td>{{ segment.ImportDefinition?.Size ?? 0 }}</td>
                            <td>{{ getLocalTime(segment.LastModifiedDate) }}</td>
                            <td>
                              <v-icon @click="removeSegment(segment)">delete</v-icon>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                  </table>
                </div>
              </v-card>

              <!-- Template -->
              <v-card class="ma-1" v-if="tab=='tab-3'" flat>
                <v-btn @click="updateTemplate(null)" color="primary" dark class="mb-2">
                  <v-icon left>add</v-icon>
                  <span>New Template</span>
                </v-btn>
                <div class="table_sub">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th width="40%" style="text-align:left">Name</th>
                        <th width="30%" style="text-align:left">Type</th>
                        <th width="20%" style="text-align:left">LastUpdate</th>
                        <th width="10%" style="text-align:left">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        <template v-for="tmplate in templateList">
                        <tr>
                            <td>{{ tmplate.TemplateName ?? "" }}</td>
                            <td>{{ tmplate.TemplateType ?? "" }}</td>
                            <td>{{ getLocalTime(tmplate.LastModifiedDate) }}</td>
                            <td>
                              <v-icon @click="updateTemplate(tmplate)">edit</v-icon>
                              <v-icon @click="removeTemplate(tmplate)">delete</v-icon>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                  </table>
                </div>
              </v-card>

              <!-- Campaign -->
              <v-card class="ma-1" v-if="tab=='tab-4'" flat>
                <v-btn @click="updateCampaign(null)" color="primary" dark class="mb-2">
                  <v-icon left>add</v-icon>
                  <span>New Campaign</span>
                </v-btn>
                <div class="table_sub">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th width="20%" style="text-align:left;word-wrap: break-word;">Name</th>
                        <th width="auto" style="text-align:left;word-wrap: break-word;">Segment</th>
                        <th width="auto" style="text-align:left;word-wrap: break-word;">Template</th>
                        <th width="10%" style="text-align:left;word-wrap: break-word;">StartTime</th>
                        <th width="10%" style="text-align:left;word-wrap: break-word;">LastUpdate</th>
                        <th width="10%" style="text-align:left;word-wrap: break-word;">Status</th>
                        <th width="10%" style="text-align:left;word-wrap: break-word;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        <template v-for="campaign in campaignList">
                        <tr>
                            <td style="text-align:left;word-wrap: break-word; vertical-align: top;">{{ campaign.Name ?? "" }}</td>
                            <td style="text-align:left;word-wrap: break-word; vertical-align: top;">{{ getSegmentName(campaign) }}</td>
                            <td style="text-align:left;word-wrap: break-word; vertical-align: top;">{{ campaign.TemplateConfiguration?.PushTemplate?.Name ?? "" }}</td>
                            <td style="text-align:left;word-wrap: break-word; vertical-align: top;">{{ getLocalTime(campaign.Schedule.StartTime) }}</td>
                            <td style="text-align:left;word-wrap: break-word; vertical-align: top;">{{ getLocalTime(campaign.LastModifiedDate) }}</td>
                            <td style="text-align:left;word-wrap: break-word; vertical-align: top;">{{ campaign.State?.CampaignStatus ?? "" }}</td>
                            <td style="text-align:left;word-wrap: break-word; vertical-align: top;">
                              <v-icon @click="updateCampaign(campaign)">edit</v-icon>
                              <v-icon @click="removeCampaign(campaign)">delete</v-icon>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                  </table>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>

    <template>
        <v-row justify="center">
          <v-dialog
            v-model="isUpdateTemplate"
            max-width="700px">
            <v-card>
              <v-card-title>Update Template</v-card-title>
              <v-divider></v-divider>
              <v-row class="ma-2">
                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="templateUpdate.name"
                    label="Name"
                    clearable
                    :disabled="!isNewTemplate"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    v-model="templateUpdate.title"
                    label="Title"
                    clearable
                  ></v-text-field>
                  <v-textarea
                    outlined
                    v-model="templateUpdate.body"
                    height="100"
                    label="Body"
                  ></v-textarea>
                  <v-text-field
                    outlined
                    v-model="templateUpdate.id"
                    label="Id"
                    clearable
                  ></v-text-field>
                  <v-select
                    outlined
                    v-model="templateUpdate.type"
                    label="Type"
                    :items="['PUSH']"
                  ></v-select>
                  <v-select
                    label="Action"
                    outlined 
                    v-model="templateUpdate.action"
                    :items="templateAction" 
                    item-text="desc" 
                    item-value="id" 
                  ></v-select>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isUpdateTemplate = false">
                  Close
                </v-btn>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn color="primary" @click="saveTemplate">
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
    </template>

    <template>
        <v-row justify="center">
          <v-dialog
            v-model="isUpdateCampaign"
            max-width="700px">
            <v-card>
              <v-card-title>Update Campaign</v-card-title>
              <v-divider></v-divider>
              <v-row class="ma-2">
                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="campaignUpdate.name"
                    label="Name"
                    clearable
                    :disabled="!isNewCampaign"
                  ></v-text-field>
                  <v-select
                    label="Segment"
                    outlined 
                    v-model="campaignUpdate.segment_id"
                    :items="segmentList"
                    item-text="Name"
                    item-value="Id"
                  ></v-select>
                  <v-select
                    outlined
                    v-model="campaignUpdate.template_name"
                    label="Template"
                    :items="templateList"
                    item-text="TemplateName" 
                    item-value="TemplateName" 
                  ></v-select>
                  <v-datetime-picker
                    v-model="startDate"
                    datetime="String"
                    label="Start Datetime"
                  ></v-datetime-picker>
                  <!-- <v-text-field v-if="campaignUpdate.State"
                    outlined
                    v-model="campaignUpdate.State.CampaignStatus"
                    label="Status"
                    clearable
                  ></v-text-field> -->
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isUpdateCampaign = false">
                  Close
                </v-btn>
                <v-divider class="mx-4" inset vertical></v-divider>
                <!-- v-if="campaignUpdate?.State?.CampaignStatus ?? '' != 'COMPLETED'" -->
                <v-btn color="primary" @click="saveCampaign">
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
    </template>

    </v-container>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import sendNotiService from "@/services/sendNotiService";
  import moment from 'moment'
  
  export default {
    name: "sendnoti",
    data() {
      return {
        loading: false,
        tab: null,
        // search: "",

        startDate: "",

        fileList: [],
        segmentList: [],
        templateList: [],
        campaignList: [],
        segmentNextToken: "",

        isUpdateTemplate: false,
        isNewTemplate: true,
        templateUpdate: {
          name: "",
          type: "PUSH",
          title: "",
          body: "",
          action: null,
          id: ""
        },
        templateAction: [
          { id: "OPEN_APP", desc: "Noti อย่างเดียว" },
          { id: "OPEN_PROMOTION", desc: "Noti และ ไปหน้า Promotion" },
          { id: "OPEN_CAMPAIGNS", desc: "Noti และ ไปหน้า Campaigns" },
          { id: "OPEN_PRODUCT", desc: "Noti และ ไปหน้า Product" },
          { id: "OPEN_SEARCH", desc: "Noti และ ไปหน้า Search" }
        ],

        isUpdateCampaign: false,
        isNewCampaign: true,
        campaignUpdate: {
          name: "",
          segment_id: "",
          template_name: "",
          start_time: ""
        },
        campaignSegmentSelect: null,
      };
    },
    // components: {
    // },
    async mounted() {
        try {
          this.loading = true;
          //await this.loadListFile();
          await this.loadListSegment();
          await this.loadListTemplate();
          await this.loadListCampaign();
        } catch (error) {
          alert(error);
        } finally {
          this.loading = false;
        }
    },
    methods: {
      getLocalTime(d) {
        return moment(new Date(d)).format('YYYY-MM-DD HH:mm:ss');
      },
      async removeFileName(fileName) {
        alert(fileName);
      },
      async onFileSelected(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        await this.uploadFile(files[0]);
      },
      async uploadFile(file) {
        var result = await sendNotiService.getUploadUrl(file.name);
        // console.log("--- signedURL ---", result.signedURL);
        if (result.signedURL) {
          await axios.put(result.signedURL,
            file,
            {
            headers: {
              'Content-Type': file.type
            },
            onUploadProgress: (e) => {
              var percentCompleted = Math.round((e.loaded * 100) / e.total);
              document .getElementById("uploadPercent").innerHTML = percentCompleted;
            }
            }).then(async res => {
                console.log({res});
                setTimeout(async () => {
                  await this.loadListSegment();
                }, 2000);
            }).catch(err => {
                console.error({err});
            });
          };
      },
      // async loadListFile() {
      //   var result = await sendNotiService.listFile();
      //   this.fileList = result.Data;
      //   // console.log("--- list file ---", this.fileList);
      // },
      getSegmentName(campaign) {
        var objSegment = this.segmentList.find(d => d.Id === campaign.SegmentId ?? "");
        if (objSegment) return objSegment.Name;
        return "";
      },
      async loadListSegment() {
        this.segmentNextToken = "";
        var result = await sendNotiService.listSegment(1000, this.segmentNextToken);
        this.segmentList = result.Item;
        //this.segmentNextToken = result.NextToken;
        // console.log("--- list Segment ---", this.segmentList);
      },
      async removeSegment(segment) {
        if (confirm(`Delete segment ${segment.Name}?`)) {
          try {
            var result = await sendNotiService.deleteSegment(segment.Id);
            //if (result.Message == "Deleted") {
            await this.loadListSegment();
            //}
          } catch (error) {
            if (error?.response?.data?.Error)
              alert(error.response.data.Error)
            else
              alert(error)
          } finally {
            // this.loading = false;
          }
        }
      },
      async loadListTemplate() {
        var result = await sendNotiService.listTemplate(1000);
        this.templateList = result.Item;
        // console.log("--- list Template ---", this.templateList);
      },
      async updateTemplate(tmplate) {
        if (tmplate)
        {
          this.isNewTemplate = false;
          var result = await sendNotiService.getTemplate(tmplate.TemplateType, tmplate.TemplateName);
          // console.log("--- template ---", result);
          var rowContent = JSON.parse(result?.GCM?.RawContent ?? {});
          // console.log("--- rowContent ---", rowContent);
          this.templateUpdate.name = result.TemplateName;
          this.templateUpdate.type = result.TemplateType;
          this.templateUpdate.title = rowContent?.notification?.title ?? "";
          this.templateUpdate.body = rowContent?.notification?.body ?? "";
          this.templateUpdate.action = rowContent?.data?.action ?? "";
          this.templateUpdate.id = rowContent?.data?.id ?? "";
        }
        else {
          this.isNewTemplate = true;
          this.templateUpdate.name = "";
          this.templateUpdate.type = "PUSH";
          this.templateUpdate.title = "";
          this.templateUpdate.body = "";
          this.templateUpdate.action = "OPEN_APP";
          this.templateUpdate.id = "";
        }
        this.isUpdateTemplate = true;
      },
      async saveTemplate() {
        try {
          if (!this.templateUpdate.id || this.templateUpdate.id == "")
            this.templateUpdate.id = "0";
          var result;
          if (this.isNewTemplate)
            result = await sendNotiService.createTemplate(this.templateUpdate);
          else
            result = await sendNotiService.updateTemplate(this.templateUpdate);

          if (result.Message == "Created" || result.Message == "Accepted") {
            await this.loadListTemplate();
            this.isUpdateTemplate = false;
          }
        } catch (error) {
          if (error?.response?.data?.Error)
            alert(error.response.data.Error)
          else
            alert(error)
        } finally {
          // this.loading = false;
        }
      },
      async removeTemplate(tmplate) {
        if (confirm(`Delete template ${tmplate.TemplateName}?`)) {
          try {
            var result = await sendNotiService.deleteTemplate(tmplate.TemplateType, tmplate.TemplateName);
            if (result.Message == "Deleted") {
              await this.loadListTemplate();
            }
          } catch (error) {
            if (error?.response?.data?.Error)
              alert(error.response.data.Error)
            else
              alert(error)
          } finally {
            // this.loading = false;
          }
        }
      },
      async loadListCampaign() {
        var result = await sendNotiService.listCampaign(500);
        this.campaignList = result.Item;
        // console.log("--- list Campaign ---", this.campaignList);
      },
      async updateCampaign(campaign) {
        if (campaign)
        {
          this.isNewCampaign = false;
          var result = await sendNotiService.getCampaign("push", campaign.Id);
          // console.log("--- campaign ---", result);
          this.campaignUpdate.campaign_id = result.Id;
          this.campaignUpdate.name = result.Name;
          this.campaignUpdate.segment_id = result.SegmentId ?? "";
          this.campaignUpdate.template_name = result.TemplateConfiguration?.PushTemplate?.Name;
          if (result.Schedule?.StartTime)
            this.startDate = new Date(result.Schedule?.StartTime);
            // this.startDate = new Date(result.Schedule?.StartTime.slice(0, -1));
          // alert(this.startDate);
          // this.compaignUpdate["State"] = result.State;
        }
        else {
          this.isNewCampaign = true;
          this.campaignUpdate.name = "";
          this.campaignUpdate.segment_id = "";
          this.campaignUpdate.template_name = "";
          this.campaignUpdate.start_time = "";
          this.startDate = "";
          // this.compaignUpdate["State"] = null;
        }
        this.isUpdateCampaign = true;
      },
      async saveCampaign() {
        try {
          var result;
          var start_time = new Date(this.startDate);
          this.campaignUpdate.start_time = start_time.toISOString();
          // alert(this.campaignUpdate.start_time);
          if (this.isNewCampaign)
            result = await sendNotiService.createCampaign(this.campaignUpdate);
          else
            result = await sendNotiService.updateCampaign(this.campaignUpdate);

          // if (result.Message == "Created" || result.Message == "Accepted") {
            await this.loadListCampaign();
            this.isUpdateCampaign = false;
          // }
        } catch (error) {
          if (error?.response?.data?.Error)
            alert(error.response.data.Error)
          else
            alert(error)
        } finally {
          // this.loading = false;
        }
      },
      async removeCampaign(campaign) {
        if (confirm(`Delete campaign ${campaign.Name}?`)) {
          try {
            var result = await sendNotiService.deleteCampaign("push", campaign.Id);
            // if (result.Message == "Deleted") {
            await this.loadListCampaign();
            // }
          } catch (error) {
            if (error?.response?.data?.Error)
              alert(error.response.data.Error)
            else
              alert(error)
          } finally {
            // this.loading = false;
          }
        }
      },



      // async loadData() {
      //   var result = await sendNotiService.listFile();
      //   this.fileList = result.Data;
      //   // console.log("--- list file ---", this.fileList);
      
      //     // var branchId = "";
      //     // if (this.branchSelect != null)
      //     //   branchId = this.branchSelect.storeCode;
      //     // else
      //     //   branchId = "";
  
      //     // let startDate = this.date1 == null || this.date1 == "" ? "" : moment(this.date1).format('YYYY-MM-DD');
      //     // let endDate = this.date2 == null || this.date2 == "" ? "" : moment(this.date2).format('YYYY-MM-DD');
  
      //     // var filter = { action: "preorder", branchId: branchId, startDate: startDate, endDate: endDate };
      //     // // console.log("--- filter ---", filter);
      //     // var result = await api.getOrder2List(filter);
      //     // // console.log("--- order list ---", result.data);
      //     // this.mPreorderArray = result.data;
  
      //     // filter = { action: "preorderdelivery", branchId: branchId, startDate: startDate, endDate: endDate };
      //     // // console.log("--- filter ---", filter);
      //     // result = await api.getOrder2List(filter);
      //     // // console.log("--- order list ---", result.data);
      //     // this.mPreorderDeliveryArray = result.data;
  
      //     // filter = { action: "readytogo", branchId: branchId, startDate: startDate, endDate: endDate };
      //     // // console.log("--- filter ---", filter);
      //     // result = await api.getOrder2List(filter);
      //     // // console.log("--- mReadyToGoArray list ---", result.data);
      //     // this.mReadyToGoArray = result.data;
  
      //     // filter = { action: "readytogodelivery", branchId: branchId, startDate: startDate, endDate: endDate };
      //     // // console.log("--- filter ---", filter);
      //     // result = await api.getOrder2List(filter);
      //     // // console.log("--- order list ---", result.data);
      //     // this.mReadyToGoDeliveryArray = result.data;
      // },
    },
    computed: {
      // currentDate() {
      //   return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      // },
      userCode() {
        return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
      },
      userGroup() {
        return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
      },
      userType() {
        return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
      },
      userBrcode() {
        return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
      }
    }
  };
  </script>
  
  <style scoped>
  table {
    border-spacing: 0 0.5rem;
    font-size: small;
  }
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .header {
    font-size: 18px;
    font-weight: bold;
    color: #D50000;
  }

  .table_sub {
    height: 420px;
    overflow: auto;
  }
  </style>