<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  data() {
    return {
      options: {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }, 
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    // alert(JSON.stringify(this.chartData));
    // alert(JSON.stringify(this.options));
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style>
</style>