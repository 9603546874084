<template>
    <div>
      <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-card>
        <v-data-table
          height="500"
          fixed-header
          :headers="headers"
          :items="mDataArray"
          sort-by="VoucherCode"
          :sort-desc="false"
          :items-per-page="15"
        >
          <!-- table top section -->
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Voucher</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-menu
                hide-details
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-details
                    v-model="date"
                    label="RequestDate"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="$refs.menu.save(date)"
                  v-model="date"
                  no-title
                  scrollable>
                </v-date-picker>
              </v-menu>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                clearable
                hide-details
                v-model="voucherCode"
                label="VoucherCode"
                @keyup.enter="searchClick"
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                clearable
                hide-details
                v-model="orderId"
                label="OrderId"
                @keyup.enter="searchClick"
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-icon class="mr-2" @click="searchClick">
                search
              </v-icon>
              <v-spacer></v-spacer>
              <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
              <v-btn @click="newVoucher" color="primary" dark class="mb-2">
                <v-icon left>add</v-icon>
                <span>Create Request</span>
              </v-btn>
            </v-toolbar>
          </template>
  
          <!-- table tr section -->
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-icon class="mr-2" @click="editItem(item)">
                  edit
                </v-icon>
              </td>
              <td>{{ item.voucherCode }}</td>
              <!-- <td>{{ item.voucherTypeDesc }}</td> -->
              <td>{{ item.orderId }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.requestDesc }}</td>
              <td>{{ item.createdDate }}</td>
              <td>{{ item.requestCode }}</td>
              <td>
                <v-checkbox readonly v-model="item.voucherFlag"></v-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    </div>
  </template>
  
  <script>
  import api from "@/services/lambdaApi";
  import moment from 'moment'
  
  export default {
    name: "voucherrequest",
    data() {
      return {
        loading: false,
        // search: "",
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        voucherCode: "",
        orderId: "",
        mDataArray: [],
        headers: [
          {
            text: "Action",
            align: "left",
            sortable: false,
            value: "action",
          },
          { text: "VoucherCode", value: "voucherCode" },
          // { text: "Type", value: "voucherTypeDesc" },
          { text: "OrderId", value: "orderId" },
          { text: "Amount", value: "amount" },
          { text: "Remark", value: "requestDesc" },
          { text: "Request Date", value: "createdDate" },
          { text: "Request By", value: "requestCode" },
          { text: "Account Confirm", value: "voucherFlag" },
        ],
      };
    },
    // components: {
    // },
    async mounted() {
        this.loadData();
    },
    methods: {
      searchClick() {
        this.loadData();
      },
      editItem(item) {
        this.$router.push(`/voucher/editrequest/${item.voucherCode}`);
      },
      newVoucher() {
        this.$router.push("/voucher/editrequest/0");
      },
      async loadData() {
        try {
          this.loading = true;
          let storeDate = this.date == null || this.date == "" ? "" : moment(this.date).format('YYYY-MM-DD');
          let filter = { action: "voucherrequest", storeDate: storeDate, voucherCode: this.voucherCode, orderId: this.orderId }
          // console.log("--- filter ---", filter);
          let result = await api.getVoucherList(filter);
          // console.log("--- data list ---", result.data);
          this.mDataArray = result.data;
        } catch (error) {
            alert(error)
        } finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  </style>