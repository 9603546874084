<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-card>
        <!-- height="500"
        fixed-header -->
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="cprcode"
        :sort-desc="false"
        :items-per-page="15"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Inventory</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-select 
              clearable
              hide-details 
              v-model="branchSelect"
              :items="stores" 
              item-text="brname" 
              item-value="brcode" 
              label="Branch"
              :disabled="userBrcode != ''"
              return-object
            ></v-select>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              clearable
              hide-details
              v-model="cprcodes"
              label="cprcode [ ex: 205237,23772 ]"
              @keyup.enter="searchClick"
            ></v-text-field>
            
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn @click="exportClick" color="primary" v-if="userCode == 'V16982' || userGroup == 'it'">
              <v-icon left>save</v-icon>
              <span>Export</span>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="clearProduct">
              <v-icon left>delete</v-icon>Clear
            </v-btn>
            <v-spacer></v-spacer>
            <v-tooltip bottom color="pink darken-4">
              <template v-slot:activator="{ on, attrs }">
                <h5 class="mr-3" v-bind="attrs" v-on="on">Import Excel (XLSX)</h5>
              </template>
              <span>ใส่รหัสสินค้า 1 column ต้องมี column header ชื่อ prcode</span>
            </v-tooltip>
            <input type="file" accept=".xlsx" @change="onFileImportSelected" ref="importFile">
            <!-- <v-spacer></v-spacer> -->
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-img
                contain
                class="ma-2"
                :eager="true"
                :src="getImageUrl(item)"
                v-on:error="getImage(item)"
                lazy-src="@/assets/noimage.png"
                aspect-ratio="1"
                min-width="80"
                max-width="80"
                max-height="80"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular  v-show="loading"
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </td>
            <!-- <td>{{ item.brcode }}</td> -->
            <td>{{ item.brname }}</td>
            <td>{{ item.cprcode }}</td>
            <td>{{ item.iprcode }}</td>
            <td>{{ item.prname }}</td>
            <td>{{ item.hema_brand_en }}</td>
            <td>{{ item.psqty }}</td>
            <td>{{ item.cf_qty }}</td>
            <td>{{ item.stockQty }}</td>
            <!-- <td>
              <v-checkbox readonly v-model="item.master_online"></v-checkbox>
            </td> -->
            <td>
              <v-checkbox readonly v-model="item.isRange"></v-checkbox>
            </td>
            <td>
              <v-checkbox readonly v-model="item.visibility"></v-checkbox>
            </td>
            <td>
              <v-checkbox readonly v-model="item.offLine"></v-checkbox>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import { imageUrl } from "@/services/constants";
import api from "@/services/lambdaApi";
import XLSX from 'xlsx';

export default {
  name: "inventory",
  data() {
    return {
      loading: false,
      cprcodes: "",
      branchSelect: null,
      stores:[],
      mDataArray: [],
      headers: [
        {
          text: "Image",
          align: "left",
          sortable: false,
          value: "image"
        },
        // { text: "Br Code", value: "brcode" },
        { text: "Br Name", value: "brname" },
        { text: "(C) Pr Code", value: "cprcode" },
        { text: "(I) Pr Code", value: "iprcode" },
        { text: "Pr Name", value: "prname" },
        { text: "Brand", value: "hema_brand_en" },
        { text: "Ps Qty", value: "psqty" },
        { text: "Stock Qty", value: "cf_qty" },
        { text: "Online Qty", value: "stockQty" },
        // {
        //   text: "Online",
        //   align: "left",
        //   // sortable: false,
        //   value: "master_online",
        // },
        {
          text: "Online",
          align: "left",
          sortable: false,
          value: "isRange",
        },
        {
          text: "Visibility",
          align: "left",
          sortable: false,
          value: "visibility",
        },
        {
          text: "Offline",
          align: "left",
          sortable: false,
          value: "offLine",
        },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      try {
        this.loading = true;

        // let filter = { fileName: "online_stores.json" };
        let filter = { fileName: "branchs.json" };
        let result = await api.getS3Json(filter);
        this.stores = result.data;

        if (this.userBrcode != "") {
          let mStore = this.stores.find(d => d.brcode === this.userBrcode);
          if (mStore) {
            this.branchSelect = mStore;
          }
        }

        // await this.loadData();
      } catch (error) {
        alert(error)
      } finally {
        this.loading = false;
      }
  },
  methods: {
    clearProduct() {
      this.mDataArray = []
    },
    onFileImportSelected(e) {
      try {
        this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 5000);
      }
    },
    previewFiles(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let prcodes = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- prcodes --', prcodes);
            let prcodeList = [];
            prcodes.forEach(item => {
              prcodeList.push(parseInt(item.prcode ?? 0));
            });
            if (prcodeList && prcodeList.length>0) {
              // console.log('-- prcodeList --', prcodeList);

              var brcode = "";
              if (vm.branchSelect != null) {
                brcode = vm.branchSelect.brcode;
              }

              let filter = { brcode: brcode.toString(), cprcodes: prcodeList.toString() };

              // console.log("--- filter ---", filter);
              let result = await api.getInventory(filter);
              // console.log("--- getInventory ---", result.data);
              vm.mDataArray = result.data;
              vm.loading = false;
            }
        };
        reader.readAsArrayBuffer(file);
    },
    getImageUrl(item) {
      return `${imageUrl}/${item.image}`;
    },
    //****** ของต่อ start */
    // https://villa.kitchen/public/imagenames/allProducts
    // getImages() {
    //   import axios from "axios";
    //   import { IMAGES_ENDPOINT } from "../config/images";

    //   let Images = {};

    //   export async function loadAllImages() {
    //       const data = (await axios.get(IMAGES_ENDPOINT)).data;
    //       Images = data;
    //   }

    //   export function getImages(cprcode) {
    //       const padded = cprcode.toString().padStart(7, "0");
    //       return Images[padded] || [];
    //   }
    // },
    //****** ของต่อ end */
    getImage(item) {
      return;
      // var image = item["image"] ?? "";
      // if (image.substr(image.length-2, 1) != "-") {
      //   image = `${image}-0`;
      // };
      // let imageIndex = parseInt(image.substr(image.length-1, 1)) + 1;
      // if (imageIndex > 1) return;

      // let prcode = parseInt(item["cprcode"] ?? 0);
      // var cprcode = `0000000${prcode}`;
      // cprcode = cprcode.substr(cprcode.length-7, 7)
      // item["image"] = `${imageUrl}/${cprcode}-${imageIndex}`;
      // // console.log('-- image --', cprcode, item["image"]);
      // let index = this.mDataArray.indexOf(item);
      // this.$set(this.mDataArray, index, item);
    },
    exportClick() {
      try {
        this.loading = true;
        const dataWS = XLSX.utils.json_to_sheet(this.dataExport)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(wb,'inventory.xlsx')
      } catch (error) {
        alert(error)
      } finally {
        this.loading = false;
      }
    },
    async searchClick() {
      try {
        this.loading = true;
        await this.loadData();
      } catch (error) {
        alert(error)
      } finally {
        this.loading = false;
      }
    },
    async loadData() {
      // if (this.branchSelect == null) {
      //     alert("Please select branch!!!")
      //     return;
      // }
      if (this.cprcodes == null || this.cprcodes == "") {
          alert("Please enter cprcode!!!")
          return;
      }

      var brcode = "";
      if (this.branchSelect != null) {
        brcode = this.branchSelect.brcode;
      }

      let filter = { brcode: brcode.toString(), cprcodes: this.cprcodes.toString() };

      // console.log("--- filter ---", filter);
      let result = await api.getInventory(filter);
      // console.log("--- getInventory ---", result.data);
      this.mDataArray = result.data;
    },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    userGroup() {
      // console.log('-- userGroup --', this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "");
      return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    },
    userType() {
      return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    },
    userBrcode() {
      return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    },
    dataExport() {
      if (this.mDataArray == null)
        return []
      else {
        let dataResult = [];
        this.mDataArray.forEach(item => {
          var data = {
            "brcode": item['brcode'] ?? "", 
            "brname": item['brname'] ?? "", 
            "cprcode": item['cprcode'] ?? "",
            "iprcode": item['iprcode'] ?? "",
            "prname": item['prname'] ?? "",
            "brand": item['hema_brand_en'] ?? "",
            "psqty": item['psqty'] ?? 0,
            "stockqty": item['cf_qty'] ?? 0,
            "onlineqty": item['stockQty'] ?? 0,
            "image": item['image'] ?? "",
            "online": item['isRange'] ?? false,
            "visibility": item['visibility'] ?? false,
            "offline": !(item['isRange'] ?? false),
          };
          dataResult.push(data);
        });
        return dataResult;
      };
    },
  }
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>