<template>
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate :size="80" :width="8" color="primary">
        </v-progress-circular>
      </div>
    </v-container>
    <v-container fluid v-else>
      <v-row>
        <v-col lg="3" md="3" sm="12" cols="12">
          <v-btn class="mt-3" @click="loadData" color="primary">
          <v-icon left>refresh</v-icon>
          <span>Refresh</span>
          </v-btn>
          <v-btn class="ml-3 mt-3" @click="exportClick" color="primary">
            <v-icon left>save</v-icon>
            <span>Export</span>
          </v-btn>
        </v-col>
      </v-row>
  
      <v-card class="mt-1">
          <v-data-table
              height="560"
              fixed-header
              :headers="headers"
              :items="mDataArray"
              sort-by="brcode"
              :sort-desc="false"
              :items-per-page="15">
          <template v-slot:item="{ item }">
            <tr>
              <td>
                  <v-img
                    contain
                    class="ma-2"
                    :eager="true"
                    :src="getImageUrl(item)"
                    lazy-src="@/assets/noimage.png"
                    aspect-ratio="1"
                    min-width="80"
                    max-width="80"
                    max-height="80">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular v-show="loading"
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
              </td>
              <td>{{ item.brcode }}</td>
              <td>{{ item.brname }}</td>
              <td>{{ item.prcode }}</td>
              <td>{{ item.prname }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.countCustomer }}</td>
            </tr>
          </template>
          </v-data-table>
      </v-card>
    </v-container>
  </template>
  
  <script>
    import { imageUrl } from "@/services/constants";
    import api from "@/services/lambdaApi";
    import moment from 'moment'
    import XLSX from 'xlsx';
  
    export default {
      name: "pendingitems",
      data () {
        return {
          loading: false,
          mDataArray: [],
          headers: [
            {
              text: "Image",
              align: "left",
              sortable: false,
              value: "imageUrl"
            },
            { text: "Brcode", value: "brcode" },
            { text: "Brname", value: "brname" },
            { text: "Prcode", value: "prcode" },
            { text: "Prname", value: "prname" },
            { text: "Status", value: "status" },
            { text: "Count", value: "countCustomer" }
          ],
        }
      },
      async mounted () {
        await this.loadData();
      },
      methods: {
        getImageUrl(item) {
          return `${imageUrl}/${item.imageUrl}`;
        },
        async exportClick() {
          try {
              this.loading = true;
              let cdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
              const dataWS = XLSX.utils.json_to_sheet(this.mDataArray)
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, dataWS)
              XLSX.writeFile(wb,`pendingitems_${moment(cdate).format('YYYYMMDD')}.xlsx`)
          } catch (error) {
              alert(error)
          } finally {
              this.loading = false;
          }
        },
        async loadData () {
            try {
                this.loading = true;
                let data = {};
                let result = await api.getPendingItems(data);
                // console.log("--- data list ---", result);
                this.mDataArray = result.data;
            } catch (error) {
                alert(error.response.data);
            } finally {
                this.loading = false;
            }
        },
      },
  
      computed: {
        userCode() {
          return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
        },
        userGroup() {
          return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
        },
        userType() {
          return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
        },
        userBrcode() {
          return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
        },
      }
    }
  </script>
  
  <style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  v-data-table table {
    border-spacing: 0 0.5rem;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  </style>