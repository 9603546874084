import auth from "@/services/auth";
import { apiUrl2 } from "@/services/constants";

const apiUrl = "https://wlets63xte.execute-api.ap-southeast-1.amazonaws.com/production";
// const apiUrl2 = "https://i9xmmtqrmf.execute-api.ap-southeast-1.amazonaws.com/Prod";

//-------
const getProductSeo = async (cprcode) => {
    const instance = await auth.getInstance(apiUrl2);
    let payload = {
        "cprcode": cprcode
    };
    return (
        await instance.post("/getProductSeo", payload)
    ).data;
};

const updateProductSeo = async (data) => {
    const instance = await auth.getInstance(apiUrl2);
    let payload = data;
    return (
        await instance.post("/updateProductSeo", payload)
    ).data;
};
//-------

const getItems = async () => {
    const instance = await auth.getInstance(apiUrl);
    return (
        await instance.get("/items")
    ).data;
};

const getItem = async (url, th, en) => {
    const instance = await auth.getInstance(apiUrl);
    let payload = {
        "URL": url,
        "Th": th,
        "En": en
    };
    return (
        await instance.post("/item/get", payload)
    ).data;
};

const createItem = async (url, metaTitle, metaDescription, th, en) => {
    const instance = await auth.getInstance(apiUrl);
    let payload = {
        "URL": url,
        "MetaTitle": metaTitle,
        "MetaDescription": metaDescription,
        "Th": th,
        "En": en
    };
    return (
        await instance.post("/item/create", payload)
    ).data;
};

const updateItem = async (url, metaTitle, metaDescription, th, en) => {
    const instance = await auth.getInstance(apiUrl);
    let payload = {
        "URL": url,
        "MetaTitle": metaTitle,
        "MetaDescription": metaDescription,
        "Th": th,
        "En": en
    };
    return (
        await instance.post("/item/update", payload)
    ).data;
};

const deleteItem = async (url, th, en) => {
    const instance = await auth.getInstance(apiUrl);
    let payload = {
        "URL": url,
        "Th": th,
        "En": en
    };
    return (
        await instance.post("/item/delete", payload)
    ).data;
};

export default {
    getProductSeo,
    updateProductSeo,
    getItems,
    getItem,
    createItem,
    updateItem,
    deleteItem
};