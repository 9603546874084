<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <template>
      <v-row>
        <v-col lg="3" md="3" sm="12" cols="12">
          <v-select
            clearable
            hide-details
            v-model="branchSelect"
            :items="branchs"
            item-text="storeName" 
            item-value="storeCode" 
            label="Branch"
            :disabled="userBrcode != ''"
            return-object
          ></v-select>
        </v-col>

        <v-col lg="3" md="3" sm="12" cols="12">
          <v-menu
            hide-details
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="date1"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="date1"
                label="Delivery Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="$refs.menu1.save(date1)"
              v-model="date1"
              no-title
              scrollable>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- <v-col lg="3" md="3" sm="12" cols="12">
          <v-menu
            hide-details
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date2"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="date2"
                label="Date (End)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="$refs.menu2.save(date2)"
              v-model="date2"
              no-title
              scrollable>
            </v-date-picker>
          </v-menu>
        </v-col> -->

        <v-col class="mt-3" lg="3" md="3" sm="12" cols="12">
          <v-btn class="mb-5" @click="searchClick" color="primary">
            <v-icon left>search</v-icon>
            <span>Query</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template>
      <v-card class="ma-1" flat>
        <h3 class="header">9008 - Online Warehouse</h3>
        <v-data-table
          dense
          disable-sort
          :headers="headers"
          :items="mDataArray"
          :items-per-page="-1"
          item-key="rowKey"
        >
          <!-- <template v-slot:group.header="{items, isOpen, toggle}">
            <th colspan="12">
              <v-icon @click="toggle"
                >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
              </v-icon>
              {{ items[0].hema_brand_en }}
            </th>
          </template> -->
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-icon @click="editItem(item)">
                  edit
                </v-icon>
              </td>
              <td>{{ item.orderId }}</td>
              <td>{{ item.branchName }}</td>
              <td>{{ item.orderDate }}</td>
              <td>{{ item.deliveryDatetime2 }}</td>
              <td>{{ item.cprcode }}</td>
              <td>{{ item.productName }}</td>
              <td>{{ item.shippingType }} / {{ item.deliveryMode }}</td>
              <td style="text-align: right">{{ item.quantity }}</td>
              <td style="text-align: right">{{ numberFormat(item.price,2) }}</td>
              <td style="text-align: right">{{ numberFormat(item.rowTotal,2) }}</td>
              <!-- <td>{{ item.shippingFirstName }}</td> -->
            </tr>
            <tr v-if="item.remark != null && item.remark.trim() != ''">
              <td colspan="11">Remark : {{ item.remark }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </template>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";
import moment from 'moment'

export default {
  name: "onlinewarehouse",
  data() {
    return {
      loading: false,
      tab: null,
      // search: "",

      date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2: false,

      branchs:[],
      branchSelect: null,
      mDataArray: [],
      headers: [
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action",
        },
        { text: "OrderId", value: "orderId" },
        { text: "Branch", value: "branchName" },
        { text: "OrderDate", value: "orderDate" },
        { text: "DeliveryDate", value: "deliveryDatetime2" },
        { text: "PrCode", value: "cprcode" },
        { text: "PrName", value: "productName" },
        { text: "ShippingType", value: "shippingType" },
        { text: "Qty", value: "quantity", align: "right" },
        { text: "Price", value: "price", align: "right" },
        { text: "Amount", value: "rowTotal", align: "right" },
        // { text: "Customer", value: "shippingFirstName" },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      try {
        this.loading = true;

        // this.date1 = moment(this.date1).subtract(7, 'days').format('YYYY-MM-DD');

        let filter = { fileName: "online_stores.json" };
        let result = await api.getS3Json(filter);
        this.branchs = result.data;

        let brcode = this.userBrcode;
        if (brcode == null || brcode == "") {
          this.branchSelect = null;
        }
        else {
          let mStore = this.branchs.find(d => d.storeCode === brcode);
          if (mStore) {
            this.branchSelect = mStore;
          }
        }

        await this.loadData();
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
  },
  methods: {
    numberFormat(value, decimal) {
      return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
    },
    editItem(item) {
      this.$router.push(`/order/edit/${item.orderId}`);
    },
    async searchClick() {
      try {
        this.loading = true;
        await this.loadData();
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    async loadData() {
        var branchId = "";
        if (this.branchSelect != null)
          branchId = this.branchSelect.storeCode;
        else
          branchId = "";

        let startDate = this.date1 == null || this.date1 == "" ? "" : moment(this.date1).format('YYYY-MM-DD');
        // let endDate = this.date2 == null || this.date2 == "" ? "" : moment(this.date2).format('YYYY-MM-DD');
        let endDate = startDate;

        var filter = { action: "onlinewarehouse", branchId: branchId, warehouse: "9008", startDate: startDate, endDate: endDate };
        // console.log("--- filter ---", filter);
        var result = await api.getOrder2List(filter);
        // console.log("--- order list ---", result.data);
        this.mDataArray = result.data;
    },
  },
  computed: {
    // currentDate() {
    //   return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    // },
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    userGroup() {
      return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    },
    userType() {
      return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    },
    userBrcode() {
      return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    }
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
  font-size: small;
}
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.header {
  font-size: 18px;
  font-weight: bold;
  color: #D50000;
}
</style>