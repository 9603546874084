<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-btn @click="$router.back()" color="primary">
      <v-icon left>arrow_back</v-icon>
      <span>Back</span>
    </v-btn>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="brname"
        :sort-desc="false"
        :items-per-page="-1"
      >
        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td>
                <v-btn color="primary" :href="`https://shop.villamarket.com/product/${item.cprcode}?br=${item.brcode}`" target="_blank" :disabled="!item.master_online || !item.isRange || !item.visibility || item.stockQty <= 0">
                    <v-icon left>shopping_cart</v-icon>
                    <span>Shop Now</span>
                </v-btn>
            </td>
            <td>
              <v-img
                contain
                class="ma-2"
                :eager="true"
                :src="item.image"
                lazy-src="@/assets/noimage.png"
                aspect-ratio="1"
                min-width="80"
                max-width="80"
                max-height="80"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular v-show="loading"
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </td>
            <!-- <td>{{ item.brcode }}</td> -->
            <td>{{ item.brname }}</td>
            <td>{{ item.cprcode }}</td>
            <td>{{ item.iprcode }}</td>
            <td>{{ item.prname }}</td>
            <td>{{ item.hema_brand_en }}</td>
            <td>{{ item.psqty }}</td>
            <td>{{ item.cf_qty }}</td>
            <td>{{ item.stockQty }}</td>
            <!-- <td>
              <v-checkbox readonly v-model="item.master_online"></v-checkbox>
            </td> -->
            <td>
              <v-checkbox readonly v-model="item.isRange"></v-checkbox>
            </td>
            <td>
              <v-checkbox readonly v-model="item.visibility"></v-checkbox>
            </td>
            <td>
              <v-checkbox readonly v-model="!item.visibility"></v-checkbox>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import { imageUrl } from "@/services/constants";
import api from "@/services/lambdaApi";
import XLSX from 'xlsx';

export default {
  name: "product-inventory",
  data() {
    return {
      loading: false,
      mDataArray: [],
      headers: [
        {
            text: "Action",
            align: "left",
            sortable: false,
            value: "action"
        },
        {
          text: "Image",
          align: "left",
          sortable: false,
          value: "image"
        },
        // { text: "Br Code", value: "brcode" },
        { text: "Br Name", value: "brname" },
        { text: "(C) Pr Code", value: "cprcode" },
        { text: "(I) Pr Code", value: "iprcode" },
        { text: "Pr Name", value: "prname" },
        { text: "Brand", value: "hema_brand_en" },
        { text: "Ps Qty", value: "psqty" },
        { text: "Stock Qty", value: "cf_qty" },
        { text: "Online Qty", value: "stockQty" },
        // {
        //   text: "Online",
        //   align: "left",
        //   // sortable: false,
        //   value: "master_online",
        // },
        {
          text: "Online",
          align: "left",
          sortable: false,
          value: "isRange",
        },
        {
          text: "Visibility",
          align: "left",
          sortable: false,
          value: "visibility",
        },
        {
          text: "Offline",
          align: "left",
          sortable: false,
          value: "isRange",
        },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      try {
        this.loading = true;
        await this.loadData();
      } catch (error) {
        alert(error)
      } finally {
        this.loading = false;
      }
  },
  methods: {
    async loadData() {
      let cprcodes = parseInt(this.$route.params.id ?? 0).toString();
    //   if (cprcodes == null || cprcodes == "") {
    //       alert("Please enter cprcode!!!")
    //       return;
    //   };
      let filter = { brcode: "", cprcodes: cprcodes };
    //   console.log("--- filter ---", filter);
      let result = await api.getInventory(filter);
    //   console.log("--- getInventory ---", result.data);
      this.mDataArray = result.data;
    },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    userGroup() {
      // console.log('-- userGroup --', this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "");
      return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    },
    userType() {
      return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    },
    userBrcode() {
      return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    },
    dataExport() {
      if (this.mDataArray == null)
        return []
      else {
        let dataResult = [];
        this.mDataArray.forEach(item => {
          var data = {
            "brcode": item['brcode'] ?? "", 
            "brname": item['brname'] ?? "", 
            "cprcode": item['cprcode'] ?? "",
            "iprcode": item['iprcode'] ?? "",
            "prname": item['prname'] ?? "",
            "brand": item['hema_brand_en'] ?? "",
            "psqty": item['psqty'] ?? 0,
            "stockqty": item['cf_qty'] ?? 0,
            "onlineqty": item['stockQty'] ?? 0,
            "image": item['image'] ?? "",
            "online": item['isRange'] ?? false,
            "visibility": item['visibility'] ?? false,
            "offline": !(item['isRange'] ?? false),
          };
          dataResult.push(data);
        });
        return dataResult;
      };
    },
  }
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>