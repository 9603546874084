<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <!-- <v-alert
      v-model="show_success"
      dismissible
      outlined
      text
      type="success"
    >Update ข้อมูลเว็บ เรียบร้อยแล้ว</v-alert>
    <v-alert
      v-model="show_error"
      dismissible
      outlined
      text
      type="error"
    >Update ข้อมูลเว็บ ไม่สำเร็จ!!</v-alert> -->
    <!-- Table section -->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="docId"
        :sort-desc="true"
        :items-per-page="5"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Manual Stock</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <!-- <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              @keyup.enter="searchClick"
            ></v-text-field> -->

            <v-dialog
              ref="dialog1"
              v-model="modal1"
              :return-value.sync="date1"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  v-model="date1"
                  label="From Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date1"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal1 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog1.save(date1)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-dialog
              ref="dialog2"
              v-model="modal2"
              :return-value.sync="date2"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  v-model="date2"
                  label="To Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date2"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal2 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog2.save(date2)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-checkbox
              v-model="enabled"
              label="Enabled"
              hide-details
            ></v-checkbox>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <v-btn @click="newDocument" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>New Document</span>
            </v-btn>
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.docId }}</td>
            <td>{{ item.docName }}</td>
            <td>{{ item.docDesc }}</td>
            <td>{{ item.startDate }}</td>
            <td>{{ item.endDate }}</td>
            <!-- <td>{{ item.updatedDate }}</td> -->
            <td>{{ item.updatedDatetime }}</td>
            <td>
              <v-checkbox readonly v-model="item.enabled"></v-checkbox>
            </td>
            <td>
              <v-icon class="mr-2" @click="editItem(item)">
                edit
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";

export default {
  name: "manualstock",
  data() {
    return {
      loading: false,
      // search: "",
      // show_success: false,
      // show_error: false,
      enabled: true,
      date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal1: false,
      modal2: false,
      mDataArray: [],
      headers: [
        { text: "Doc Id", value: "docId" },
        { text: "Doc Name", value: "docName" },
        { text: "Description", value: "docDesc" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "LastUpdate", value: "updatedDatetime" },
        {
          text: "Enabled",
          align: "left",
          // sortable: false,
          value: "enabled",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      this.loadManualStock();
  },
  methods: {
    searchClick() {
      this.loadManualStock();
    },
    editItem(item) {
      this.$router.push(`/manualstock/edit/${item.docId}`);
    },
    newDocument() {
      this.$router.push("/manualstock/edit/0");
    },
    async loadManualStock() {
      try {
        this.loading = true;
        let filter = { enabled: this.enabled, startDate: this.date1, endDate: this.date2 }
        // console.log("--- filter ---", filter);
        let result = await api.getManualStockList(filter);
        // console.log("--- manualstock list ---", result.data);
        this.mDataArray = result.data;
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>