<template>
  <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="8"
        color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="mx-10 pa-5" outlined>
          <v-form @submit.prevent="submit">
            <v-row>
              <!-- Left -->
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="promotion.couponId"
                  label="Coupon Id"
                  disabled
                ></v-text-field>
              </v-col>
              <!-- Right -->
              <v-col cols="6">
                <v-row>
                  <v-col cols="4">
                    <v-switch
                      v-model="promotion.active"
                      label="Active"
                      color="success"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="8">
                    <v-switch
                      v-model="promotion.newCustomer"
                      label="New Customer"
                      color="success"
                      hide-details
                  ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <!-- Left -->
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="promotion.couponName"
                  label="Coupon Name"
                  required
                ></v-text-field>

                <v-textarea
                  outlined
                  v-model="promotion.couponDescription"
                  label="Description"
                ></v-textarea>

                <div class="subheader">Avaliable Store :</div>
                <div class="table_store">
                  <table class="table table-striped" width="100%">
                    <thead class="head-dark" align-center>
                      <tr>
                        <th width="30%" style="text-align:left">Store Code</th>
                        <th width="40%" style="text-align:left">Store Name</th>
                        <th width="30%" style="text-align:left">
                            <v-checkbox
                                height="1"
                                v-model="selectAll"
                                label="Select"
                                @change="onSelectAllChange"
                            ></v-checkbox>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                        <template v-for="store in avail_store">
                        <!-- <tr :key="store.storeCode"> -->
                        <tr>
                            <td>{{ store.storeCode }}</td>
                            <td>{{ store.storeName }}</td>
                            <td>
                            <v-checkbox
                                height="1"
                                v-model="store.visibility"
                            ></v-checkbox>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                  </table>
                </div>
              </v-col>

              <!-- Right -->
              <v-col cols="6">
                <v-select
                    outlined
                    v-model="promotion.couponCodeType"
                    label="Coupon Type"
                    :items="couponCodeTypes"
                ></v-select>

                <!-- v-if="promotion.couponCodeType == 'FIXED'" -->
                <v-text-field
                  outlined
                  v-model="promotion.couponCode"
                  label="Coupon Code"
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="promotion.usesPerCoupon"
                  type="number"
                  label="Uses Per Coupon"
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="promotion.numberCouponUsed"
                  type="number"
                  label="Number Coupon Used"
                ></v-text-field>

                <v-text-field
                  outlined
                  v-model="promotion.usesPerCustomer"
                  type="number"
                  label="Uses Per Customer"
                ></v-text-field>

                <v-datetime-picker
                  v-model="startDate"
                  datetime="String"
                  label="Start Date"
                ></v-datetime-picker>

                <v-datetime-picker
                  v-model="endDate"
                  datetime="String"
                  label="End Date"
                ></v-datetime-picker>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <br />
            <template>
              <div>
                <v-expansion-panels :value="1">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="header">Conditions :</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <h3 class="subheader">Cart :</h3>
                      <br />
                      <v-row v-if="promotion.condition.cartTotal != null">
                        <!-- Left -->
                        <v-col cols="6">
                          <v-select
                              outlined
                              v-model="promotion.condition.cartTotal.operator"
                              label="Operator"
                              :items="['', '>=']"
                          ></v-select>
                          <v-expansion-panels :value="1" v-if="promotion.condition.cartTotal != null">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="subheader">Apply To Product List :</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row v-if="promotion.condition.cartTotal.productList != null">
                                  <v-col cols="10">
                                    <input type="file" accept=".xlsx" @change="onFileImportSelected_11" ref="importFile_11">
                                    <div>import column header (prcode)</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="promotion.condition.cartTotal.productList=[]">delete</v-icon>
                                    <!-- <v-icon @click="newCategory">add</v-icon> -->
                                  </v-col>
                                </v-row>
                                <v-row v-if="promotion.condition.cartTotal.productList != null">
                                  <v-col cols="12">
                                    <div class="table_sub">
                                      <table class="table table-striped" width="100%">
                                        <thead class="head-dark" align-center>
                                          <tr>
                                            <th width="80%" style="text-align:left">Cprcode</th>
                                            <th width="20%" style="text-align:left">Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in promotion.condition.cartTotal.productList">
                                            <!-- <tr :key="excludeProduct"> -->
                                            <tr>
                                                <td>{{ item }}</td>
                                                <td>
                                                  <v-icon @click="removeItem(item, promotion.condition.cartTotal.productList)">delete</v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <br />
                          <v-expansion-panels :value="1" v-if="promotion.condition.cartTotal != null">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="subheader">Apply To Group List :</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row v-if="promotion.condition.cartTotal.groupList != null">
                                  <v-col cols="10">
                                    <input type="file" accept=".xlsx" @change="onFileImportSelected_12" ref="importFile_12">
                                    <div>import column header (groupid)</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="promotion.condition.cartTotal.groupList=[]">delete</v-icon>
                                    <!-- <v-icon @click="newCategory">add</v-icon> -->
                                  </v-col>
                                </v-row>
                                <v-row v-if="promotion.condition.cartTotal.groupList != null">
                                  <v-col cols="12">
                                    <div class="table_sub">
                                      <table class="table table-striped" width="100%">
                                        <thead class="head-dark" align-center>
                                          <tr>
                                            <th width="80%" style="text-align:left">GroupId</th>
                                            <th width="20%" style="text-align:left">Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in promotion.condition.cartTotal.groupList">
                                            <!-- <tr :key="item"> -->
                                            <tr>
                                                <td>{{ item }}</td>
                                                <td>
                                                  <v-icon @click="removeItem(item, promotion.condition.cartTotal.groupList)">delete</v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <br />
                          <v-divider></v-divider>
                          <br />
                          <v-expansion-panels :value="1" v-if="promotion.condition.cartTotal != null">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="subheader">Exclude Product List :</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row v-if="promotion.condition.cartTotal.excludeProduct != null">
                                  <v-col cols="10">
                                    <input type="file" accept=".xlsx" @change="onFileImportSelected_1" ref="importFile_1">
                                    <div>import column header (prcode)</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="promotion.condition.cartTotal.excludeProduct=[]">delete</v-icon>
                                    <!-- <v-icon @click="newCategory">add</v-icon> -->
                                  </v-col>
                                </v-row>
                                <v-row v-if="promotion.condition.cartTotal.excludeProduct != null">
                                  <v-col cols="12">
                                    <div class="table_sub">
                                      <table class="table table-striped" width="100%">
                                        <thead class="head-dark" align-center>
                                          <tr>
                                            <th width="80%" style="text-align:left">Cprcode</th>
                                            <th width="20%" style="text-align:left">Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in promotion.condition.cartTotal.excludeProduct">
                                            <!-- <tr :key="excludeProduct"> -->
                                            <tr>
                                                <td>{{ item }}</td>
                                                <td>
                                                  <v-icon @click="removeItem(item, promotion.condition.cartTotal.excludeProduct)">delete</v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <br />
                          <v-expansion-panels :value="1" v-if="promotion.condition.cartTotal != null">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="subheader">Exclude Group List :</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row v-if="promotion.condition.cartTotal.excludeGroupId != null">
                                  <v-col cols="10">
                                    <input type="file" accept=".xlsx" @change="onFileImportSelected_2" ref="importFile_2">
                                    <div>import column header (groupid)</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="promotion.condition.cartTotal.excludeGroupId=[]">delete</v-icon>
                                    <!-- <v-icon @click="newCategory">add</v-icon> -->
                                  </v-col>
                                </v-row>
                                <v-row v-if="promotion.condition.cartTotal.excludeGroupId != null">
                                  <v-col cols="12">
                                    <div class="table_sub">
                                      <table class="table table-striped" width="100%">
                                        <thead class="head-dark" align-center>
                                          <tr>
                                            <th width="80%" style="text-align:left">GroupId</th>
                                            <th width="20%" style="text-align:left">Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in promotion.condition.cartTotal.excludeGroupId">
                                            <!-- <tr :key="item"> -->
                                            <tr>
                                                <td>{{ item }}</td>
                                                <td>
                                                  <v-icon @click="removeItem(item, promotion.condition.cartTotal.excludeGroupId)">delete</v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>

                        </v-col>
                        <!-- Right -->
                        <v-col cols="6">
                          <v-text-field
                            outlined
                            v-model="promotion.condition.cartTotal.amount"
                            type="number"
                            label="Amount"
                          ></v-text-field>
                          <div class="table_store">
                            <table class="table table-striped" width="100%">
                              <thead class="head-dark" align-center>
                                <tr>
                                  <th width="70%" style="text-align:left" class="subheader">Exclude Promotion :</th>
                                  <th width="30%" style="text-align:left">
                                      <v-checkbox
                                          height="1"
                                          v-model="selectPromotionAll"
                                          label="Select"
                                          @change="onSelectPromotionAllChange"
                                      ></v-checkbox>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                  <template v-for="excludePromotion in excludePromotions">
                                  <!-- <tr :key="excludePromotion.promotion"> -->
                                  <tr>
                                      <td>{{ excludePromotion.promotion }}</td>
                                      <td>
                                      <v-checkbox
                                          height="1"
                                          v-model="excludePromotion.selected"
                                      ></v-checkbox>
                                      </td>
                                  </tr>
                                  </template>
                              </tbody>
                            </table>
                          </div>
                        </v-col>
                      </v-row>
                      <br />
                      <v-divider></v-divider>
                      <br />
                      <h3 class="subheader">Product :</h3>
                      <br />
                      <v-row v-if="promotion.condition.productQuantity != null">
                        <!-- Left -->
                        <v-col cols="6">
                          <v-select
                              outlined
                              v-model="promotion.condition.productQuantity.operator"
                              label="Operator"
                              :items="['', '=']"
                          ></v-select>
                          <v-expansion-panels :value="1" v-if="promotion.condition.productQuantity != null">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="subheader">Product List :</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row v-if="promotion.condition.productQuantity.productList != null">
                                  <v-col cols="10">
                                    <input type="file" accept=".xlsx" @change="onFileImportSelected_3" ref="importFile_3">
                                    <div>import column header (prcode)</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="promotion.condition.productQuantity.productList=[]">delete</v-icon>
                                    <!-- <v-icon @click="newCategory">add</v-icon> -->
                                  </v-col>
                                </v-row>
                                <v-row v-if="promotion.condition.productQuantity.productList != null">
                                  <v-col cols="12">
                                    <div class="table_sub">
                                      <table class="table table-striped" width="100%">
                                        <thead class="head-dark" align-center>
                                          <tr>
                                            <th width="80%" style="text-align:left">Cprcode</th>
                                            <th width="20%" style="text-align:left">Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in promotion.condition.productQuantity.productList">
                                            <!-- <tr :key="excludeProduct"> -->
                                            <tr>
                                                <td>{{ item }}</td>
                                                <td>
                                                  <v-icon @click="removeItem(item, promotion.condition.productQuantity.productList)">delete</v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                        <!-- Right -->
                        <v-col cols="6">
                          <v-text-field
                            outlined
                            v-model="promotion.condition.productQuantity.quantity"
                            type="number"
                            label="Quantity"
                          ></v-text-field>
                          <v-expansion-panels :value="1" v-if="promotion.condition.productQuantity != null">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="subheader">Group List :</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row v-if="promotion.condition.productQuantity.groupList != null">
                                  <v-col cols="10">
                                    <input type="file" accept=".xlsx" @change="onFileImportSelected_4" ref="importFile_4">
                                    <div>import column header (groupid)</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="promotion.condition.productQuantity.groupList=[]">delete</v-icon>
                                    <!-- <v-icon @click="newCategory">add</v-icon> -->
                                  </v-col>
                                </v-row>
                                <v-row v-if="promotion.condition.productQuantity.groupList != null">
                                  <v-col cols="12">
                                    <div class="table_sub">
                                      <table class="table table-striped" width="100%">
                                        <thead class="head-dark" align-center>
                                          <tr>
                                            <th width="80%" style="text-align:left">GroupId</th>
                                            <th width="20%" style="text-align:left">Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in promotion.condition.productQuantity.groupList">
                                            <!-- <tr :key="excludeProduct"> -->
                                            <tr>
                                                <td>{{ item }}</td>
                                                <td>
                                                  <v-icon @click="removeItem(item, promotion.condition.productQuantity.groupList)">delete</v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <br />
                <v-divider></v-divider>

                <v-expansion-panels :value="1" v-if="promotion.action != null">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="header">Actions :</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <h3 class="subheader">Discount :</h3>
                      <br />
                      <v-row v-if="promotion.action.discount != null">
                        <!-- Left -->
                        <v-col cols="6">
                          <v-select
                              outlined
                              v-model="promotion.action.discount.applyTo"
                              label="Apply To"
                              :items="applyTos"
                          ></v-select>
                          <v-select
                              outlined
                              v-model="promotion.action.discount.discountType"
                              label="Discount Type"
                              :items="discountTypes"
                          ></v-select>
                          <v-expansion-panels :value="1" v-if="promotion.action.discount != null">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="subheader">Apply To Product List :</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row v-if="promotion.action.discount.discountProductList != null">
                                  <v-col cols="10">
                                    <input type="file" accept=".xlsx" @change="onFileImportSelected_5" ref="importFile_5">
                                    <div>import column header (prcode)</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="promotion.action.discount.discountProductList=[]">delete</v-icon>
                                    <!-- <v-icon @click="newCategory">add</v-icon> -->
                                  </v-col>
                                </v-row>
                                <v-row v-if="promotion.action.discount.discountProductList != null">
                                  <v-col cols="12">
                                    <div class="table_sub">
                                      <table class="table table-striped" width="100%">
                                        <thead class="head-dark" align-center>
                                          <tr>
                                            <th width="80%" style="text-align:left">Cprcode</th>
                                            <th width="20%" style="text-align:left">Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in promotion.action.discount.discountProductList">
                                            <!-- <tr :key="excludeProduct"> -->
                                            <tr>
                                                <td>{{ item }}</td>
                                                <td>
                                                  <v-icon @click="removeItem(item, promotion.action.discount.discountProductList)">delete</v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                        <!-- Right -->
                        <v-col cols="6">
                          <v-text-field
                            outlined
                            v-model="promotion.action.discount.amount"
                            type="number"
                            label="Amount"
                          ></v-text-field>
                          <v-expansion-panels :value="1" v-if="promotion.action.discount != null">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="subheader">Apply To Group List :</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row v-if="promotion.action.discount.discountGroupList != null">
                                  <v-col cols="10">
                                    <input type="file" accept=".xlsx" @change="onFileImportSelected_6" ref="importFile_6">
                                    <div>import column header (groupid)</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="promotion.action.discount.discountGroupList=[]">delete</v-icon>
                                    <!-- <v-icon @click="newCategory">add</v-icon> -->
                                  </v-col>
                                </v-row>
                                <v-row v-if="promotion.action.discount.discountGroupList != null">
                                  <v-col cols="12">
                                    <div class="table_sub">
                                      <table class="table table-striped" width="100%">
                                        <thead class="head-dark" align-center>
                                          <tr>
                                            <th width="80%" style="text-align:left">GroupId</th>
                                            <th width="20%" style="text-align:left">Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in promotion.action.discount.discountGroupList">
                                            <!-- <tr :key="excludeProduct"> -->
                                            <tr>
                                                <td>{{ item }}</td>
                                                <td>
                                                  <v-icon @click="removeItem(item, promotion.action.discount.discountGroupList)">delete</v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                      <br />
                      <v-divider></v-divider>
                      <br />
                      <h3 class="subheader">Bogo :</h3>
                      <br />
                      <v-row v-if="promotion.action.getFreeProduct != null">
                        <!-- Left -->
                        <v-col cols="6">
                          <v-text-field
                            outlined
                            v-model="promotion.action.getFreeProduct.quantity"
                            type="number"
                            label="Quantity"
                          ></v-text-field>
                          <v-expansion-panels :value="1" v-if="promotion.action.getFreeProduct != null">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="subheader">Apply To Product List :</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row v-if="promotion.action.getFreeProduct.productList != null">
                                  <v-col cols="10">
                                    <input type="file" accept=".xlsx" @change="onFileImportSelected_7" ref="importFile_7">
                                    <div>import column header (prcode)</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="promotion.action.getFreeProduct.productList=[]">delete</v-icon>
                                    <!-- <v-icon @click="newCategory">add</v-icon> -->
                                  </v-col>
                                </v-row>
                                <v-row v-if="promotion.action.getFreeProduct.productList != null">
                                  <v-col cols="12">
                                    <div class="table_sub">
                                      <table class="table table-striped" width="100%">
                                        <thead class="head-dark" align-center>
                                          <tr>
                                            <th width="80%" style="text-align:left">Cprcode</th>
                                            <th width="20%" style="text-align:left">Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in promotion.action.getFreeProduct.productList">
                                            <!-- <tr :key="excludeProduct"> -->
                                            <tr>
                                                <td>{{ item }}</td>
                                                <td>
                                                  <v-icon @click="removeItem(item, promotion.action.getFreeProduct.productList)">delete</v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                        <!-- Right -->
                        <v-col cols="6">
                          <v-expansion-panels :value="1" v-if="promotion.action.getFreeProduct != null">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="subheader">Apply To Group List :</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row v-if="promotion.action.getFreeProduct.groupList != null">
                                  <v-col cols="10">
                                    <input type="file" accept=".xlsx" @change="onFileImportSelected_8" ref="importFile_8">
                                    <div>import column header (groupid)</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="promotion.action.getFreeProduct.groupList=[]">delete</v-icon>
                                    <!-- <v-icon @click="newCategory">add</v-icon> -->
                                  </v-col>
                                </v-row>
                                <v-row v-if="promotion.action.getFreeProduct.groupList != null">
                                  <v-col cols="12">
                                    <div class="table_sub">
                                      <table class="table table-striped" width="100%">
                                        <thead class="head-dark" align-center>
                                          <tr>
                                            <th width="80%" style="text-align:left">GroupId</th>
                                            <th width="20%" style="text-align:left">Delete</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in promotion.action.getFreeProduct.groupList">
                                            <!-- <tr :key="excludeProduct"> -->
                                            <tr>
                                                <td>{{ item }}</td>
                                                <td>
                                                  <v-icon @click="removeItem(item, promotion.action.getFreeProduct.groupList)">delete</v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-switch
                            v-model="promotion.action.freeShipping"
                            label="Free Shipping"
                            color="success"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <br />
                <v-row>
                  <!-- Left -->
                  <v-col cols="6">
                    <v-expansion-panels :value="1" v-if="promotion.customers != null">
                      <v-expansion-panel>
                        <v-expansion-panel-header class="subheader">Customers :</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row>
                            <v-col cols="10">
                              <input type="file" accept=".xlsx" @change="onFileImportSelected_9" ref="importFile_9">
                              <div>import column header (customerid)</div>
                            </v-col>
                            <v-col cols="2">
                              <v-icon @click="promotion.customers=[]">delete</v-icon>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <div class="table_sub">
                                <table class="table table-striped" width="100%">
                                  <thead class="head-dark" align-center>
                                    <tr>
                                      <th width="80%" style="text-align:left">CustomerId</th>
                                      <th width="20%" style="text-align:left">Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                      <template v-for="item in promotion.customers">
                                      <!-- <tr :key="excludeProduct"> -->
                                      <tr>
                                          <td>{{ item }}</td>
                                          <td>
                                            <v-icon @click="removeItem(item, promotion.customers)">delete</v-icon>
                                          </td>
                                      </tr>
                                      </template>
                                  </tbody>
                                </table>
                              </div>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <br />
                    <!-- <v-divider></v-divider>
                    <br /> -->
                  </v-col>
                  <!-- Right -->
                  <v-col cols="6">
                    <v-expansion-panels :value="1" v-if="promotion.incompatibleCoupons != null">
                      <v-expansion-panel>
                        <v-expansion-panel-header class="subheader">Incompatible Coupons :</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row>
                            <v-col cols="10">
                              <input type="file" accept=".xlsx" @change="onFileImportSelected_10" ref="importFile_10">
                              <div>import column header (couponid)</div>
                            </v-col>
                            <v-col cols="2">
                              <v-icon @click="promotion.incompatibleCoupons=[]">delete</v-icon>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <div class="table_sub">
                                <table class="table table-striped" width="100%">
                                  <thead class="head-dark" align-center>
                                    <tr>
                                      <th width="80%" style="text-align:left">CouponId</th>
                                      <th width="20%" style="text-align:left">Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                      <template v-for="item in promotion.incompatibleCoupons">
                                      <!-- <tr :key="excludeProduct"> -->
                                      <tr>
                                          <td>{{ item }}</td>
                                          <td>
                                            <v-icon @click="removeItem(item, promotion.incompatibleCoupons)">delete</v-icon>
                                          </td>
                                      </tr>
                                      </template>
                                  </tbody>
                                </table>
                              </div>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>

              </div>
            </template>
            <br />
            <v-divider></v-divider>
            <br />
            <v-row>
              <v-spacer></v-spacer>
              <v-btn class="mr-4" @click="cancel">
                Cancel
              </v-btn>

              <v-btn color="primary" type="submit">
                Confirm
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { groupBucketName } from "@/services/constants";
import XLSX from 'xlsx';
import Vue from 'vue'
import axios from "axios";
import moment from 'moment'
import DatetimePicker from 'vuetify-datetime-picker'
import api from "@/services/lambdaApi";

Vue.use(DatetimePicker)

export default {
  name: "promotion-edit",
  // components:{
  // },
  data: () => ({
    loading: false,
    selectAll: false,
    selectPromotionAll: false,
    startDate: "",
    endDate: "",
    couponCodeTypes: ["FIXED", "AUTO_GENERATED", "NO_CODE"],
    applyTos: ["CART", "PRODUCT"],
    discountTypes: ["FIXED_AMOUNT", "PERCENT"],
    excludePromotions: [
      { promotion: "BOGO", selected: false},
      { promotion: "TWO4", selected: false},
      { promotion: "SPECIALPRICE", selected: false}
    ],
    avail_store: [],
    promotion: {
      couponId: 0,
      couponName: "",
      couponDescription: "",
      active: false,
      couponCodeType: "",
      couponCode: "",
      usesPerCoupon: 0,
      numberCouponUsed: 0,
      usesPerCustomer: 0,
      startDate: "",
      endDate: "",
      newCustomer: false,
      condition: {
        cartTotal: {
          operator: "",
          amount: 0,
          productList: [], 
          groupList: [],
          excludeProduct: [],
          excludeGroupId: [],
          excludePromotion: []
        },
        productQuantity: {
          productList: [], 
          groupList: [], 
          operator: "", 
          quantity: 0
          }
      },
      action: {
        discount: {
          applyTo: "",
          discountProductList: [],
          discountGroupList: [],
          discountType: "",
          amount: 0
        },
        getFreeProduct: {
          productList: [], 
          groupList: [],
          quantity: 0
        },
        freeShipping: false
      },
      customers: [],
      incompatibleCoupons: [],
      branches: []
    },
  }),
  async mounted() {
    try {
      this.loading = true;

      let filter = { fileName: "online_stores.json" };
      let avail_store = await api.getS3Json(filter);
      this.avail_store = avail_store.data;

      // console.log("--- avail_store ---", JSON.stringify(this.avail_store));

      let couponId = parseInt(this.$route.params.id ?? 0);
      if (couponId != 0) {
        let data = { couponId: couponId }
        let result = await api.getCouponById(data);
        // console.log("--- data ---", JSON.stringify(result.data));
        this.startDate = result.data.startDate;
        this.endDate = result.data.endDate;
        this.promotion = result.data;
        // console.log("--- load ---", JSON.stringify(this.promotion));
        this.setAvailStore();
        this.setExcludePromotions();
      }
    // } catch (error) {
    }
    finally {
      this.loading = false;
    } 
  },

  methods: {
    removeItem: function (value, objectList) {
      let item = objectList.find(d => d === value);
      if (item) {
        let index = objectList.indexOf(item);
        if (index >= 0) {
          objectList.splice(index, 1)
        }
      }
    },
    onSelectPromotionAllChange() {
      this.excludePromotions.forEach(item => {
        item.selected = this.selectPromotionAll;
      });
    },
    setExcludePromotions() {
        let exPromotions = this.promotion.condition.cartTotal.excludePromotion;
        exPromotions.forEach(item => {
          let mItem = this.excludePromotions.find(d => d.promotion === item);
          if (mItem) {
            mItem.selected = true;
          }
        });
    },
    setAvailStore() {
        let availStores = this.promotion.branches;
        availStores.forEach(item => {
          let mItem = this.avail_store.find(d => d.storeCode === item);
          if (mItem) {
            mItem.visibility = true;
          }
        });
    },
    onSelectAllChange() {
      this.avail_store.forEach(item => {
        item.visibility = this.selectAll;
      });
    },

    onFileImportSelected_1(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_1(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_1.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_1(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.prcode > 0) {
                var mItem = vm.promotion.condition.cartTotal.excludeProduct.find(d => d === item.prcode);
                if (!mItem) {
                  vm.promotion.condition.cartTotal.excludeProduct.push(parseInt(item.prcode ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_2(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_2(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_2.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_2(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.groupid > 0) {
                var mItem = vm.promotion.condition.cartTotal.excludeGroupId.find(d => d === item.groupid);
                if (!mItem) {
                  vm.promotion.condition.cartTotal.excludeGroupId.push(parseInt(item.groupid ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_3(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_3(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_3.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_3(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            // let prcodeList = [];
            items.forEach(item => {
              if (item.prcode > 0) {
                var mItem = vm.promotion.condition.productQuantity.productList.find(d => d === item.prcode);
                if (!mItem) {
                  vm.promotion.condition.productQuantity.productList.push(parseInt(item.prcode ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_4(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_4(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_4.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_4(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            // let prcodeList = [];
            items.forEach(item => {
              if (item.groupid > 0) {
                var mItem = vm.promotion.condition.productQuantity.groupList.find(d => d === item.groupid);
                if (!mItem) {
                  vm.promotion.condition.productQuantity.groupList.push(parseInt(item.groupid ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_5(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_5(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_5.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_5(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.prcode > 0) {
                var mItem = vm.promotion.action.discount.discountProductList.find(d => d === item.prcode);
                if (!mItem) {
                  vm.promotion.action.discount.discountProductList.push(parseInt(item.prcode ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_6(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_6(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_6.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_6(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.groupid > 0) {
                var mItem = vm.promotion.action.discount.discountGroupList.find(d => d === item.groupid);
                if (!mItem) {
                  vm.promotion.action.discount.discountGroupList.push(parseInt(item.groupid ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_7(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_7(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_7.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_7(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.prcode > 0) {
                var mItem = vm.promotion.action.getFreeProduct.productList.find(d => d === item.prcode);
                if (!mItem) {
                  vm.promotion.action.getFreeProduct.productList.push(parseInt(item.prcode ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_8(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_8(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_8.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_8(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.groupid > 0) {
                var mItem = vm.promotion.action.getFreeProduct.groupList.find(d => d === item.groupid);
                if (!mItem) {
                  vm.promotion.action.getFreeProduct.groupList.push(parseInt(item.groupid ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_9(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_9(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_9.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_9(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.customerid > 0) {
                var mItem = vm.promotion.customers.find(d => d === item.customerid);
                if (!mItem) {
                  vm.promotion.customers.push(parseInt(item.customerid ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_10(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_10(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_10.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_10(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.couponid > 0) {
                var mItem = vm.promotion.incompatibleCoupons.find(d => d === item.couponid);
                if (!mItem) {
                  vm.promotion.incompatibleCoupons.push(parseInt(item.couponid ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_11(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_11(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_11.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_11(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.prcode > 0) {
                var mItem = vm.promotion.condition.cartTotal.productList.find(d => d === item.prcode);
                if (!mItem) {
                  vm.promotion.condition.cartTotal.productList.push(parseInt(item.prcode ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    onFileImportSelected_12(e) {
      try {
        // this.loading = true;
        var files = e.target.files || e.dataTransfer.files;
        if (files.length == 0)
          return;
        this.previewFiles_12(files[0]);
      // } catch (error) {
      }
      finally {
        this.$refs.importFile_12.value = "";
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
      }
    },
    previewFiles_12(file) { // detect file event
        var reader = new FileReader();
        var vm = this;
        reader.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            // console.log(XLSX.utils.sheet_to_json(worksheet));
            let items = XLSX.utils.sheet_to_json(worksheet);
            // console.log('-- items --', items);
            items.forEach(item => {
              if (item.groupid > 0) {
                var mItem = vm.promotion.condition.cartTotal.groupList.find(d => d === item.groupid);
                if (!mItem) {
                  vm.promotion.condition.cartTotal.groupList.push(parseInt(item.groupid ?? 0));
                }
              }
            });
        };
        reader.readAsArrayBuffer(file);
    },

    cancel() {
      this.$router.back();
    },
    async submit() {
      try {
        this.loading = true;
        let data = this.promotion;
        data.startDate = this.startDate == null || this.startDate == "" ? "" : moment(this.startDate).format('YYYY-MM-DD HH:mm');
        data.endDate = this.endDate == null || this.endDate == "" ? "" : moment(this.endDate).format('YYYY-MM-DD HH:mm');
        data.usesPerCoupon = parseInt(data.usesPerCoupon);
        data.numberCouponUsed = parseInt(data.numberCouponUsed);
        data.usesPerCustomer = parseInt(data.usesPerCustomer);

        try {
          data.condition.cartTotal.amount = parseFloat(data.condition.cartTotal.amount);
        } catch (error) {
        }
        try {
          data.condition.productQuantity.quantity = parseInt(data.condition.productQuantity.quantity);
        } catch (error) {
        }

        try {
          data.action.discount.amount = parseFloat(data.action.discount.amount);
        } catch (error) {
        }
        try {
          data.action.getFreeProduct.quantity = parseInt(data.action.getFreeProduct.quantity);
        } catch (error) {
        }
        
        let avail_store = [];
        this.avail_store.forEach(item => {
            if (item.visibility) {
              avail_store.push(item.storeCode);
            }
        });
        data.branches = avail_store;
        let excludePromotions = []
        this.excludePromotions.forEach(item => {
            if (item.selected) {
              excludePromotions.push(item.promotion);
            }
        });
        data.condition.cartTotal.excludePromotion = excludePromotions;

        console.log('request', JSON.stringify(data));
        // return;
        let result;
        if (data.couponId == 0) {
          result = await api.createCoupon(data);
        }
        else {
          result = await api.updateCoupon(data);
        }
        console.log('result', JSON.stringify(result));
        // let response = result.data;
        // console.log('response', JSON.stringify(response));

        this.$router.back();
      // } catch (error) {
      }
      finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
.table_store {
   height: 320px;
   overflow: auto;
}
.table_sub {
   height: 220px;
   overflow: auto;
}
.subject {
  color: #0D47A1;
}
.header {
  font-size: 18px;
  font-weight: bold;
  color: #D50000;
}
.subheader {
  font-size: 18px;
  font-weight: bold;
  color: #0D47A1;
}
/* expansion-panel */
/* ::v-deep { */
    /* .v-expansion-panel__header {
        padding: 0;
        flex: 0;
        fon
        > span:first-child {
            margin: 0 10px;
        }
    }
    /* no expansion-panel icon */
    /* .v-expansion-panel__header__icon {
        display: none;
    }  */
</style>